import { ActionIcon, Avatar, Group, Stack, Text } from "@mantine/core"
import { useHover, useMediaQuery } from "@mantine/hooks"
import { IconArchive, IconMailbox } from "@tabler/icons-react"
import { useContext } from "react"

import {
  Notification,
  NotificationStatus,
} from "@ensol/types/entities/notifications"

import { NotificationContent } from "@ensol/shared/components/layout/Notifications/NotificationContent"
import { NotificationsProviderContext } from "@ensol/shared/components/layout/Notifications/Provider"
import { colors } from "@ensol/shared/styles/colors"
import { devices } from "@ensol/shared/styles/theme"
import {
  formatDate,
  SHORT_DISPLAY_DATE_TIME_FORMAT,
} from "@ensol/shared/utils/format"

type Props = {
  notification: Notification
  onDrawerClose: () => void
}

export const NotificationRow = ({ notification, onDrawerClose }: Props) => {
  const isMobile = useMediaQuery(devices.sm)
  const { hovered: isHovered, ref } = useHover()

  const { updateNotificationStatus } = useContext(NotificationsProviderContext)

  const isArchived = notification.archivedAt !== null

  return (
    <Group
      ref={ref}
      key={notification.id}
      align="flex-start"
      gap="12"
      wrap="nowrap"
      p="20px 12px 12px 12px"
      style={{ borderBottom: `1px solid ${colors.gray[300]}` }}
    >
      <Avatar
        src={notification.notifier?.avatarUrl ?? "/ensolLogo.svg"}
        size="24"
      />
      <Stack gap="8">
        <NotificationContent
          notification={notification}
          onDrawerClose={onDrawerClose}
        />
        <Text size="sm" c="gray.7">
          {formatDate(notification.createdAt, SHORT_DISPLAY_DATE_TIME_FORMAT)}
        </Text>
      </Stack>
      {(isMobile || isHovered) && (
        <ActionIcon
          variant="outline"
          bg="white"
          pos="absolute"
          right={isMobile ? 0 : 12}
          color="gray.7"
          p="2"
          size="24"
          style={{ borderColor: colors.gray[400] }}
          onClick={() =>
            updateNotificationStatus({
              notificationId: notification.id,
              status: isArchived
                ? NotificationStatus.ACTIVE
                : NotificationStatus.ARCHIVED,
            })
          }
        >
          {isArchived ? (
            <IconMailbox stroke={1.5} />
          ) : (
            <IconArchive stroke={1.5} />
          )}
        </ActionIcon>
      )}
    </Group>
  )
}
