import { Group, MantineSize, Text, ThemeIcon } from "@mantine/core"
import { IconBolt, IconUsers } from "@tabler/icons-react"

import {
  computeInstallationCharacteristics,
  InstallationCharacteristic,
  InstallationCharacteristicsInput,
} from "@ensol/shared/entities/installations/characteristics"
import { getCurrent } from "@ensol/shared/material/currentType"

type Props = {
  installation: InstallationCharacteristicsInput
  installers: string[]
  size?: MantineSize
}

export const InstallationCharacteristics = ({
  installation,
  installers,
  size = "sm",
}: Props) => {
  const {
    photovoltaicInstallation,
    batteryInstallation,
    evChargerInstallation,
    controlDeviceInstallations,
    extraWorks,
  } = computeInstallationCharacteristics(installation)

  return (
    <>
      <CharacteristicRow
        label="Courant"
        Icon={IconBolt}
        value={getCurrent(installation.house.currentType).name}
        size={size}
      />
      {[
        ...photovoltaicInstallation,
        ...batteryInstallation,
        ...evChargerInstallation,
        ...controlDeviceInstallations,
        ...extraWorks,
      ].map(({ label, value, Icon }) => (
        <CharacteristicRow
          key={label}
          label={label}
          Icon={Icon}
          value={value}
          size={size}
        />
      ))}
      {installers.length > 0 && (
        <CharacteristicRow
          label={`Installateur${installers.length > 1 ? "s" : ""}`}
          Icon={IconUsers}
          value={installers.join(", ")}
        />
      )}
    </>
  )
}

const CharacteristicRow = ({
  Icon,
  label,
  value,
  size = "sm",
}: InstallationCharacteristic & { size?: MantineSize }) => {
  if (value === null) {
    return null
  }

  return (
    <Group gap="4" aria-label={label}>
      <ThemeIcon size={18} color="gray.6" title={label}>
        <Icon />
      </ThemeIcon>
      <Text size={size} c="gray.6">
        {value}
      </Text>
    </Group>
  )
}
