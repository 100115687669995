import { Helmet } from "react-helmet-async"

import { PageLayout } from "@ensol/shared/components/layout/PageLayout"
import { RoleGroups, Role } from "@ensol/shared/entities/users"

import { DisplayRoleGuard } from "@ensol/entool/components/DisplayRoleGuard"

import { InstallerProjectsOverview } from "./Installers"
import { ProjectsOperationsOverview } from "./Operations"

export const ProjectsOverview = () => {
  return (
    <PageLayout
      title="Tous les projets"
      contentContainerProps={{ px: 20, mih: "unset" }}
    >
      <Helmet>
        <title>Tous les projets | Entool</title>
      </Helmet>

      <DisplayRoleGuard
        authorizedGroups={[
          { group: RoleGroups.PARTNER, minimumRole: Role.INSTALLER },
        ]}
      >
        <InstallerProjectsOverview />
      </DisplayRoleGuard>
      <DisplayRoleGuard
        authorizedGroups={[
          { group: RoleGroups.ENSOL, minimumRole: Role.EMPLOYEE },
        ]}
      >
        <ProjectsOperationsOverview />
      </DisplayRoleGuard>
    </PageLayout>
  )
}
