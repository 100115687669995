import { Button, Stack, ThemeIcon } from "@mantine/core"
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react"
import { useLocalStorage } from "@uidotdev/usehooks"
import { ReactNode } from "react"
import { Location, useLocation } from "react-router-dom"

import { MenuItem } from "./Item"
import classes from "./Menu.module.css"
import { MenuItem as MenuItemType, SubMenuItem } from "./types"

type MenuProps = {
  closeNavbar?: () => void
  toggleDesktop?: () => void
  desktopOpened?: boolean
  menuItems: MenuItemType[]
}

const COMMON_MENU_BUTTON_PROPS = {
  variant: "subtle",
  color: "dark.4",
  w: "100%",
  fw: 400,
}

const CLOSED_NAVBAR_BUTTON_PROPS = {
  ...COMMON_MENU_BUTTON_PROPS,
  p: 0,
  h: 60,
  size: "11",
  styles: {
    inner: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    section: {
      marginTop: 8,
      marginRight: 0,
    },
  },
} as const

const OPENED_NAVBAR_BUTTON_PROPS = {
  ...COMMON_MENU_BUTTON_PROPS,
  h: 32,
  mt: 12,
  w: "100%",
  justify: "flex-start",
  px: 12,
  size: "md",
  fw: 400,
}

const SELECTED_NAVBAR_BUTTON_PROPS = {
  color: "blue.5",
  bg: "gray.0",
}

const isItemOpen = (id: string, location: Location) =>
  new RegExp(id, "g").test(`${location.pathname}${location.search}`)

export const Menu = ({
  desktopOpened,
  closeNavbar,
  toggleDesktop,
  menuItems,
}: MenuProps) => {
  const location = useLocation()

  return menuItems.map(({ id, to, name, Icon, isExternal, subItems }) =>
    to !== undefined ? (
      <MenuItem
        key={to}
        id={id}
        name={name}
        to={to}
        isExternal={isExternal}
        onClick={closeNavbar}
        leftSection={<Icon size={16} />}
        {...(desktopOpened === false
          ? CLOSED_NAVBAR_BUTTON_PROPS
          : OPENED_NAVBAR_BUTTON_PROPS)}
        {...(id && isItemOpen(id, location)
          ? SELECTED_NAVBAR_BUTTON_PROPS
          : {})}
      />
    ) : (
      <SubMenu
        key={name}
        id={id}
        name={name}
        Icon={<Icon size={16} />}
        subItems={subItems}
        onClick={closeNavbar}
        desktopOpened={desktopOpened}
        toggleDesktop={toggleDesktop}
      />
    ),
  )
}

type SubMenuProps = {
  id?: string
  name: string
  Icon: ReactNode
  subItems: SubMenuItem[]
  onClick?: () => void
  toggleDesktop?: () => void
  desktopOpened?: boolean
}

const SubMenu = ({
  id,
  name,
  Icon,
  subItems,
  toggleDesktop,
  desktopOpened,
  onClick,
}: SubMenuProps) => {
  const location = useLocation()

  const [isSubMenuOpen, setIsSubMenuOpen] = useLocalStorage(
    `${name} : isSubMenuOpen`,
    true,
  )

  return (
    <>
      <Button
        classNames={classes}
        leftSection={Icon}
        onClick={() => {
          if (desktopOpened === false) {
            toggleDesktop && toggleDesktop()
            setIsSubMenuOpen(true)
          } else setIsSubMenuOpen(!isSubMenuOpen)
        }}
        {...(desktopOpened === false
          ? CLOSED_NAVBAR_BUTTON_PROPS
          : OPENED_NAVBAR_BUTTON_PROPS)}
        {...(desktopOpened === false && id && isItemOpen(id, location)
          ? SELECTED_NAVBAR_BUTTON_PROPS
          : {})}
        rightSection={
          desktopOpened === false ? null : isSubMenuOpen ? (
            <IconChevronUp size={16} />
          ) : (
            <IconChevronDown size={16} />
          )
        }
      >
        {name}
      </Button>
      {isSubMenuOpen && desktopOpened !== false && (
        <Stack pl={12} gap={0}>
          {subItems.map(({ id, to, name, Icon, isExternal, color }) => (
            <MenuItem
              key={to}
              classNames={classes}
              id={id}
              name={name}
              to={to}
              isExternal={isExternal}
              {...OPENED_NAVBAR_BUTTON_PROPS}
              {...(id && isItemOpen(id, location)
                ? SELECTED_NAVBAR_BUTTON_PROPS
                : {})}
              leftSection={
                color ? (
                  <ThemeIcon color={color} variant="light" p={2}>
                    <Icon />
                  </ThemeIcon>
                ) : (
                  <Icon size={16} />
                )
              }
              onClick={() => onClick?.()}
            />
          ))}
        </Stack>
      )}
    </>
  )
}
