import { BackgroundImage, Badge, Box, Stack } from "@mantine/core"
import { ReactNode } from "react"

import { BG_LINEAR_GRADIENT } from "@ensol/entool/styles/theme"

type Props = {
  action?: ReactNode
  badge?: string
  pictureUrl: string
  imgMaw?: number
  w?: number | string
  h?: number | string
}

export const MaterialImage = ({
  action,
  badge,
  pictureUrl,
  imgMaw,
  w = "100%",
  h = 250,
}: Props) => {
  return (
    <Box
      w={w}
      h={h}
      bg={BG_LINEAR_GRADIENT}
      style={{ borderRadius: 4, justifyContent: "center", flexShrink: 0 }}
      pos="relative"
      display="flex"
    >
      {action && (
        <Stack pos="absolute" top={8} left={8}>
          {action}
        </Stack>
      )}
      {badge && (
        <Badge pos="absolute" top={8} right={8} size="lg">
          {badge}
        </Badge>
      )}
      <BackgroundImage
        w="100%"
        h="100%"
        maw={imgMaw}
        src={pictureUrl}
        style={{
          backgroundPosition: "top center",
          backgroundRepeat: "no-repeat",
        }}
      />
    </Box>
  )
}
