import {
  GoogleCalendarEventType,
  GoogleResponses,
} from "@ensol/types/endpoints/google"
import { ProjectResponses } from "@ensol/types/endpoints/projects"

import {
  ProjectEventStatus,
  ProjectEventType,
} from "@ensol/shared/entities/projects/events"

import { PublicHolidaysEvents } from "@ensol/entool/queries/calendars"
import {
  EVENT_STATUSES_COLORS,
  EVENT_WARNING_BORDER_COLOR,
  EXTERNAL_EVENT_COLOR,
  GOOGLE_CALENDAR_TYPE_BORDER_COLORS,
  PUBLIC_HOLIDAY_BORDER_COLOR,
} from "@ensol/entool/utils/projects/events"

import { EMPTY_INSTALLER_RESOURCE_ID } from "./"

export const formatProjectsEvents = (
  projectsEventsResponse: ProjectResponses.ProjectsEvents,
) =>
  projectsEventsResponse.events.map(
    ({ type, start, end, status, warning, metadata }) => ({
      id: `${metadata.projectId}-${type}`,
      title: `${warning ? "⚠️ " : ""}${metadata.clientName}${metadata.installerName ? ` , ${metadata.installerName}` : ""}`,
      start,
      end,
      resourceId: metadata.installerId ?? EMPTY_INSTALLER_RESOURCE_ID,
      allDay:
        type === ProjectEventType.INSTALLATION ||
        type === ProjectEventType.INSTALLATION_NEW_VISIT,
      textColor: status === ProjectEventStatus.PROPOSAL ? "black" : "white",
      backgroundColor: EVENT_STATUSES_COLORS[status],
      borderColor: warning
        ? EVENT_WARNING_BORDER_COLOR
        : EVENT_STATUSES_COLORS[status],
      startEditable:
        status !== ProjectEventStatus.PROPOSAL &&
        status !== ProjectEventStatus.COMPLETED,
      durationEditable:
        type === ProjectEventType.INSTALLATION &&
        status !== ProjectEventStatus.PROPOSAL &&
        status !== ProjectEventStatus.COMPLETED,
      extendedProps: { ...metadata, type, status, warning },
    }),
  )

export const formatGoogleCalendarEvents = ({
  events,
  calendarType,
}: {
  events: GoogleResponses.CalendarEvent[]
  calendarType: GoogleCalendarEventType
}) => {
  return events.map(({ name, start, end, isAllDay, installerId }) => ({
    title: name,
    start,
    end,
    allDay: isAllDay,
    backgroundColor: "white",
    resourceId: installerId,
    textColor: EXTERNAL_EVENT_COLOR,
    borderColor: GOOGLE_CALENDAR_TYPE_BORDER_COLORS[calendarType],
  }))
}

export const formatPublicHolidayEvents = (
  publicHolidaysResponse: PublicHolidaysEvents,
) =>
  Object.entries(publicHolidaysResponse).map(([date, name]) => ({
    title: name,
    date: new Date(date),
    allDay: true,
    backgroundColor: "white",
    textColor: EXTERNAL_EVENT_COLOR,
    borderColor: PUBLIC_HOLIDAY_BORDER_COLOR,
  }))
