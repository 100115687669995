import { BatteryInstallation } from "@ensol/types/entities/batteryInstallation"
import { EvChargerInstallation } from "@ensol/types/entities/evChargerInstallation"
import { PhotovoltaicInstallation } from "@ensol/types/entities/photovoltaicInstallation"

import { getBattery } from "@ensol/shared/material/batteries"
import { getEvCharger } from "@ensol/shared/material/evCharger"
import { getPanel } from "@ensol/shared/material/photovoltaic/panels"

export const computeInstallationName = ({
  photovoltaicInstallation,
  batteryInstallation,
  evChargerInstallation,
}: {
  photovoltaicInstallation: PhotovoltaicInstallation<{
    select: { panelType: true; panelsCount: true }
  }> | null
  batteryInstallation: BatteryInstallation<{
    select: { type: true; count: true }
  }> | null
  evChargerInstallation: EvChargerInstallation<{
    select: { type: true }
  }> | null
}) => {
  const productsName = new Array<string>()

  if (photovoltaicInstallation !== null) {
    productsName.push(
      computePhotovoltaicInstallationName(photovoltaicInstallation),
    )
  }

  if (batteryInstallation !== null) {
    productsName.push(computeBatteryInstallationName(batteryInstallation))
  }

  if (evChargerInstallation !== null) {
    productsName.push(computeEvChargerInstallationName(evChargerInstallation))
  }

  return productsName.join(" - ")
}

const computePhotovoltaicInstallationName = ({
  panelType,
  panelsCount,
}: PhotovoltaicInstallation<{
  select: { panelType: true; panelsCount: true }
}>) => {
  const panel = getPanel(panelType)

  return `${panel.power * panelsCount} kWc - ${panelsCount} panneaux ${panel.power * 1000}Wc`
}

const computeBatteryInstallationName = ({
  type,
  count,
}: BatteryInstallation<{ select: { type: true; count: true } }>) => {
  const battery = getBattery(type)

  return `${count > 1 ? `${count} × ` : ""}Batterie ${battery.capacity} kWh`
}

const computeEvChargerInstallationName = ({
  type,
}: EvChargerInstallation<{ select: { type: true } }>) => {
  const evCharger = getEvCharger(type)

  return `Borne ${evCharger.power} kW`
}
