import { ToulouseMetropolisStep } from "@ensol/shared/entities/projects/processes"

import { StepComponentGetter } from "@ensol/entool/components/entities/Project/ProcessView"

import { ToSent } from "./1-ToSent"
import { Sent } from "./2-Sent"
import { Validated } from "./3-Validated"

export const getToulouseMetropolisStepComponent: StepComponentGetter = (
  currentStep,
) => {
  switch (currentStep) {
    case ToulouseMetropolisStep.REQUEST_TO_SENT:
      return { StepComponent: ToSent }
    case ToulouseMetropolisStep.REQUEST_SENT:
      return { StepComponent: Sent }
    case ToulouseMetropolisStep.REQUEST_VALIDATED:
      return { StepComponent: Validated }
    default:
      return { StepComponent: null }
  }
}
