import { Drawer, Tabs, Text } from "@mantine/core"

import {
  Notification,
  NotificationStatus,
} from "@ensol/types/entities/notifications"

import { NotificationsList } from "./NotificationsList"

type Props = {
  activeNotifications: Notification[]
  archivedNotifications: Notification[]
  isOpen: boolean
  onClose: () => void
  activeTab: NotificationStatus
  setActiveTab: (tab: NotificationStatus) => void
}

export const NotificationsDrawer = ({
  activeNotifications,
  archivedNotifications,
  isOpen,
  onClose,
  activeTab,
  setActiveTab,
}: Props) => (
  <Drawer
    styles={{
      content: { display: "flex", flexDirection: "column" },
      body: { display: "flex", minHeight: 0, flex: 1 },
      title: { fontSize: "18px", fontWeight: 500 },
    }}
    opened={isOpen}
    onClose={onClose}
    position="right"
    title="Notifications"
  >
    <Tabs
      value={activeTab}
      onChange={(tab) => setActiveTab(tab as NotificationStatus)}
      styles={{
        root: {
          display: "flex",
          flexDirection: "column",
          minHeight: 0,
          flex: 1,
        },
        panel: {
          display: "flex",
          minHeight: 0,
          flex: 1,
        },
      }}
    >
      <Tabs.List grow>
        <Tabs.Tab value={NotificationStatus.ACTIVE}>
          <Text size="md">Récentes</Text>
        </Tabs.Tab>
        <Tabs.Tab value={NotificationStatus.ARCHIVED}>
          <Text size="md">Archivées</Text>
        </Tabs.Tab>
      </Tabs.List>
      <Tabs.Panel value={NotificationStatus.ACTIVE}>
        <NotificationsList
          notifications={activeNotifications}
          onDrawerClose={onClose}
        />
      </Tabs.Panel>
      <Tabs.Panel value={NotificationStatus.ARCHIVED}>
        <NotificationsList
          notifications={archivedNotifications}
          onDrawerClose={onClose}
        />
      </Tabs.Panel>
    </Tabs>
  </Drawer>
)
