export const CLIENT_FIELDS_LABELS = {
  gender: "Civilité",
  residentialStatus: "Statut résidentiel",
  firstName: "Prénom",
  lastName: "Nom",
  email: "Email",
  phone: "Téléphone",
  hubspotContactId: "Id Hubspot",
  birthDate: "Date de naissance",
  birthPlace: "Lieu de naissance",
  isComplexCase: "Client difficile ?",
}
