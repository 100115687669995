import { Button, SimpleGrid, TextInput } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"

import {
  ClientInput,
  UpdateClientInput,
  updateClientSchema,
} from "@ensol/types/forms/clients"

import { ResidentialStatus } from "@ensol/shared/entities/clients"
import { Team } from "@ensol/shared/entities/users"
import { STANDARD_DATE_FORMAT } from "@ensol/shared/utils/format"

import { DateInput } from "@ensol/entool/components/form/DateInput"
import { FormField } from "@ensol/entool/components/form/FormField"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { useAuthenticatedUser } from "@ensol/entool/queries/users"
import { GENDER_OPTIONS } from "@ensol/entool/utils/clients/options"
import { booleanOptions } from "@ensol/entool/utils/form/options"

type Props = {
  client: ClientInput
  submitAction: (values: UpdateClientInput) => void
  isPending: boolean
}

export const ClientForm = ({
  client: {
    gender,
    firstName,
    lastName,
    email,
    phone,
    hubspotContactId,
    birthDate,
    birthPlace,
    residentialStatus,
    isComplexCase,
  },
  submitAction,
  isPending,
}: Props) => {
  const form = useForm({
    validateInputOnChange: true,
    validate: zodResolver(updateClientSchema),
    initialValues: {
      gender,
      firstName,
      lastName,
      email,
      phone,
      hubspotContactId: hubspotContactId ?? "",
      birthDate,
      birthPlace: birthPlace ?? "",
      residentialStatus,
      isComplexCase,
    },
  })

  const user = useAuthenticatedUser()

  const handleSubmit = (values: ClientInput) => {
    const validateData: UpdateClientInput = updateClientSchema.parse(values)
    submitAction(validateData)
  }

  return (
    <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
      <SimpleGrid cols={2} spacing={16}>
        <FormField propertyName="gender" isRequired initialValues={form.values}>
          <RadioGroup
            options={GENDER_OPTIONS}
            {...form.getInputProps("gender")}
          />
        </FormField>
        <FormField propertyName="residentialStatus" initialValues={form.values}>
          <RadioGroup
            options={[
              { label: "Propriétaire", value: ResidentialStatus.OWNER },
              { label: "Locataire", value: ResidentialStatus.RENTER },
            ]}
            {...form.getInputProps("residentialStatus")}
          />
        </FormField>
        <FormField
          propertyName="firstName"
          isRequired
          initialValues={form.values}
        >
          <TextInput {...form.getInputProps("firstName")} />
        </FormField>
        <FormField
          propertyName="lastName"
          isRequired
          initialValues={form.values}
        >
          <TextInput {...form.getInputProps("lastName")} />
        </FormField>
        <FormField propertyName="email" isRequired initialValues={form.values}>
          <TextInput {...form.getInputProps("email")} />
        </FormField>
        <FormField propertyName="phone" isRequired initialValues={form.values}>
          <TextInput {...form.getInputProps("phone")} />
        </FormField>
        <FormField propertyName="birthDate" initialValues={form.values}>
          <DateInput
            clearable
            {...form.getInputProps("birthDate")}
            maxDate={new Date()}
            valueFormat={STANDARD_DATE_FORMAT}
          />
        </FormField>
        <FormField propertyName="birthPlace" initialValues={form.values}>
          <TextInput {...form.getInputProps("birthPlace")} />
        </FormField>
        <FormField propertyName="hubspotContactId" initialValues={form.values}>
          <TextInput {...form.getInputProps("hubspotContactId")} />
        </FormField>
        {!user.data?.teams.includes(Team.ACCOUNT_EXECUTIVE) &&
          !user.data?.teams.includes(Team.INSIDE_SALES) && (
            <FormField propertyName="isComplexCase" initialValues={form.values}>
              <RadioGroup
                options={booleanOptions}
                {...form.getInputProps("isComplexCase")}
              />
            </FormField>
          )}
      </SimpleGrid>
      <Button
        display="flex"
        justify="flex-end"
        disabled={!form.isValid()}
        loading={isPending}
        type="submit"
        mt={16}
        ml="auto"
      >
        Enregistrer
      </Button>
    </form>
  )
}
