import { Project } from "@ensol/types/entities/project"

export const CONSUEL_PROPERTIES: (keyof Project)[] = [
  "consuelNotes",
  "electricityBillPath",
  "consuelReference",
  "consuelSummonsWeek",
  "consuelSummonsDate",
  "consuelFirstNoticeOfVisitPath",
  "consuelComplianceReportPath",
  "consuelCertificatePath",
  "consuelSecondNoticeOfVisitPath",
  "consuelNonComplianceReportPaths",
  "consuelWorksDate",
  "consuelWorksLocations",
  "consuelWorksReasons",
  "consuelWorksInstallerId",
  "isValidationVisitNecessary",
]
