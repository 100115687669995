import { Alert } from "@mantine/core"
import { IconInfoCircle } from "@tabler/icons-react"

import { preliminaryValidationStepFormSchema } from "@ensol/types/forms/projects/preliminaryRequest"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/utils/initialValues"

import { PreliminaryRequestForm } from "./components/PreliminaryRequestForm"

export const WaitingForInfo = ({ project, ...props }: StepComponentProps) => (
  <PreliminaryRequestForm
    schema={preliminaryValidationStepFormSchema}
    initialValues={getInitialValues(
      preliminaryValidationStepFormSchema,
      project,
    )}
    project={project}
    actions={[]}
    {...props}
  >
    <Alert
      variant="light"
      color="blue"
      title="L'AE doit remplir le formulaire de visite pour que le projet passe à l'étape suivante."
      icon={<IconInfoCircle size={30} />}
    />
  </PreliminaryRequestForm>
)
