import {
  Card,
  Group,
  Stack,
  Text,
  ThemeIcon,
  Button,
  Collapse,
  UnstyledButton,
  Switch,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import {
  IconChevronDown,
  IconChevronUp,
  IconExternalLink,
  IconShieldCheck,
  IconTrash,
} from "@tabler/icons-react"

import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { UnpackArray } from "@ensol/types/utils"

import { EquipmentType } from "@ensol/shared/entities/houses/equipments"
import { getBrand } from "@ensol/shared/material/brands"
import { getControlDevice } from "@ensol/shared/material/controlDevices"
import { colors } from "@ensol/shared/styles/colors"

import { MaterialProperty } from "@ensol/entool/pages/Installation/components/MaterialCard"
import { MaterialImage } from "@ensol/entool/pages/Installation/components/MaterialImage"
import { ControlledEquipmentsSelector } from "@ensol/entool/pages/Installation/sections/7-ControlDevices/ControlledEquipmentsSelector"

type Props = {
  installationId: string
  controlDeviceInstallation: UnpackArray<InstallationsResponses.ControlDeviceInstallations>
  houseEquipments: EquipmentType[]
  onDelete: () => void
  onChange: (
    data: UnpackArray<InstallationsResponses.ControlDeviceInstallations>,
  ) => void
}

const BORDER_BOTTOM_STYLE = { borderBottom: `1px solid ${colors.gray[300]}` }

export const ControlDeviceCard = ({
  installationId,
  controlDeviceInstallation,
  houseEquipments,
  onDelete,
  onChange,
}: Props) => {
  const [isOpened, { toggle }] = useDisclosure(true)
  const { name, brand, description, pictureUrl, warranty, techDocUrl } =
    getControlDevice(controlDeviceInstallation.deviceType)

  return (
    <Card withBorder radius="8" padding="0">
      <UnstyledButton onClick={toggle}>
        <Group
          gap="16"
          wrap="nowrap"
          align="center"
          style={isOpened ? BORDER_BOTTOM_STYLE : {}}
        >
          <MaterialImage pictureUrl={pictureUrl} w={80} h={80} />
          <Stack gap="4" w="100%">
            {brand && <Text c="gray.6">{getBrand(brand).name}</Text>}
            <Text fw="bold">{name}</Text>
          </Stack>
          <ThemeIcon color="gray.6" size="24" mr="24">
            {isOpened ? <IconChevronUp /> : <IconChevronDown />}
          </ThemeIcon>
        </Group>
      </UnstyledButton>
      <Collapse in={isOpened}>
        <Stack gap="12" p="24" style={BORDER_BOTTOM_STYLE}>
          <Text c="gray.6">{description}</Text>
          <Group>
            <MaterialProperty
              icon={IconShieldCheck}
              label={`Garantie ${warranty} ans`}
            />
            {techDocUrl && (
              <MaterialProperty
                icon={IconExternalLink}
                label="Fiche technique"
                href={techDocUrl}
              />
            )}
          </Group>
        </Stack>
        <Stack p="24" gap="12" style={BORDER_BOTTOM_STYLE}>
          <Text>Sélectionnez les équipements à piloter avec cet appareil</Text>
          <ControlledEquipmentsSelector
            installationId={installationId}
            controlDeviceInstallation={controlDeviceInstallation}
            houseEquipments={houseEquipments}
            onChange={onChange}
          />
        </Stack>
        <Group justify="space-between" p="24">
          <Switch
            label="Offrir l'équipement"
            checked={controlDeviceInstallation.isFree}
            onChange={({ target: { checked } }) =>
              onChange({ ...controlDeviceInstallation, isFree: checked })
            }
          />
          <Button
            variant="light"
            color="red"
            leftSection={<IconTrash size={16} />}
            onClick={onDelete}
          >
            Supprimer
          </Button>
        </Group>
      </Collapse>
    </Card>
  )
}
