import {
  ActionIcon,
  Box,
  Group,
  Loader,
  Paper,
  Stack,
  Text,
} from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import { IconEye, IconEyeOff } from "@tabler/icons-react"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { Client } from "@ensol/types/entities/clients"
import { InstallationFormInput } from "@ensol/types/forms/installations"
import { SimulationResults } from "@ensol/types/simulation"

import {
  useResponsiveDimensions,
  CONTENT_PADDING_IN_PX,
  INSTALLATION_HEADER_HEIGHT_IN_PX,
} from "@ensol/shared/styles/constants"
import { getZIndex } from "@ensol/shared/styles/zIndex"

import { Quote } from "@ensol/entool/components/entities/Installation/Quote"

import { Summary } from "./Summary"
import { TownHallRequirements } from "./TownHallRequirements"

type Props = {
  house: HousesResponses.House<{
    include: {
      switchgridConsent: true
      switchgridOrder: true
      roofSections: true
    }
  }>
  client: Client
  installationId: string
  installationDate: Date
  form: UseFormReturnType<InstallationFormInput>
  isInstallationValid: boolean
  areEnergyStatsAvailable: boolean
  isLoading: boolean
  simulationResults?: SimulationResults
}

export const OVERVIEW_WIDTH_IN_PX = 400

export const InstallationOverview = ({
  house,
  client,
  installationId,
  installationDate,
  form,
  isInstallationValid,
  areEnergyStatsAvailable,
  isLoading,
  simulationResults,
}: Props) => {
  const { navbarHeight, contentPadding } = useResponsiveDimensions()
  const [areCostsVisible, { toggle: toggleCostsVisibility }] =
    useDisclosure(false)

  return (
    <Paper
      w={OVERVIEW_WIDTH_IN_PX}
      shadow="xs"
      pos="fixed"
      px={CONTENT_PADDING_IN_PX}
      pb={CONTENT_PADDING_IN_PX}
      right={CONTENT_PADDING_IN_PX}
      top={INSTALLATION_HEADER_HEIGHT_IN_PX + contentPadding + navbarHeight}
      bottom={CONTENT_PADDING_IN_PX}
      radius={8}
      style={{ overflowY: "auto" }}
    >
      <Group
        pos="sticky"
        top="0"
        gap="8"
        pt={CONTENT_PADDING_IN_PX}
        pb="12"
        bg="white"
        style={{ zIndex: getZIndex("stickyHeader") }}
      >
        <Text fz="18" fw="500">
          Résumé du projet
        </Text>
        <ActionIcon variant="light" onClick={toggleCostsVisibility}>
          {areCostsVisible ? <IconEye size={16} /> : <IconEyeOff size={16} />}
        </ActionIcon>
      </Group>
      {isLoading ? (
        <Box h={200} py={16}>
          <Stack flex="1" h="100%" align="center" justify="center">
            <Loader size="sm" color="gray" />
          </Stack>
        </Box>
      ) : (
        <Stack gap="8">
          <TownHallRequirements townHallId={house.townHallId} />
          <Summary
            house={house}
            installationValues={form.values}
            simulationResults={simulationResults}
            areEnergyStatsAvailable={areEnergyStatsAvailable}
            areCostsVisible={areCostsVisible}
            hasBattery={form.values.batteryInstallation !== null}
          />
          <Quote
            installationId={installationId}
            installationDate={installationDate}
            quoteFileName={`Devis Ensol - ${client.lastName} - ${form.values.name}`}
            isInstallationValid={isInstallationValid}
          />
        </Stack>
      )}
    </Paper>
  )
}
