import { createContext, ReactNode, useState } from "react"

import { MenuItem } from "@ensol/shared/components/layout/Menu/types"
import { AuthorizedGroup } from "@ensol/shared/entities/users"

import { GLOBAL_MENU_ITEMS } from "./config"

type Props = {
  children: ReactNode
}

export type AuthorizedMenuItem = MenuItem & {
  authorizedGroups: AuthorizedGroup[]
}

export const MenuProviderContext = createContext<{
  menuItems: AuthorizedMenuItem[]
  setMenuItems: (newMenuItems: AuthorizedMenuItem[]) => void
}>({
  menuItems: GLOBAL_MENU_ITEMS,
  setMenuItems: (_: AuthorizedMenuItem[]) => {},
})

export const MenuProvider = ({ children }: Props) => {
  const [menuItems, setMenuItems] =
    useState<AuthorizedMenuItem[]>(GLOBAL_MENU_ITEMS)

  return (
    <MenuProviderContext.Provider value={{ menuItems, setMenuItems }}>
      {children}
    </MenuProviderContext.Provider>
  )
}
