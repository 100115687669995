import { Button } from "@mantine/core"
import { IconChecks, IconSend } from "@tabler/icons-react"
import React from "react"

import { Project } from "@ensol/types/entities/project"

import {
  EmailEvent,
  getProjectEmailData,
} from "@ensol/shared/entities/projects/emails"

import { useSendEmail } from "@ensol/entool/components/entities/Project/StepActions/useSendEmail"
import { getFileUrl } from "@ensol/entool/utils/files"

type Props = {
  eventName: EmailEvent
  project: Project
  children: React.ReactNode
}

export const EmailSender = ({ eventName, project, children }: Props) => {
  const { sendEmail, hasEmailBeenSent, isEmailSending, areEventsLoading } =
    useSendEmail(project.id, project.installation.clientId, eventName)

  try {
    const emailData = getProjectEmailData(eventName, project, getFileUrl)

    return (
      <Button
        variant="light"
        disabled={areEventsLoading}
        loading={isEmailSending}
        leftSection={
          hasEmailBeenSent ? <IconChecks size={20} /> : <IconSend size={20} />
        }
        color={hasEmailBeenSent ? "green" : "blue"}
        onClick={() => sendEmail(emailData)}
      >
        {children}
      </Button>
    )
  } catch (error) {
    return null
  }
}
