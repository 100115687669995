import { Container, MantineStyleProps } from "@mantine/core"

import {
  CONTAINER_MIN_WIDTH,
  CONTENT_PADDING_IN_PX,
} from "@ensol/shared/styles/constants"

type Props = {
  children: React.ReactNode
}

export const ContentContainer = ({
  children,
  ...props
}: Props & MantineStyleProps) => {
  return (
    <Container
      miw={CONTAINER_MIN_WIDTH}
      maw="100%"
      px={CONTENT_PADDING_IN_PX}
      mt={{ base: 8, md: 20 }}
      {...props}
    >
      {children}
    </Container>
  )
}
