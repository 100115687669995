import { IconMail } from "@tabler/icons-react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { UnpackArray } from "@ensol/types/utils"

import { Link } from "@ensol/shared/components/Link"

const generateCetelemMailto = (
  project: UnpackArray<ProjectResponses.ProjectsList>,
) =>
  `https://mail.google.com/mail/?view=cm&fs=1&to=etude.hab.net@wcc-distribution.fr&cc=christian.filosa@bnpparibas-pf.com&su=COMPLÉMENT${project.cetelemReference ? ` - ${project.cetelemReference}` : ""} - ${project.installation.house.client.firstName || ""} ${project.installation.house.client.lastName || ""}&body=Bonjour,%0A%0A[Votre message]%0A%0ACordialement`

export const AnswerByMail = (
  project: UnpackArray<ProjectResponses.ProjectsList>,
) => {
  return (
    <Link
      label="Répondre par mail"
      to={generateCetelemMailto(project)}
      Icon={<IconMail />}
      isExternal
    />
  )
}
