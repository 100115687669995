import { useProjectsAlertsQuery } from "@ensol/entool/queries/projects"
import { useProjectsSearch } from "@ensol/entool/utils/projects/search"

export const useProjectsAlerts = () => {
  const { search, installerIds } = useProjectsSearch()

  return useProjectsAlertsQuery({
    search,
    installerIds,
  })
}
