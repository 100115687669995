import { Button, Group, Modal, Select, Text } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import _ from "lodash"
import { z } from "zod"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { KizeoProjectReportType } from "@ensol/types/entities/project/kizeoProjectReport"

import { Field } from "@ensol/entool/components/form/Field"
import { useCreateKizeoProjectReportMutation } from "@ensol/entool/queries/kizeoProjectReports"
import { useAuthenticatedUser } from "@ensol/entool/queries/users"
import { KIZEO_PROJECT_REPORT_TYPE_OPTIONS } from "@ensol/entool/utils/projects/options"

type Props = {
  isOpen: boolean
  onClose: () => void
  project: ProjectResponses.ProjectTechnicalOverview
}

const schema = z.object({
  type: z.nativeEnum(KizeoProjectReportType, {
    invalid_type_error: "Champ obligatoire",
  }),
})

export const CreateReportModal = ({ project, isOpen, onClose }: Props) => {
  const { data: user } = useAuthenticatedUser()

  const { mutateAsync: createReport, isPending: isLoading } =
    useCreateKizeoProjectReportMutation(project.id, user?.kizeoId ?? null)

  const form = useForm({
    validate: zodResolver(schema),
    initialValues: {
      type: null,
    },
  })

  const handleSubmit = async ({ type }: z.infer<typeof schema>) => {
    await createReport(type)
    form.reset()
    onClose()
  }

  return (
    <Modal
      centered
      opened={isOpen}
      onClose={onClose}
      title={
        <Text size="lg" fw="bold">
          Créer un nouveau compte rendu
        </Text>
      }
    >
      <form
        onSubmit={form.onSubmit((values) => handleSubmit(schema.parse(values)))}
      >
        <Field name="Type de compte rendu" withAsterisk>
          <Select
            data={KIZEO_PROJECT_REPORT_TYPE_OPTIONS.filter(
              (option) =>
                option.metadata?.canBeMany ||
                !_.countBy(
                  project.kizeoProjectReports,
                  (report) => report.type,
                )[option.value],
            )}
            {...form.getInputProps("type")}
          />
        </Field>
        <Group justify="flex-end" mt={24}>
          <Button variant="outline" onClick={onClose}>
            Annuler
          </Button>
          <Button type="submit" loading={isLoading}>
            Valider
          </Button>
        </Group>
      </form>
    </Modal>
  )
}
