import { Alert, SimpleGrid, Stack, Textarea, TitleOrder } from "@mantine/core"
import { DateTimePicker } from "@mantine/dates"
import { UseFormReturnType } from "@mantine/form"
import { useMediaQuery } from "@mantine/hooks"
import { IconBriefcase, IconInfoCircle, IconNote } from "@tabler/icons-react"

import { Project } from "@ensol/types/entities/project"
import { CetelemFieldsInput } from "@ensol/types/forms/projects/cetelem"
import { Nullable } from "@ensol/types/utils"

import { Link } from "@ensol/shared/components/Link"
import { Section } from "@ensol/shared/components/Section"
import { FundingSource } from "@ensol/shared/entities/projects/fundingSource"
import { Team } from "@ensol/shared/entities/users"
import { devices } from "@ensol/shared/styles/theme"
import { getClientFileName } from "@ensol/shared/utils/files"
import { DISPLAY_DATE_TIME_FORMAT } from "@ensol/shared/utils/format"

import { SubSection } from "@ensol/entool/components/SubSection"
import { ProjectNotes } from "@ensol/entool/components/entities/Project/ProjectNotes"
import { Field } from "@ensol/entool/components/form/Field"
import { FileField } from "@ensol/entool/components/form/File/FileField"
import { InstallerSelect } from "@ensol/entool/components/form/InstallerSelect"
import { CetelemFields } from "@ensol/entool/components/form/Project/CetelemFields"
import { FundingSourceField } from "@ensol/entool/components/form/Project/FundingSourceField"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { UserSelect } from "@ensol/entool/components/form/UserSelect"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { CETELEM_PORTAL_URL } from "@ensol/entool/config"
import { CLIENT_MOTIVATION_OPTIONS } from "@ensol/entool/utils/projects/options"

type Props<Values> = {
  project: Project
  form: UseFormReturnType<Values>
  titleOrder: TitleOrder
}

const PROJECT_NOTES_PLACEHOLDER = `Date installation souhaitée : asap / mois
Infos projet ou client : ...`

export const ProjectForm = <
  Values extends {
    salesOwnerId: string | null
    salesVisitPanelsLayoutPath: string | null
    salesVisitK2ReportPath: string | null
    salesVisitClientMotivation: string | null
    technicalVisitSalesComments: string
    fundingSource: FundingSource | null
    cetelemReference: string | null
    technicalVisitStartDate: Date | null
    technicalVisitInstallerId: string | null
    technicalVisitOwnerId: string | null
  } & Nullable<CetelemFieldsInput>,
>({
  project,
  form,
  titleOrder,
}: Props<Values>) => {
  const { client } = project.installation.house
  const panelsLayoutFileName = getClientFileName(
    "calepinage-visite-commerciale",
    client,
  )

  const isMobile = useMediaQuery(devices.sm)

  return (
    <>
      {!isMobile && (
        <Section title="Notes" icon={IconNote} titleOrder={titleOrder}>
          <SimpleGrid cols={{ base: 1, md: 2 }} spacing={{ base: 16, md: 62 }}>
            <ProjectNotes
              projectId={project.id}
              mah={600}
              inputPlaceholder={PROJECT_NOTES_PLACEHOLDER}
            />
          </SimpleGrid>
        </Section>
      )}
      <Section title="Projet" icon={IconBriefcase} titleOrder={titleOrder}>
        <Stack gap="24">
          <SubSection title="Informations générales" withBorder>
            <SimpleGrid
              cols={{ base: 1, md: 2 }}
              spacing={{ base: 16, md: 62 }}
              mb={{ base: 8, md: 16 }}
            >
              <Stack gap="16">
                <Field name="Image calepinage" withAsterisk>
                  <FileField
                    previewProps={{
                      fileName: panelsLayoutFileName,
                    }}
                    inputProps={{ accept: ["image/jpeg", "image/png"] }}
                    {...form.getInputProps("salesVisitPanelsLayoutPath")}
                  />
                </Field>
                <Field name="Rapport K2 visite commerciale">
                  <FileField
                    previewProps={{
                      fileName: panelsLayoutFileName,
                    }}
                    inputProps={{ accept: ["application/pdf"] }}
                    {...form.getInputProps("salesVisitK2ReportPath")}
                  />
                </Field>
              </Stack>
              <Stack gap="16">
                <Field name="Responsable" withAsterisk>
                  <UserSelect
                    w={FIELD_WIDTH}
                    teams={[Team.ACCOUNT_EXECUTIVE, Team.INSIDE_SALES]}
                    withAuthenticatedUserAsDefault
                    {...form.getInputProps("salesOwnerId")}
                  />
                </Field>
                <Field name="Niveau de chaleur" withAsterisk>
                  <RadioGroup
                    options={CLIENT_MOTIVATION_OPTIONS}
                    {...form.getInputProps("salesVisitClientMotivation")}
                  />
                </Field>
                <FundingSourceField
                  fundingSourceStatus={project.fundingSourceStatus}
                  form={form}
                />
                {form.values.fundingSource === FundingSource.CETELEM && (
                  <>
                    <Alert
                      w={FIELD_WIDTH}
                      variant="light"
                      color="blue"
                      title="L'emprunteur (principal) désigné dans la demande de crédit doit correspondre avec le client dont le nom apparaît en haut de cette page. Modifiez le nom du client si besoin en cliquant sur l'icône du crayon en haut de la page."
                      icon={<IconInfoCircle size={30} />}
                    />
                    <Link
                      w={FIELD_WIDTH}
                      label="Lien vers le portail Cetelem"
                      to={CETELEM_PORTAL_URL}
                      isExternal
                    />
                    <CetelemFields form={form} initialValues={form.values} />
                  </>
                )}
              </Stack>
            </SimpleGrid>
          </SubSection>
          <SubSection title="Visite technique">
            <SimpleGrid
              cols={{ base: 1, md: 2 }}
              spacing={{ base: 16, md: 62 }}
            >
              <Stack gap="16">
                <Field name="Date">
                  <DateTimePicker
                    w={FIELD_WIDTH}
                    valueFormat={DISPLAY_DATE_TIME_FORMAT}
                    clearable
                    {...form.getInputProps("technicalVisitStartDate")}
                  />
                </Field>
                <Field name="Installateur">
                  <InstallerSelect
                    w={FIELD_WIDTH}
                    {...form.getInputProps("technicalVisitInstallerId")}
                    onChange={(value) => {
                      form
                        .getInputProps("technicalVisitInstallerId")
                        .onChange(value)
                      form.getInputProps("technicalVisitOwnerId").onChange(null)
                    }}
                  />
                </Field>
                {form.values.technicalVisitInstallerId !== null && (
                  <Field name="Responsable">
                    <UserSelect
                      teams={[Team.TECHNICAL_VISIT_OWNER]}
                      w={FIELD_WIDTH}
                      withAuthenticatedUserAsDefault={false}
                      installerId={form.values.technicalVisitInstallerId}
                      {...form.getInputProps("technicalVisitOwnerId")}
                    />
                  </Field>
                )}
              </Stack>
              <Stack gap="16">
                <Field name="Commentaires pour la visite technique">
                  <Textarea
                    autosize
                    w={FIELD_WIDTH}
                    minRows={3}
                    {...form.getInputProps("technicalVisitSalesComments")}
                  />
                </Field>
              </Stack>
            </SimpleGrid>
          </SubSection>
        </Stack>
      </Section>
    </>
  )
}
