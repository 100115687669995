import { notifications } from "@mantine/notifications"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"

import { FilesResponses } from "@ensol/types/endpoints/files"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { PanelsLayout } from "@ensol/types/prisma-client"

import { httpClient } from "@ensol/entool/backend/axios"
import { queryClient } from "@ensol/entool/backend/queryClient"

const fetchInstallation = async (
  id: string,
): Promise<InstallationsResponses.Installation> => {
  const response = await httpClient.get(`/installations/${id}`)
  return response.data
}

export const useGetInstallationQuery = (id?: string) => {
  return useQuery<InstallationsResponses.Installation>({
    queryKey: ["installations", id],
    queryFn: async () => fetchInstallation(id!),
    enabled: id !== undefined,
  })
}

export const updateInstallation = async <T>(
  installationId: string,
  installation: T,
) => {
  const response = await httpClient.put<InstallationsResponses.Installation>(
    `/installations/${installationId}`,
    installation,
  )
  return response.data
}

export const useCreateInstallationMutation = () => {
  const navigate = useNavigate()
  return useMutation({
    mutationFn: async ({
      houseId,
      isUpsell,
    }: {
      houseId: string
      isUpsell: boolean
    }) => {
      const response =
        await httpClient.post<InstallationsResponses.Installation>(
          "/installations",
          { houseId, isUpsell },
        )
      return response.data
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: ["clients", data.clientId],
      })
      navigate(`/installations/${data.id}`)
      notifications.show({ message: "Installation créée !" })
    },
  })
}

type PanelsLayoutInput = {
  panelsGrids: google.maps.Polygon[]
  screenshotBase64: string
  screenshotWithHeatmapBase64: string
  withOrthorectifiedMap: boolean
}

export const useSavePanelsLayoutMutation = (installationId: string) => {
  return useMutation<PanelsLayout, unknown, PanelsLayoutInput>({
    mutationFn: async ({
      panelsGrids,
      screenshotBase64,
      withOrthorectifiedMap,
      screenshotWithHeatmapBase64,
    }) => {
      const jsonPaths = panelsGrids.map((panelsGrid) =>
        panelsGrid
          .getPaths()
          .getArray()
          .map((path) => path.getArray().map((point) => point.toJSON())),
      )

      const response = await httpClient.post(
        `/installations/${installationId}/panels-layout`,
        {
          paths: jsonPaths,
          screenshotBase64,
          screenshotWithHeatmapBase64,
          withOrthorectifiedMap,
        },
      )
      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["installations", installationId],
      })
      notifications.show({ message: "Calepinage sauvegardé !" })
    },
  })
}

export const useDuplicateInstallation = (
  clientId: string,
  installationId: string,
) => {
  const navigate = useNavigate()
  return useMutation({
    mutationFn: async () => {
      const response = await httpClient.post(
        `/installations/${installationId}/duplicate`,
      )
      return response.data
    },

    onSuccess: async (installation) => {
      await queryClient.invalidateQueries({
        queryKey: ["clients", clientId],
      })
      notifications.show({ message: "Installation dupliquée !" })
      navigate(`/installations/${installation.id}`)
    },
  })
}

export const useDeleteInstallation = (
  clientId: string,
  installationId: string,
) => {
  return useMutation({
    mutationFn: async () => {
      const response = await httpClient.delete(
        `/installations/${installationId}`,
      )
      return response.data
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["clients", clientId],
      })
      notifications.show({ message: "Installation supprimée !" })
    },
  })
}

export const useGetInstallationQuotesQuery = (installationId: string) => {
  return useQuery<InstallationsResponses.Quotes>({
    queryKey: ["installation", installationId, "quotes"],
    queryFn: async () => {
      const response = await httpClient.get(
        `/installations/${installationId}/quotes`,
      )
      return response.data
    },
  })
}

export const useListExternalPlantsQuery = (
  installationId: string,
  isQueryEnabled: boolean,
  setIsQueryEnabled: (isQueryEnabled: boolean) => void,
) =>
  useQuery<InstallationsResponses.ExternalPlant[]>({
    queryKey: ["installations", installationId, "external-plants"],
    queryFn: async () => {
      try {
        const response = await httpClient.get(
          `/installations/${installationId}/external-plants`,
        )

        return response.data
      } catch (error) {
        notifications.show({
          title: "Une erreur est survenue",
          message: error?.toString(),
          color: "red",
        })
        setIsQueryEnabled(false)
        throw error
      }
    },
    retry: false,
    enabled: isQueryEnabled,
  })

export const useHouseSetupQuery = (installationId: string) => {
  return useQuery<InstallationsResponses.HouseSetup | undefined>({
    queryKey: ["installations", installationId, "house-setup"],
    queryFn: async () => {
      const response = await httpClient.get(
        `/installations/${installationId}/house-setup`,
      )
      return response.data
    },
  })
}

export const useBundleInstallationMutation = (clientId: string, id: string) => {
  return useMutation<void, unknown, boolean>({
    mutationFn: async (bundled) => {
      const response = await httpClient.put(`/installations/${id}/bundle`, {
        bundled,
      })
      return response.data
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["clients", clientId],
      })
      notifications.show({ message: "Bundle mis à jour !" })
    },
  })
}

export const useGetInstallationMonthlyReport = (slug: string, date: string) =>
  useQuery<FilesResponses.DownloadFile>({
    queryKey: ["installations", slug, "reports", "monthly", "download", date],
    queryFn: async () => {
      const response = await httpClient.get(
        `/installations/${slug}/reports/monthly/download`,
        { params: { date }, responseType: "blob" },
      )
      return response.data
    },
  })
