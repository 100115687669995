import { typedKeys } from "@ensol/types/utils"

import { getBrand } from "@ensol/shared/material/brands"
import {
  CONTROL_DEVICES,
  getControlDevice,
} from "@ensol/shared/material/controlDevices"

export const getControlDeviceOptions = () =>
  typedKeys(CONTROL_DEVICES).map((controlDeviceType) => {
    const controlDeviceInfo = getControlDevice(controlDeviceType)
    const brandInfo = controlDeviceInfo.brand
      ? getBrand(controlDeviceInfo.brand)
      : undefined

    return {
      value: controlDeviceType,
      brandInfo,
      ...controlDeviceInfo,
      isSelected: false,
      isCompatible: true,
      extraColumnsValues: [],
    }
  })
