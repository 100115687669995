import {
  Alert,
  Button,
  Group,
  Modal,
  Stack,
  Stepper,
  Text,
} from "@mantine/core"
import { DateInput } from "@mantine/dates"
import { IconAlertCircle } from "@tabler/icons-react"

import { DISPLAY_DATE_FORMAT } from "@ensol/shared/utils/format"

import { CustomActionModalProps } from "@ensol/entool/components/entities/Project/StepActions"
import { CheckboxGroup } from "@ensol/entool/components/form/CheckboxGroup"
import { Field } from "@ensol/entool/components/form/Field"
import { FormField } from "@ensol/entool/components/form/FormField"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { booleanOptions } from "@ensol/entool/utils/form/options"
import {
  TECHNICAL_VISIT_REQUIRED_CHANGES_OPTIONS,
  TECHNICAL_VISIT_REQUIRED_WORKS_OPTIONS,
} from "@ensol/entool/utils/projects/options"

import { useValidateActionModal } from "./useValidateActionModal"

export const ValidateActionModal = (props: CustomActionModalProps) => {
  const { opened, onClose, project } = props

  const {
    form,
    isLoading,
    active,
    setActive,
    handleSubmit,
    prevStep,
    nextStep,
  } = useValidateActionModal(props)

  const { house } = project.installation

  return (
    <Modal
      opened={opened}
      pos="relative"
      size="lg"
      padding="xl"
      centered
      onClose={isLoading ? () => {} : onClose}
      title={
        <Text size="lg" fw="bold">
          Valider la visite technique
        </Text>
      }
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stepper active={active} onStepClick={setActive}>
          <Stepper.Step label="Ajout des changements">
            <Stack gap={32}>
              {house.potentialShading !== "no" && (
                <Alert
                  title="Le projet présente un risque d'ombrage"
                  icon={<IconAlertCircle />}
                  color="red"
                >
                  Sélectionnez une option correspondante dans les changements à
                  ajouter
                </Alert>
              )}
              <Field name="Le projet nécessite-t-il des changements ?">
                <RadioGroup
                  value={form.values.isChangesNeeded}
                  onChange={(isChangesNeeded) => {
                    if (
                      isChangesNeeded === false &&
                      form.values.technicalVisitRequiredChanges.length > 0
                    ) {
                      form.setFieldValue("technicalVisitRequiredChanges", [])
                    }
                    form.setFieldValue("isChangesNeeded", !!isChangesNeeded)
                  }}
                  options={booleanOptions}
                />
              </Field>
              {form.values.isChangesNeeded && (
                <Field name="Sélectionnez les changements à ajouter :">
                  <CheckboxGroup
                    options={TECHNICAL_VISIT_REQUIRED_CHANGES_OPTIONS}
                    {...form.getInputProps("technicalVisitRequiredChanges")}
                  />
                </Field>
              )}
            </Stack>
          </Stepper.Step>
          <Stepper.Step label="Ajout des travaux">
            <Stack gap={32}>
              <Field name="Le projet nécessite-t-il des travaux ?">
                <RadioGroup
                  value={form.values.isWorksNeeded}
                  onChange={(e) => {
                    if (
                      e === false &&
                      form.values.technicalVisitRequiredWorks.length > 0
                    ) {
                      form.setFieldValue("technicalVisitRequiredWorks", [])
                      form.setFieldValue("technicalVisitEndOfWorksDate", null)
                    }
                    form.setFieldValue("isWorksNeeded", !!e)
                  }}
                  options={booleanOptions}
                />
              </Field>
              {form.values.isWorksNeeded && (
                <>
                  <Field name="Sélectionnez les travaux à ajouter :">
                    <CheckboxGroup
                      options={TECHNICAL_VISIT_REQUIRED_WORKS_OPTIONS}
                      {...form.getInputProps("technicalVisitRequiredWorks")}
                    />
                  </Field>
                  <FormField
                    initialValues={{
                      technicalVisitEndOfWorksDate:
                        form.values.technicalVisitEndOfWorksDate,
                    }}
                    propertyName="technicalVisitEndOfWorksDate"
                  >
                    <DateInput
                      w={FIELD_WIDTH}
                      valueFormat={DISPLAY_DATE_FORMAT}
                      clearable
                      {...form.getInputProps("technicalVisitEndOfWorksDate")}
                    />
                  </FormField>
                </>
              )}
            </Stack>
          </Stepper.Step>
        </Stepper>

        <Group justify="flex-end" mt={24} pos="sticky" bottom={32}>
          <Button
            variant="outline"
            onClick={prevStep}
            bg="white"
            disabled={isLoading}
          >
            Retour
          </Button>
          {active !== 1 ? (
            <Button
              onClick={(e) => {
                e.preventDefault()
                nextStep()
              }}
            >
              Suivant
            </Button>
          ) : (
            <Button type="submit" loading={isLoading}>
              Valider
            </Button>
          )}
        </Group>
      </form>
    </Modal>
  )
}
