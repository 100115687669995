export type BrandInfo = {
  name: string
  logoUrl: string
}

export const PanelBrandType = {
  DUAL_SUN: "DUAL_SUN",
  VOLTEC: "VOLTEC",
  FSI: "FSI",
} as const
export type PanelBrandType = keyof typeof PanelBrandType

export const InverterBrandType = {
  ENPHASE: "ENPHASE",
  HUAWEI: "HUAWEI",
} as const
export type InverterBrandType = keyof typeof InverterBrandType

export const EvChargerBrandType = {
  WALLBOX: "WALLBOX",
  ENPHASE: "ENPHASE",
} as const
export type EvChargerBrandType = keyof typeof EvChargerBrandType

export const ControlDeviceBrandType = {
  ENPHASE: "ENPHASE",
  SHELLY: "SHELLY",
  ECOJOKO: "ECOJOKO",
} as const
export type ControlDeviceBrandType = keyof typeof ControlDeviceBrandType

export const BrandType = {
  ...PanelBrandType,
  ...InverterBrandType,
  ...EvChargerBrandType,
  ...ControlDeviceBrandType,
} as const

export type BrandType = keyof typeof BrandType

export const BRANDS: Record<BrandType, BrandInfo> = {
  DUAL_SUN: {
    name: "DualSun",
    logoUrl: "/dualsun.png",
  },
  VOLTEC: {
    name: "Voltec",
    logoUrl: "/voltec.png",
  },
  FSI: {
    name: "French Solar Industry",
    logoUrl: "/frenchSolarIndustry.png",
  },
  ENPHASE: {
    name: "Enphase",
    logoUrl: "/enphase.png",
  },
  HUAWEI: {
    name: "Huawei",
    logoUrl: "/huawei.png",
  },
  WALLBOX: {
    name: "Wallbox",
    logoUrl: "/wallbox.png",
  },
  SHELLY: {
    name: "Shelly",
    logoUrl: "/shelly.png",
  },
  ECOJOKO: {
    name: "Ecojoko",
    logoUrl: "/ecojoko.png",
  },
} as const

export const getBrand = (brandType: BrandType) => BRANDS[brandType]
