import { Group, Select, Stack, Switch, Text } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { Helmet } from "react-helmet-async"
import { redirect, useParams } from "react-router-dom"

import { FundingSource } from "@ensol/types/prisma-client"
import { typedKeys } from "@ensol/types/utils"

import { PageLayout } from "@ensol/shared/components/layout/PageLayout"
import { ProcessId } from "@ensol/shared/entities/projects/processes"
import { CONTENT_PADDING_IN_PX } from "@ensol/shared/styles/constants"
import { devices } from "@ensol/shared/styles/theme"

import { ProjectSearch } from "@ensol/entool/components/entities/Project/ProjectSearch"
import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { FUNDING_SOURCE_OPTIONS } from "@ensol/entool/utils/projects/options"
import { PROJECT_SEARCHABLE_PROPS } from "@ensol/entool/utils/projects/search"

import { View } from "./View"
import { PROJECT_PROCESS_LIST_VIEWS } from "./config"
import { useProjects } from "./useProjects"

export const ProjectsByProcess = () => {
  const { processId } = useParams<{ processId: ProcessId }>()
  const isMobile = useMediaQuery(devices.sm)

  const {
    projectsQuery,
    isSearching,
    showOnlyScheduledInstallations,
    setShowOnlyScheduledInstallations,
    fundingSource,
    setFundingSource,
  } = useProjects(processId)

  if (!processId) {
    redirect("/projects")
    return null
  }

  const {
    id,
    name,
    color,
    milestones,
    doneMilestone,
    abortedMilestones,
    warnDelayInDays,
    Component,
    orderBy,
  } = PROJECT_PROCESS_LIST_VIEWS[processId]

  return (
    <PageLayout title={name} contentContainerProps={{ px: 0 }}>
      <Helmet>
        <title>{name} | Entool</title>
      </Helmet>
      <Stack flex="1" mih="0" miw="0">
        <ProjectSearch
          filteredProps={typedKeys(PROJECT_SEARCHABLE_PROPS)}
          px={CONTENT_PADDING_IN_PX}
        >
          {!isMobile && processId === "preliminaryRequestStep" && (
            <Group gap={4} style={{ flexShrink: 0 }} pb="8">
              <Switch
                checked={showOnlyScheduledInstallations}
                onChange={(event) =>
                  setShowOnlyScheduledInstallations(event.currentTarget.checked)
                }
              />

              <Text size="sm" fw="500">
                Installations planifiées
              </Text>
            </Group>
          )}
          {!isMobile && processId === "paymentStep" && (
            <Select
              w={150}
              display={{ base: "none", md: "block" }}
              label="Source de financement"
              data={FUNDING_SOURCE_OPTIONS}
              value={fundingSource}
              onChange={(event) => setFundingSource(event as FundingSource)}
              style={{ flexShrink: 0 }}
              clearable
            />
          )}
        </ProjectSearch>
        <QueryWrapper
          query={projectsQuery}
          disabledQueryMessage="Entrez le nom, le téléphone ou l'email d'un client pour commencer"
        >
          {({ data }) => (
            <View
              projects={data}
              propertyName={id}
              milestonesConfig={milestones}
              doneMilestone={doneMilestone}
              abortedMilestones={abortedMilestones}
              warnDelayInDays={warnDelayInDays}
              processId={processId}
              viewColor={color}
              isSearching={isSearching}
              orderBy={orderBy}
              Component={Component}
            />
          )}
        </QueryWrapper>
      </Stack>
    </PageLayout>
  )
}
