import * as z from "zod"
import { isValidPhoneNumber } from "libphonenumber-js"
import { formatPhoneNum } from "@ensol/shared/utils/format"
import { Gender, ResidentialStatus } from "@ensol/shared/entities/clients"

export const phoneSchema = z
  .string()
  .min(1, "Champ obligatoire")
  .refine(
    (val) =>
      isValidPhoneNumber(val, {
        defaultCountry: "FR",
      }),
    "Numéro de téléphone invalide",
  )
  .transform((val) => formatPhoneNum(val, "E.164"))

export const createClientSchema = z.object({
  gender: z.nativeEnum(Gender).nullable().optional(),
  firstName: z.string().min(1, "Champ obligatoire"),
  lastName: z.string().min(1, "Champ obligatoire"),
  email: z.string().email("Email invalide"),
  phone: phoneSchema,
  residentialStatus: z.nativeEnum(ResidentialStatus).optional(),
  hubspotContactId: z
    .string()
    .nullable()
    .optional()
    .transform((value) => (value === "" || value === undefined ? null : value)),
  isSubscribedToNewsletter: z.boolean().optional(),
  birthDate: z.coerce.date().nullable().optional(),
  birthPlace: z.string().nullable().optional(),
  isComplexCase: z.boolean().optional(),
})

export type ClientInput = z.infer<typeof createClientSchema>

export const updateClientSchema = createClientSchema.extend({
  gender: z.nativeEnum(Gender, {
    invalid_type_error: "Champ obligatoire",
  }),
})

export type UpdateClientInput = z.infer<typeof updateClientSchema>

export const contactFormSchema = z.discriminatedUnion("isOnSite", [
  createClientSchema.extend({
    isOnSite: z.literal(true),
    hasAcceptedTerms: z.boolean().refine((val) => val),
    phone: z.string(),
  }),
  createClientSchema.extend({
    isOnSite: z.literal(false),
    hasAcceptedTerms: z.boolean().refine((val) => val),
  }),
])

createClientSchema.extend({
  hasAcceptedTerms: z.boolean().refine((val) => val),
  phone: z
    .string()
    .min(1, "Champ obligatoire")
    .transform((val) => formatPhoneNum(val, "E.164")),
})

export type ContactFormInput = z.infer<typeof contactFormSchema>

export const listClientsFilters = z.object({
  search: z.string().optional(),
  orderBy: z
    .object({
      field: z.enum(["createdAt"]),
      direction: z.enum(["asc", "desc"]),
    })
    .optional(),
})

export type ListClientsFilters = z.infer<typeof listClientsFilters>

export const clientContactFormSchema = z.object({
  firstname: z.string().min(1, "Champ obligatoire"),
  lastname: z.string().min(1, "Champ obligatoire"),
  email: z.string().email("Email invalide"),
  mobilephone: phoneSchema,
  zip: z.string().regex(/^\d{5}$/, "Code postal invalide"),
})

export type ClientContactFormInput = z.infer<typeof clientContactFormSchema>
