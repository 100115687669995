import {
  IconBatteryCharging,
  IconBolt,
  IconBrandGoogleAnalytics,
  IconBriefcase,
  IconBulb,
  IconChargingPile,
  IconDashboard,
  IconFileInvoice,
  IconFolderOpen,
  IconHome,
  IconHomeEdit,
  IconHomeMove,
  IconSettings,
  IconSolarPanel,
  IconThumbUp,
  IconWallet,
  IconWashMachine,
} from "@tabler/icons-react"
import { useContext, useEffect } from "react"
import { useLocation } from "react-router-dom"

import {
  getStatusIndex,
  ProjectStatus,
} from "@ensol/shared/entities/projects/statuses"
import { Role, RoleGroups } from "@ensol/shared/entities/users"

import { MenuProviderContext } from "@ensol/entool/components/layout/Menu/Provider"
import {
  GLOBAL_MENU_ITEMS,
  HOME_MENU_ITEM,
} from "@ensol/entool/components/layout/Menu/config"
import { useGetInstallationQuery } from "@ensol/entool/queries/installations"
import { getSummaryUrl } from "@ensol/entool/utils/projects/reports"

export enum InstallationPageSection {
  DESCRIPTION = "description",
  EQUIPMENTS = "equipments",
  CONSUMPTION = "consumption",
  PHOTOVOLTAIC = "photovoltaic",
  BATTERY = "battery",
  EV_CHARGER = "evCharger",
  CONTROL_DEVICES = "controlDevices",
  SETTINGS = "settings",
  AUTO_CONSUMPTION = "autoConsumption",
  SAVINGS = "savings",
}

export const useInstallationMenu = (installationId: string) => {
  const location = useLocation()
  const { setMenuItems } = useContext(MenuProviderContext)
  const installationQuery = useGetInstallationQuery(installationId as string)

  const { slug, project } = installationQuery.data ?? {}

  const isProjectSigned =
    project !== undefined &&
    project !== null &&
    getStatusIndex(project.status) >= getStatusIndex(ProjectStatus.SIGNED)
  useEffect(() => {
    const getSectionPath = (section: InstallationPageSection) =>
      `/installations/${installationId}?section=${section}`

    setMenuItems([
      HOME_MENU_ITEM,
      {
        id: `(${InstallationPageSection.DESCRIPTION}|${InstallationPageSection.EQUIPMENTS}|${InstallationPageSection.CONSUMPTION})`,
        name: "Maison",
        Icon: IconHomeEdit,
        authorizedGroups: [
          { group: RoleGroups.ENSOL, minimumRole: Role.EMPLOYEE },
        ],
        subItems: [
          {
            id: InstallationPageSection.DESCRIPTION,
            to: getSectionPath(InstallationPageSection.DESCRIPTION),
            name: "Description",
            Icon: IconHome,
          },
          {
            id: InstallationPageSection.CONSUMPTION,
            to: getSectionPath(InstallationPageSection.CONSUMPTION),
            name: "Consommation",
            Icon: IconBulb,
          },
          {
            id: InstallationPageSection.EQUIPMENTS,
            to: getSectionPath(InstallationPageSection.EQUIPMENTS),
            name: "Équipements",
            Icon: IconWashMachine,
          },
        ],
      },
      {
        id: `(${InstallationPageSection.PHOTOVOLTAIC}|${InstallationPageSection.BATTERY}|${InstallationPageSection.EV_CHARGER}|${InstallationPageSection.SETTINGS})`,
        name: "Projet",
        Icon: IconBriefcase,
        authorizedGroups: [
          { group: RoleGroups.ENSOL, minimumRole: Role.EMPLOYEE },
        ],
        subItems: [
          {
            id: `(${InstallationPageSection.PHOTOVOLTAIC}|panels-layout)`,
            to: getSectionPath(InstallationPageSection.PHOTOVOLTAIC),
            name: "Photovoltaïque",
            Icon: IconSolarPanel,
          },
          {
            id: InstallationPageSection.BATTERY,
            to: getSectionPath(InstallationPageSection.BATTERY),
            name: "Batterie",
            Icon: IconBatteryCharging,
          },
          {
            id: InstallationPageSection.EV_CHARGER,
            to: getSectionPath(InstallationPageSection.EV_CHARGER),
            name: "Borne de recharge",
            Icon: IconChargingPile,
          },
          {
            id: InstallationPageSection.CONTROL_DEVICES,
            to: getSectionPath(InstallationPageSection.CONTROL_DEVICES),
            name: "Pilotage",
            Icon: IconDashboard,
          },
          {
            id: InstallationPageSection.SETTINGS,
            to: getSectionPath(InstallationPageSection.SETTINGS),
            name: "Paramètres",
            Icon: IconSettings,
          },
        ],
      },
      {
        id: `(${InstallationPageSection.SAVINGS}|${InstallationPageSection.AUTO_CONSUMPTION})`,
        name: "Analyse",
        Icon: IconBrandGoogleAnalytics,
        authorizedGroups: [
          { group: RoleGroups.ENSOL, minimumRole: Role.EMPLOYEE },
        ],
        subItems: [
          {
            id: InstallationPageSection.AUTO_CONSUMPTION,
            to: getSectionPath(InstallationPageSection.AUTO_CONSUMPTION),
            name: "Autoconsommation",
            Icon: IconBolt,
          },
          {
            id: InstallationPageSection.SAVINGS,
            to: getSectionPath(InstallationPageSection.SAVINGS),
            name: "Économies",
            Icon: IconWallet,
          },
        ],
      },
      ...(installationId !== undefined
        ? [
            {
              name: "Liens",
              Icon: IconFolderOpen,
              authorizedGroups: [
                { group: RoleGroups.ENSOL, minimumRole: Role.EMPLOYEE },
              ],
              subItems: [
                ...(slug
                  ? [
                      {
                        to: getSummaryUrl(slug),
                        name: "Résumé client",
                        Icon: IconFileInvoice,
                        isExternal: true,
                      },
                    ]
                  : []),
                ...(project?.id
                  ? [
                      {
                        id: "sales/",
                        to: `/sales/${project.id}`,
                        name: "Visite commerciale",
                        Icon: IconHomeMove,
                      },
                    ]
                  : []),
                ...(isProjectSigned
                  ? [
                      {
                        id: "feedbacks/",
                        to: `/feedbacks/${project.id}`,
                        name: "NPS",
                        Icon: IconThumbUp,
                      },
                    ]
                  : []),
              ],
            },
          ]
        : []),
    ])

    return () => setMenuItems(GLOBAL_MENU_ITEMS)
  }, [
    setMenuItems,
    location.pathname,
    installationId,
    slug,
    project?.id,
    isProjectSigned,
  ])
}
