import { Modal, Table, Group, Stack, Badge, Image, Text } from "@mantine/core"
import { ReactNode } from "react"

import { BrandInfo } from "@ensol/shared/material/brands"

type Props<ValueType> = {
  title: string
  isOpened: boolean
  onClose: () => void
  onChange: (value: ValueType) => void
  extraColumnsLabel: string[]
  options: {
    value: ValueType
    brandInfo?: BrandInfo
    name: string
    pictureUrl: string
    isSelected: boolean
    isCompatible: boolean
    isRecommended?: boolean
    extraColumnsValues: ReactNode[]
  }[]
}

export const MaterialSelectorModal = <ValueType extends string>({
  title,
  isOpened,
  onClose,
  onChange,
  extraColumnsLabel,
  options,
}: Props<ValueType>) => (
  <Modal size="auto" opened={isOpened} onClose={onClose} title={title}>
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Fabricant</Table.Th>
          <Table.Th>Nom</Table.Th>
          {extraColumnsLabel.map((label) => (
            <Table.Th key={label}>{label}</Table.Th>
          ))}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {options.map(
          ({
            value,
            brandInfo,
            name,
            pictureUrl,
            isSelected,
            isCompatible,
            isRecommended,
            extraColumnsValues,
          }) => (
            <Table.Tr
              key={value}
              bg={
                !isCompatible ? "red.0" : isSelected ? "blue.0" : "transparent"
              }
              data-test={`${value}Option`}
              onClick={() => {
                if (isCompatible) {
                  onChange(value)
                  onClose()
                }
              }}
              style={{ cursor: isCompatible ? "pointer" : "not-allowed" }}
            >
              <Table.Td>
                {brandInfo ? (
                  <Image w={80} src={brandInfo.logoUrl} alt={brandInfo.name} />
                ) : (
                  <Text>-</Text>
                )}
              </Table.Td>
              <Table.Td>
                <Group wrap="nowrap" gap={8} align="flex-start">
                  <Image src={pictureUrl} w={24} />
                  <Stack gap={4}>
                    <Text>{name}</Text>
                    <Group gap={8}>
                      {isRecommended && (
                        <Badge size="xs" color="green" variant="light">
                          Recommandé
                        </Badge>
                      )}
                      {isSelected && (
                        <Badge size="xs" color="blue" variant="light">
                          Sélectionné
                        </Badge>
                      )}
                      {!isCompatible && (
                        <Badge size="xs" color="red" variant="light">
                          Incompatible
                        </Badge>
                      )}
                    </Group>
                  </Stack>
                </Group>
              </Table.Td>
              {extraColumnsValues.map((value, index) => (
                <Table.Td key={`column-${index}`}>
                  <Text component="span">{value}</Text>
                </Table.Td>
              ))}
            </Table.Tr>
          ),
        )}
      </Table.Tbody>
    </Table>
  </Modal>
)
