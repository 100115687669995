import { LoadingOverlay, Stack } from "@mantine/core"

import { Location } from "@ensol/types/forms/houses"
import { InstallationFormInput } from "@ensol/types/forms/installations"

import { getZIndex } from "@ensol/shared/styles/zIndex"

import { useGetEnergyReportQuery } from "@ensol/entool/queries/energy"

import { SavingsDistribution } from "./SavingsDistribution"

type Props = {
  slug: string
  computedAutoConsumptionPercent: number
  manualAutoConsumptionPercent: number
  startDate: Date
  endDate: Date
  installationValues: InstallationFormInput
  coords: Pick<Location, "lat" | "lng">
}

export const SavingsReport = ({
  slug,
  computedAutoConsumptionPercent,
  manualAutoConsumptionPercent,
  installationValues,
  startDate,
  endDate,
  coords,
}: Props) => {
  const { data, isPending } = useGetEnergyReportQuery({
    slug,
    startDate,
    endDate,
    installationValues,
    coords,
  })

  return (
    <Stack pos="relative" mih={400} justify="center">
      <LoadingOverlay visible={isPending} zIndex={getZIndex("overlay")} />
      {data && (
        <SavingsDistribution
          totalBill={data.totalBill}
          autoConsumedAmount={
            computedAutoConsumptionPercent > 0
              ? (manualAutoConsumptionPercent /
                  computedAutoConsumptionPercent) *
                data.autoConsumptionSavings
              : 0
          }
          resellAmount={
            computedAutoConsumptionPercent < 100
              ? ((100 - manualAutoConsumptionPercent) /
                  (100 - computedAutoConsumptionPercent)) *
                data.resellSavings
              : 0
          }
          maxHeight={300}
        />
      )}
    </Stack>
  )
}
