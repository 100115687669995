import { EmailEvent } from "@ensol/shared/entities/projects/emails"

import {
  StepActions,
  StepComponentProps,
} from "@ensol/entool/components/entities/Project/StepActions"
import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { PostDepositPaymentForm } from "@ensol/entool/pages/Project/processes/Payment/standard/components/PostDepositPaymentForm"
import { useGetEstimateQuery } from "@ensol/entool/queries/projects"

export const FinalInvoicePaid = ({
  project,
  processId,
}: StepComponentProps) => {
  const estimateQuery = useGetEstimateQuery(project.id)

  return (
    <QueryWrapper query={estimateQuery} returnEmptyResults={true}>
      {({ data: estimate }) => (
        <StepActions
          project={project}
          processId={processId}
          emails={[
            {
              eventName: EmailEvent.FINAL_INVOICE_PAID,
              label: "Envoyer le mail de facture payée",
            },
          ]}
          actions={[]}
        >
          <PostDepositPaymentForm estimate={estimate} project={project} />
        </StepActions>
      )}
    </QueryWrapper>
  )
}
