import { PanelBrandType } from "@ensol/shared/material/brands"

export type PanelInfo = {
  name: string
  reference: string
  brand: PanelBrandType
  pictureUrl: string
  power: number
  price: number
  efficiency: number
  warranty: number
  lengthInMillimeters: number
  widthInMillimeters: number
  techDocUrl: string
  disabled: boolean
}

// TODO FIXME: Cannot import enum types in frontend https://github.com/prisma/prisma/issues/19261
export const PanelType = {
  DUALSUN_425_TOPCON: "DUALSUN_425_TOPCON",
  DUALSUN_500_TOPCON: "DUALSUN_500_TOPCON",
  VOLTEC_355_TARKA: "VOLTEC_355_TARKA",
  FSI_500_SUNELIX: "FSI_500_SUNELIX",
  DUALSUN_500_BLACK: "DUALSUN_500_BLACK", // Deprecated
  DUALSUN_425_BLACK: "DUALSUN_425_BLACK", // Deprecated
} as const

export type PanelType = keyof typeof PanelType

export const PANELS: Record<PanelType, PanelInfo> = {
  DUALSUN_425_TOPCON: {
    name: "FLASH 425 TopCon",
    reference: "DualSun FLASH 425 TopCon",
    brand: PanelBrandType.DUAL_SUN,
    pictureUrl: "/panelDualsun.png",
    power: 0.425,
    price: 80,
    efficiency: 0.21,
    warranty: 30,
    lengthInMillimeters: 1722,
    widthInMillimeters: 1134,
    techDocUrl:
      "https://dualsun.com/wp-content/uploads/2023/05/dualsun-fr-fiche-technique-flash-425-half-cut-glass-glass-topcon.pdf",
    disabled: false,
  },
  DUALSUN_500_TOPCON: {
    name: "FLASH 500 TopCon",
    reference:
      "DualSun FLASH 500 Half-Cut Glass-Glass TOPCon - DS500-120M10TB-03",
    brand: PanelBrandType.DUAL_SUN,
    pictureUrl: "/panelDualsun.png",
    power: 0.5,
    price: 85,
    efficiency: 0.2261,
    warranty: 30,
    lengthInMillimeters: 1950,
    widthInMillimeters: 1134,
    techDocUrl:
      "https://dualsun.com/espace-professionnel/fiche-technique/flash-500-half-cut-glass-glass-topcon/",
    disabled: false,
  },
  VOLTEC_355_TARKA: {
    name: "TARKA 355",
    reference: "Voltec TARKA 355",
    brand: PanelBrandType.VOLTEC,
    pictureUrl: "/panelVoltec.png",
    power: 0.355,
    price: 192,
    efficiency: 0.1857,
    warranty: 25,
    lengthInMillimeters: 1835,
    widthInMillimeters: 1042,
    techDocUrl:
      "https://dualsun.com/espace-professionnel/fiche-technique/flash-500-half-cut-glass-glass-topcon/",
    disabled: false,
  },
  FSI_500_SUNELIX: {
    name: "SUNELIX 500",
    reference: "French SolarIndustry SUNELIX 500",
    brand: PanelBrandType.FSI,
    pictureUrl: "/panelFrenchSolarIndustry.png",
    power: 0.5,
    price: 94,
    efficiency: 0.2226,
    warranty: 30,
    lengthInMillimeters: 1980,
    widthInMillimeters: 1134,
    techDocUrl:
      "https://www.frenchsolarindustry.fr/assets/pdf/FR-FSI-FP-PS-500_HPBC_ULTRABLACK.pdf",
    disabled: false,
  },
  DUALSUN_500_BLACK: {
    name: "FLASH 500 Half-Cut Black",
    reference: "DualSun FLASH 500 Half-Cut Black",
    brand: PanelBrandType.DUAL_SUN,
    pictureUrl: "/panelDualsun.png",
    power: 0.5,
    price: 175,
    efficiency: 0.2,
    warranty: 25,
    lengthInMillimeters: 2094,
    widthInMillimeters: 1134,
    techDocUrl:
      "https://dualsun.com/wp-content/uploads/dualsun-fr-fiche-technique-flash-500-half-cut-black.pdf",
    disabled: true,
  },
  DUALSUN_425_BLACK: {
    name: "FLASH 425 Shingle Black",
    reference: "DualSun FLASH 425 Shingle Black",
    brand: PanelBrandType.DUAL_SUN,
    pictureUrl: "/panelDualsun.png",
    power: 0.425,
    price: 170,
    efficiency: 0.2,
    warranty: 25,
    lengthInMillimeters: 1899,
    widthInMillimeters: 1096,
    techDocUrl:
      "https://dualsun.com/wp-content/uploads/dualsun-fr-fiche-technique-flash-425-shingle-black.pdf",
    disabled: true,
  },
}

export const getPanel = (panelType: PanelType): PanelInfo => PANELS[panelType]
