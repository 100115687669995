import { Button, Group, Modal, Text } from "@mantine/core"

import { abortProjectSchema } from "@ensol/types/forms/projects/abort"

import { CustomActionModalProps } from "@ensol/entool/components/entities/Project/StepActions"
import { SchedulingIssueFormField } from "@ensol/entool/components/form/Project/SchedulingIssueFormField"
import { ABORT_REASONS_OPTIONS } from "@ensol/entool/utils/projects/options"

import { useAbortProjectReasonsModal } from "./useAbortProjectReasonsModal"

export const AbortProjectReasonsModal = (props: CustomActionModalProps) => {
  const { opened, onClose, project } = props

  const { form, handleSubmit, isLoading } = useAbortProjectReasonsModal(props)

  return (
    <Modal
      opened={opened}
      size="lg"
      padding="xl"
      centered
      onClose={onClose}
      title={
        <Text size="lg" fw="bold">
          Sélectionner la raison de l&apos;abandon du projet
        </Text>
      }
    >
      <form
        onSubmit={form.onSubmit((values) =>
          handleSubmit(abortProjectSchema.parse(values)),
        )}
      >
        <SchedulingIssueFormField
          options={ABORT_REASONS_OPTIONS}
          form={form}
          initialValues={{
            schedulingIssueMainReason: project.schedulingIssueMainReason,
            schedulingIssueSubReason: project.schedulingIssueSubReason,
          }}
        />
        <Group justify="flex-end" mt={24}>
          <Button variant="outline" onClick={onClose} disabled={isLoading}>
            Annuler
          </Button>
          <Button type="submit" loading={isLoading} disabled={!form.isValid()}>
            Valider
          </Button>
        </Group>
      </form>
    </Modal>
  )
}
