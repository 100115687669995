import { Button, Stack, Text } from "@mantine/core"
import { IconPlus } from "@tabler/icons-react"

import { ClientResponses } from "@ensol/types/endpoints/clients"

import { InstallationRow } from "./InstallationRow"
import { useInstallations } from "./useInstallations"

export type InstallationsProps = {
  house: ClientResponses.ClientDetails["houses"][number]
}

export const Installations = ({ house }: InstallationsProps) => {
  const { createNewInstallation, canBundle, isCreatingInstallation } =
    useInstallations({ house })

  return (
    <Stack flex="1">
      {house.installations.length > 0 ? (
        house.installations.map((installation) => (
          <InstallationRow
            key={installation.id}
            installation={installation}
            canBundle={canBundle}
          />
        ))
      ) : (
        <Text c="gray">
          Cette maison n&apos;a pas encore d&apos;installation...
        </Text>
      )}
      <Button
        w="fit-content"
        leftSection={<IconPlus size={18} />}
        onClick={createNewInstallation}
        variant="outline"
        data-test="createInstallationButton"
        loading={isCreatingInstallation}
      >
        Nouvelle installation
      </Button>
    </Stack>
  )
}
