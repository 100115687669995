import { Button, Stack, ThemeIcon, Text } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconPlug } from "@tabler/icons-react"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { UnpackArray } from "@ensol/types/utils"

import { Section } from "@ensol/shared/components/Section"
import { ControlDeviceType } from "@ensol/shared/material/controlDevices"

import { MaterialSelectorModal } from "@ensol/entool/pages/Installation/components/MaterialSelectorModal"
import { ControlDeviceCard } from "@ensol/entool/pages/Installation/sections/7-ControlDevices/ControlDeviceCard"
import { getControlDeviceOptions } from "@ensol/entool/utils/installations/controlDevice"

type Props = {
  installationId: string
  controlDeviceInstallations: InstallationsResponses.ControlDeviceInstallations
  house: HousesResponses.House
  insertListItem: (
    data: UnpackArray<InstallationsResponses.ControlDeviceInstallations>,
  ) => void
  removeListItem: (index: number) => void
  replaceListItem: (
    index: number,
    data: UnpackArray<InstallationsResponses.ControlDeviceInstallations>,
  ) => void
}

export const ControlDeviceInstallations = ({
  installationId,
  controlDeviceInstallations,
  house,
  insertListItem,
  removeListItem,
  replaceListItem,
}: Props) => {
  const [isSelectorOpened, { open: openSelector, close: closeSelector }] =
    useDisclosure(false)

  return (
    <Section
      title="Équipements de pilotage installés"
      Actions={
        <Button onClick={openSelector} data-test="addControlDeviceButton">
          Ajouter
        </Button>
      }
    >
      {controlDeviceInstallations.length > 0 ? (
        <Stack>
          {controlDeviceInstallations.map(
            (controlDeviceInstallation, index) => (
              <ControlDeviceCard
                key={`${controlDeviceInstallation.deviceType};${controlDeviceInstallation.controlledEquipments.join("-")}`}
                installationId={installationId}
                controlDeviceInstallation={controlDeviceInstallation}
                houseEquipments={house.equipments}
                onDelete={() => removeListItem(index)}
                onChange={(data) => replaceListItem(index, data)}
              />
            ),
          )}
        </Stack>
      ) : (
        <Stack align="center" gap="4">
          <ThemeIcon color="gray.6" size="32">
            <IconPlug />
          </ThemeIcon>
          <Text c="gray.6">
            Aucun équipement de pilotage n&apos;a encore été installé.
          </Text>
        </Stack>
      )}
      <MaterialSelectorModal<ControlDeviceType>
        title="Sélectionner un ou plusieurs équipements de pilotage"
        isOpened={isSelectorOpened}
        onClose={closeSelector}
        extraColumnsLabel={[]}
        onChange={(deviceType) =>
          insertListItem({
            deviceType,
            controlledEquipments: [],
            isFree: false,
          })
        }
        options={getControlDeviceOptions()}
      />
    </Section>
  )
}
