import _ from "lodash"

import { RoofType } from "@ensol/types/prisma-client"
import { typedKeys } from "@ensol/types/utils"

import { ContractHolderType } from "@ensol/shared/entities/houses/switchgrid"
import { CURRENT_TYPES, CurrentType } from "@ensol/shared/material/currentType"
import { ROOF_TYPES } from "@ensol/shared/material/roofType"

import { getOptionsFromList } from "@ensol/entool/utils/form/options"
import { Option } from "@ensol/entool/utils/form/radio"

export const CURRENT_TYPE_OPTIONS: Option<CurrentType>[] = _.map(
  CURRENT_TYPES,
  (current, currentType) => ({
    label: current.name,
    value: currentType as CurrentType,
  }),
)

export const ROOF_TYPE_OPTIONS: Option<RoofType>[] = typedKeys(ROOF_TYPES).map(
  (roofType) => {
    const { name } = ROOF_TYPES[roofType]

    return {
      label: name,
      value: roofType,
    }
  },
)

export const FLOORS_OPTIONS = [
  { label: "R0", value: 0 },
  { label: "R1", value: 1 },
  { label: "R2", value: 2 },
  { label: "R3", value: 3 },
]

export const ROOF_FRAMING_MATERIALS_OPTIONS = [
  { label: "Bois", value: "wood" },
  { label: "Béton", value: "concrete" },
  { label: "Métal", value: "metal" },
]

export const POTENTIAL_SHADINGS_OPTIONS = [
  { label: "Non", subtitle: "Pas d'ombrage", value: "no" },
  { label: "Lointain", subtitle: "Ex: colline", value: "distant" },
  {
    label: "Proche",
    subtitle: "Ex: arbre",
    value: "near",
  },
]

export const EXISTING_INSTALLATION_CONTRACT_OPTIONS = getOptionsFromList([
  "Auto-consommation",
  "Revente totale",
])

export const EXISTING_INSTALLATION_AGE_OPTIONS = getOptionsFromList([
  "- 18 mois",
  "+ 18 mois",
])

export const CONTRACT_HOLDER_TYPE_OPTIONS = [
  {
    label: "Particulier",
    value: ContractHolderType.INDIVIDUAL,
  },
  {
    label: "Entreprise",
    value: ContractHolderType.COMPANY,
  },
]

export const UNREGULATED_CONSTRUCTION_OPTIONS = [
  { label: "Oui", value: "yes" },
  { label: "Non", value: "no" },
  { label: "Ne sait pas", value: "don't know" },
]

export const PARKING_EASE_OPTIONS = getOptionsFromList([
  "Propriété",
  "Alentours facile",
  "Alentours compliqué",
])
