import { UserResponses } from "@ensol/types/endpoints/users"

import {
  AuthorizedGroup,
  ROLE_GROUPS_CONFIG,
} from "@ensol/shared/entities/users"

export const isUserAuthorized = (
  authorizedRoles: AuthorizedGroup[],
  user?: UserResponses.User,
) => {
  if (!user) {
    return false
  }

  return authorizedRoles.some(({ group, minimumRole }) => {
    const userRoleWithLevel = ROLE_GROUPS_CONFIG[group].find(
      ({ role }) => role === user.role,
    )
    const minimumRoleWithLevel = ROLE_GROUPS_CONFIG[group].find(
      ({ role }) => role === minimumRole,
    )

    if (!userRoleWithLevel || !minimumRoleWithLevel) {
      return false
    }

    return userRoleWithLevel.level >= minimumRoleWithLevel.level
  })
}
