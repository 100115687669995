import { submittedStepFormSchema } from "@ensol/types/forms/projects/preliminaryRequest"

import { EmailEvent } from "@ensol/shared/entities/projects/emails"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { PreliminaryRequestForm } from "@ensol/entool/pages/Project/processes/PreliminaryRequest/components/PreliminaryRequestForm"
import { pickDefinedProperties } from "@ensol/entool/utils/helpers/pick"
import { getInitialValues } from "@ensol/entool/utils/initialValues"

import { PRELIMINARY_REQUEST_PROPERTIES } from "./config"

export const Submitted = ({ project, ...props }: StepComponentProps) => (
  <PreliminaryRequestForm
    schema={submittedStepFormSchema}
    initialValues={{
      ...pickDefinedProperties(PRELIMINARY_REQUEST_PROPERTIES, project),
      ...getInitialValues(submittedStepFormSchema, project),
    }}
    emails={[
      {
        eventName: EmailEvent.PRELIMINARY_REQUEST_ACKNOWLEDGED,
        label: "Récépissé",
      },
    ]}
    project={project}
    actions={[{ action: "validate", label: "Accusé de réception reçu" }]}
    {...props}
  />
)
