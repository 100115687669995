import { IconArrowBackUp } from "@tabler/icons-react"

import { incompleteStepFormSchema } from "@ensol/types/forms/projects/enedis"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { EnedisForm } from "@ensol/entool/pages/Project/processes/Enedis/components/EnedisForm"
import { getInitialValues } from "@ensol/entool/utils/initialValues"

export const Incomplete = ({ project, processId }: StepComponentProps) => (
  <EnedisForm
    schema={incompleteStepFormSchema}
    initialValues={getInitialValues(incompleteStepFormSchema, project)}
    project={project}
    processId={processId}
    actions={[
      {
        action: "validate",
        label: "Repasser en soumis",
        Icon: IconArrowBackUp,
      },
    ]}
  />
)
