import { useForm, zodResolver } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import * as z from "zod"

import { useUpdateHouseAlertMutation } from "@ensol/entool/queries/houses"

const ignoreAlertSchema = z.object({
  ignoredUntil: z.date({ invalid_type_error: "Champ obligatoire" }),
  ignoreReason: z.string({ invalid_type_error: "Champ obligatoire" }),
})

export const useIgnoreAlert = (alertId: string) => {
  const ignoreForm = useForm({
    validate: zodResolver(ignoreAlertSchema),
  })

  const [isIgnoreFormOpen, { open: openIgnoreForm, close: closeIgnoreForm }] =
    useDisclosure(false)

  const { mutateAsync: updateAlert } = useUpdateHouseAlertMutation(alertId)

  return {
    ignoreForm,
    isIgnoreFormOpen,
    openIgnoreForm,
    closeIgnoreForm,
    onSubmit: async () => {
      await updateAlert(ignoreForm.values)
      closeIgnoreForm()
    },
  }
}
