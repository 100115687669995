import axios from "axios"

import { createHTTPClient } from "@ensol/shared/backend/axios"

import { clerk } from "./clerk"

const axiosClient = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
})

export const httpClient = createHTTPClient(axiosClient, clerk)
