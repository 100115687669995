import { SegmentedControl, Text } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconTrendingUp, IconWaveSine } from "@tabler/icons-react"

import {
  checkInverterCompatibility,
  computeInvertersCount,
} from "@ensol/shared/entities/installations/energy"
import { getBrand } from "@ensol/shared/material/brands"
import { CurrentType } from "@ensol/shared/material/currentType"
import {
  getInverter,
  InverterType,
} from "@ensol/shared/material/photovoltaic/inverters"
import { roundDecimalNumber } from "@ensol/shared/utils/format"

import { MaterialCard } from "@ensol/entool/pages/Installation/components/MaterialCard"
import { MaterialSelectorModal } from "@ensol/entool/pages/Installation/components/MaterialSelectorModal"
import { InverterOption } from "@ensol/entool/utils/installations/photovoltaic"

type Props = {
  inverterType: InverterType
  panelsCount: number
  totalPanelsCount: number
  installedCapacity: number
  currentType: CurrentType
  invertersOptions: InverterOption[]
  onChange: (inverterType: InverterType) => void
  isDisabled?: boolean
}

enum InverterTypeOption {
  MICRO = "MICRO",
  CENTRAL = "CENTRAL",
}

const INVERTER_TYPE_OPTIONS = [
  { label: "Micro", value: InverterTypeOption.MICRO },
  { label: "Central", value: InverterTypeOption.CENTRAL },
]

export const InverterSelector = ({
  inverterType,
  panelsCount,
  totalPanelsCount,
  installedCapacity,
  currentType,
  invertersOptions,
  onChange,
  isDisabled = false,
}: Props) => {
  const [opened, { open, close }] = useDisclosure(false)

  const inverter = getInverter(inverterType)
  const invertersCount = computeInvertersCount(inverterType, panelsCount)
  const inverterTypeLabel = inverter.isCentralInverter
    ? "onduleur central"
    : "micro-onduleurs"

  const { DCACPercent, isRecommended, isCompatible } =
    checkInverterCompatibility({
      installedCapacity,
      panelsCount: totalPanelsCount,
      inverterType,
      currentType,
    })

  const options = invertersOptions.map((inverterOption) => {
    const isSelected = inverterOption.value === inverterType
    const inverterInfo = getInverter(inverterOption.value)
    const brandInfo = getBrand(inverterInfo.brand)

    const DCACRow = inverterOption.metadata?.DCACPercent
      ? `${roundDecimalNumber(inverterOption.metadata.DCACPercent, 0)}%`
      : "-"

    return {
      value: inverterOption.value,
      brandInfo,
      ...inverterInfo,
      isSelected,
      isCompatible: !inverterOption.disabled,
      isRecommended: inverterOption.metadata?.recommended,
      isCentralInverter: inverterInfo.isCentralInverter,
      extraColumnsValues: [
        inverterInfo.isCentralInverter ? "Central" : "Micro",
        <Text
          key="DC/AC"
          c={inverterOption.metadata?.recommended ? "inherit" : "red"}
        >
          {DCACRow}
        </Text>,
      ],
    }
  })

  return (
    <>
      <MaterialCard
        {...inverter}
        action={
          !isDisabled && (
            <SegmentedControl
              size="xs"
              data={INVERTER_TYPE_OPTIONS}
              value={
                inverter.isCentralInverter
                  ? InverterTypeOption.CENTRAL
                  : InverterTypeOption.MICRO
              }
              onChange={(value) => {
                if (value === InverterTypeOption.CENTRAL) {
                  const firstRecommendedCentralInverter = options.find(
                    ({ isCentralInverter, isRecommended }) =>
                      isCentralInverter && isRecommended,
                  )
                  firstRecommendedCentralInverter &&
                    onChange(firstRecommendedCentralInverter.value)
                } else {
                  const firstRecommendedMicroInverter = options.find(
                    ({ isCentralInverter, isRecommended }) =>
                      !isCentralInverter && isRecommended,
                  )
                  firstRecommendedMicroInverter &&
                    onChange(firstRecommendedMicroInverter.value)
                }
              }}
            />
          )
        }
        badge={`${invertersCount} ${inverterTypeLabel}`}
        pictureUrl={inverter.pictureUrl}
        dataTest="updateInverterTypeButton"
        properties={[
          {
            icon: IconWaveSine,
            label: `Type ${inverterTypeLabel}`,
          },
          {
            icon: IconTrendingUp,
            label: `Rapport DC/AC: ${DCACPercent}%`,
            isWarning: !isRecommended || !isCompatible,
          },
        ]}
        {...(isDisabled
          ? {}
          : {
              onClick: open,
              ctaLabel: "Changer onduleur",
            })}
      />
      <MaterialSelectorModal<InverterType>
        title="Sélectionner un type de borne"
        isOpened={opened}
        onClose={close}
        onChange={onChange}
        extraColumnsLabel={["Type Onduleur", "Rapport DC/AC"]}
        options={options}
      />
    </>
  )
}
