import { Divider, Loader, Select, Stack, Text } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationFormInput } from "@ensol/types/forms/installations"
import { SimulationResults } from "@ensol/types/simulation"

import { Section } from "@ensol/shared/components/Section"
import { getCoords } from "@ensol/shared/entities/houses/coords"
import { getMaxDiscount } from "@ensol/shared/entities/installations/costs/discounts"
import { REFERRAL_DISCOUNT } from "@ensol/shared/entities/installations/savings"
import { Role, RoleGroups } from "@ensol/shared/entities/users"
import { isUserAuthorized } from "@ensol/shared/entities/users/roles"
import { formatFullName, formatNumber } from "@ensol/shared/utils/format"

import { Field } from "@ensol/entool/components/form/Field"
import { NumberInput } from "@ensol/entool/components/form/NumberInput"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { useListClientsQuery } from "@ensol/entool/queries/clients"
import { useEnergyProductionPerDataSource } from "@ensol/entool/queries/energy"
import { useAuthenticatedUser } from "@ensol/entool/queries/users"
import { PRODUCTION_DATA_SOURCE_OPTIONS } from "@ensol/entool/utils/installations/options"

type Props = {
  form: UseFormReturnType<InstallationFormInput>
  house: HousesResponses.House<{
    include: {
      switchgridConsent: true
      switchgridOrder: true
      roofSections: true
    }
  }>
  simulationResults?: SimulationResults
}

export const Settings = ({ form, house, simulationResults }: Props) => {
  const {
    data: referrersOptions,
    isPending: isLoadingReferrers,
    isError: isErrorReferrers,
  } = useListClientsQuery({
    select: (data) =>
      data.map((client) => ({
        label: formatFullName(client),
        value: client.id,
      })),
  })

  const {
    data: energyProductionPerDataSource,
    isPending: isEnergyProductionLoading,
  } = useEnergyProductionPerDataSource({
    coords: getCoords(house),
    installation: form.values,
  })

  const { data: user } = useAuthenticatedUser()

  const productionDataSourceOptions = PRODUCTION_DATA_SOURCE_OPTIONS.map(
    (option) => {
      const energyProduction = energyProductionPerDataSource?.[option.value]

      return {
        ...option,
        disabled: !isEnergyProductionLoading && !energyProduction,
        subtitle: isEnergyProductionLoading ? (
          <Loader size={24} type="dots" />
        ) : energyProduction ? (
          `Production: ${formatNumber(energyProduction)} kWh/an`
        ) : (
          "Indisponible"
        ),
      }
    },
  )

  return (
    <>
      <Section title="Avantages">
        <Stack gap={32}>
          <Stack gap={12} maw={300}>
            <Text fw={600} fz="lg">
              Parrain
            </Text>
            <Field name="Sélectionner le nom du parrain">
              <Select
                w="100%"
                clearable
                searchable
                data={referrersOptions}
                disabled={isErrorReferrers || isLoadingReferrers}
                {...form.getInputProps("referrerId")}
                onChange={(value) => {
                  form.setFieldValue("referrerId", value)
                  if (!form.values.discount && value !== null) {
                    form.setFieldValue("discount", REFERRAL_DISCOUNT)
                  } else if (
                    value === null &&
                    form.values.discount === REFERRAL_DISCOUNT
                  ) {
                    form.setFieldValue("discount", 0)
                  }
                }}
              />
            </Field>
          </Stack>
          <Divider />
          <Stack gap={12} maw={300} align="flex-start">
            <Text fw={600} fz="lg">
              Réduction TTC
            </Text>
            <Field name="Montant estimé">
              <NumberInput
                w={300}
                max={
                  !isUserAuthorized(
                    [{ group: RoleGroups.ENSOL, minimumRole: Role.MANAGER }],
                    user,
                  )
                    ? getMaxDiscount(simulationResults)
                    : undefined
                }
                disabled={!simulationResults}
                ml="auto"
                suffix="€"
                min={0}
                {...form.getInputProps("discount")}
              />
            </Field>
          </Stack>
        </Stack>
      </Section>
      <Section title="Paramètres">
        <Stack gap={32}>
          <Stack gap={12} maw={300}>
            <Text fw={600} fz="lg">
              Coût électricité
            </Text>
            <Field
              name="Augmentation annuelle du prix de l'électricité"
              helpText="Recommandé : 5%"
            >
              <NumberInput
                w={300}
                ml="auto"
                suffix="%"
                min={0}
                max={100}
                isNullable={false}
                {...form.getInputProps("electricityPriceAnnualRaise")}
              />
            </Field>
          </Stack>
          <Stack gap={12} maw={300}>
            <Text fw={600} fz="lg">
              Source des données de production
            </Text>
            <RadioGroup
              options={productionDataSourceOptions}
              {...form.getInputProps("productionDataSource")}
            />
          </Stack>
        </Stack>
      </Section>
    </>
  )
}
