import { Group, Input, SimpleGrid, Stack } from "@mantine/core"
import { DateInput } from "@mantine/dates"
import { UseFormReturnType } from "@mantine/form"
import { IconGavel } from "@tabler/icons-react"

import { RequiredWorksInput } from "@ensol/types/forms/projects/technicalVisit"
import { Client } from "@ensol/types/prisma-client"
import { Nullable } from "@ensol/types/utils"

import { Section } from "@ensol/shared/components/Section"
import { getClientFileName } from "@ensol/shared/utils/files"
import { DISPLAY_DATE_FORMAT } from "@ensol/shared/utils/format"

import { CheckboxGroup } from "@ensol/entool/components/form/CheckboxGroup"
import { MultiFileField } from "@ensol/entool/components/form/File/MultiFileField"
import { FormField } from "@ensol/entool/components/form/FormField"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { TECHNICAL_VISIT_REQUIRED_WORKS_OPTIONS } from "@ensol/entool/utils/projects/options"

type Props<Values> = {
  form: UseFormReturnType<Values>
  initialValues: Nullable<RequiredWorksInput>
  client: Pick<Client, "firstName" | "lastName">
}

export const RequiredWorksForm = <Values,>({
  form,
  initialValues,
  client,
}: Props<Values>) => (
  <Section title="Travaux" icon={IconGavel} iconColor="red.5" titleOrder={3}>
    <SimpleGrid cols={2} spacing="32" mt="16">
      <Stack gap={32}>
        <FormField
          initialValues={initialValues}
          propertyName="technicalVisitRequiredWorks"
          align="flex-start"
        >
          <CheckboxGroup
            options={TECHNICAL_VISIT_REQUIRED_WORKS_OPTIONS}
            {...form.getInputProps("technicalVisitRequiredWorks")}
          />
        </FormField>
        <FormField
          initialValues={initialValues}
          propertyName="technicalVisitStartOfWorksDate"
        >
          <DateInput
            w={FIELD_WIDTH}
            valueFormat={DISPLAY_DATE_FORMAT}
            clearable
            {...form.getInputProps("technicalVisitStartOfWorksDate")}
          />
        </FormField>

        <FormField
          initialValues={initialValues}
          propertyName="technicalVisitEndOfWorksDate"
          isRequired
        >
          <DateInput
            w={FIELD_WIDTH}
            valueFormat={DISPLAY_DATE_FORMAT}
            clearable
            {...form.getInputProps("technicalVisitEndOfWorksDate")}
          />
        </FormField>
        <FormField
          initialValues={initialValues}
          propertyName="technicalVisitWorksArtisan"
        >
          <Input
            w={FIELD_WIDTH}
            {...form.getInputProps("technicalVisitWorksArtisan")}
          />
        </FormField>
      </Stack>
      <Group align="flex-end">
        <FormField
          initialValues={initialValues}
          propertyName="technicalVisitWorksPhotos"
        >
          <MultiFileField
            previewProps={{
              fileName: getClientFileName("photos-travaux-post-VT", client),
            }}
            inputProps={{
              accept: ["image/png", "image/jpeg", "image/heic"],
            }}
            {...form.getInputProps("technicalVisitWorksPhotos")}
          />
        </FormField>
      </Group>
    </SimpleGrid>
  </Section>
)
