import { Stack, Text, ScrollArea, ThemeIcon } from "@mantine/core"
import { IconInbox } from "@tabler/icons-react"

import { Notification } from "@ensol/types/entities/notifications"

import { NotificationRow } from "./NotificationRow"

type Props = {
  notifications: Notification[]
  onDrawerClose: () => void
}

export const NotificationsList = ({ notifications, onDrawerClose }: Props) => {
  if (notifications.length === 0) {
    return (
      <Stack p="24" gap="8" align="center">
        <ThemeIcon size={28} c="gray.7">
          <IconInbox stroke={1.5} />
        </ThemeIcon>
        <Text size="lg">Vous êtes à jour !</Text>
        <Text ta="center" c="gray.7">
          Revenez ici pour voir vos prochaines notifications.
        </Text>
      </Stack>
    )
  }

  return (
    <ScrollArea h="100%">
      {notifications.map((notification) => (
        <NotificationRow
          key={notification.id}
          notification={notification}
          onDrawerClose={onDrawerClose}
        />
      ))}
    </ScrollArea>
  )
}
