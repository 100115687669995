import { SimpleGrid, Stack } from "@mantine/core"
import { IconBatteryCharging2 } from "@tabler/icons-react"
import { useState } from "react"

import { Section } from "@ensol/shared/components/Section"
import { EvChargerType, getEvCharger } from "@ensol/shared/material/evCharger"
import { roundDecimalNumber } from "@ensol/shared/utils/format"

import { StatComparisonCard } from "@ensol/entool/components/StatComparisonCard"
import { Field } from "@ensol/entool/components/form/Field"
import { NumberInput } from "@ensol/entool/components/form/NumberInput"

type Props = {
  evChargerType: EvChargerType
  currentElectricityPrice: number
  targetElectricityPrice: number
}

const OUTLET_POWER_IN_WC = 3

export const StatsSection = ({
  evChargerType,
  currentElectricityPrice,
  targetElectricityPrice,
}: Props) => {
  const [evBatteryCapacity, setEvBatteryCapacity] = useState(50)
  const evCharger = getEvCharger(evChargerType)
  const powerOutletRechargeDuration = roundDecimalNumber(
    evBatteryCapacity / OUTLET_POWER_IN_WC,
    0,
  )
  const evChargerRechargeDuration = roundDecimalNumber(
    evBatteryCapacity / evCharger.power,
    0,
  )

  return (
    <Section title="Roulez pour presque rien">
      <Stack>
        <SimpleGrid cols={2} spacing={12}>
          <StatComparisonCard
            label="Durée de recharge (prise standard vs borne)"
            startStat={powerOutletRechargeDuration}
            targetStat={evChargerRechargeDuration}
            unit="h"
          />
          <StatComparisonCard
            label="Prix du plein (achat réseau vs solaire)"
            startStat={
              currentElectricityPrice > 0
                ? roundDecimalNumber(
                    evBatteryCapacity * currentElectricityPrice,
                    0,
                  )
                : 0
            }
            targetStat={
              targetElectricityPrice > 0
                ? roundDecimalNumber(
                    evBatteryCapacity * targetElectricityPrice,
                    0,
                  )
                : 0
            }
            unit="€"
          />
        </SimpleGrid>
        <Field name="Capacité batterie" w="150">
          <NumberInput
            suffix=" kWh"
            value={evBatteryCapacity}
            leftSection={<IconBatteryCharging2 size={16} />}
            onChange={(value) => setEvBatteryCapacity(Number(value))}
            isNullable={false}
            hideControls
          />
        </Field>
      </Stack>
    </Section>
  )
}
