import { Project } from "@ensol/types/entities/project"

import { colors } from "@ensol/shared/styles/colors"

import { NotFound } from "@ensol/entool/components/NotFound"
import { SubSection } from "@ensol/entool/components/SubSection"
import { MultiFilePreview } from "@ensol/entool/components/entities/File/MultiFilePreview"

type Props = {
  project: Project<{
    select: {
      technicalVisitPanelsLayoutPath: true
      salesVisitPanelsLayoutPath: true
    }
  }>
}

export const PanelsLayout = ({ project }: Props) => (
  <SubSection title="Calepinage">
    {project.salesVisitPanelsLayoutPath ||
    project.technicalVisitPanelsLayoutPath ? (
      <MultiFilePreview
        w="100%"
        maw="600px"
        h="250px"
        files={[
          ...(project.technicalVisitPanelsLayoutPath
            ? [
                {
                  path: project.technicalVisitPanelsLayoutPath,
                  tag: {
                    label: "Visite technique",
                    color: colors.purple[500],
                  },
                },
              ]
            : []),
          ...(project.salesVisitPanelsLayoutPath
            ? [
                {
                  path: project.salesVisitPanelsLayoutPath,
                  tag: {
                    label: "Visite commerciale",
                    color: colors.blue[500],
                  },
                },
              ]
            : []),
        ]}
        fileName="calepinage.pdf"
      />
    ) : (
      <NotFound w="100%" maw="600px" h="250" type="file" />
    )}
  </SubSection>
)
