import { Badge } from "@mantine/core"
import {
  IconBatteryCharging,
  IconChargingPile,
  IconDashboard,
  IconSolarPanel,
} from "@tabler/icons-react"

import { Installation } from "@ensol/types/entities/installation"

type Props = {
  installation: Installation<{
    select: {
      isUpsell: true
      photovoltaicInstallation: {
        select: object
      }
      batteryInstallation: {
        select: object
      }
      evChargerInstallation: {
        select: object
      }
      controlDeviceInstallations: {
        select: object
      }
    }
  }>
}

export const InstallationProducts = ({ installation }: Props) => (
  <>
    {installation.photovoltaicInstallation !== null && (
      <Badge variant="light" leftSection={<IconSolarPanel size={14} />}>
        {installation.isUpsell ? "Panneaux" : "PV"}
      </Badge>
    )}
    {installation.batteryInstallation !== null && (
      <Badge variant="light" leftSection={<IconBatteryCharging size={16} />}>
        Batterie
      </Badge>
    )}
    {installation.evChargerInstallation !== null && (
      <Badge variant="light" leftSection={<IconChargingPile size={16} />}>
        Borne
      </Badge>
    )}
    {installation.controlDeviceInstallations.length > 0 && (
      <Badge variant="light" leftSection={<IconDashboard size={16} />}>
        Pilotage
      </Badge>
    )}
  </>
)
