import { onboardingPendingFormSchema } from "@ensol/types/forms/projects/followUp"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { FollowUpForm } from "@ensol/entool/pages/Project/processes/FollowUp/components/FollowUpForm"
import { getInitialValues } from "@ensol/entool/utils/initialValues"

export const MoreThan6MonthsProducing = ({
  project,
  ...props
}: StepComponentProps) => (
  <FollowUpForm
    schema={onboardingPendingFormSchema}
    initialValues={getInitialValues(onboardingPendingFormSchema, project)}
    project={project}
    actions={[]}
    {...props}
  />
)
