import {
  Button,
  Group,
  ScrollArea,
  Stack,
  Tabs,
  Title,
  Text,
} from "@mantine/core"
import { useDisclosure, useMediaQuery } from "@mantine/hooks"
import _ from "lodash"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { KizeoProjectReportType } from "@ensol/types/entities/project/kizeoProjectReport"

import { Section } from "@ensol/shared/components/Section"
import { devices } from "@ensol/shared/styles/theme"

import { CreateReportModal } from "./CreateReportModal"
import { ReportCard } from "./ReportCard"
import { KIZEO_PROJECT_REPORT_TYPE_LABELS } from "./constants"

type Props = {
  project: ProjectResponses.ProjectTechnicalOverview
}

export const KizeoProjectReportsSection = ({ project }: Props) => {
  const [
    isCreateReportModalOpen,
    { open: openCreateReportModal, close: closeCreateReportModal },
  ] = useDisclosure()

  const isMobile = useMediaQuery(devices.sm)

  return (
    <Section
      isCollapsed={true}
      title={
        <Group gap={8}>
          <Title order={2}>Compte rendu</Title>
        </Group>
      }
      Actions={
        <Button onClick={openCreateReportModal} variant="light">
          Créer un CR
        </Button>
      }
      isCollapsible
    >
      {project.kizeoProjectReports.length === 0 ? (
        <Text>Vous n&apos;avez pas encore créé de CR pour ce projet</Text>
      ) : (
        <Tabs defaultValue="ALL">
          <Tabs.List justify={isMobile ? "center" : "start"}>
            <Tabs.Tab value="ALL">
              Tous ({project.kizeoProjectReports.length})
            </Tabs.Tab>
            {Object.values(KizeoProjectReportType).map((type) => {
              const count =
                _.countBy(project.kizeoProjectReports, (report) => report.type)[
                  type
                ] ?? 0
              return (
                <Tabs.Tab key={type} value={type} disabled={count === 0}>
                  {KIZEO_PROJECT_REPORT_TYPE_LABELS[type]} ({count})
                </Tabs.Tab>
              )
            })}
          </Tabs.List>

          <Tabs.Panel value="ALL">
            <ScrollArea.Autosize mah={400} mt={24}>
              <Stack gap={24} pr={24}>
                {project.kizeoProjectReports.map((kizeoProjectReport) => (
                  <ReportCard
                    key={kizeoProjectReport.kizeoDataId}
                    kizeoProjectReport={kizeoProjectReport}
                  />
                ))}
              </Stack>
            </ScrollArea.Autosize>
          </Tabs.Panel>
          {Object.values(KizeoProjectReportType).map((type) => (
            <Tabs.Panel key={type} value={type}>
              <ScrollArea.Autosize mah={400} mt={24}>
                <Stack gap={24} pr={24}>
                  {project.kizeoProjectReports
                    .filter((report) => report.type === type)
                    .map((kizeoProjectReport) => (
                      <ReportCard
                        key={kizeoProjectReport.kizeoDataId}
                        kizeoProjectReport={kizeoProjectReport}
                      />
                    ))}
                </Stack>
              </ScrollArea.Autosize>
            </Tabs.Panel>
          ))}
        </Tabs>
      )}
      <CreateReportModal
        isOpen={isCreateReportModalOpen}
        onClose={closeCreateReportModal}
        project={project}
      />
    </Section>
  )
}
