import { Group, Badge } from "@mantine/core"
import { IconTools } from "@tabler/icons-react"

import { REQUIRED_CHANGES } from "@ensol/shared/entities/projects/technicalVisit"

import { SubSection } from "@ensol/entool/components/SubSection"

type Props = {
  requiredChanges: string[]
}

export const RequiredChanges = ({ requiredChanges }: Props) => {
  return (
    <SubSection title="Changement requis">
      <Group gap={8}>
        {requiredChanges.map((change) => (
          <Badge
            key={change}
            color="yellow"
            fw="bold"
            variant="light"
            leftSection={<IconTools size={16} />}
          >
            {REQUIRED_CHANGES[change]}
          </Badge>
        ))}
      </Group>
    </SubSection>
  )
}
