import * as Sentry from "@sentry/react"
import Compressor from "compressorjs"
import heic2any from "heic2any"

import { FILE_UPLOAD_FIELD_NAME } from "@ensol/shared/utils/files"

const IMAGE_TYPES = ["jpg", "jpeg", "png", "heic"]

export const isImage = (type?: string) => {
  if (!type) {
    return false
  }
  return IMAGE_TYPES.includes(type.toLowerCase())
}

export const convertHeicToJpeg = async (file: File) => {
  try {
    if (file.type === "image/heic") {
      const convertedImageBlob = await heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 0.5,
      })

      return new File(
        [convertedImageBlob] as BlobPart[],
        `${FILE_UPLOAD_FIELD_NAME}.jpg`,
        { type: "image/jpeg" },
      )
    }
  } catch (error) {
    Sentry.captureException(new Error("Can't convert HEIC image"), {
      extra: { error },
    })
  }

  return file
}

export const compressImage = async (file: File): Promise<File> =>
  await new Promise<File>((resolve) => {
    new Compressor(file, {
      maxHeight: 2000,
      maxWidth: 2000,
      quality: 0.7,
      mimeType: "image/jpeg",
      success(result: Blob) {
        resolve(new File([result], file.name, { type: "image/jpeg" }))
      },
    })
  })

export const blobToBase64 = async (blob: Blob): Promise<string> =>
  new Promise<string>((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result?.toString() ?? "")
    reader.readAsDataURL(blob)
  })

export const convertCanvasToBlob = async (
  canvas: HTMLCanvasElement,
): Promise<Blob | null> =>
  await new Promise<Blob | null>((resolve) => {
    canvas.toBlob((blob) => resolve(blob))
  })
