import { submittedStepFormSchema } from "@ensol/types/forms/projects/greendeal"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { GreendealForm } from "@ensol/entool/pages/Project/processes/Greendeal/components/GreendealForm"
import { getInitialValues } from "@ensol/entool/utils/initialValues"

export const FileCompleted = ({ project, ...props }: StepComponentProps) => {
  return (
    <GreendealForm
      schema={submittedStepFormSchema}
      initialValues={getInitialValues(submittedStepFormSchema, project)}
      project={project}
      actions={[{ action: "validate", label: "Demande validée" }]}
      {...props}
    />
  )
}
