import { Button, Group, Stack, Text, ThemeIcon } from "@mantine/core"
import {
  IconExternalLink,
  IconShieldCheck,
  TablerIcon,
} from "@tabler/icons-react"
import { ReactNode } from "react"

import { BrandType, getBrand } from "@ensol/shared/material/brands"

import { MaterialImage } from "./MaterialImage"

type MaterialProperty = {
  icon: TablerIcon
  label: string
  isWarning?: boolean
  href?: string
}

export const MaterialProperty = ({
  icon: Icon,
  label,
  isWarning,
  href,
}: MaterialProperty) => {
  const color = isWarning ? "red.4" : "gray.7"
  return (
    <Group
      gap={4}
      wrap="nowrap"
      {...(href ? { component: "a", href, target: "_blank" } : {})}
    >
      <ThemeIcon c={color} variant="subtle" size="xs">
        <Icon />
      </ThemeIcon>
      <Text c={color}>{label}</Text>
    </Group>
  )
}

type Props = {
  name: string
  brand: BrandType
  warranty: number
  techDocUrl: string
  badge?: string
  action?: ReactNode
  pictureUrl: string
  properties: MaterialProperty[]
  ctaLabel?: string
  dataTest?: string
  onClick?: () => void
  imgMaw?: number
}

export const MaterialCard = ({
  name,
  brand,
  warranty,
  techDocUrl,
  action,
  badge,
  pictureUrl,
  properties,
  ctaLabel,
  dataTest,
  onClick,
  imgMaw,
}: Props) => {
  return (
    <Stack gap={4}>
      <MaterialImage
        action={action}
        badge={badge}
        pictureUrl={pictureUrl}
        imgMaw={imgMaw}
      />
      <Text c="gray.7">{getBrand(brand).name}</Text>
      <Text fw={500}>{name}</Text>
      {properties.map((property) => (
        <MaterialProperty key={property.label} {...property} />
      ))}
      <MaterialProperty
        icon={IconShieldCheck}
        label={`Garantie ${warranty} ans`}
      />
      <MaterialProperty
        icon={IconExternalLink}
        label="Fiche technique"
        href={techDocUrl}
      />
      {onClick && (
        <Button mt={20} mb={16} onClick={onClick} data-test={dataTest}>
          {ctaLabel ?? "Changer"}
        </Button>
      )}
    </Stack>
  )
}
