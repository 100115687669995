import { useForm, zodResolver } from "@mantine/form"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import {
  HouseUpdateType,
  updateElectricalMeterConfigSchema,
} from "@ensol/types/forms/houses"

import { HousePhotosField } from "@ensol/entool/components/form/House/HousePhotosField"
import { useUpdateHouseMutation } from "@ensol/entool/queries/houses"
import { useDebounce } from "@ensol/entool/utils/helpers/useDebounce"

type Props = {
  house: HousesResponses.House
}

export const UpdateElectricalMeterPhotos = ({ house }: Props) => {
  const { mutateAsync: updateHouse } = useUpdateHouseMutation(house.id)
  const debouncedUpdateHouse = useDebounce(updateHouse, 250)

  const form = useForm({
    validateInputOnChange: true,
    validate: zodResolver(updateElectricalMeterConfigSchema),
    onValuesChange: async (values) => debouncedUpdateHouse(values),
    initialValues: {
      updateType: HouseUpdateType.UPDATE_ELECTRICAL_METER_PHOTOS,
      electricMeterPhotos: house.electricMeterPhotos,
    },
  })

  return (
    <HousePhotosField
      houseStreetAddress={house.streetAddress}
      label="Photos compteur électrique"
      fileNameExtension="compteur-électrique"
      isRequired={false}
      {...form.getInputProps("electricMeterPhotos")}
    />
  )
}
