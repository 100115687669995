import { Prisma } from "@ensol/types/prisma-client"

export const DEFAULT_NOTIFICATION_INCLUDES = {
  recipient: true,
  notifier: true,
} satisfies Prisma.NotificationInclude

type DefaultNotificationIncludes = {
  include: typeof DEFAULT_NOTIFICATION_INCLUDES
}

export type Notification<
  Include extends
    Prisma.NotificationDefaultArgs | null = DefaultNotificationIncludes,
> = Prisma.NotificationGetPayload<Include>

export enum NotificationType {
  PROJECT_NOTE_MENTION = "PROJECT_NOTE_MENTION",
}

export enum NotificationStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}

export type UpdateNotificationStatusInput = {
  notificationId: string
  status: NotificationStatus
}

export type NotificationData = {
  type: NotificationType.PROJECT_NOTE_MENTION
  projectId: string
  projectName: string
}
