import { PENNYLANE_URL } from "@ensol/entool/config"

type PennylaneEstimateLinkInput =
  | {
      firstName: string
      lastName: string
      estimateLabel?: undefined
    }
  | {
      firstName?: undefined
      lastName?: undefined
      estimateLabel: string
    }

export const getPennylaneEstimateLink = ({
  firstName,
  lastName,
  estimateLabel,
}: PennylaneEstimateLinkInput) => {
  const filter = [
    {
      field: "customer",
      operator: "search_all",
      value: estimateLabel ? estimateLabel : `${firstName} ${lastName}`,
    },
  ]
  return `${PENNYLANE_URL}?filter=${encodeURIComponent(JSON.stringify(filter))}`
}
