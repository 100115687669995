export const CurrentType = {
  SINGLE_PHASE: "SINGLE_PHASE",
  TRI_PHASE: "TRI_PHASE",
} as const

export type CurrentType = keyof typeof CurrentType

export const CURRENT_TYPES = {
  [CurrentType.SINGLE_PHASE]: {
    name: "Monophasé",
    cost: 180,
  },
  [CurrentType.TRI_PHASE]: {
    name: "Triphasé",
    cost: 220,
  },
}

export const getCurrent = (currentType: CurrentType) =>
  CURRENT_TYPES[currentType]
