import { useDisclosure } from "@mantine/hooks"
import _ from "lodash"
import { useState } from "react"

import {
  Notification,
  NotificationStatus,
} from "@ensol/types/entities/notifications"

export const useNotificationsDrawer = (
  notifications: Notification[] | null,
) => {
  const [isDrawerOpen, { open: openDrawer, close: closeDrawer }] =
    useDisclosure()
  const [activeDrawerTab, setActiveDrawerTab] = useState<NotificationStatus>(
    NotificationStatus.ACTIVE,
  )

  const [activeNotifications, archivedNotifications] = _.chain(notifications)
    .orderBy(({ createdAt }) => createdAt, "desc")
    .partition(({ archivedAt }) => archivedAt === null)
    .value()

  return {
    activeNotifications,
    archivedNotifications,
    isDrawerOpen,
    openDrawer,
    closeDrawer,
    activeDrawerTab,
    setActiveDrawerTab,
  }
}
