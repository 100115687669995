import { schedulingOnHoldStepFormSchema } from "@ensol/types/forms/projects/technicalVisit"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/utils/initialValues"

import { TechnicalVisitForm } from "./components/TechnicalVisitForm"

export const Unscheduled = ({ project, processId }: StepComponentProps) => (
  <TechnicalVisitForm
    schema={schedulingOnHoldStepFormSchema}
    initialValues={getInitialValues(schedulingOnHoldStepFormSchema, project)}
    project={project}
    processId={processId}
    actions={[{ action: "validate", label: "Replanifier la visite technique" }]}
  />
)
