import { Group, Stack, Title } from "@mantine/core"
import { Helmet } from "react-helmet-async"
import { useParams } from "react-router-dom"

import { Section } from "@ensol/shared/components/Section"
import { ContentContainer } from "@ensol/shared/components/layout/ContentContainer"
import { CONTENT_PADDING_IN_PX } from "@ensol/shared/styles/constants"
import { formatFullName } from "@ensol/shared/utils/format"

import { ClientHeader } from "@ensol/entool/components/entities/Client/ClientHeader"
import { CreateHouseButton } from "@ensol/entool/components/form/House/Create/CreateButton"
import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { House } from "@ensol/entool/pages/Client/House"
import { useGetClientQuery } from "@ensol/entool/queries/clients"

export const ClientPage = () => {
  const { clientId } = useParams()
  const clientQuery = useGetClientQuery(clientId as string)

  return (
    <QueryWrapper query={clientQuery}>
      {({ data: client }) => (
        <Stack flex="1" gap="0">
          <Helmet>
            <title>{formatFullName(client)} | Entool</title>
          </Helmet>
          <ClientHeader
            client={client}
            RightSection={<CreateHouseButton clientId={client.id} />}
          />
          <ContentContainer
            flex="1"
            px={CONTENT_PADDING_IN_PX}
            py={CONTENT_PADDING_IN_PX}
            mt="0"
            bg="gray.0"
          >
            <Stack gap={24}>
              {client.houses.length === 0 && (
                <Section>
                  <Group justify="space-between">
                    <Title order={2} c="gray.6">
                      Ce client n&apos;a pas encore de maison
                    </Title>
                  </Group>
                </Section>
              )}
              {client.houses.map((house) => (
                <House key={house.id} house={house} />
              ))}
            </Stack>
          </ContentContainer>
        </Stack>
      )}
    </QueryWrapper>
  )
}
