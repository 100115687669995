import { ActionIcon, Group } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { IconSettings } from "@tabler/icons-react"
import { Helmet } from "react-helmet-async"

import { PageLayout } from "@ensol/shared/components/layout/PageLayout"
import { CONTENT_PADDING_IN_PX } from "@ensol/shared/styles/constants"
import { devices } from "@ensol/shared/styles/theme"

import { Calendar } from "./Calendar"
import { EventsMenu } from "./Menu/EventsMenu"
import { useEventsMenu } from "./Menu/useEventsMenu"

export const ProjectsEventsPage = () => {
  const isSmallScreen = useMediaQuery(devices.md)
  const {
    isMenuDrawerOpen,
    openMenuDrawer,
    closeMenuDrawer,
    eventTypes,
    setEventTypes,
    installers,
    setInstallers,
    coverageZones,
    setCoverageZones,
    showInstallerEvents,
    setShowInstallerEvents,
    showEventsWithoutInstaller,
    setShowEventsWithoutInstaller,
    showWeekEnds,
    setShowWeekEnds,
    showPublicHolidays,
    setShowPublicHolidays,
    showSavEvents,
    setShowSavEvents,
    showAuditEvents,
    setShowAuditEvents,
  } = useEventsMenu()

  return (
    <PageLayout
      title="Calendrier"
      headerProps={{
        Actions: isSmallScreen && (
          <ActionIcon color="black" onClick={openMenuDrawer}>
            <IconSettings />
          </ActionIcon>
        ),
      }}
      contentContainerProps={{ px: 0 }}
    >
      <Helmet>
        <title>Calendrier | Entool</title>
      </Helmet>
      <Group flex="1" pb={CONTENT_PADDING_IN_PX} gap="32">
        <Calendar
          eventTypes={eventTypes}
          installers={installers}
          coverageZones={coverageZones}
          showInstallerEvents={showInstallerEvents}
          showEventsWithoutInstaller={showEventsWithoutInstaller}
          showWeekEnds={showWeekEnds}
          showPublicHolidays={showPublicHolidays}
          showSavEvents={showSavEvents}
          showAuditEvents={showAuditEvents}
        />
        <EventsMenu
          showPublicHolidays={showPublicHolidays}
          setShowPublicHolidays={setShowPublicHolidays}
          showAuditEvents={showAuditEvents}
          setShowAuditEvents={setShowAuditEvents}
          isOpen={isMenuDrawerOpen}
          onClose={closeMenuDrawer}
          eventTypes={eventTypes}
          setEventTypes={setEventTypes}
          installers={installers}
          setInstallers={setInstallers}
          coverageZones={coverageZones}
          setCoverageZones={setCoverageZones}
          showInstallerEvents={showInstallerEvents}
          setShowInstallerEvents={setShowInstallerEvents}
          showEventsWithoutInstaller={showEventsWithoutInstaller}
          setShowEventsWithoutInstaller={setShowEventsWithoutInstaller}
          showWeekEnds={showWeekEnds}
          setShowWeekEnds={setShowWeekEnds}
          showSavEvents={showSavEvents}
          setShowSavEvents={setShowSavEvents}
        />
      </Group>
    </PageLayout>
  )
}
