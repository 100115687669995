import * as z from "zod"

const textContentSchema = z.object({
  type: z.literal("text"),
  text: z.string(),
  styles: z.record(z.string(), z.union([z.string(), z.boolean(), z.number()])),
})

const inlineContentSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("mention"),
    props: z.object({
      id: z.string(),
      name: z.string(),
    }),
  }),
  z.object({
    type: z.literal("link"),
    href: z.string(),
    content: z.array(textContentSchema),
  }),
  textContentSchema,
])

const blockContentSchema = z.object({
  type: z.enum([
    "paragraph",
    "heading",
    "bulletListItem",
    "numberedListItem",
    "checkListItem",
  ]),
  props: z.record(z.string(), z.union([z.string(), z.boolean(), z.number()])),
  content: z.array(inlineContentSchema),
})

const noteContentBlocksSchema = z.array(blockContentSchema)

export type NoteContentBlocks = z.infer<typeof noteContentBlocksSchema>

export const projectNoteSchema = z.object({
  content: noteContentBlocksSchema.min(1),
  userId: z.string({ invalid_type_error: "Champ obligatoire" }),
})

export type ProjectNoteInput = z.infer<typeof projectNoteSchema>
