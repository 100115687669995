import { MantineStyleProps, Stack } from "@mantine/core"
import { ReactNode } from "react"

import { BreadCrumb } from "@ensol/shared/components/BreadCrumb"
import { Header, HeaderProps } from "@ensol/shared/components/layout/Header"

import { ContentContainer } from "./ContentContainer"

export type PageLayoutProps = {
  title: string
  contentContainerProps?: MantineStyleProps
  headerProps?: HeaderProps
  children: ReactNode
}

export const PageLayout = ({
  title,
  contentContainerProps,
  headerProps,
  children,
}: PageLayoutProps) => {
  return (
    <Stack flex="1" miw="0" p="0" gap="0">
      <Header
        BreadCrumbs={[<BreadCrumb maw="unset" key={title} label={title} />]}
        {...headerProps}
      />
      <ContentContainer
        flex="1"
        mih="0"
        display="flex"
        pt="8"
        {...contentContainerProps}
      >
        {children}
      </ContentContainer>
    </Stack>
  )
}
