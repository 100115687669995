import {
  Badge,
  Button,
  Select,
  SimpleGrid,
  Stack,
  Textarea,
} from "@mantine/core"
import { DateTimePicker } from "@mantine/dates"
import {
  IconAdjustmentsAlt,
  IconCalendarX,
  IconCheck,
  IconInfoCircle,
  IconListCheck,
  IconMap,
  IconSolarPanel,
} from "@tabler/icons-react"

import { ProjectReportType } from "@ensol/types/entities/project"
import { KizeoProjectReportType } from "@ensol/types/entities/project/kizeoProjectReport"
import {
  requiredWorksFormSchema,
  TechnicalVisitStepInput,
} from "@ensol/types/forms/projects/technicalVisit"

import { Section } from "@ensol/shared/components/Section"
import {
  getHousePhotoFileName,
  getHousePhotos,
} from "@ensol/shared/entities/houses/photos"
import { getProjectCharacteristicsTextSummary } from "@ensol/shared/entities/projects/characteristics"
import {
  findFirstKizeoProjectReportFromType,
  getKizeoProjectReportPathsFromType,
} from "@ensol/shared/entities/projects/kizeoProjectReport"
import {
  hasReachedStep,
  TechnicalVisitStep,
} from "@ensol/shared/entities/projects/processes"
import { Team } from "@ensol/shared/entities/users"
import { getClientFileName } from "@ensol/shared/utils/files"
import {
  formatFullName,
  DISPLAY_DATE_FORMAT,
  DISPLAY_DATE_TIME_FORMAT,
  formatDate,
} from "@ensol/shared/utils/format"

import { CopiableText } from "@ensol/entool/components/CopiableText"
import { NotFound } from "@ensol/entool/components/NotFound"
import { MultiFilePreview } from "@ensol/entool/components/entities/File/MultiFilePreview"
import { PREVIEW_WIDTH } from "@ensol/entool/components/entities/File/constants"
import { ProjectNotes } from "@ensol/entool/components/entities/Project/ProjectNotes"
import {
  StepActions,
  StepActionsProps,
} from "@ensol/entool/components/entities/Project/StepActions"
import { TextInfo } from "@ensol/entool/components/entities/Project/TextInfo"
import { DateInput } from "@ensol/entool/components/form/DateInput"
import { Field } from "@ensol/entool/components/form/Field"
import { FileField } from "@ensol/entool/components/form/File/FileField"
import { MultiFileField } from "@ensol/entool/components/form/File/MultiFileField"
import { FormField } from "@ensol/entool/components/form/FormField"
import { UpdateRoofType } from "@ensol/entool/components/form/House/UpdateRoofType"
import { InstallerSelect } from "@ensol/entool/components/form/InstallerSelect"
import { NumberInput } from "@ensol/entool/components/form/NumberInput"
import { FormCheckbox } from "@ensol/entool/components/form/Project/FormCheckbox"
import { RequiredChangesForm } from "@ensol/entool/components/form/Project/RequiredChangesForm"
import { RequiredWorksForm } from "@ensol/entool/components/form/Project/RequiredWorksForm"
import { SchedulingIssueFormField } from "@ensol/entool/components/form/Project/SchedulingIssueFormField"
import { UpdateInstallationSection } from "@ensol/entool/components/form/Project/UpdateInstallationSection"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { UserSelect } from "@ensol/entool/components/form/UserSelect"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { useCreateKizeoProjectReportMutation } from "@ensol/entool/queries/kizeoProjectReports"
import { useCreateReportMutation } from "@ensol/entool/queries/projects"
import { booleanOptions } from "@ensol/entool/utils/form/options"
import { findSelectedOption } from "@ensol/entool/utils/form/radio"
import { getInitialValues } from "@ensol/entool/utils/initialValues"
import {
  INTEGRATION_KITS_OPTIONS,
  SCHEDULING_ISSUES_REASONS_OPTIONS,
  TECHNICAL_VISIT_DIFFICULTY_OPTIONS,
} from "@ensol/entool/utils/projects/options"
import {
  ProcessFormProps,
  useProcessForm,
} from "@ensol/entool/utils/projects/useProcessForm"

export const TechnicalVisitForm = <Input extends TechnicalVisitStepInput>({
  schema,
  initialValues,
  project,
  children,
  ...props
}: ProcessFormProps<Input> & StepActionsProps) => {
  const form = useProcessForm<Input>({
    schema,
    initialValues,
    projectId: project.id,
    processId: props.processId,
  })

  const { mutateAsync: createTechnicalVisitKizeo, isPending: isCreatingKizeo } =
    useCreateKizeoProjectReportMutation(
      project.id,
      project.technicalVisitOwner?.kizeoId ?? null,
    )

  const projectCharacteristicsSummary =
    getProjectCharacteristicsTextSummary(project)

  const { house } = project.installation
  const { client } = house
  const currentStep = project[props.processId]

  const {
    mutateAsync: createHorizonProfileReport,
    isPending: isHorizonProfileReportLoading,
  } = useCreateReportMutation(project.id, ProjectReportType.HORIZON_PROFILE)
  const {
    mutateAsync: createTechnicalVisitReport,
    isPending: isTechnicalVisitReportLoading,
  } = useCreateReportMutation(project.id, ProjectReportType.TECHNICAL_VISIT)

  const housePhotos = getHousePhotos(house)

  const isTechnicalVisitScheduled = hasReachedStep({
    project,
    processId: "technicalVisitStep",
    step: TechnicalVisitStep.REQUESTED,
  })

  return (
    <StepActions
      project={project}
      validateStep={() => schema.parse(form.values)}
      {...props}
    >
      {currentStep === TechnicalVisitStep.UPDATE_INSTALLATION && (
        <UpdateInstallationSection installationId={project.installation.id} />
      )}
      {project.technicalVisitRequiredChanges.length > 0 &&
        "technicalVisitRequiredChanges" in initialValues && (
          <RequiredChangesForm
            form={form}
            initialValues={{
              technicalVisitRequiredChanges:
                project.technicalVisitRequiredChanges,
            }}
          />
        )}

      {project.technicalVisitRequiredWorks.length > 0 &&
        "technicalVisitRequiredWorks" in initialValues && (
          <RequiredWorksForm
            form={form}
            initialValues={getInitialValues(requiredWorksFormSchema, project)}
            client={client}
          />
        )}
      {currentStep === TechnicalVisitStep.SCHEDULED && (
        <Section title="Vérifications" icon={IconListCheck} titleOrder={3}>
          <Stack gap="24">
            <FormCheckbox
              initialValues={initialValues}
              propertyName="isTechnicalVisitReady"
              {...form.getInputProps("isTechnicalVisitReady")}
            />
            <FormCheckbox
              initialValues={initialValues}
              propertyName="isClientIntroductoryCallDone"
              {...form.getInputProps("isClientIntroductoryCallDone")}
            />
          </Stack>
        </Section>
      )}
      {(currentStep === TechnicalVisitStep.SCHEDULING_ON_HOLD ||
        currentStep === TechnicalVisitStep.UNSCHEDULED) && (
        <Section title="Non planifiable" icon={IconCalendarX} titleOrder={3}>
          <SimpleGrid cols={2} spacing="32" mt="16">
            <Stack gap="24">
              <SchedulingIssueFormField
                options={SCHEDULING_ISSUES_REASONS_OPTIONS}
                form={form}
                initialValues={{
                  schedulingIssueMainReason: project.schedulingIssueMainReason,
                  schedulingIssueSubReason: project.schedulingIssueSubReason,
                }}
              />
              <FormField
                initialValues={initialValues}
                propertyName="technicalVisitSchedulingHoldEndDate"
              >
                <DateTimePicker
                  w={FIELD_WIDTH}
                  valueFormat={DISPLAY_DATE_TIME_FORMAT}
                  clearable
                  {...form.getInputProps("technicalVisitSchedulingHoldEndDate")}
                />
              </FormField>
              <FormField
                initialValues={initialValues}
                propertyName="technicalVisitLastContactDate"
              >
                <DateInput
                  w={FIELD_WIDTH}
                  valueFormat={DISPLAY_DATE_FORMAT}
                  clearable
                  {...form.getInputProps("technicalVisitLastContactDate")}
                />
              </FormField>
            </Stack>
            <ProjectNotes projectId={project.id} mah={300} />
          </SimpleGrid>
        </Section>
      )}
      <Section
        title={
          isTechnicalVisitScheduled
            ? "Gestion visite technique"
            : "Planification de la visite technique"
        }
        icon={IconAdjustmentsAlt}
        titleOrder={3}
      >
        <SimpleGrid cols={2} spacing="32" mt="16">
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitStartDate"
              isRequired
            >
              {isTechnicalVisitScheduled ? (
                <TextInfo
                  value={formatDate(
                    project.technicalVisitStartDate,
                    DISPLAY_DATE_TIME_FORMAT,
                  )}
                />
              ) : (
                <DateTimePicker
                  w={FIELD_WIDTH}
                  valueFormat={DISPLAY_DATE_TIME_FORMAT}
                  clearable
                  {...form.getInputProps("technicalVisitStartDate")}
                />
              )}
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitInstallerId"
              isRequired
            >
              {isTechnicalVisitScheduled ? (
                <TextInfo value={project.technicalVisitInstaller?.name} />
              ) : (
                <InstallerSelect
                  w={FIELD_WIDTH}
                  {...form.getInputProps("technicalVisitInstallerId")}
                  onChange={(value) => {
                    form
                      .getInputProps("technicalVisitInstallerId")
                      .onChange(value)
                    form.getInputProps("technicalVisitOwnerId").onChange(null)
                  }}
                />
              )}
            </FormField>
            {form.values.technicalVisitInstallerId !== null && (
              <FormField
                initialValues={initialValues}
                propertyName="technicalVisitOwnerId"
                isRequired
              >
                {isTechnicalVisitScheduled ? (
                  <TextInfo
                    value={
                      project.technicalVisitOwner
                        ? formatFullName(project.technicalVisitOwner)
                        : null
                    }
                  />
                ) : (
                  <UserSelect
                    teams={[Team.TECHNICAL_VISIT_OWNER]}
                    w={FIELD_WIDTH}
                    withAuthenticatedUserAsDefault={false}
                    installerId={form.values.technicalVisitInstallerId}
                    {...form.getInputProps("technicalVisitOwnerId")}
                  />
                )}
              </FormField>
            )}
            <FormField initialValues={initialValues} propertyName="installerId">
              <InstallerSelect
                w={FIELD_WIDTH}
                {...form.getInputProps("installerId")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitExpectedValidationDate"
            >
              <DateInput
                w={FIELD_WIDTH}
                valueFormat={DISPLAY_DATE_FORMAT}
                clearable
                {...form.getInputProps("technicalVisitExpectedValidationDate")}
              />
            </FormField>
            {hasReachedStep({
              project,
              processId: props.processId,
              step: TechnicalVisitStep.SCHEDULED,
            }) && (
              <Field name="Formulaire Kizeo visite technique">
                {findFirstKizeoProjectReportFromType(
                  project.kizeoProjectReports,
                  KizeoProjectReportType.TECHNICAL_VISIT,
                ) === null ? (
                  <Button
                    onClick={async () => {
                      await createTechnicalVisitKizeo(
                        KizeoProjectReportType.TECHNICAL_VISIT,
                      )
                    }}
                    loading={isCreatingKizeo}
                    w={FIELD_WIDTH}
                    variant="light"
                  >
                    Créer le formulaire Kizeo
                  </Button>
                ) : (
                  <Badge
                    color="green"
                    variant="light"
                    leftSection={<IconCheck size={16} />}
                  >
                    Formulaire Kizeo créé
                  </Badge>
                )}
              </Field>
            )}
            <FormField
              initialValues={initialValues}
              propertyName="installationEstimatedDuration"
              isRequired
            >
              <NumberInput
                w={FIELD_WIDTH}
                min={1}
                suffix=" jours"
                {...form.getInputProps("installationEstimatedDuration")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="installationClientAvailabilityDate"
            >
              <DateInput
                w={FIELD_WIDTH}
                valueFormat={DISPLAY_DATE_FORMAT}
                clearable
                {...form.getInputProps("installationClientAvailabilityDate")}
              />
            </FormField>
            {!isTechnicalVisitScheduled && (
              <Field name="Informations installation">
                <CopiableText
                  text={projectCharacteristicsSummary}
                  w={FIELD_WIDTH}
                />
              </Field>
            )}
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitDifficulty"
            >
              <RadioGroup
                options={TECHNICAL_VISIT_DIFFICULTY_OPTIONS}
                {...form.getInputProps("technicalVisitDifficulty")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitInstallerConclusion"
            >
              <Textarea
                autosize
                w={FIELD_WIDTH}
                minRows={3}
                {...form.getInputProps("technicalVisitInstallerConclusion")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitClientConclusion"
            >
              <Textarea
                autosize
                w={FIELD_WIDTH}
                minRows={3}
                {...form.getInputProps("technicalVisitClientConclusion")}
              />
            </FormField>
          </Stack>
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitReportsPath"
              subtitle="Compte-rendu interne uploadé manuellement"
            >
              <MultiFileField
                previewProps={{
                  fileName: getClientFileName(
                    "compte-rendu-VT-interne",
                    client,
                  ),
                }}
                inputProps={{ accept: ["application/pdf"] }}
                {...form.getInputProps("technicalVisitReportsPath")}
              />
            </FormField>
            {hasReachedStep({
              project,
              processId: props.processId,
              step: TechnicalVisitStep.COMPLETED,
            }) && (
              <Field
                name="Compte-rendu VT interne"
                subtitle="Compte-rendu interne uploadé automatiquement depuis Kizeo"
              >
                {getKizeoProjectReportPathsFromType(
                  project.kizeoProjectReports,
                  KizeoProjectReportType.TECHNICAL_VISIT,
                ).length > 0 ? (
                  <MultiFilePreview
                    files={getKizeoProjectReportPathsFromType(
                      project.kizeoProjectReports,
                      KizeoProjectReportType.TECHNICAL_VISIT,
                    ).map((path) => ({
                      path,
                    }))}
                    fileName={getClientFileName("compte-rendu-VT", client)}
                  />
                ) : (
                  <NotFound type="file" />
                )}
              </Field>
            )}
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitClientReportPath"
              subtitle="Compte-rendu envoyé au client, à générer avec le bouton ci-dessous"
              isRequired
            >
              <Stack gap="8">
                <Button
                  onClick={async () => {
                    const technicalVisitClientReportPath =
                      await createTechnicalVisitReport()
                    form
                      .getInputProps("technicalVisitClientReportPath")
                      .onChange(technicalVisitClientReportPath)
                  }}
                  loading={isTechnicalVisitReportLoading}
                  disabled={project.technicalVisitClientReportPath !== null}
                  w={PREVIEW_WIDTH}
                >
                  Générer le compte-rendu VT client
                </Button>
                <FileField
                  previewProps={{
                    fileName: getClientFileName(
                      "compte-rendu-visite-technique",
                      client,
                    ),
                  }}
                  inputProps={{ accept: ["application/pdf"] }}
                  {...form.getInputProps("technicalVisitClientReportPath")}
                />
              </Stack>
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitHorizonProfileReportPath"
            >
              <Stack gap="8">
                <Button
                  onClick={async () => {
                    const horizonProfileReport =
                      await createHorizonProfileReport()
                    form
                      .getInputProps("technicalVisitHorizonProfileReportPath")
                      .onChange(horizonProfileReport)
                  }}
                  loading={isHorizonProfileReportLoading}
                  disabled={
                    project.technicalVisitHorizonProfileReportPath !== null
                  }
                  w={PREVIEW_WIDTH}
                >
                  Générer le rapport de pertes
                </Button>
                <FileField
                  previewProps={{
                    fileName: getClientFileName("rapport-de-pertes", client),
                  }}
                  inputProps={{ accept: ["application/pdf"] }}
                  {...form.getInputProps(
                    "technicalVisitHorizonProfileReportPath",
                  )}
                />
              </Stack>
            </FormField>
          </Stack>
        </SimpleGrid>
      </Section>
      {!hasReachedStep({
        project,
        processId: props.processId,
        step: TechnicalVisitStep.COMPLETED,
      }) && (
        <Section
          title="Préparation visite technique"
          icon={IconInfoCircle}
          titleOrder={3}
        >
          <SimpleGrid cols={2} spacing="32">
            <Stack gap="24">
              <Field name="Calepinage">
                {project.salesVisitPanelsLayoutPath !== null ? (
                  <MultiFilePreview
                    files={[{ path: project.salesVisitPanelsLayoutPath }]}
                    fileName={getClientFileName(
                      "calepinage-visite-commerciale",
                      client,
                    )}
                  />
                ) : (
                  <NotFound type="file" />
                )}
              </Field>
              <Field name="Rapport K2">
                {project.salesVisitK2ReportPath !== null ? (
                  <MultiFilePreview
                    files={[{ path: project.salesVisitK2ReportPath }]}
                    fileName={getClientFileName(
                      "rapport-k2-visite-commerciale",
                      client,
                    )}
                  />
                ) : (
                  <NotFound type="file" />
                )}
              </Field>
            </Stack>
            <Stack gap="24">
              <Field name="Photos">
                {housePhotos.length > 0 ? (
                  <MultiFilePreview
                    files={housePhotos.map((path) => ({ path }))}
                    fileName={getHousePhotoFileName(
                      house.streetAddress,
                      "photo",
                    )}
                  />
                ) : (
                  <NotFound type="file" />
                )}
              </Field>
              <Field name="Accès aux combles possible ?">
                <TextInfo
                  value={
                    findSelectedOption(house.isAtticAccessible, booleanOptions)
                      ?.label
                  }
                />
              </Field>
              <Field name="Commentaires AE visite technique">
                <TextInfo
                  value={
                    project.technicalVisitSalesComments !== ""
                      ? project.technicalVisitSalesComments
                      : null
                  }
                />
              </Field>
            </Stack>
          </SimpleGrid>
        </Section>
      )}
      {"technicalVisitK2ReportsPath" in initialValues && (
        <Section title="Calepinages" icon={IconMap} titleOrder={3}>
          <SimpleGrid cols={2} spacing="32" mt="16">
            <Stack gap="24">
              <FormField
                initialValues={initialValues}
                propertyName="technicalVisitK2ReportsPath"
              >
                <MultiFileField
                  previewProps={{
                    fileName: getClientFileName("rapport-k2-VT", client),
                  }}
                  inputProps={{
                    accept: {
                      "application/pdf": [".pdf"],
                      "application/octet-stream": [".k2o"],
                    },
                  }}
                  {...form.getInputProps("technicalVisitK2ReportsPath")}
                />
              </FormField>
              <FormField
                initialValues={initialValues}
                propertyName="technicalVisitPanelsLayoutPath"
              >
                <FileField
                  previewProps={{
                    fileName: getClientFileName("calepinage-VT", client),
                  }}
                  inputProps={{ accept: ["image/png", "image/jpeg"] }}
                  {...form.getInputProps("technicalVisitPanelsLayoutPath")}
                />
              </FormField>
            </Stack>
            <Stack gap="24">
              <FormField
                initialValues={initialValues}
                propertyName="salesVisitK2ReportPath"
              >
                <FileField
                  previewProps={{
                    fileName: getClientFileName("rapport-K2-VC", client),
                  }}
                  inputProps={{
                    accept: {
                      "application/pdf": [".pdf"],
                    },
                  }}
                  {...form.getInputProps("salesVisitK2ReportPath")}
                />
              </FormField>
              <FormField
                initialValues={initialValues}
                propertyName="salesVisitPanelsLayoutPath"
              >
                <FileField
                  previewProps={{
                    fileName: getClientFileName("calepinage-VC", client),
                  }}
                  inputProps={{ accept: ["image/png", "image/jpeg"] }}
                  {...form.getInputProps("salesVisitPanelsLayoutPath")}
                />
              </FormField>
            </Stack>
          </SimpleGrid>
        </Section>
      )}
      <Section title="Matériel" icon={IconSolarPanel} titleOrder={3}>
        <SimpleGrid cols={2} spacing="32" mt="16">
          <Stack gap="24">
            <UpdateRoofType house={project.installation.house} />
            <FormField
              initialValues={initialValues}
              propertyName="integrationKitType"
            >
              <Select
                w={FIELD_WIDTH}
                data={INTEGRATION_KITS_OPTIONS}
                {...form.getInputProps("integrationKitType")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="isPLCNeeded"
              isRequired
            >
              <RadioGroup
                options={booleanOptions}
                {...form.getInputProps("isPLCNeeded")}
              />
            </FormField>
          </Stack>
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="hardwareNotes"
            >
              <Textarea
                autosize
                w={FIELD_WIDTH}
                minRows={3}
                {...form.getInputProps("hardwareNotes")}
              />
            </FormField>
          </Stack>
        </SimpleGrid>
      </Section>
      {children}
    </StepActions>
  )
}
