import { ActionIcon, Table, Text } from "@mantine/core"
import { modals } from "@mantine/modals"
import { IconTrash } from "@tabler/icons-react"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"

import { Client } from "@ensol/types/entities/clients"

import { formatFullName, formatPhoneNum } from "@ensol/shared/utils/format"

import { HubspotLink } from "@ensol/entool/components/entities/Client/HubspotLink"
import { useDeleteClientMutation } from "@ensol/entool/queries/clients"

type Props = {
  client: Client
  installationsCount: number
}

export const ClientRow = ({ client, installationsCount }: Props) => {
  const navigate = useNavigate()
  const { mutateAsync: deleteClient, isPending: isClientDeleting } =
    useDeleteClientMutation(client.id)

  return (
    <Table.Tr
      key={client.id}
      onClick={() => navigate(`/clients/${client.id}`)}
      style={{ cursor: "pointer" }}
    >
      <Table.Td display={{ base: "none", md: "table-cell" }}>
        {dayjs(client.createdAt).format("DD MMMM")}
      </Table.Td>
      <Table.Td>{formatFullName(client)}</Table.Td>
      <Table.Td>{client.email}</Table.Td>
      <Table.Td display={{ base: "none", md: "table-cell" }}>
        {formatPhoneNum(client.phone)}
      </Table.Td>
      <Table.Td display={{ base: "none", md: "table-cell" }}>
        {installationsCount}
      </Table.Td>
      <Table.Td>
        <HubspotLink
          hubspotContactId={client.hubspotContactId}
          w="fit-content"
          p="0"
          variant="transparent"
          emptyContent="-"
        />
      </Table.Td>
      <Table.Td>
        <ActionIcon
          loading={isClientDeleting}
          aria-label="Supprimer"
          color="red"
          p="4"
          size="md"
          onClick={(e) => {
            e.stopPropagation()
            modals.openConfirmModal({
              title: "Supprimer le client",
              children: (
                <Text size="sm">
                  Êtes-vous sûr(e) de vouloir supprimer ce client ? Cette action
                  est irréversible.
                </Text>
              ),
              confirmProps: { color: "red" },
              centered: true,
              labels: {
                confirm: "Supprimer le client",
                cancel: "Annuler",
              },
              onConfirm: () => {
                deleteClient()
              },
            })
          }}
        >
          <IconTrash />
        </ActionIcon>
      </Table.Td>
    </Table.Tr>
  )
}
