import { useQuery } from "@tanstack/react-query"

import { IntercomResponses } from "@ensol/types/endpoints/intercom"

import { httpClient } from "@ensol/entool/backend/axios"

export const useClientEventsQuery = (clientId: string) => {
  return useQuery<IntercomResponses.ClientEvents>({
    queryKey: ["intercom", clientId, "events"],
    queryFn: async () => {
      const response = await httpClient.get(`/intercom/${clientId}/events`)
      return response.data
    },
    retry: false,
  })
}
