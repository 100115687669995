import _ from "lodash"

import { EnergyResponses } from "@ensol/types/endpoints/energy"
import { typedKeys } from "@ensol/types/utils"

import { MountingType } from "@ensol/shared/entities/installations/batteries"
import { BrandType, InverterBrandType } from "@ensol/shared/material/brands"

type BatteryInfo = {
  name: string
  brand: InverterBrandType
  capacity: number
  maxOutput: number
  installationCost: number
  price: number
  warranty: number
  techDocUrl: string
  disabled: boolean
  mountingTypes: MountingType[]
  pictureUrl: string
}

export const BatteryType = {
  HUAWEI_5KWH: "HUAWEI_5KWH",
  HUAWEI_10KWH: "HUAWEI_10KWH",
  HUAWEI_15KWH: "HUAWEI_15KWH",
  HUAWEI_7KWH: "HUAWEI_7KWH",
  HUAWEI_14KWH: "HUAWEI_14KWH",
  HUAWEI_21KWH: "HUAWEI_21KWH",
  ENPHASE_3T: "ENPHASE_3T",
  ENPHASE_10T: "ENPHASE_10T",
  ENPHASE_5P: "ENPHASE_5P",
} as const

export type BatteryType = keyof typeof BatteryType

export const BATTERIES: Record<BatteryType, BatteryInfo> = {
  HUAWEI_5KWH: {
    name: "Huawei LUNA2000 5kWh",
    brand: InverterBrandType.HUAWEI,
    capacity: 5,
    maxOutput: 2.5,
    installationCost: 500,
    price: 2836.55,
    warranty: 10,
    techDocUrl:
      "https://solar.huawei.com/en-GB/download?p=%2F-%2Fmedia%2FSolar%2Fattachment%2Fpdf%2Ffr%2Fdatasheet%2FLUNA2000-5-15-S0.pdf",
    disabled: true,
    mountingTypes: [MountingType.ON_FLOOR],
    pictureUrl: "/batteryHuawei.png",
  },
  HUAWEI_10KWH: {
    name: "Huawei LUNA2000 10kWh",
    brand: InverterBrandType.HUAWEI,
    capacity: 10,
    maxOutput: 5,
    installationCost: 500,
    price: 4862.52,
    warranty: 10,
    techDocUrl:
      "https://solar.huawei.com/en-GB/download?p=%2F-%2Fmedia%2FSolar%2Fattachment%2Fpdf%2Ffr%2Fdatasheet%2FLUNA2000-5-15-S0.pdf",
    disabled: true,
    mountingTypes: [MountingType.ON_FLOOR],
    pictureUrl: "/batteryHuawei.png",
  },
  HUAWEI_15KWH: {
    name: "Huawei LUNA2000 15kWh",
    brand: InverterBrandType.HUAWEI,
    capacity: 15,
    maxOutput: 5,
    installationCost: 500,
    price: 6888.49,
    warranty: 10,
    techDocUrl:
      "https://solar.huawei.com/en-GB/download?p=%2F-%2Fmedia%2FSolar%2Fattachment%2Fpdf%2Ffr%2Fdatasheet%2FLUNA2000-5-15-S0.pdf",
    disabled: true,
    mountingTypes: [MountingType.ON_FLOOR],
    pictureUrl: "/batteryHuawei.png",
  },
  HUAWEI_7KWH: {
    name: "Huawei LUNA2000 S1 7kWh",
    brand: InverterBrandType.HUAWEI,
    capacity: 6.9,
    maxOutput: 3.5,
    installationCost: 500,
    price: 3308.92,
    warranty: 15,
    techDocUrl:
      "https://solar.huawei.com/be-fr/professionals/all-products/LUNA2000-7-14-21-S1/specs",
    disabled: false,
    mountingTypes: [MountingType.ON_FLOOR],
    pictureUrl: "/batteryHuawei.png",
  },
  HUAWEI_14KWH: {
    name: "Huawei LUNA2000 S1 14kWh",
    brand: InverterBrandType.HUAWEI,
    capacity: 13.8,
    maxOutput: 3.5,
    installationCost: 500,
    price: 5674.19,
    warranty: 15,
    techDocUrl:
      "https://solar.huawei.com/be-fr/professionals/all-products/LUNA2000-7-14-21-S1/specs",
    disabled: false,
    mountingTypes: [MountingType.ON_FLOOR],
    pictureUrl: "/batteryHuawei.png",
  },
  HUAWEI_21KWH: {
    name: "Huawei LUNA2000 S1 21kWh",
    brand: InverterBrandType.HUAWEI,
    capacity: 20.7,
    maxOutput: 10.5,
    installationCost: 500,
    price: 8039.46,
    warranty: 15,
    techDocUrl:
      "https://solar.huawei.com/be-fr/professionals/all-products/LUNA2000-7-14-21-S1/specs",
    disabled: false,
    mountingTypes: [MountingType.ON_FLOOR],
    pictureUrl: "/batteryHuawei.png",
  },
  ENPHASE_3T: {
    name: "Enphase 3T",
    brand: InverterBrandType.ENPHASE,
    capacity: 3.36,
    maxOutput: 1.28,
    installationCost: 500,
    price: 2202.59,
    warranty: 15,
    techDocUrl:
      "https://enphase.com/sites/default/files/2021-08/Enphase-DS-Encharge-3T-FR.pdf",
    disabled: true,
    mountingTypes: [MountingType.ON_FLOOR, MountingType.ON_WALL],
    pictureUrl: "/batteryEnphase.png",
  },
  ENPHASE_10T: {
    name: "Enphase 10T",
    brand: InverterBrandType.ENPHASE,
    capacity: 10.08,
    maxOutput: 3.84,
    installationCost: 500,
    price: 5982.03,
    warranty: 15,
    techDocUrl:
      "https://enphase.com/sites/default/files/2021-08/Enphase-DS-Encharge-10T-FR.pdf",
    disabled: false,
    mountingTypes: [MountingType.ON_FLOOR, MountingType.ON_WALL],
    pictureUrl: "/batteryEnphase.png",
  },
  ENPHASE_5P: {
    name: "Enphase 5P",
    brand: InverterBrandType.ENPHASE,
    capacity: 5,
    maxOutput: 3.84,
    installationCost: 500,
    price: 3102.25,
    warranty: 15,
    techDocUrl:
      "https://enphase.com/fr-fr/download/iq-battery-5p-fiche-technique",
    disabled: false,
    mountingTypes: [MountingType.ON_FLOOR, MountingType.ON_WALL],
    pictureUrl: "/batteryEnphase.png",
  },
}

export const getBattery = (batteryType: BatteryType): BatteryInfo =>
  BATTERIES[batteryType]

export const findRecommendedBatteryType = ({
  batteryStats,
  inverterBrand,
}: {
  batteryStats?: EnergyResponses.BatteryStats
  inverterBrand: BrandType
}): BatteryType => {
  const recommendedCapacity = batteryStats
    ? Math.min(
        batteryStats.averageNightConsumption,
        batteryStats.averageDayExcess,
      )
    : 0

  const sortedAvailableBatteries = _.chain(typedKeys(BATTERIES))
    .map((batteryType) => {
      const battery = getBattery(batteryType)
      return {
        type: batteryType,
        ...battery,
      }
    })
    .filter(({ brand, disabled }) => brand === inverterBrand && !disabled)
    .sortBy(({ capacity }) => capacity, "asc")
    .value()
  return sortedAvailableBatteries.reduce((closestBatteryType, battery) => {
    // If the battery capacity is less than 1kWh bigger than the recommended capacity
    if (battery.capacity - recommendedCapacity <= 1) {
      return battery.type
    }
    // If the battery capacity is smaller than recommended capacity
    if (battery.capacity <= recommendedCapacity) {
      return battery.type
    }
    // Keep the previous battery if none of the criteria are met
    return closestBatteryType
  }, sortedAvailableBatteries[0].type)
}
