import { orderDeliveredStepSchema } from "@ensol/types/forms/projects/hardware"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { HardwareForm } from "@ensol/entool/pages/Project/processes/Hardware/components/HardwareForm"
import { getInitialValues } from "@ensol/entool/utils/initialValues"

export const OrderDelivered = ({ project, ...props }: StepComponentProps) => (
  <HardwareForm
    schema={orderDeliveredStepSchema}
    initialValues={getInitialValues(orderDeliveredStepSchema, project)}
    project={project}
    actions={[{ action: "validate", label: "Commande vérifiée" }]}
    relatedProcesses={["installationStep"]}
    {...props}
  />
)
