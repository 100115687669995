import { IconX } from "@tabler/icons-react"
import * as z from "zod"

import { schedulingOnHoldStepFormSchema } from "@ensol/types/forms/projects/installation"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/utils/initialValues"
import { validateAction } from "@ensol/entool/utils/projects/validateAction"

import { InstallationForm } from "./components/InstallationForm"

export const SchedulingOnHold = ({
  project,
  processId,
}: StepComponentProps) => (
  <InstallationForm
    schema={schedulingOnHoldStepFormSchema}
    initialValues={getInitialValues(schedulingOnHoldStepFormSchema, project)}
    project={project}
    processId={processId}
    relatedProcesses={["technicalVisitStep", "preliminaryRequestStep"]}
    actions={[
      {
        action: "unschedule",
        label: "Impossible à planifier",
        color: "red",
        Icon: IconX,
      },
      {
        action: "validate",
        label: "Planifier l'installation",
        validateAction: validateAction(
          z.object({
            installerId: z.string({ invalid_type_error: "Champ obligatoire" }),
            installationStartDate: z.date({
              invalid_type_error: "Champ obligatoire",
            }),
            installationEndDate: z.date({
              invalid_type_error: "Champ obligatoire",
            }),
            isPLCNeeded: z.boolean({ invalid_type_error: "Champ obligatoire" }),
          }),
        ),
      },
    ]}
  />
)
