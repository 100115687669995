import { UseFormReturnType } from "@mantine/form"

import { UnpackArray } from "@ensol/types/utils"

export const getSubformActions = <Values, Key extends keyof Values>(
  form: UseFormReturnType<Values>,
  subformKey: Key,
) => ({
  onChange: form.getInputProps(subformKey).onChange,
  insertListItem: (data: UnpackArray<Values[Key]>) =>
    form.insertListItem(subformKey, data),
  replaceListItem: (index: number, data: UnpackArray<Values[Key]>) =>
    form.replaceListItem(subformKey, index, data),
  removeListItem: (index: number) => form.removeListItem(subformKey, index),
  setError: (error: string) => form.setFieldError(subformKey, error),
  clearError: () => form.clearFieldError(subformKey),
})
