import { Container, Stack, Loader } from "@mantine/core"
import { Outlet } from "react-router-dom"

import { AuthorizedGroup } from "@ensol/shared/entities/users"
import { isUserAuthorized } from "@ensol/shared/entities/users/roles"

import { InfoBox } from "@ensol/entool/components/InfoBox"
import { useAuthenticatedUser } from "@ensol/entool/queries/users"

type Props = {
  authorizedGroups: AuthorizedGroup[]
}

export const RouteRoleGuard = ({ authorizedGroups }: Props) => {
  const { data: user, isPending } = useAuthenticatedUser()

  if (isPending) {
    return (
      <Container mt="5%">
        <Stack align="center">
          <Loader />
        </Stack>
      </Container>
    )
  }

  if (!isUserAuthorized(authorizedGroups, user)) {
    return (
      <Container mt="5%">
        <InfoBox
          color="red"
          message="Vous n'avez pas les droits pour accéder à cette page"
        />
      </Container>
    )
  }

  return <Outlet />
}
