import { z } from "zod"

export const abortProjectSchema = z.object({
  schedulingIssueMainReason: z.string({
    invalid_type_error: "Champ obligatoire",
  }),
  schedulingIssueSubReason: z.string({
    invalid_type_error: "Champ obligatoire",
  }),
})

export type AbortProjectInput = z.infer<typeof abortProjectSchema>
