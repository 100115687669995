import { ThemeIcon } from "@mantine/core"
import { IconAlertCircle, IconCircleDotted } from "@tabler/icons-react"

import { Project } from "@ensol/types/entities/project"

import { WorksAndChangesIndicators } from "@ensol/entool/components/entities/Project/WorksAndChangesIndicators"

export const ProjectIndicators = ({
  project,
  isActionNeeded,
}: {
  project: Project<{
    select: {
      technicalVisitRequiredChanges: true
      technicalVisitRequiredWorks: true
      technicalVisitEndOfWorksDate: true
      status: true
    }
  }>
  isActionNeeded: boolean
}) => {
  return (
    <>
      <ThemeIcon color={isActionNeeded ? "red" : "gray"}>
        {isActionNeeded ? <IconAlertCircle /> : <IconCircleDotted />}
      </ThemeIcon>
      <WorksAndChangesIndicators {...project} />
    </>
  )
}
