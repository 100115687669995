import { useDisclosure } from "@mantine/hooks"
import { IconBatteryCharging, IconChecks } from "@tabler/icons-react"

import { BatteryType, getBattery } from "@ensol/shared/material/batteries"
import { getBrand } from "@ensol/shared/material/brands"
import { formatNumber } from "@ensol/shared/utils/format"

import { MaterialCard } from "@ensol/entool/pages/Installation/components/MaterialCard"
import { MaterialSelectorModal } from "@ensol/entool/pages/Installation/components/MaterialSelectorModal"
import { BatteryMaterialOptions } from "@ensol/entool/utils/installations/battery"

type Props = {
  batteryOptions: BatteryMaterialOptions
  selectedBatteryType: BatteryType
  onChange: (batteryType: BatteryType) => void
}

export const BatterySelector = ({
  batteryOptions,
  selectedBatteryType,
  onChange,
}: Props) => {
  const selectedBatteryInfo = getBattery(selectedBatteryType)
  const [opened, { open, close }] = useDisclosure(false)
  const selectedRecommendationDescription = batteryOptions.find(
    (battery) => battery.value === selectedBatteryType,
  )?.metadata?.recommendationReason

  return (
    <>
      <MaterialCard
        dataTest="updateBatteryTypeButton"
        {...selectedBatteryInfo}
        ctaLabel="Changer batterie"
        imgMaw={300}
        properties={[
          ...(selectedRecommendationDescription
            ? [
                {
                  icon: IconChecks,
                  label: selectedRecommendationDescription,
                },
              ]
            : []),
          {
            icon: IconBatteryCharging,
            label: `${formatNumber(selectedBatteryInfo.capacity)} kWh`,
          },
        ]}
        onClick={open}
      />

      <MaterialSelectorModal<BatteryType>
        title="Sélectionner un type de batterie"
        isOpened={opened}
        onClose={close}
        onChange={onChange}
        extraColumnsLabel={["Capacité", "Sortie maximale"]}
        options={batteryOptions.map(({ value, disabled, metadata }) => {
          const isSelected = value === selectedBatteryType
          const batteryInfo = getBattery(value)
          const brandInfo = getBrand(batteryInfo.brand)

          return {
            value,
            brandInfo,
            ...batteryInfo,
            isSelected,
            isCompatible: !disabled,
            isRecommended: metadata?.isRecommended,
            extraColumnsValues: [
              `${batteryInfo.capacity} kWh`,
              `${formatNumber(batteryInfo.maxOutput)} kW`,
            ],
          }
        })}
      />
    </>
  )
}
