import { Group, Switch, Tabs, Text, ThemeIcon } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { IconAlertCircle } from "@tabler/icons-react"
import { useState } from "react"

import { typedKeys } from "@ensol/types/utils"

import { devices } from "@ensol/shared/styles/theme"

import { StickyHeader } from "@ensol/entool/components/StickyHeader"
import { ProjectAccordion } from "@ensol/entool/components/entities/Project/ProjectAccordion"
import { ProjectSearch } from "@ensol/entool/components/entities/Project/ProjectSearch"
import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import {
  NavigationTab,
  useTabsNavigation,
} from "@ensol/entool/utils/helpers/useTabsNavigation"
import { PROJECT_SEARCHABLE_PROPS } from "@ensol/entool/utils/projects/search"
import {
  getProjectStatusLabel,
  OPS_PROJECT_STATUSES,
} from "@ensol/entool/utils/projects/statuses"

import { ProjectDrawer } from "./ProjectDrawer"
import { ProjectRow } from "./Row"
import { useProjectDrawer } from "./useProjectDrawer"
import { useProjectsOperationsOverview } from "./useProjectsOperationsOverview"

const ALL_PROJECTS_TAB_ID = "ALL"

const OPS_PROJECTS_OVERVIEW_TABS: NavigationTab[] = [
  { id: ALL_PROJECTS_TAB_ID, label: "Tous" },
  ...OPS_PROJECT_STATUSES,
]

export const ProjectsOperationsOverview = () => {
  const isMobile = useMediaQuery(devices.sm)
  const { currentTab, handleTabChange } = useTabsNavigation(
    OPS_PROJECTS_OVERVIEW_TABS,
  )

  const {
    selectedProject,
    activeDrawerTab,
    setActiveDrawerTab,
    openDrawer,
    closeDrawer,
  } = useProjectDrawer()

  const [showOnlyActionNeeded, setShowOnlyActionNeeded] = useState(false)
  const projectsQuery = useProjectsOperationsOverview(
    currentTab,
    showOnlyActionNeeded,
  )

  return (
    <Tabs
      value={currentTab}
      onChange={handleTabChange}
      color="gray.8"
      styles={{
        root: {
          display: "flex",
          flexDirection: "column",
          flex: 1,
        },
      }}
    >
      <StickyHeader>
        {!isMobile && (
          <Tabs.List>
            {OPS_PROJECTS_OVERVIEW_TABS.map(({ id, label }) => (
              <Tabs.Tab key={id} value={id}>
                {label}
              </Tabs.Tab>
            ))}
          </Tabs.List>
        )}
        <ProjectSearch
          filteredProps={[
            PROJECT_SEARCHABLE_PROPS.search,
            PROJECT_SEARCHABLE_PROPS.operationsOwnerIds,
            PROJECT_SEARCHABLE_PROPS.installerIds,
          ]}
        >
          {!isMobile && (
            <Group gap={4} style={{ flexShrink: 0 }} pb="8">
              <Switch
                checked={showOnlyActionNeeded}
                onChange={(event) =>
                  setShowOnlyActionNeeded(event.currentTarget.checked)
                }
              />
              <ThemeIcon c="red">
                <IconAlertCircle />
              </ThemeIcon>
              <Text size="sm" fw="500">
                Actions uniquement
              </Text>
            </Group>
          )}
        </ProjectSearch>
      </StickyHeader>
      <QueryWrapper
        query={projectsQuery}
        disabledQueryMessage="Entrez le nom, le téléphone ou l'email d'un client pour commencer"
      >
        {({ data }) => {
          return (
            <ProjectAccordion
              data={typedKeys(data).map((status) => ({
                items: data[status],
                id: status,
                title: getProjectStatusLabel(status),
              }))}
              render={(items) =>
                items.map((project) => (
                  <ProjectRow
                    key={project.id}
                    project={project}
                    isDrawerOpen={selectedProject?.id === project.id}
                    openDrawer={openDrawer}
                  />
                ))
              }
            />
          )
        }}
      </QueryWrapper>
      <ProjectDrawer
        project={selectedProject}
        activeTab={activeDrawerTab}
        setActiveTab={setActiveDrawerTab}
        onClose={closeDrawer}
      />
    </Tabs>
  )
}
