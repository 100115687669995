import { Popover, ActionIcon, Group, Stack, Text } from "@mantine/core"
import { useDisclosure, useClickOutside } from "@mantine/hooks"
import { IconInfoCircle, TablerIcon } from "@tabler/icons-react"

import { CopiableText } from "@ensol/entool/components/CopiableText"
import { PopoverContent } from "@ensol/entool/components/PopoverContent"

type Props = {
  infos: {
    Icon: TablerIcon
    name: string
    value: string
    Link?: React.ReactNode
  }[]
}

export const InfosIconPopover = ({ infos }: Props) => {
  const [opened, { open, close }] = useDisclosure(false)
  const ref = useClickOutside(close)

  return (
    <Popover withArrow position="bottom" offset={0} opened={opened}>
      <Popover.Target>
        <ActionIcon
          variant="transparent"
          onClick={open}
          c={opened ? "blue.4" : "gray.5"}
        >
          <IconInfoCircle />
        </ActionIcon>
      </Popover.Target>
      <PopoverContent ref={ref}>
        <Stack gap={12} flex={1}>
          {infos.map((info) => (
            <Stack gap={4} key={info.name}>
              <Text size="md">{info.name}</Text>
              <Group wrap="nowrap" maw="450px" gap={8}>
                <CopiableText Icon={info.Icon} text={info.value} flex={1} />
                {info.Link}
              </Group>
            </Stack>
          ))}
        </Stack>
      </PopoverContent>
    </Popover>
  )
}
