import { Alert } from "@mantine/core"
import { IconAlertCircle } from "@tabler/icons-react"

import { ClientInput } from "@ensol/types/forms/clients"

import { ContentContainer } from "@ensol/shared/components/layout/ContentContainer"

import { ClientForm } from "@ensol/entool/components/form/Client/ClientForm"
import { useCreateClientMutation } from "@ensol/entool/queries/clients"

export const FallbackForm = (client: ClientInput) => {
  const { mutateAsync: createClient, isPending } = useCreateClientMutation()

  return (
    <ContentContainer flex="1">
      <Alert
        color="red"
        title="Nous n'avons pas pu créer le client automatiquement, ajouter les informations manquantes"
        icon={<IconAlertCircle />}
        mb={16}
      />
      <ClientForm
        client={client}
        submitAction={createClient}
        isPending={isPending}
      />
    </ContentContainer>
  )
}
