import { CLIENT_FIELDS_LABELS } from "./clients"
import { EMAILS_FIELDS_LABELS } from "./emails"
import { INSTALLATION_FIELDS_LABELS } from "./installations"
import { PROJECT_FIELDS_LABELS } from "./projects"

export const FIELDS_LABELS = {
  ...PROJECT_FIELDS_LABELS,
  ...INSTALLATION_FIELDS_LABELS,
  ...EMAILS_FIELDS_LABELS,
  ...CLIENT_FIELDS_LABELS,
}
