import { Stack, Button, Modal, ScrollArea } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconHomePlus } from "@tabler/icons-react"

import { useCreateClientHouseMutation } from "@ensol/entool/queries/clients"

import { CreateHouseForm } from "./Form"

type Props = {
  clientId: string
}

export const CreateHouseButton = ({ clientId }: Props) => {
  const [isModalOpen, { open, close }] = useDisclosure(false)

  const { mutateAsync: createHouse, isPending: isCreatingHouse } =
    useCreateClientHouseMutation(clientId, close)

  return (
    <>
      <Stack align="center">
        <Button
          leftSection={<IconHomePlus size={18} />}
          data-test="createHouseButton"
          onClick={open}
        >
          Créer une nouvelle maison
        </Button>
      </Stack>
      <Modal
        size={1000}
        radius="lg"
        padding="xl"
        opened={isModalOpen}
        scrollAreaComponent={ScrollArea.Autosize}
        onClose={close}
        title="Nouvelle maison"
      >
        <CreateHouseForm onSave={createHouse} isLoading={isCreatingHouse} />
      </Modal>
    </>
  )
}
