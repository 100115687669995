import { createPortal } from "react-dom"

type Props = {
  children: React.ReactNode
}
export const MOBILE_HEADER_PORTAL_CONTAINER_ID =
  "mobile-header-portal-container-id"

export const MobileHeaderPortal = ({ children }: Props) => {
  const portalContainer = document.getElementById(
    MOBILE_HEADER_PORTAL_CONTAINER_ID,
  )

  if (!portalContainer) {
    return null
  }

  return createPortal(children, portalContainer)
}
