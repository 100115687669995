import { showNotification } from "@mantine/notifications"
import { useMutation, useQuery } from "@tanstack/react-query"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { TechnicalOverviewValidateInstallationInput } from "@ensol/types/forms/projects/technicalOverview"

import { isEnsolError } from "@ensol/shared/utils/errors"

import { httpClient } from "@ensol/entool/backend/axios"
import { queryClient } from "@ensol/entool/backend/queryClient"

export const useGetProjectTechnicalOverviewQuery = (projectId: string) =>
  useQuery<ProjectResponses.ProjectTechnicalOverview>({
    queryKey: ["projects", projectId, "technical-overview"],
    queryFn: async () => {
      const response = await httpClient.get(
        `/projects/${projectId}/technical-overview`,
      )
      return response.data
    },
  })

export const useValidateInstallationMutation = (projectId: string) => {
  return useMutation({
    mutationFn: async (input: TechnicalOverviewValidateInstallationInput) => {
      const response = await httpClient.post(
        `/projects/${projectId}/validate-installation`,
        input,
      )
      return response.data
    },
    onSuccess: async (_, input) => {
      await queryClient.invalidateQueries({
        queryKey: ["projects", projectId, "technical-overview"],
      })
      await queryClient.invalidateQueries({
        queryKey: ["projects", projectId],
      })
      await queryClient.invalidateQueries({
        queryKey: ["installer-projects", projectId],
      })
      showNotification({
        message: input.isInstallationCompleted
          ? "L'installation a été validée avec succès"
          : "L'installation a été mise à jour avec succès",
        color: "green",
      })
    },
    onError: (error) => {
      if (isEnsolError(error)) {
        showNotification({
          title: "Erreur lors de la validation de l'installation",
          message: error.message,
          color: "red",
        })
      } else {
        showNotification({
          title: "Erreur lors de la validation de l'installation",
          message: "Merci de réessayer plus tard",
          color: "red",
        })
      }
    },
  })
}
