import { useCallback } from "react"
import { Edge, ReactFlowInstance } from "reactflow"

import { STEP_WIDTH_IN_PX } from "@ensol/entool/components/entities/Project/MilestonesStepper/Milestone"
import { ProjectMilestonesConfig } from "@ensol/entool/utils/projects/types"

type Props<MilestoneType> = {
  currentMilestone: MilestoneType | null
  milestonesConfig: ProjectMilestonesConfig<MilestoneType>
  abortedMilestones?: MilestoneType[]
  stepperRef: React.RefObject<HTMLDivElement | null>
}

export const useMilestonesStepper = <MilestoneType>({
  currentMilestone,
  milestonesConfig,
  abortedMilestones,
  stepperRef,
}: Props<MilestoneType>) => {
  const nodes = milestonesConfig
    .filter((milestone) => !abortedMilestones?.includes(milestone.id))
    .map(({ id, nodePosition, label, color }) => ({
      id,
      type: "milestone",
      position: nodePosition!,
      data: { id, label, color },
    }))

  const edges = milestonesConfig.reduce(
    (edges, { id, nextSteps }) => [
      ...edges,
      ...nextSteps.map((nextStepId) => ({
        id: `${id}-${nextStepId}`,
        source: String(id),
        target: String(nextStepId),
        animated: true,
        sourceHandle: "rightHandle",
        targetHandle: "leftHandle",
      })),
    ],
    new Array<Edge>(),
  )

  const onInit = useCallback(
    ({ setViewport }: ReactFlowInstance) => {
      const activeStep = milestonesConfig.find(
        ({ id }) => id === currentMilestone,
      )

      if (
        !activeStep ||
        !activeStep.nodePosition ||
        stepperRef.current === null
      ) {
        return
      }

      const { width } = stepperRef.current.getBoundingClientRect()

      const initialX =
        activeStep.nodePosition.x < width - STEP_WIDTH_IN_PX
          ? 0
          : width / 2 - activeStep.nodePosition.x

      setViewport({
        x: initialX,
        y: 0,
        zoom: 1,
      })
    },
    [milestonesConfig, currentMilestone, stepperRef],
  )

  return {
    nodes,
    edges,
    onInit,
    isAborted:
      currentMilestone !== null &&
      abortedMilestones?.includes(currentMilestone),
  }
}
