import { Container, Loader, Stack } from "@mantine/core"
import { UseQueryResult } from "@tanstack/react-query"
import _ from "lodash"
import { ReactNode } from "react"

import {
  isAxiosBadRequestError,
  isAxiosForbiddenError,
  isAxiosNotFoundError,
  isEnsolError,
} from "@ensol/shared/utils/errors"

import { InfoBox } from "@ensol/entool/components/InfoBox"

type Props<TData, TError> = {
  query: UseQueryResult<TData, TError>
  returnEmptyResults?: boolean
  disabledQueryMessage?: string
  children: (props: { data: TData }) => ReactNode | null
}

export const QueryWrapper = <TData, TError>({
  query,
  returnEmptyResults,
  disabledQueryMessage,
  children,
}: Props<TData, TError>) => {
  const { data, isLoading, isPending, isError, error } = query

  if (isAxiosBadRequestError(error) && isEnsolError(error.response?.data)) {
    return (
      <Container mt="5%">
        <InfoBox
          color="red"
          title="Une erreur est survenue"
          message={error.response?.data.message}
        />
      </Container>
    )
  } else if (isAxiosNotFoundError(error)) {
    return (
      <Container mt="5%">
        <InfoBox
          color="orange"
          title="Page non trouvée"
          message="La page demandée n'existe pas, un mauvais lien peut-être ?"
        />
      </Container>
    )
  } else if (isAxiosForbiddenError(error)) {
    return (
      <Container mt="5%">
        <InfoBox
          color="red"
          title="Accès refusé"
          message="Vous n'avez pas les permissions pour accéder à ces données"
        />
      </Container>
    )
  } else if (isError) {
    return (
      <Container mt="5%">
        <InfoBox
          color="red"
          title="Une erreur est survenue"
          message="Une erreur est survenue lors du chargement des données"
        />
      </Container>
    )
  }

  if (isLoading)
    return (
      <Container mt="5%">
        <Stack align="center">
          <Loader />
        </Stack>
      </Container>
    )

  if (isPending) {
    return (
      <Container mt="5%">
        <InfoBox
          message={disabledQueryMessage ?? "Cette requête est désactivée"}
        />
      </Container>
    )
  }

  if ((!data || _.isEmpty(data)) && !returnEmptyResults) {
    return (
      <Container mt="5%">
        <InfoBox
          title="Aucun résultat"
          message="Nous n'avons trouvé aucun résultat pour votre requête"
        />
      </Container>
    )
  }

  return children({ data })
}
