export const Role = {
  EMPLOYEE: "EMPLOYEE",
  INSTALLER: "INSTALLER",
  MANAGER: "MANAGER",
} as const

export type Role = keyof typeof Role

export enum RoleGroups {
  ENSOL = "ENSOL",
  PARTNER = "PARTNER",
}

export const ROLE_GROUPS_CONFIG: Record<
  RoleGroups,
  { role: Role; level: number }[]
> = {
  [RoleGroups.ENSOL]: [
    { role: "MANAGER", level: 2 },
    { role: "EMPLOYEE", level: 1 },
  ],
  [RoleGroups.PARTNER]: [{ role: "INSTALLER", level: 1 }],
}

export type AuthorizedGroup = { group: RoleGroups; minimumRole: Role }

export const Team = {
  INSIDE_SALES: "INSIDE_SALES",
  ACCOUNT_EXECUTIVE: "ACCOUNT_EXECUTIVE",
  CUSTOMER_SUCCESS: "CUSTOMER_SUCCESS",
  ADMIN: "ADMIN",
  OPERATIONS: "OPERATIONS",
  TECHNICAL_VISIT_OWNER: "TECHNICAL_VISIT_OWNER",
  TECHNICAL_MANAGER: "TECHNICAL_MANAGER",
  TECHNICAL_STAFF: "TECHNICAL_STAFF",
} as const

export type Team = keyof typeof Team
