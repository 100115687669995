import { Divider, SimpleGrid, Stack, Text } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import _ from "lodash"
import { useMemo } from "react"

import { InstallationsResponses } from "@ensol/types/endpoints/installations"

import { Section } from "@ensol/shared/components/Section"
import { EV_CHARGER_SUBSIDIES } from "@ensol/shared/entities/installations/subsidies/evCharger"
import { formatCurrency } from "@ensol/shared/utils/format"

import { NumberInput } from "@ensol/entool/components/form/NumberInput"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { ExtraWorksForm } from "@ensol/entool/pages/Installation/components/ExtraWorksForm"
import { isFormValid } from "@ensol/entool/utils/form/validation"

type Props = {
  form: UseFormReturnType<InstallationsResponses.EvChargerInstallation>
  productsCount: number
}

export const SettingsSection = ({ form, productsCount }: Props) => {
  const subsidyOptions = useMemo(() => {
    return _.map(EV_CHARGER_SUBSIDIES, (subsidyInfo, subsidyType) => {
      if (!isFormValid(form)) {
        return { label: subsidyInfo.shortName, value: subsidyType }
      }

      const amount = subsidyInfo.computeAmount({
        evChargerInstallation: form.values,
        productsCount,
      })

      return {
        label: subsidyInfo.shortName,
        value: subsidyType,
        subtitle: `Montant: ${formatCurrency(amount)}`,
      }
    })
  }, [form, productsCount])

  return (
    <Section title="Paramètres" isCollapsible>
      <Stack gap={32}>
        <ExtraWorksForm {...form.getInputProps("extraWorks")} />
        <Divider />
        <Stack gap={12}>
          <Text fw={600} fz="lg">
            Supplément pose externe TTC
          </Text>
          <NumberInput
            suffix="€"
            min={0}
            {...form.getInputProps("additionalCost")}
          />
        </Stack>
        <Divider />
        <Stack gap={12}>
          <Text fw={600} fz="lg">
            Subventions
          </Text>
          <RadioGroup
            nullable
            options={subsidyOptions}
            OptionsWrapper={({ children }) => (
              <SimpleGrid cols={2}>{children}</SimpleGrid>
            )}
            {...form.getInputProps("subsidyType")}
          />
        </Stack>
      </Stack>
    </Section>
  )
}
