import { useMemo } from "react"

import { MonthlyConsumptions } from "@ensol/types/entities/installation"
import { ConsumptionDataSource } from "@ensol/types/simulation"

import { formatDate } from "@ensol/shared/utils/format"
import { computeTimeFrameDuration } from "@ensol/shared/utils/timeFrame"

import { ConsumptionSectionProps } from "@ensol/entool/pages/Installation/sections/2-Consumption"
import {
  useSwitchgridConsumption,
  useRealDataConsumption,
  useBillConsumption,
} from "@ensol/entool/queries/energy"
import { CONSUMPTION_DATA_SOURCE_OPTIONS } from "@ensol/entool/utils/installations/options"

import { InputMode } from "."

export const useEnergyConsumptionQueries = <
  Values extends {
    consumptionDataSource: ConsumptionDataSource
    yearlyConsumption: number
    monthlyConsumptions: MonthlyConsumptions
    monthlyBill: number
  },
>({
  form,
  house,
  inputMode,
}: ConsumptionSectionProps<Values> & { inputMode: InputMode }) => {
  const { yearlyConsumption, monthlyConsumptions, consumptionDataSource } =
    form.values
  const billConsumptionQuery = useBillConsumption(
    inputMode === "monthly" && monthlyConsumptions.length > 0
      ? { yearlyConsumption, monthlyConsumptions }
      : { yearlyConsumption },
  )
  const switchgridConsumptionQuery = useSwitchgridConsumption(house)
  const realDataConsumptionQuery = useRealDataConsumption(house)

  const consumptionOptionsQueries = useMemo(
    () => ({
      [ConsumptionDataSource.BILL]: {
        data: billConsumptionQuery.data,
        isLoading: billConsumptionQuery.isFetching,
      },
      [ConsumptionDataSource.SWITCHGRID]: {
        data: switchgridConsumptionQuery.data,
        isLoading: switchgridConsumptionQuery.isFetching,
      },
      [ConsumptionDataSource.REAL]: {
        data: realDataConsumptionQuery.data,
        isLoading: realDataConsumptionQuery.isFetching,
      },
    }),
    [
      billConsumptionQuery.data,
      billConsumptionQuery.isFetching,
      realDataConsumptionQuery.data,
      realDataConsumptionQuery.isFetching,
      switchgridConsumptionQuery.data,
      switchgridConsumptionQuery.isFetching,
    ],
  )

  const consumptionDataSourceOptions = useMemo(() => {
    return CONSUMPTION_DATA_SOURCE_OPTIONS.map((option) => {
      const consumptionQuery = consumptionOptionsQueries[option.value]
      if (consumptionQuery.isLoading || !consumptionQuery.data) {
        return
      }
      return {
        ...option,
        metadata: {
          yearlyConsumption: consumptionQuery.data.yearlyConsumption,
        },
      }
    }).filter((option) => option !== undefined)
  }, [consumptionOptionsQueries])

  const enedisDailyDataTimeFrame =
    switchgridConsumptionQuery.data?.enedisDailyDataTimeFrame ?? null

  return {
    isConsumptionLoading:
      consumptionOptionsQueries[consumptionDataSource].isLoading,
    computedConsumptionData:
      consumptionOptionsQueries[consumptionDataSource].data,
    consumptionDataSourceOptions,
    warning:
      consumptionDataSource === ConsumptionDataSource.SWITCHGRID &&
      enedisDailyDataTimeFrame &&
      computeTimeFrameDuration(enedisDailyDataTimeFrame, "year") < 1 &&
      `Il y'a moins d'un an de données Enedis disponibles (${formatDate(enedisDailyDataTimeFrame.startDate, "MMMM YYYY")} à ${formatDate(enedisDailyDataTimeFrame.endDate, "MMMM YYYY")}). Nous utilisons des données calculées à partir d'un profil moyen pour compléter.`,
  }
}
