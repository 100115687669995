import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"

export type NavigationTab = {
  id: string
  label: string
}

export const useTabsNavigation = (tabs: Pick<NavigationTab, "id">[]) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const handleTabChange = (tab: string | null) => {
    if (tab !== null) {
      setSearchParams({ tab })
    }
  }

  const currentTab = searchParams.get("tab")

  useEffect(() => {
    if (currentTab && tabs.find(({ id }) => id === currentTab) === undefined) {
      setSearchParams(undefined)
    }
  }, [tabs, setSearchParams, currentTab])

  return {
    currentTab: currentTab ?? tabs[0].id,
    handleTabChange,
  }
}
