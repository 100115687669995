import { Checkbox, CheckboxProps } from "@mantine/core"

import { PROJECT_FIELDS_LABELS } from "@ensol/entool/utils/form/fieldLabels/projects"

type Props<Values> = {
  initialValues: Values
  propertyName: keyof typeof PROJECT_FIELDS_LABELS
  name?: string
  value?: boolean
} & CheckboxProps

export const FormCheckbox = <Values extends Record<string, unknown>>({
  initialValues,
  propertyName,
  name,
  value,
  ...props
}: Props<Values>) => {
  if (!(propertyName in initialValues)) {
    return null
  }

  return (
    <Checkbox
      {...props}
      label={name ?? PROJECT_FIELDS_LABELS[propertyName]}
      checked={value}
      styles={{ label: { fontSize: 14 } }}
    />
  )
}
