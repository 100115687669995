import { useParams } from "react-router-dom"

import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { SalesVisitForm } from "@ensol/entool/pages/SalesVisit/SalesVisitForm"
import { useGetProjectQuery } from "@ensol/entool/queries/projects"

export const SalesVisitPage = () => {
  const { projectId } = useParams()
  const projectQuery = useGetProjectQuery(projectId!)

  return (
    <QueryWrapper query={projectQuery}>
      {({ data: project }) => <SalesVisitForm project={project} />}
    </QueryWrapper>
  )
}
