import { Group } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"

import { OnSiteInstallation } from "@ensol/types/entities/installation"
import { GeneralUpdateHouseInput } from "@ensol/types/forms/houses"

import { Section } from "@ensol/shared/components/Section"
import { getBattery } from "@ensol/shared/material/batteries"
import { getEvCharger } from "@ensol/shared/material/evCharger"
import { getInverter } from "@ensol/shared/material/photovoltaic/inverters"
import { getPanel } from "@ensol/shared/material/photovoltaic/panels"

import { HouseEquipments } from "@ensol/entool/components/form/House/HouseEquipments"
import { SignedInstallationCard } from "@ensol/entool/components/form/House/SignedInstallationCard"

type Props = {
  form: UseFormReturnType<GeneralUpdateHouseInput>
  onSiteInstallation?: OnSiteInstallation
}
export const HouseEquipmentsForm = ({ form, onSiteInstallation }: Props) => {
  return (
    <>
      {onSiteInstallation && (
        <Section title="Équipements Ensol déjà installés">
          <Group gap={12} align="stretch">
            <SignedInstallationCard
              products={[
                {
                  type: "panels",
                  name: `${onSiteInstallation.photovoltaicInstallation.panelsCount} ${
                    getPanel(
                      onSiteInstallation.photovoltaicInstallation.panelType,
                    ).name
                  }`,
                },
                {
                  type: "inverter",
                  name: getInverter(
                    onSiteInstallation.photovoltaicInstallation.inverterType,
                  ).name,
                },
              ]}
            />
            <SignedInstallationCard
              products={onSiteInstallation.batteryInstallations.map(
                (installation) => ({
                  type: "battery",
                  name: `${installation.count} ${getBattery(installation.type).name}`,
                }),
              )}
            />
            <SignedInstallationCard
              products={onSiteInstallation.evChargerInstallations.map(
                (installation) => ({
                  type: "evCharger",
                  name: getEvCharger(installation.type).name,
                }),
              )}
            />
          </Group>
        </Section>
      )}
      <Section title="Équipements">
        <HouseEquipments {...form.getInputProps("equipments")} />
      </Section>
    </>
  )
}
