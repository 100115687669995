import { Anchor, Group, ThemeIcon } from "@mantine/core"
import {
  IconExternalLink,
  IconMail,
  IconMapPin,
  IconPhone,
} from "@tabler/icons-react"
import { Link } from "react-router-dom"

import { Client } from "@ensol/types/entities/client"

import { formatFullName } from "@ensol/shared/utils/format"

import { IconButton } from "@ensol/entool/components/IconButton"
import { InfosIconPopover } from "@ensol/entool/components/InfosIconPopover"

type Props = {
  projectId: string
  address: string
  client: Client<{
    select: {
      email: true
      isComplexCase: true
      phone: true
      firstName: true
      lastName: true
    }
  }>
  to: string
}

export const ClientInfo = ({ address, client, to }: Props) => {
  return (
    <Group w="fit-content" gap="4" flex={2}>
      <Anchor
        component={Link}
        to={to}
        fw="500"
        c={`${client.isComplexCase ? "red.3" : "inherit"}`}
      >
        {formatFullName(client)}
      </Anchor>

      <InfosIconPopover
        infos={[
          {
            Icon: IconMail,
            name: "Email",
            value: client.email,
          },
          {
            Icon: IconPhone,
            name: "Téléphone",
            value: client.phone,
          },
          {
            Icon: IconMapPin,
            name: "Adresse",
            value: address,
            Link: (
              <IconButton
                Icon={
                  <ThemeIcon size={20}>
                    <IconExternalLink />
                  </ThemeIcon>
                }
                href={`https://maps.google.com/?q=${address}`}
                ariaLabel="Ouvrir l'adresse dans Google Maps"
                isExternal
              />
            ),
          },
        ]}
      />
    </Group>
  )
}
