import * as Sentry from "@sentry/react"

import { OnSiteInstallation } from "@ensol/types/entities/installation"
import { typedKeys } from "@ensol/types/utils"

import { CurrentType } from "@ensol/shared/material/currentType"
import {
  getInverter,
  INVERTERS,
  InverterType,
} from "@ensol/shared/material/photovoltaic/inverters"
import {
  OPTIMIZERS,
  getOptimizer,
} from "@ensol/shared/material/photovoltaic/optimizers"
import { getPanel, PanelType } from "@ensol/shared/material/photovoltaic/panels"
import { roundDecimalNumber } from "@ensol/shared/utils/format"

const ELECTRICITY_PRICE = 0.2016 // €/kWh
export const DEFAULT_ELECTRICITY_PRICE_ANNUAL_RAISE = 5 // %

export const YEARLY_MATERIAL_EFFICIENCY_LOSS = 0.995 // %

const AVERAGE_KWH_PRODUCED_PER_KWC_INSTALLED = 1450 // kWh/KWc

type EnergyPriceInput = {
  year: number
  baseYear: number
  baseEnergyPrice: number
  electricityPriceAnnualRaise?: number
}

const computeEnergyPriceWithAnnualRaise = ({
  baseEnergyPrice,
  baseYear,
  year,
  electricityPriceAnnualRaise = DEFAULT_ELECTRICITY_PRICE_ANNUAL_RAISE,
}: EnergyPriceInput) => {
  if (year < baseYear) {
    Sentry.captureMessage("Year is lower than base year", {
      extra: {
        baseEnergyPrice,
        baseYear,
        year,
      },
    })
  }
  const yearOffset = year - baseYear
  return baseEnergyPrice * (1 + electricityPriceAnnualRaise / 100) ** yearOffset
}

export const estimateDefaultEnergyPrice = (year: number): number => {
  return computeEnergyPriceWithAnnualRaise({
    year,
    baseYear: 2024,
    baseEnergyPrice: ELECTRICITY_PRICE,
  })
}

type EstimateEnergyPriceInput = {
  year?: number

  electricityPriceAnnualRaise?: number
  electricityBuyPrice: number
}

export const estimateEnergyPrice = ({
  year,
  electricityBuyPrice,
  electricityPriceAnnualRaise,
}: EstimateEnergyPriceInput) => {
  const baseYear = new Date().getFullYear()
  return computeEnergyPriceWithAnnualRaise({
    year: year ?? baseYear,
    baseYear,
    baseEnergyPrice: electricityBuyPrice,
    electricityPriceAnnualRaise,
  })
}

type YearlyEnergyUsageInput = {
  year: number
  autoConsumptionPercent: number
  estimatedYearlyProduction: number
}

type YearlyEnergyUsage = {
  autoConsumedEnergy: number
  resellableEnergy: number
}

export const computeYearlyEnergyUsage = ({
  year,
  estimatedYearlyProduction,
  autoConsumptionPercent,
}: YearlyEnergyUsageInput): YearlyEnergyUsage => {
  const currentYear = new Date().getFullYear()
  const yearOffset = year - currentYear

  const currentYearlyEstimatedEnergyProduction =
    estimatedYearlyProduction * YEARLY_MATERIAL_EFFICIENCY_LOSS ** yearOffset

  return {
    autoConsumedEnergy:
      currentYearlyEstimatedEnergyProduction * (autoConsumptionPercent / 100),
    resellableEnergy:
      currentYearlyEstimatedEnergyProduction *
      (1 - autoConsumptionPercent / 100),
  }
}

export const computeYearlyConsumption = (monthlyBill: number): number => {
  const electricityPrice = estimateDefaultEnergyPrice(new Date().getFullYear())
  return Math.round((monthlyBill * 12) / electricityPrice)
}

export const computeMonthlyBill = (yearlyConsumption: number): number => {
  const electricityPrice = estimateDefaultEnergyPrice(new Date().getFullYear())
  return Math.round((yearlyConsumption / 12) * electricityPrice)
}

export const computeYearlyEnergyPrice = ({
  yearlyConsumption,
  monthlyBill,
}: {
  yearlyConsumption: number
  monthlyBill: number
}): number => {
  if (yearlyConsumption === 0) return 0
  return (monthlyBill * 12) / yearlyConsumption
}

type RecommendedPanelsCountInput = {
  panelType: PanelType
  yearlyConsumption: number
  shouldBeCapped: boolean
}

const RECOMMENDED_CONSUMED_ENERGY_MATCH_PERCENT = 0.85 // %
const MAX_PANELS_COUNT_FOR_PRE_INSTALLATION = 20

export const getRecommendedPanelsCount = ({
  panelType,
  yearlyConsumption,
  shouldBeCapped,
}: RecommendedPanelsCountInput) => {
  const panel = getPanel(panelType)
  const recommendedPanelsCount = Math.ceil(
    (yearlyConsumption * RECOMMENDED_CONSUMED_ENERGY_MATCH_PERCENT) /
      AVERAGE_KWH_PRODUCED_PER_KWC_INSTALLED /
      panel.power,
  )

  return Math.min(
    recommendedPanelsCount,
    shouldBeCapped ? MAX_PANELS_COUNT_FOR_PRE_INSTALLATION : Infinity,
  )
}

export const computePanelsCount = <
  T extends {
    panelsCount: number
  },
>(
  roofSectionsWithPanels: T[],
) =>
  roofSectionsWithPanels.reduce((acc, section) => acc + section.panelsCount, 0)

export const computePanelsCountForCapacity = ({
  installationCapacityInKw,
  panelType,
}: {
  installationCapacityInKw: number
  panelType: PanelType
}) => {
  const panelsCount = installationCapacityInKw / getPanel(panelType).power
  if (panelsCount === 0) return panelsCount
  return panelsCount < 1 ? 1 : Math.floor(panelsCount)
}

type PhotovoltaicInstallationCapacityInput = {
  panelType: PanelType
  panelsCount: number
}

export const computePanelsCapacity = ({
  panelType,
  panelsCount,
}: PhotovoltaicInstallationCapacityInput): number => {
  const panel = getPanel(panelType)
  return roundDecimalNumber(panel.power * panelsCount, 1)
}

export const computeOnSiteInstallationsCapacity = (
  onSiteInstallation?: OnSiteInstallation,
): number => {
  if (!onSiteInstallation) return 0

  return computePanelsCapacity(onSiteInstallation.photovoltaicInstallation)
}

export const computeOnSiteInstallationPanelsCount = (
  onSiteInstallation?: OnSiteInstallation,
): number => {
  if (!onSiteInstallation) return 0

  return onSiteInstallation.photovoltaicInstallation.panelsCount
}

export const computeOnSiteInstallationsPanelCountForRoofSection = (
  roofSectionId: string,
  onSiteInstallation?: OnSiteInstallation,
): number => {
  if (!onSiteInstallation) return 0

  return (
    onSiteInstallation.photovoltaicInstallation.roofSectionsWithPanels.find(
      (roofSectionsWithPanels) =>
        roofSectionsWithPanels.roofSection.id === roofSectionId,
    )?.panelsCount ?? 0
  )
}

export const computeMaxAutoConsumptionPercent = ({
  yearlyConsumption,
  yearlyProduction,
}: {
  yearlyConsumption: number
  yearlyProduction: number
}): number =>
  Math.floor(Math.min((yearlyConsumption / yearlyProduction) * 100, 100))

// A more complex calculation can be done later, see commit b8d0a97d50205c8c08e3c9cefa03cfc3db8b61bd
export const computeInvertersCount = (
  inverterType: InverterType,
  panelsCount: number,
): number => {
  const inverter = getInverter(inverterType)
  return inverter.isCentralInverter ? 1 : panelsCount
}

type InverterCompatibilityInput = {
  panelsCount: number
  inverterType: InverterType
  installedCapacity: number
  currentType: CurrentType
}

type InverterCompatibility = {
  isRecommended: boolean
  DCACPercent: number
  isCompatible: boolean
}

export const checkInverterCompatibility = ({
  installedCapacity,
  panelsCount,
  inverterType,
  currentType,
}: InverterCompatibilityInput): InverterCompatibility => {
  if (panelsCount === 0) {
    return {
      DCACPercent: NaN,
      isCompatible: false,
      isRecommended: false,
    }
  }

  const inverter = getInverter(inverterType)
  const invertersCount = computeInvertersCount(inverterType, panelsCount)
  const inverterCapacity = invertersCount * inverter.power

  const DCACRatio = installedCapacity / inverterCapacity

  const isCompatible = inverter.allowedCurrentTypes.includes(currentType)

  // With a 6KTL inverter, you can actually accept a bigger DC/AC ratio for single phase installation, up to 8.5kWc
  // Explanation: https://ensol-group.slack.com/archives/C05RD1NE5D1/p1709225115401209
  const edgeCaseCompatibility =
    inverterType === InverterType.HUAWEI_SUN_2000_6_KTL_L_1 &&
    currentType === CurrentType.SINGLE_PHASE &&
    installedCapacity >= 6 &&
    installedCapacity <= 8.5

  return {
    DCACPercent: Math.round(DCACRatio * 100),
    isCompatible,
    isRecommended:
      edgeCaseCompatibility ||
      // cf: https://ensol-group.slack.com/archives/C04PDPE1RC1/p1700739722672509
      (isCompatible && DCACRatio >= 0.95 && DCACRatio <= 1.25),
  }
}

export const getRecommendedInverterType = ({
  panelsCount,
  panelType,
  isCentralInverter,
  currentType,
}: {
  panelsCount: number
  panelType: PanelType
  isCentralInverter: boolean
  currentType: CurrentType
}) =>
  typedKeys(INVERTERS).find((inverterType) => {
    const inverter = getInverter(inverterType)
    const { isRecommended } = checkInverterCompatibility({
      panelsCount,
      installedCapacity: computePanelsCapacity({
        panelsCount,
        panelType,
      }),
      inverterType,
      currentType,
    })

    return isRecommended && inverter.isCentralInverter === isCentralInverter
  })

export const getRecommendedOptimizerType = ({
  panelType,
}: {
  panelType: PanelType
}) => {
  const panel = getPanel(panelType)
  return typedKeys(OPTIMIZERS).find((optimizerType) => {
    const optimizer = getOptimizer(optimizerType)
    return optimizer !== undefined && optimizer.power >= panel.power
  })
}
