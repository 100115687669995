import { Button, Group } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconX, IconRotate360 } from "@tabler/icons-react"

import { Project } from "@ensol/types/entities/project"

import { ProjectStatus } from "@ensol/shared/entities/projects/statuses"

import { useResumeProjectMutation } from "@ensol/entool/queries/projects"

import { AbortProjectReasonsModal } from "./AbortProjectReasonsModal"

type Props = {
  project: Project
}

export const ProjectActions = ({ project }: Props) => {
  const [isAbortModalOpen, { open: openAbortModal, close: closeAbortModal }] =
    useDisclosure(false)

  const { mutateAsync: resumeProject } = useResumeProjectMutation(project.id)

  if (project.status === ProjectStatus.ABORTED) {
    return (
      <Button
        leftSection={<IconRotate360 size={14} />}
        variant="outline"
        onClick={() => resumeProject()}
      >
        Relancer le projet
      </Button>
    )
  }

  return (
    <Group>
      <AbortProjectReasonsModal
        onClose={closeAbortModal}
        opened={isAbortModalOpen}
        project={project}
      />
      <Button
        leftSection={<IconX size={14} />}
        variant="outline"
        color="red"
        onClick={openAbortModal}
      >
        Abandonner le projet
      </Button>
    </Group>
  )
}
