import { ProjectInstallerStatus } from "@ensol/types/entities/project"

import { useGetProjectsInstallersOverviewQuery } from "@ensol/entool/queries/projects/overview"
import { useProjectsSearch } from "@ensol/entool/utils/projects/search"

export const useProjectsInstallersOverview = (currentTab: string) => {
  const { search } = useProjectsSearch()
  const statusesFilters = isProjectInstallerStatus(currentTab)
    ? [currentTab]
    : [
        ...Object.values(ProjectInstallerStatus).filter((status) =>
          search
            ? true
            : status !== ProjectInstallerStatus.ABORTED &&
              status !== ProjectInstallerStatus.INSTALLATION_COMPLETED,
        ),
      ]

  return useGetProjectsInstallersOverviewQuery({
    filters: {
      search,
      statuses: statusesFilters,
    },
  })
}

const isProjectInstallerStatus = (tab: string): tab is ProjectInstallerStatus =>
  tab in ProjectInstallerStatus
