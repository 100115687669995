import { useLocalStorage } from "@uidotdev/usehooks"
import { useContext } from "react"

import { ProjectSearchContext } from "@ensol/entool/components/entities/Project/ProjectSearchProvider"

export const PROJECT_SEARCHABLE_PROPS = {
  search: "search",
  installerIds: "installerIds",
  supplierIds: "supplierIds",
  operationsOwnerIds: "operationsOwnerIds",
  salesOwnerIds: "salesOwnerIds",
  adminOwnerIds: "adminOwnerIds",
} as const

export const useProjectsSearch = () => {
  const { search, setSearch } = useContext(ProjectSearchContext)
  const [installerIds, setInstallerIds] = useLocalStorage<string[]>(
    PROJECT_SEARCHABLE_PROPS.installerIds,
    [],
  )
  const [supplierIds, setSupplierIds] = useLocalStorage<string[]>(
    PROJECT_SEARCHABLE_PROPS.supplierIds,
    [],
  )
  const [salesOwnerIds, setSalesOwnerIds] = useLocalStorage<string[]>(
    PROJECT_SEARCHABLE_PROPS.salesOwnerIds,
    [],
  )
  const [operationsOwnerIds, setOperationsOwnerIds] = useLocalStorage<string[]>(
    PROJECT_SEARCHABLE_PROPS.operationsOwnerIds,
    [],
  )
  const [adminOwnerIds, setAdminOwnerIds] = useLocalStorage<string[]>(
    PROJECT_SEARCHABLE_PROPS.adminOwnerIds,
    [],
  )

  return {
    search,
    setSearch,
    installerIds,
    setInstallerIds,
    supplierIds,
    setSupplierIds,
    salesOwnerIds,
    setSalesOwnerIds,
    operationsOwnerIds,
    setOperationsOwnerIds,
    adminOwnerIds,
    setAdminOwnerIds,
  }
}
