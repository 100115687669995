import {
  IconBattery4,
  IconBolt,
  IconBuildingFortress,
  IconClipboardList,
  IconFile,
  IconLeaf,
  IconMessageCircle,
  IconPlugConnected,
  IconSolarPanel,
  IconTools,
  IconWallet,
  TablerIcon,
} from "@tabler/icons-react"

import {
  PreliminaryRequestStep,
  ProcessStep,
  TechnicalVisitStep,
  ConsuelStep,
  EnedisStep,
  HardwareStep,
  InstallationStep,
  PaymentStep,
  GreendealStep,
  EdfOaStep,
  FollowUpStep,
  ProcessId,
  ToulouseMetropolisStep,
} from "@ensol/shared/entities/projects/processes"

import {
  PRELIMINARY_REQUEST_STEPS,
  TECHNICAL_VISIT_STEPS,
  INSTALLATION_STEPS,
  PAYMENT_STEPS,
  HARDWARE_STEPS,
  GREENDEAL_STEPS,
  ENEDIS_STEPS,
  CONSUEL_STEPS,
  EDF_OA_STEPS,
  FOLLOW_UP_STEPS,
  TOULOUSE_METROPOLIS_STEPS,
} from "./processes"
import { ProjectMilestonesConfig } from "./types"

export type ProjectProcessView<T extends ProcessId> = {
  id: T
  name: string
  color: string
  Icon: TablerIcon
  warnDelayInDays: number
  milestones: ProjectMilestonesConfig<ProcessStep<T>>
  doneMilestone?: ProcessStep<T>
  abortedMilestones?: ProcessStep<T>[]
}

export const PROJECTS_PROCESS_VIEWS: ProjectProcessView<ProcessId>[] = [
  {
    id: "preliminaryRequestStep",
    name: "Demande Préalable",
    color: "teal",
    Icon: IconFile,
    warnDelayInDays: 30,
    milestones: Object.values(PRELIMINARY_REQUEST_STEPS),
    doneMilestone: PreliminaryRequestStep.AUTHORISATION_RECEIVED,
    abortedMilestones: [PreliminaryRequestStep.ABORTED],
  },
  {
    id: "technicalVisitStep",
    name: "Visite Technique",
    color: "violet",
    Icon: IconMessageCircle,
    warnDelayInDays: 30,
    milestones: Object.values(TECHNICAL_VISIT_STEPS),
    doneMilestone: TechnicalVisitStep.VALIDATED,
    abortedMilestones: [TechnicalVisitStep.REJECTED],
  },
  {
    id: "hardwareStep",
    name: "Matériel",
    color: "indigo",
    Icon: IconSolarPanel,
    warnDelayInDays: 30,
    milestones: Object.values(HARDWARE_STEPS),
    doneMilestone: HardwareStep.ORDER_INSTALLED,
  },
  {
    id: "paymentStep",
    name: "Paiement",
    color: "grape",
    Icon: IconWallet,
    warnDelayInDays: 30,
    milestones: Object.values(PAYMENT_STEPS),
    doneMilestone: PaymentStep.FINAL_INVOICE_PAID,
  },
  {
    id: "installationStep",
    name: "Installation",
    color: "blue",
    Icon: IconTools,
    warnDelayInDays: 30,
    milestones: Object.values(INSTALLATION_STEPS),
    doneMilestone: InstallationStep.CERTIFICATE_SIGNED,
  },
  {
    id: "enedisStep",
    name: "Enedis",
    color: "pink",
    Icon: IconPlugConnected,
    warnDelayInDays: 15,
    milestones: Object.values(ENEDIS_STEPS),
    doneMilestone: EnedisStep.ACTIVATED,
  },
  {
    id: "consuelStep",
    name: "Consuel",
    color: "yellow",
    Icon: IconClipboardList,
    warnDelayInDays: 15,
    milestones: Object.values(CONSUEL_STEPS),
    doneMilestone: ConsuelStep.VALIDATED,
  },
  {
    id: "greendealStep",
    name: "Green Deal",
    color: "green",
    Icon: IconLeaf,
    warnDelayInDays: 30,
    milestones: Object.values(GREENDEAL_STEPS),
    doneMilestone: GreendealStep.VALIDATED,
  },
  {
    id: "edfOaStep",
    name: "EDF OA",
    color: "green",
    Icon: IconBattery4,
    warnDelayInDays: 10,
    milestones: Object.values(EDF_OA_STEPS),
    doneMilestone: EdfOaStep.CERTIFICATE_SENT,
  },
  {
    id: "toulouseMetropolisStep",
    name: "Toulouse Métropole",
    color: "pink",
    Icon: IconBuildingFortress,
    warnDelayInDays: 30,
    milestones: Object.values(TOULOUSE_METROPOLIS_STEPS),
    doneMilestone: ToulouseMetropolisStep.REQUEST_VALIDATED,
  },
  {
    id: "followUpStep",
    name: "Suivi",
    color: "orange",
    Icon: IconBolt,
    warnDelayInDays: 30,
    milestones: Object.values(FOLLOW_UP_STEPS),
    doneMilestone: FollowUpStep.ONBOARDING_DONE,
  },
]
