import _ from "lodash"

import { InstallationsResponses } from "@ensol/types/endpoints/installations"

import { withMargin } from "@ensol/shared/entities/installations/costs"
import { SubsidyType } from "@ensol/shared/entities/installations/subsidies"
import {
  ControlDeviceType,
  getControlDevice,
} from "@ensol/shared/material/controlDevices"

import { CostsBuilder } from "./CostsBuilder"

const TRAVEL_EXPENSES_IN_EURO = 200

export class ControlDevicesCostsBuilder extends CostsBuilder<
  InstallationsResponses.ControlDeviceInstallations,
  SubsidyType
> {
  constructor(
    installation: InstallationsResponses.ControlDeviceInstallations,
    private productsCount: number,
  ) {
    super(installation)
  }

  public computeVatRate() {
    return 0.2
  }

  public computeControlDeviceUnitPrice({
    deviceType,
    isFree,
  }: {
    deviceType: ControlDeviceType
    isFree: boolean
  }) {
    const controlDevice = getControlDevice(deviceType)
    return isFree ? 0 : withMargin(controlDevice.price)
  }

  public computeMaterialCosts() {
    return _.sum(this.installation.map(this.computeControlDeviceUnitPrice))
  }

  public computeControlDeviceInstallationCost({
    deviceType,
    isFree,
  }: {
    deviceType: ControlDeviceType
    isFree: boolean
  }) {
    const controlDevice = getControlDevice(deviceType)
    return isFree ? 0 : withMargin(controlDevice.installationCost)
  }

  public computeWorkforceCosts() {
    const travelExpenses = this.productsCount > 1 ? 0 : TRAVEL_EXPENSES_IN_EURO
    const installationWorkforceCosts = _.sum(
      this.installation.map(this.computeControlDeviceInstallationCost),
    )
    return installationWorkforceCosts + travelExpenses
  }

  public computeExtraWorksCosts() {
    return 0
  }

  public computeAdminCosts() {
    return 0
  }

  public computeSubsidy() {
    return null
  }
}
