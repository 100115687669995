import _ from "lodash"

type ProjectInstallers = {
  technicalVisitInstaller: { name: string } | null
  installer: { name: string } | null
}

export const getProjectInstallers = ({
  technicalVisitInstaller,
  installer,
}: ProjectInstallers) =>
  _.uniq([
    ...(technicalVisitInstaller ? [technicalVisitInstaller.name] : []),
    ...(installer ? [installer.name] : []),
  ])
