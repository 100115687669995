import {
  KizeoProjectReport,
  KizeoProjectReportType,
} from "@ensol/types/entities/project/kizeoProjectReport"

export const TECHNICAL_VISIT_KIZEO_FORM_ID = "987114"
export const INSTALLATION_KIZEO_FORM_ID = "1061464"
export const CUSTOMER_SERVICE_KIZEO_FORM_ID = "1058038"
export const AUDIT_KIZEO_FORM_ID = "1061721"

export const KIZEO_PROJECT_REPORT_FORM_ID_CONFIG: Record<
  KizeoProjectReportType,
  string
> = {
  [KizeoProjectReportType.TECHNICAL_VISIT]: TECHNICAL_VISIT_KIZEO_FORM_ID,
  [KizeoProjectReportType.INSTALLATION]: INSTALLATION_KIZEO_FORM_ID,
  [KizeoProjectReportType.CUSTOMER_SERVICE]: CUSTOMER_SERVICE_KIZEO_FORM_ID,
  [KizeoProjectReportType.AUDIT]: AUDIT_KIZEO_FORM_ID,
}

export const findFirstKizeoProjectReportFromType = <
  T extends Pick<KizeoProjectReport, "type">,
>(
  kizeoProjectReports: T[],
  type: KizeoProjectReportType,
): T | null =>
  kizeoProjectReports.find(
    (kizeoProjectReport) => kizeoProjectReport.type === type,
  ) ?? null

export const getKizeoProjectReportPathsFromType = (
  kizeoProjectReports: Pick<KizeoProjectReport, "type" | "reportPath">[],
  type: KizeoProjectReportType,
): string[] => {
  return kizeoProjectReports.reduce(
    (kizeoProjectReportPaths: string[], kizeoProjectReport) => {
      if (kizeoProjectReport.type === type && kizeoProjectReport.reportPath) {
        kizeoProjectReportPaths.push(kizeoProjectReport.reportPath)
      }
      return kizeoProjectReportPaths
    },
    [],
  )
}

export const getKizeoProjectReportFormUrl = (
  type: KizeoProjectReportType,
): string => {
  return `https://forms.kizeo.com/data/history/${KIZEO_PROJECT_REPORT_FORM_ID_CONFIG[type]}`
}
