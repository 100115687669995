import { SimpleGrid } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { useEffect } from "react"

import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { evChargerInstallationSchemaWithExtraWorks } from "@ensol/types/forms/installations/evCharger"

import { Section } from "@ensol/shared/components/Section"
import { DEFAULT_EV_CHARGER_INSTALLATION } from "@ensol/shared/entities/installations/defaults"
import { computeYearlyEnergyPrice } from "@ensol/shared/entities/installations/energy"
import { EvChargerType } from "@ensol/shared/material/evCharger"

import {
  ProductWrapper,
  ProductFormProps,
} from "@ensol/entool/pages/Installation/components/ProductWrapper"
import { isFormValid } from "@ensol/entool/utils/form/validation"

import { EvChargerSelector } from "./EvChargerSelector"
import { SettingsSection } from "./SettingsSection"
import { StatsSection } from "./StatsSection"

type Props = {
  evChargerInstallation: InstallationsResponses.EvChargerInstallation | null
  onChange: (data: InstallationsResponses.EvChargerInstallation | null) => void
  setError: (error: string) => void
  clearError: () => void
  productsCount: number
  yearlyProduction: number
  monthlyBill: number
  billReductionRatio?: number
}

export const EvChargerInstallation = ({
  evChargerInstallation,
  yearlyProduction,
  monthlyBill,
  billReductionRatio,
  ...props
}: Props) => {
  const yearlyBill = monthlyBill * 12
  const currentElectricityPrice = computeYearlyEnergyPrice({
    yearlyConsumption: yearlyProduction,
    monthlyBill,
  })

  const targetElectricityPrice =
    (yearlyBill * (1 - (billReductionRatio ?? 0))) / yearlyProduction

  return (
    <>
      <StatsSection
        currentElectricityPrice={currentElectricityPrice}
        targetElectricityPrice={targetElectricityPrice}
        evChargerType={
          evChargerInstallation?.type ??
          EvChargerType.WALLBOX_PULSAR_PLUS_SOCKET_MONO
        }
      />
      <ProductWrapper<
        InstallationsResponses.EvChargerInstallation,
        { productsCount: number }
      >
        type="EvCharger"
        title="Ajouter une borne de recharge"
        addButtonLabel="Ajouter une borne de recharge"
        deleteButtonLabel="Supprimer la borne de recharge"
        pictureUrl="/wallboxEvCharger.png"
        onAdd={() => props.onChange(DEFAULT_EV_CHARGER_INSTALLATION)}
        ProductForm={EvChargerInstallationForm}
        formProps={{ productInstallation: evChargerInstallation, ...props }}
      />
    </>
  )
}

const EvChargerInstallationForm = ({
  productInstallation: evChargerInstallation,
  onChange,
  setError,
  clearError,
  DeleteButton,
  productsCount,
}: ProductFormProps<InstallationsResponses.EvChargerInstallation> & {
  productsCount: number
}) => {
  const form = useForm<InstallationsResponses.EvChargerInstallation>({
    onValuesChange: (values) => onChange(values),
    validate: zodResolver(evChargerInstallationSchemaWithExtraWorks),
    initialValues: evChargerInstallation ?? undefined,
  })

  useEffect(() => {
    if (!isFormValid(form)) {
      setError("ev charger installation is invalid")
    } else {
      clearError()
    }
  })

  return (
    <>
      <Section title="Ajouter une borne de recharge" Actions={DeleteButton}>
        <SimpleGrid cols={2} spacing={32}>
          <EvChargerSelector
            evChargerType={form.values.type}
            onChange={(evChargerType) =>
              form.setFieldValue("type", evChargerType)
            }
          />
        </SimpleGrid>
      </Section>
      <SettingsSection form={form} productsCount={productsCount} />
    </>
  )
}
