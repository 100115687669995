import * as z from "zod"

import { transformSchemaToNullish } from "../utils"
import { integrationKitTypeField } from "./shared"

export const beforeVisitStepsFormSchema = z.object({
  technicalVisitStartDate: z.date().nullable().optional(),
  technicalVisitExpectedValidationDate: z.date().nullable().optional(),
  technicalVisitInstallerId: z.string().nullable().optional(),
  technicalVisitOwnerId: z.string().nullable().optional(),
})

export const schedulingOnHoldStepFormSchema = beforeVisitStepsFormSchema.extend(
  {
    technicalVisitSchedulingHoldEndDate: z.date().nullable().optional(),
    technicalVisitLastContactDate: z.date().nullable().optional(),
    schedulingIssueMainReason: z.string().nullable(),
    schedulingIssueSubReason: z.string().nullable(),
  },
)

export const scheduledStepFormSchema = schedulingOnHoldStepFormSchema.extend({
  installerId: z.string().nullable().optional(),
  isTechnicalVisitReady: z.boolean().optional(),
  isClientIntroductoryCallDone: z.boolean().optional(),
  technicalVisitStartDate: z.date({ invalid_type_error: "Champ obligatoire" }),
  technicalVisitInstallerId: z.string({
    invalid_type_error: "Champ obligatoire",
  }),
  technicalVisitOwnerId: z.string({ invalid_type_error: "Champ obligatoire" }),
  technicalVisitHorizonProfileReportPath: z.string().nullable(),
})

export const completedStepFormSchema = scheduledStepFormSchema.extend({
  technicalVisitDifficulty: z.string().nullable().optional(),
  technicalVisitPanelsLayoutPath: z.string().nullable().optional(),
  technicalVisitK2ReportsPath: z.array(z.string()),
  technicalVisitReportsPath: z.array(z.string()),
  technicalVisitClientConclusion: z.string().optional(),
  technicalVisitInstallerConclusion: z.string().optional(),
  salesVisitPanelsLayoutPath: z.string().nullable().optional(),
  salesVisitK2ReportPath: z.string().nullable().optional(),
})

export const beingProcessedStepFormSchema = completedStepFormSchema.extend({
  installationEstimatedDuration: z.number({
    invalid_type_error: "Champ obligatoire",
  }),
  installationClientAvailabilityDate: z.date().optional().nullable(),
  integrationKitType: integrationKitTypeField.nullable().optional(),
  isPLCNeeded: z.boolean({ invalid_type_error: "Champ obligatoire" }),
  hardwareNotes: z.string().optional(),
})

export const requiredWorksFormSchema = z.object({
  technicalVisitStartOfWorksDate: z.date().optional().nullable(),
  technicalVisitEndOfWorksDate: z.date({
    invalid_type_error: "Champ obligatoire",
  }),
  technicalVisitWorksPhotos: z.array(z.string()),
  technicalVisitRequiredWorks: z.array(z.string()),
  technicalVisitWorksArtisan: z.string().optional(),
})

export const validatedStepFormSchema = beingProcessedStepFormSchema
  .merge(requiredWorksFormSchema)
  .extend({
    technicalVisitRequiredChanges: z.array(z.string()),
    technicalVisitClientReportPath: z.string({
      invalid_type_error: "Champ obligatoire",
    }),
  })

export type RequiredWorksInput = z.infer<typeof requiredWorksFormSchema>

export type TechnicalVisitStepInput =
  | z.infer<typeof beforeVisitStepsFormSchema>
  | z.infer<typeof schedulingOnHoldStepFormSchema>
  | z.infer<typeof scheduledStepFormSchema>
  | z.infer<typeof completedStepFormSchema>
  | z.infer<typeof beingProcessedStepFormSchema>
  | z.infer<typeof validatedStepFormSchema>

export const technicalVisitSchema = transformSchemaToNullish(
  validatedStepFormSchema,
)
