import { EventImpl } from "@fullcalendar/core/internal"
import { Text } from "@mantine/core"
import { modals } from "@mantine/modals"
import { useCallback } from "react"

import {
  ProjectEventStatus,
  ProjectEventType,
} from "@ensol/shared/entities/projects/events"

import { queryClient } from "@ensol/entool/backend/queryClient"
import {
  updateProjectEventDates,
  UpdateProjectEventDatesInput,
  useCompleteProcessStepMutation,
} from "@ensol/entool/queries/projects"

export const useEventUpdate = () => {
  const { mutateAsync: completeStep } = useCompleteProcessStepMutation(false)

  const reloadEvents = useCallback(
    async () =>
      await queryClient.invalidateQueries({
        queryKey: ["projects", "events"],
      }),
    [],
  )

  const scheduleEvent = useCallback(
    async ({ projectId, type, start, end }: UpdateProjectEventDatesInput) => {
      await updateProjectEventDates({ projectId, type, start, end })
      await reloadEvents()
    },
    [reloadEvents],
  )

  const rescheduleEvent = useCallback(
    (
      { start, end, extendedProps: { projectId, type } }: EventImpl,
      revert: () => void,
    ) => {
      return modals.openConfirmModal({
        title: "Évènement déjà planifié",
        children: (
          <Text size="sm">
            Les évènements Google installateur et client seront supprimés puis
            recréés. L’installateur seul sera averti. Continuer ?
          </Text>
        ),
        labels: { confirm: "Confirmer", cancel: "Annuler" },
        centered: true,
        onConfirm: async () => {
          const processId =
            type === ProjectEventType.INSTALLATION
              ? "installationStep"
              : "technicalVisitStep"
          await scheduleEvent({ projectId, type, start, end })
          await completeStep({
            projectId,
            processId,
            action: "reschedule",
          })
          // We need to reset dates here because reschedule cleans them up by default
          await updateProjectEventDates({ projectId, type, start, end })
          await completeStep({
            projectId,
            processId,
            action: "validate",
          })
          await reloadEvents()
        },
        onCancel: revert,
        withCloseButton: false,
        closeOnClickOutside: false,
        closeOnEscape: false,
      })
    },
    [scheduleEvent, reloadEvents, completeStep],
  )

  return useCallback(
    async ({ event, revert }: { event: EventImpl; revert: () => void }) => {
      const {
        start,
        end,
        extendedProps: { projectId, type, status },
      } = event

      try {
        if (
          (status === ProjectEventStatus.TO_SCHEDULE &&
            type === ProjectEventType.INSTALLATION) ||
          type === ProjectEventType.INSTALLATION_NEW_VISIT
        ) {
          return await scheduleEvent({ projectId, type, start, end })
        }

        if (
          (status === ProjectEventStatus.TO_SCHEDULE &&
            type === ProjectEventType.TECHNICAL_VISIT) ||
          status === ProjectEventStatus.SCHEDULED
        ) {
          return rescheduleEvent(event, revert)
        }

        if (status === ProjectEventStatus.REJECTED) {
          await updateProjectEventDates({ projectId, type, start, end })
          await completeStep({
            projectId,
            processId: "technicalVisitStep",
            action: "validate",
          })
          await completeStep({
            projectId,
            processId: "technicalVisitStep",
            action: "validate",
          })
          return await reloadEvents()
        }
      } catch (error) {
        revert()
      }
    },
    [scheduleEvent, rescheduleEvent, completeStep, reloadEvents],
  )
}
