import { SegmentedControl } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"

import { MonthlyConsumptions } from "@ensol/types/entities/installation"
import { ConsumptionDataSource } from "@ensol/types/simulation"

import { InputMode } from "@ensol/entool/pages/Installation/sections/2-Consumption/DataConfiguration"
import { findSelectedOption, Option } from "@ensol/entool/utils/form/radio"

type Props<Values> = {
  form: UseFormReturnType<Values>
  consumptionDataSourceOptions: Option<
    ConsumptionDataSource,
    { yearlyConsumption: number }
  >[]
  setInputMode: (value: InputMode) => void
  setLinkBillAndConsumption: (value: boolean) => void
}

export const ConsumptionSourceSelect = <
  Values extends {
    consumptionDataSource: ConsumptionDataSource
    yearlyConsumption: number
    monthlyConsumptions: MonthlyConsumptions
    monthlyBill: number
  },
>({
  form,
  consumptionDataSourceOptions,
  setLinkBillAndConsumption,
  setInputMode,
}: Props<Values>) => {
  if (consumptionDataSourceOptions.length < 2) {
    return null
  }

  return (
    <SegmentedControl
      w="100%"
      data={consumptionDataSourceOptions}
      {...form.getInputProps("consumptionDataSource")}
      onChange={(value) => {
        form.getInputProps("consumptionDataSource").onChange(value)
        if (value) {
          form
            .getInputProps("yearlyConsumption")
            .onChange(
              findSelectedOption(value, consumptionDataSourceOptions)?.metadata
                ?.yearlyConsumption,
            )
        }

        if (value === ConsumptionDataSource.BILL) {
          setLinkBillAndConsumption(true)
        } else {
          setLinkBillAndConsumption(false)
          setInputMode("yearly")
        }
      }}
    />
  )
}
