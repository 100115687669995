import { showNotification } from "@mantine/notifications"
import { keepPreviousData, useQuery } from "@tanstack/react-query"
import _ from "lodash"

import { EnergyResponses } from "@ensol/types/endpoints/energy"
import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import {
  OnSiteInstallation,
  MonthlyConsumptions,
} from "@ensol/types/entities/installation"
import { Location } from "@ensol/types/forms/houses"
import { UpdateInstallationInput } from "@ensol/types/forms/installations"
import {
  InstallationEnergyConsumptionInput,
  InstallationEnergyProductionInput,
  InstallationEnergyStatsInput,
} from "@ensol/types/forms/installations/energy"
import {
  ConsumptionDataSource,
  ProductionDataSource,
} from "@ensol/types/simulation"

import { OrderStatus } from "@ensol/shared/entities/houses/switchgrid"
import { hasPhotovoltaicInstallation } from "@ensol/shared/entities/installations/characteristics"

import { httpClient } from "@ensol/entool/backend/axios"

type UseInstallationEnergyProductionInput = {
  coords: Pick<Location, "lat" | "lng">
  installation: Pick<UpdateInstallationInput, "photovoltaicInstallation">
}

export const useEnergyProductionPerDataSource = ({
  coords,
  installation,
}: UseInstallationEnergyProductionInput) =>
  useQuery<Record<ProductionDataSource, number> | null>({
    queryKey: [
      "energy",
      "production",
      coords,
      _.pick(installation.photovoltaicInstallation, [
        "panelType",
        "panelsCount",
        "inverterType",
        "roofSectionsWithPanels",
      ]),
    ],
    queryFn: async () => {
      if (!hasPhotovoltaicInstallation(installation)) {
        return null
      }

      try {
        const yearlyProductions = await Promise.all(
          Object.values(ProductionDataSource).map(async (source) => {
            const payload = {
              coords,
              ...installation,
              productionDataSource: source,
            } satisfies InstallationEnergyProductionInput

            const response = await httpClient.post<EnergyResponses.Production>(
              "/energy/production",
              payload,
            )
            return response.data?.yearlyProduction
              ? { source, yearlyProduction: response.data?.yearlyProduction }
              : null
          }),
        )

        return yearlyProductions
          .filter((sourceProduction) => sourceProduction !== null)
          .reduce(
            (energyProductionPerDataSource, { source, yearlyProduction }) => ({
              ...energyProductionPerDataSource,
              [source]: yearlyProduction,
            }),
            {} as Record<ProductionDataSource, number>,
          )
      } catch (error) {
        showNotification({
          title: "Erreur lors du calcul de la production",
          message:
            "La production n'a pas pu être récupérée. Veuillez contacter l'équipe technique et l'équipe énergie.",
          color: "red",
        })

        return null
      }
    },
  })

type UseInstallationEnergyStatsInput = {
  houseId: string
  coords: Pick<Location, "lat" | "lng">
  installation: Pick<
    UpdateInstallationInput,
    | "photovoltaicInstallation"
    | "batteryInstallation"
    | "productionDataSource"
    | "consumptionDataSource"
    | "yearlyConsumption"
  >
  switchgridOrderId: string | null
  onSiteInstallation?: OnSiteInstallation
}

export const useInstallationEnergyStats = ({
  houseId,
  coords,
  installation,
  switchgridOrderId,
  onSiteInstallation,
}: UseInstallationEnergyStatsInput) =>
  useQuery({
    queryKey: [
      "energy",
      "stats",
      switchgridOrderId,
      getEnergyStatsBaseQueryKey({
        coords,
        installation,
      }),
      onSiteInstallation,
    ],
    queryFn: async () => {
      if (
        !hasPhotovoltaicInstallation(installation) &&
        !onSiteInstallation?.photovoltaicInstallation
      ) {
        return null
      }

      try {
        const payload = {
          houseId,
          coords,
          switchgridOrderId,
          ...installation,
          onSiteInstallation,
        } satisfies InstallationEnergyStatsInput

        const response = await httpClient.post<EnergyResponses.Stats>(
          "/energy/stats",
          payload,
        )
        return response.data
      } catch (error) {
        showNotification({
          title: "Erreur lors du calcul des données énergétiques",
          message:
            "La production, la consommation et le taux d'autoconsommation n'ont pas pu être récupérés. Veuillez contacter l'équipe technique et l'équipe énergie.",
          color: "red",
        })
        return null
      }
    },
  })

const getEnergyStatsBaseQueryKey = ({
  coords,
  installation,
}: Pick<UseInstallationEnergyStatsInput, "coords" | "installation">) => [
  coords,
  _.pick(installation.photovoltaicInstallation, [
    "panelType",
    "inverterType",
    "roofSectionsWithPanels",
  ]),
  installation.batteryInstallation,
  installation.productionDataSource,
  installation.consumptionDataSource,
  installation.yearlyConsumption,
]

const fetchEnergyConsumption = async (
  input: InstallationEnergyConsumptionInput,
) => {
  try {
    const response = await httpClient.post<EnergyResponses.Consumption>(
      "/energy/consumption",
      input,
    )

    return response.data
  } catch (error) {
    showNotification({
      title: `Erreur lors de la récupération de la consommation ${input.consumptionDataSource}`,
      message:
        "La consommation n'a pas pu être récupérée. Veuillez contacter l'équipe technique et l'équipe énergie.",
      color: "red",
    })

    return null
  }
}

type BillConsumptionInput =
  | { yearlyConsumption: number }
  | { monthlyConsumptions: MonthlyConsumptions }

export const useBillConsumption = (input: BillConsumptionInput) => {
  return useQuery({
    queryKey: ["energy", "consumption", "bill", input],
    queryFn: async () => {
      return fetchEnergyConsumption({
        consumptionDataSource: ConsumptionDataSource.BILL,
        ...input,
      })
    },
    placeholderData: keepPreviousData,
  })
}

export const useSwitchgridConsumption = (
  house: HousesResponses.House<{
    include: { switchgridOrder: true; switchgridConsent: true }
  }>,
) => {
  const { switchgridOrder, switchgridConsent } = house

  return useQuery({
    queryKey: [
      "energy",
      "consumption",
      "switchgrid",
      switchgridOrder?.orderId,
      switchgridOrder?.orderStatus,
    ],
    queryFn: async () => {
      if (
        switchgridConsent === null ||
        switchgridOrder === null ||
        switchgridOrder.orderId === null ||
        (switchgridOrder.orderStatus !== OrderStatus.SUCCEEDED &&
          switchgridOrder.orderStatus !== OrderStatus.SOME_REQUESTS_SUCCEEDED &&
          switchgridOrder.orderStatus !== OrderStatus.RETRY_LATER) ||
        switchgridConsent.expirationDate < new Date()
      ) {
        return null
      }

      return fetchEnergyConsumption({
        consumptionDataSource: ConsumptionDataSource.SWITCHGRID,
        switchgridOrderId: switchgridOrder.orderId,
      })
    },
  })
}

export const useRealDataConsumption = (
  house: HousesResponses.House<{
    include: { switchgridOrder: true; switchgridConsent: true }
  }>,
) => {
  return useQuery({
    queryKey: [
      "energy",
      "consumption",
      "real",
      house.switchgridOrder?.orderId,
      house.switchgridOrder?.orderStatus,
    ],
    queryFn: async () => {
      return fetchEnergyConsumption({
        houseId: house.id,
        switchgridOrderId: house.switchgridOrder?.orderId,
        consumptionDataSource: ConsumptionDataSource.REAL,
      })
    },
  })
}

type UseEnergyReportQueryInput = {
  slug: string
  coords: Pick<Location, "lat" | "lng">
  installationValues: Pick<
    UpdateInstallationInput,
    | "photovoltaicInstallation"
    | "batteryInstallation"
    | "evChargerInstallation"
    | "yearlyConsumption"
    | "productionDataSource"
    | "consumptionDataSource"
    | "electricityBuyPrice"
  >
  startDate: Date
  endDate: Date
}

export const useGetEnergyReportQuery = ({
  slug,
  startDate,
  endDate,
  installationValues,
  coords,
}: UseEnergyReportQueryInput) => {
  return useQuery<InstallationsResponses.EnergyReport>({
    queryKey: [
      "installations",
      slug,
      "reports",
      "simulated-energy",
      startDate,
      endDate,
      installationValues.electricityBuyPrice,
      getEnergyStatsBaseQueryKey({
        coords,
        installation: installationValues,
      }),
    ],
    queryFn: async () => {
      const response = await httpClient.post(
        `/installations/${slug}/reports/simulated-energy`,
        {
          startDate,
          endDate,
          installationValues: {
            coords,
            ...installationValues,
          },
        },
      )

      return response.data
    },
    retry: false,
  })
}
