import { BlockNoteSchema, defaultInlineContentSpecs } from "@blocknote/core"
import { createReactInlineContentSpec } from "@blocknote/react"

import { colors } from "@ensol/shared/styles/colors"

export const schema = BlockNoteSchema.create({
  inlineContentSpecs: {
    ...defaultInlineContentSpecs,
    mention: createReactInlineContentSpec(
      {
        type: "mention",
        propSchema: { name: { default: "" }, id: { default: "" } },
        content: "none",
      },
      {
        render: (props) => (
          <span
            style={{
              backgroundColor: colors.blue[100],
              color: colors.blue[500],
              borderRadius: 4,
              padding: 2,
            }}
          >
            @{props.inlineContent.props.name}
          </span>
        ),
      },
    ),
  },
})
