export type Nullable<T> =
  T extends Record<string, unknown>
    ? {
        [K in keyof T]: T[K] extends Record<string, unknown>
          ? Nullable<T[K]>
          : T[K] | null
      }
    : never

export type UnpackArray<T> = T extends (infer U)[] ? U : never

export const typedKeys = <T extends Record<string, unknown>>(
  object: T,
): (keyof T)[] => Object.keys(object)

export const typedGroupBy = <K extends string | number, T>(
  array: T[],
  iteratee: (item: T) => K,
): Record<K, T[]> => {
  const result = {} as Record<K, T[]>

  array.forEach((item) => {
    const key = iteratee(item)

    if (!result[key]) {
      result[key] = []
    }

    result[key].push(item)
  })

  return result
}

export const hasKey = <T extends Record<string, unknown>>(
  obj: T,
  key: string | number | symbol,
): key is keyof T => {
  return key in obj
}
