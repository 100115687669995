import { useParams } from "react-router-dom"

import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { useGetProjectQuery } from "@ensol/entool/queries/projects"

import { Project } from "./Project"

export const ProjectPage = () => {
  const { projectId } = useParams()

  const projectQuery = useGetProjectQuery(projectId!)

  return (
    <QueryWrapper query={projectQuery}>
      {({ data }) => <Project project={data} />}
    </QueryWrapper>
  )
}
