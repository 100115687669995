import { Anchor, Text, TextProps } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { Link } from "react-router-dom"

import { devices } from "@ensol/shared/styles/theme"

type BreadCrumbLinkProps = {
  label: string
  to?: string
} & TextProps

const BASE_BREADCRUMB_STYLE_PROPS = {
  fz: "18",
  fw: "500",
  c: "black",
  style: { whiteSpace: "nowrap" },
} as const

const BREADCRUMB_MOBILE_STYLE_PROPS = {
  ...BASE_BREADCRUMB_STYLE_PROPS,
  style: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "32dvw",
    textWrap: "nowrap",
  },
} as const

export const BreadCrumb = ({ label, to, ...props }: BreadCrumbLinkProps) => {
  const isMobile = useMediaQuery(devices.sm)

  if (to === undefined) {
    return (
      <Text
        {...(isMobile
          ? BREADCRUMB_MOBILE_STYLE_PROPS
          : BASE_BREADCRUMB_STYLE_PROPS)}
        {...props}
      >
        {label}
      </Text>
    )
  }

  return (
    <Anchor
      component={Link}
      to={to}
      {...(isMobile
        ? BREADCRUMB_MOBILE_STYLE_PROPS
        : BASE_BREADCRUMB_STYLE_PROPS)}
      {...props}
    >
      {label}
    </Anchor>
  )
}
