import * as z from "zod"

import { PanelType } from "@ensol/shared/material/photovoltaic/panels"
import { InverterType } from "@ensol/shared/material/photovoltaic/inverters"
import { OptimizerType } from "@ensol/shared/material/photovoltaic/optimizers"
import { PhotovoltaicSubsidyType } from "@ensol/shared/entities/installations/subsidies/photovoltaic"
import { roofSectionConfigSchema } from "@ensol/types/forms/houses"

export const roofSectionWithPanelsSchema = z.object({
  panelsCount: z.number().nonnegative(),
  roofSection: roofSectionConfigSchema.extend({
    id: z.string(),
  }),
})

export type RoofSectionWithPanels = z.infer<typeof roofSectionWithPanelsSchema>

export const photovoltaicInstallationSchema = z.object({
  panelsCount: z.number().nonnegative(),
  panelType: z.nativeEnum(PanelType),
  inverterType: z.nativeEnum(InverterType),
  optimizerType: z.nativeEnum(OptimizerType).nullable(),
  optimizerCount: z.number().nullable(),
  subsidyType: z.nativeEnum(PhotovoltaicSubsidyType).nullable(),
  energyResellPrice: z.number().nonnegative(),
  roofSectionsWithPanels: z.array(roofSectionWithPanelsSchema),
})

export type PhotovoltaicInstallation = z.infer<
  typeof photovoltaicInstallationSchema
>
