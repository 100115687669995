import { AuthorizedGroup } from "@ensol/shared/entities/users"
import { isUserAuthorized } from "@ensol/shared/entities/users/roles"

import { useAuthenticatedUser } from "@ensol/entool/queries/users"

type Props = {
  authorizedGroups: AuthorizedGroup[]
  children: React.ReactNode
}

export const DisplayRoleGuard = ({ authorizedGroups, children }: Props) => {
  const { data: user, isPending } = useAuthenticatedUser()

  if (!user || !isUserAuthorized(authorizedGroups, user) || isPending) {
    return null
  }

  return children
}
