import { SimpleGrid } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { MonthlyConsumptions } from "@ensol/types/entities/installation"
import { ConsumptionDataSource } from "@ensol/types/simulation"

import { Section } from "@ensol/shared/components/Section"
import { computeYearlyBillsForecast } from "@ensol/shared/entities/installations/savings"
import { colors } from "@ensol/shared/styles/colors"
import { formatCurrency, formatNumber } from "@ensol/shared/utils/format"

import { StatCard } from "@ensol/entool/components/StatCard"
import { BillEvolutionGraph } from "@ensol/entool/pages/Installation/components/BillEvolutionGraph"

import { ConsumptionDataConfiguration } from "./DataConfiguration"
import { ElectricityPriceFigures } from "./ElectricityPriceFigures"
import { SwitchgridParameters } from "./Switchgrid"

export type ConsumptionSectionProps<Values> = {
  form: UseFormReturnType<Values>
  house: HousesResponses.House<{
    include: {
      switchgridConsent: true
      switchgridOrder: true
      roofSections: true
    }
  }>
}

export const ConsumptionSection = <
  Values extends {
    consumptionDataSource: ConsumptionDataSource
    yearlyConsumption: number
    monthlyConsumptions: MonthlyConsumptions
    monthlyBill: number
    electricityPriceAnnualRaise: number
    electricityBuyPrice: number
  },
>({
  form,
  house,
}: ConsumptionSectionProps<Values>) => {
  const {
    yearlyConsumption,
    electricityBuyPrice,
    electricityPriceAnnualRaise,
    monthlyBill,
  } = form.values
  const yearlyBill = 12 * monthlyBill

  return (
    <>
      <SimpleGrid cols={3}>
        <StatCard
          label="Consommation annuelle"
          stat={`${formatNumber(yearlyConsumption)} kWh`}
          unit="/ an"
        />
        <StatCard
          label="Facture annuelle"
          stat={formatCurrency(yearlyBill)}
          unit="/ an"
        />
        <StatCard
          label="Prix / kWh"
          stat={
            yearlyConsumption > 0
              ? formatCurrency(yearlyBill / yearlyConsumption)
              : 0
          }
          unit="/ kWh"
        />
      </SimpleGrid>
      <ConsumptionDataConfiguration form={form} house={house} />
      <SwitchgridParameters houseId={house.id} />
      <ElectricityPriceFigures />
      <Section title="Évolution de votre facture">
        <BillEvolutionGraph
          datasets={[
            {
              data: computeYearlyBillsForecast({
                yearlyConsumption,
                electricityBuyPrice,
                electricityPriceAnnualRaise,
                config: { dataPointsCount: 11, stepInYears: 2 },
              }),
              backgroundColor: colors.purple[500],
            },
          ]}
          withLegend={false}
          shouldShowAmounts
        />
      </Section>
    </>
  )
}
