import { z } from "zod"

import { KizeoProjectReportType } from "@ensol/types/entities/project/kizeoProjectReport"
import { completedStepFormSchema } from "@ensol/types/forms/projects/technicalVisit"

import { getKizeoProjectReportPathsFromType } from "@ensol/shared/entities/projects/kizeoProjectReport"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { TechnicalVisitForm } from "@ensol/entool/pages/Project/processes/TechnicalVisit/components/TechnicalVisitForm"
import { getInitialValues } from "@ensol/entool/utils/initialValues"
import { validateAction } from "@ensol/entool/utils/projects/validateAction"

export const Completed = ({ project, ...props }: StepComponentProps) => (
  <TechnicalVisitForm
    schema={completedStepFormSchema}
    initialValues={getInitialValues(completedStepFormSchema, project)}
    project={project}
    {...props}
    actions={[
      {
        action: "validate",
        label: "Passer en cours de traitement",
        ...(getKizeoProjectReportPathsFromType(
          project.kizeoProjectReports,
          KizeoProjectReportType.TECHNICAL_VISIT,
        ).length === 0 && {
          validateAction: validateAction(
            z.object({
              technicalVisitReportsPath: z
                .array(z.string())
                .min(1, "Champ obligatoire"),
            }),
          ),
        }),
      },
    ]}
  />
)
