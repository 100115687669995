import { useForm, zodResolver } from "@mantine/form"
import { useEffect } from "react"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { OnSiteInstallation } from "@ensol/types/entities/installation"
import { photovoltaicInstallationAndExtraWorksSchema } from "@ensol/types/forms/installations"

import {
  DEFAULT_PHOTOVOLTAIC_INSTALLATION,
  getDefaultExtraPanelsInstallation,
} from "@ensol/shared/entities/installations/defaults"
import {
  computePanelsCapacity,
  computePanelsCount,
  computeOnSiteInstallationPanelsCount,
} from "@ensol/shared/entities/installations/energy"
import { computeEnergyResellPrice } from "@ensol/shared/entities/installations/resell"

import {
  ProductFormProps,
  ProductWrapper,
} from "@ensol/entool/pages/Installation/components/ProductWrapper"
import { findSelectedOption } from "@ensol/entool/utils/form/radio"
import { isFormValid } from "@ensol/entool/utils/form/validation"
import { computeHouseRoofSectionsWithPanels } from "@ensol/entool/utils/house/roofSections"
import { usePhotovoltaicMaterialOptions } from "@ensol/entool/utils/installations/photovoltaic"

import { MaterialSection } from "./MaterialSection"
import { PanelsSection } from "./PanelsSection"
import { SettingsSection } from "./SettingsSection"

type ExtraProps = {
  house: HousesResponses.House<{
    include: { roofSections: true }
  }>
  panelsLayout?: InstallationsResponses.Installation["panelsLayout"]
  onSiteInstallation?: OnSiteInstallation
  yearlyConsumption?: number
  totalDiscount: number
  enedisFileCompletionDate?: Date
  isUpsell: boolean
}

type Props = {
  photovoltaicInstallation: InstallationsResponses.PhotovoltaicInstallation | null
  onChange: (
    data: InstallationsResponses.PhotovoltaicInstallation | null,
  ) => void
  setError: (error: string) => void
  clearError: () => void
} & ExtraProps

export const PhotovoltaicInstallation = ({
  photovoltaicInstallation,
  ...props
}: Props) => {
  return (
    <ProductWrapper<InstallationsResponses.PhotovoltaicInstallation, ExtraProps>
      type="Panels"
      title="Installation de panneaux supplémentaires"
      addButtonLabel="Ajouter des panneaux"
      deleteButtonLabel="Supprimer les panneaux"
      pictureUrl="/panelDualsun.png"
      onAdd={() =>
        props.onChange(
          getDefaultExtraPanelsInstallation(
            props.onSiteInstallation?.photovoltaicInstallation,
          ),
        )
      }
      ProductForm={PhotovoltaicInstallationForm}
      formProps={{
        productInstallation: photovoltaicInstallation,
        ...props,
      }}
    />
  )
}

const PhotovoltaicInstallationForm = ({
  productInstallation: photovoltaicInstallation,
  house,
  onChange,
  setError,
  clearError,
  DeleteButton,
  panelsLayout,
  onSiteInstallation,
  yearlyConsumption,
  totalDiscount,
  enedisFileCompletionDate,
  isUpsell,
}: ProductFormProps<InstallationsResponses.PhotovoltaicInstallation> &
  ExtraProps) => {
  const form = useForm({
    onValuesChange: (values) => onChange(values),
    validate: zodResolver(photovoltaicInstallationAndExtraWorksSchema),
    initialValues: photovoltaicInstallation
      ? {
          ...photovoltaicInstallation,
          roofSectionsWithPanels: computeHouseRoofSectionsWithPanels(
            house.roofSections,
            photovoltaicInstallation.roofSectionsWithPanels,
          ),
        }
      : undefined,
  })

  const {
    panelType,
    panelsCount,
    inverterType,
    optimizerType,
    roofSectionsWithPanels,
  } = form.values

  const totalPanelsCount = isUpsell
    ? panelsCount + computeOnSiteInstallationPanelsCount(onSiteInstallation)
    : panelsCount

  const { panelsOptions, invertersOptions, optimizerOptions } =
    usePhotovoltaicMaterialOptions({
      panelsCount: totalPanelsCount,
      panelType,
      inverterType,
      currentType: house.currentType,
      installedCapacity: computePanelsCapacity({
        panelsCount,
        panelType,
      }),
      optimizerType: optimizerType ?? undefined,
    })

  const installedCapacity = computePanelsCapacity({ panelsCount, panelType })

  useEffect(() => {
    const panelsCount = computePanelsCount(roofSectionsWithPanels)
    if (form.values.panelsCount !== panelsCount) {
      form.setFieldValue("panelsCount", panelsCount)
    }
  }, [form, roofSectionsWithPanels])

  useEffect(() => {
    const selectedOptimizeOption = findSelectedOption(
      optimizerType,
      optimizerOptions,
    )
    if (selectedOptimizeOption?.metadata?.irrelevant) {
      form.getInputProps("optimizerType").onChange(null)
    } else if (selectedOptimizeOption?.disabled) {
      const firstCompatibleOptimizer = optimizerOptions.find(
        (optimizer) => !optimizer.disabled,
      )
      if (firstCompatibleOptimizer) {
        form.setFieldValue("optimizerType", firstCompatibleOptimizer.value)
      } else {
        form.setFieldError("optimizerType", "Optimiseur invalide")
      }
    } else {
      form.clearFieldError("optimizerType")
    }

    const selectedInverterOption = findSelectedOption(
      inverterType,
      invertersOptions,
    )
    if (selectedInverterOption?.metadata?.incompatible) {
      const firstRecommendedInverterType =
        invertersOptions.find((inverter) => inverter.metadata?.recommended)
          ?.value ?? DEFAULT_PHOTOVOLTAIC_INSTALLATION.inverterType
      if (firstRecommendedInverterType !== inverterType) {
        form.setFieldValue("inverterType", firstRecommendedInverterType)
      }
    }
  }, [
    form,
    panelType,
    optimizerType,
    panelsOptions,
    optimizerOptions,
    inverterType,
    invertersOptions,
  ])

  useEffect(() => {
    const energyResellPrice = computeEnergyResellPrice({
      subsidyType: form.values.subsidyType,
      installedCapacity: computePanelsCapacity(form.values),
      enedisFileCompletionDate,
    })
    if (form.values.energyResellPrice !== energyResellPrice) {
      form.setFieldValue("energyResellPrice", energyResellPrice)
    }
  }, [form, enedisFileCompletionDate])

  // Validate the form and update error state of the main installation form
  useEffect(() => {
    if (!isFormValid(form)) {
      setError("photovoltaic installation is invalid")
    } else {
      clearError()
    }
  })

  return (
    <>
      <PanelsSection
        title="Installation Photovoltaïque"
        house={house}
        installationForm={form}
        totalPanelsCount={totalPanelsCount}
        panelsLayout={panelsLayout}
        onSiteInstallation={onSiteInstallation}
        yearlyConsumption={yearlyConsumption}
        DeleteButton={isUpsell ? DeleteButton : undefined}
        isUpsell={isUpsell}
      />
      <MaterialSection
        form={form}
        panelsOptions={panelsOptions}
        invertersOptions={invertersOptions}
        totalPanelsCount={totalPanelsCount}
        installedCapacity={installedCapacity}
        currentType={house.currentType}
        isUpsell={isUpsell}
      />
      <SettingsSection
        form={form}
        house={house}
        totalDiscount={totalDiscount}
        optimizerOptions={optimizerOptions}
        isUpsell={isUpsell}
      />
    </>
  )
}
