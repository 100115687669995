import dayjs from "dayjs"

import { DEFAULT_PANELS_INSTALLATION_INCLUDES } from "@ensol/types/endpoints/installations"
import { Project } from "@ensol/types/entities/project"

import { computePanelsCapacity } from "@ensol/shared/entities/installations/energy"

export const computeInstallationEndDate = (
  project: Project<{
    include: {
      installation: {
        include: {
          house: true
          photovoltaicInstallation: {
            include: typeof DEFAULT_PANELS_INSTALLATION_INCLUDES
          }
          batteryInstallation: true
        }
      }
    }
  }>,
  startDate: Date,
): Date => {
  // TODO: See during ops multi-product refacto if we want to refine
  // auto installation duration computation for non-photovoltaic installations
  let estimatedDuration = 1

  if (project.installation.photovoltaicInstallation !== null) {
    const installedCapacity = computePanelsCapacity(
      project.installation.photovoltaicInstallation,
    )
    estimatedDuration = Math.ceil(installedCapacity / 3)
  }

  const durationInDays =
    project.installationEstimatedDuration ?? estimatedDuration

  let endDate = dayjs(startDate)
  let currentDuration = 1

  while (currentDuration < durationInDays) {
    endDate = endDate.add(1, "day")
    if (endDate.day() !== 0 && endDate.day() !== 6) {
      currentDuration++
    }
  }
  return endDate.toDate()
}

export enum InstallationNewVisitReason {
  WEATHER = "Météo",
  COMPLEX_CABLE_ROUTING = "Cheminement de câble compliqué",
  COMPLEX_ROOF_INSTALLATION = "Installation toiture compliqué",
  COMPLEX_ELECTRICAL_INSTALLATION = "Installation électrique compliqué",
  ACTIVATION_NOT_DONE = "MES non réalisée",
  MATERIAL_NOT_RECEIVED = "Matériel non reçu",
  WRONG_MATERIAL_ORDER = "Mauvaise commande matériel",
  INSTALLER_ENSOL_COMMUNICATION = "Communication installateur - Ensol",
  SITE_DAMAGE = "Dégât chantier",
  OTHER = "Autre",
}
