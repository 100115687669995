import {
  Card as MantineCard,
  Badge,
  Stack,
  Text,
  MantineStyleProp,
  Group,
} from "@mantine/core"
import dayjs from "dayjs"
import React from "react"
import { Link } from "react-router-dom"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { UnpackArray } from "@ensol/types/utils"

import { ProjectStatus } from "@ensol/shared/entities/projects/statuses"
import { formatFullName } from "@ensol/shared/utils/format"

import { COLUMN_WIDTH_IN_PX, COLUMN_PADDING_IN_PX } from "./config"

type Props = {
  project: UnpackArray<ProjectResponses.ProjectsList>
  to?: string
  style?: MantineStyleProp
  children: React.ReactNode
} & (
  | {
      lastEventDate: undefined
      warnDelayInDays: undefined
      shouldDisplayDaysSinceLastEvent: false
    }
  | {
      lastEventDate: Date
      warnDelayInDays: number
      shouldDisplayDaysSinceLastEvent: boolean
    }
)

export const ProjectCard = ({
  project,
  to,
  lastEventDate,
  warnDelayInDays,
  shouldDisplayDaysSinceLastEvent,
  style,
  children,
}: Props) => {
  const { installation, status } = project
  const { house } = installation
  const { client } = house

  const daysSinceLastEvent = dayjs().diff(lastEventDate, "day")

  return (
    <MantineCard
      component={to ? Link : undefined}
      w={COLUMN_WIDTH_IN_PX - COLUMN_PADDING_IN_PX * 2}
      shadow="sm"
      padding="md"
      radius="md"
      withBorder
      to={to ?? ""}
      style={{
        cursor: to ? "pointer" : "default",
        overflow: "unset",
        ...style,
      }}
    >
      <Group
        style={{
          textTransform: "none",
          position: "absolute",
          right: 12,
          top: 12,
        }}
      >
        {status === ProjectStatus.ABORTED ? (
          <Badge color="red" variant="light">
            Annulé
          </Badge>
        ) : (
          shouldDisplayDaysSinceLastEvent && (
            <Badge
              color={daysSinceLastEvent >= warnDelayInDays ? "red" : "blue"}
              variant="light"
            >
              {daysSinceLastEvent === 0
                ? "Aujourd'hui"
                : `${daysSinceLastEvent} jour${daysSinceLastEvent > 1 ? "s" : ""}`}
            </Badge>
          )
        )}
      </Group>

      <Stack gap="0" pt={shouldDisplayDaysSinceLastEvent ? 20 : 0}>
        <Text
          fw={600}
          truncate="end"
          c={client.isComplexCase ? "red.3" : "inherit"}
        >
          {formatFullName(client)}
        </Text>
        <Text>
          {house.postcode} - {house.city}
        </Text>
      </Stack>
      {children}
    </MantineCard>
  )
}
