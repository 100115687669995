import * as z from "zod"

import { transformSchemaToNullish } from "../utils"
import { integrationKitTypeField } from "./shared"

export const pendingStepsFormSchema = z.object({
  technicalVisitClientConclusion: z.string().optional(),
  technicalVisitInstallerConclusion: z.string().optional(),
  installationEstimatedDuration: z.number().nullable().optional(),
  installationClientAvailabilityDate: z.date().optional().nullable(),
  installerId: z.string().nullable().optional(),
  technicalVisitPanelsLayoutPath: z.string().nullable().optional(),
  technicalVisitK2ReportsPath: z.array(z.string()),
  technicalVisitReportsPath: z.array(z.string()),
  technicalVisitRequiredChanges: z.array(z.string()),
  technicalVisitRequiredWorks: z.array(z.string()),
  technicalVisitStartOfWorksDate: z.date().nullable().optional(),
  technicalVisitEndOfWorksDate: z.date().nullable().optional(),
  technicalVisitWorksArtisan: z.string().optional(),
  technicalVisitWorksPhotos: z.array(z.string()),
  installationStartDate: z.date().nullable().optional(),
  installationEndDate: z.date().nullable().optional(),
  integrationKitType: integrationKitTypeField.nullable().optional(),
  isPLCNeeded: z.boolean().nullable().optional(),
  hardwareNotes: z.string().optional(),
})

export const schedulingOnHoldStepFormSchema = pendingStepsFormSchema.extend({
  installationSchedulingHoldEndDate: z.date().nullable().optional(),
  installationLastContactDate: z.date().nullable().optional(),
  schedulingIssueMainReason: z.string().nullable(),
  schedulingIssueSubReason: z.string().nullable(),
})

export const scheduledStepFormSchema = schedulingOnHoldStepFormSchema.extend({
  installerId: z.string({ invalid_type_error: "Champ obligatoire" }),
  installationStartDate: z.date({ invalid_type_error: "Champ obligatoire" }),
  installationEndDate: z.date({ invalid_type_error: "Champ obligatoire" }),
  isPLCNeeded: z.boolean({ invalid_type_error: "Champ obligatoire" }),
  technicalManagerId: z.string().nullable().optional(),
  areInstallationsCommentsConsidered: z.boolean().optional(),
  isActivationPrepared: z.boolean().optional(),
  isClientAccountCreated: z.boolean().optional(),
  isTechnicalVisitReportSent: z.boolean().optional(),
})

export const inProgressStepFormSchema = scheduledStepFormSchema.extend({
  isActivationDone: z.boolean().optional(),
  isInstallationReportValidated: z.boolean().optional(),
  followUpExternalPlantIdentifier: z.string().nullable().optional(),
  installationReportPath: z.string().nullable().optional(),
  installationSecurityReportPath: z.string().nullable().optional(),
})

export const newVisitNeededStepFormSchema = inProgressStepFormSchema.extend({
  installationNewVisitReason: z.string().optional(),
  installationNewVisitNotes: z.string().optional(),
  installationNewVisitDate: z.date({ invalid_type_error: "Champ obligatoire" }),
})

export const completedStepFormSchema = inProgressStepFormSchema.extend({
  installationReportPath: z.string().nullable().optional(),
  installationPhotosPaths: z.array(z.string()),
  installationCertificatePath: z.string({
    invalid_type_error: "Champ obligatoire",
  }),
})

export type InstallationStepInput =
  | z.infer<typeof pendingStepsFormSchema>
  | z.infer<typeof scheduledStepFormSchema>
  | z.infer<typeof inProgressStepFormSchema>
  | z.infer<typeof newVisitNeededStepFormSchema>
  | z.infer<typeof completedStepFormSchema>

export const installationSchema = transformSchemaToNullish(
  completedStepFormSchema.merge(newVisitNeededStepFormSchema),
)
