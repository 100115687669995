import _ from "lodash"

import { HousesResponses } from "@ensol/types/endpoints/houses"

export const getHousePhotos = (house: HousesResponses.House) => [
  ...house.roofPhotos,
  ...house.fromStreetPhotos,
  ...house.fromFrontPhotos,
  ...house.atticPhotos,
  ...house.otherPhotos,
  ...house.electricMeterPhotos,
]

export const getHousePhotoFileName = (
  houseStreetAddress: string,
  fileNameExtension: string,
) => {
  const houseSlug = _.kebabCase(houseStreetAddress)

  return `${houseSlug}-${fileNameExtension}`
}
