import { typedKeys } from "@ensol/types/utils"

import { getBrand } from "@ensol/shared/material/brands"
import {
  EV_CHARGERS,
  EvChargerType,
  getEvCharger,
} from "@ensol/shared/material/evCharger"
import { formatNumber } from "@ensol/shared/utils/format"

export const getEvChargerOptions = ({
  selectedValue,
}: {
  selectedValue: EvChargerType
}) =>
  typedKeys(EV_CHARGERS).map((evChargerType) => {
    const isSelected = evChargerType === selectedValue
    const evChargerInfo = getEvCharger(evChargerType)
    const brandInfo = getBrand(evChargerInfo.brand)

    return {
      value: evChargerType,
      brandInfo,
      ...evChargerInfo,
      isSelected,
      isCompatible: true,
      extraColumnsValues: [`${formatNumber(evChargerInfo.power)} kW`],
    }
  })
