import { Divider } from "@mantine/core"
import { IconShoppingCartDollar } from "@tabler/icons-react"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationFormInput } from "@ensol/types/forms/installations"
import { SimulationResults } from "@ensol/types/simulation"

import { computeAllCosts } from "@ensol/shared/entities/installations/costs"
import { computeTotalDiscount } from "@ensol/shared/entities/installations/savings"
import { getSubsidy } from "@ensol/shared/entities/installations/subsidies"
import { formatCurrency } from "@ensol/shared/utils/format"

import { INSTALLATION_FIELDS_LABELS } from "@ensol/entool/utils/form/fieldLabels/installations"

import { SummaryItem, SummarySection } from "./shared"

type Props = {
  installationValues: InstallationFormInput
  house: HousesResponses.House<{ include: { roofSections: true } }>
  simulationResults?: SimulationResults
}

export const CostsSummary = ({
  installationValues,
  house,
  simulationResults,
}: Props) => {
  const totalDiscount = computeTotalDiscount(installationValues.discount)

  const {
    installationCost,
    finalCostWithSubsidies,
    subsidies,
    installationCostsByProduct,
  } = computeAllCosts({
    ...installationValues,
    totalDiscount,
    house,
  })

  return (
    <SummarySection title="Prix" Icon={IconShoppingCartDollar}>
      {installationCostsByProduct.length > 1 &&
        installationCostsByProduct.map(({ productType, installationCost }) => (
          <SummaryItem
            key={productType}
            title={`Total ${INSTALLATION_FIELDS_LABELS[productType]}`}
            value={formatCurrency(installationCost)}
          />
        ))}
      <SummaryItem title="Total TTC" value={formatCurrency(installationCost)} />
      <Divider variant="dashed" my={4} />
      <SummaryItem
        title="Réduction TTC"
        value={formatCurrency(-totalDiscount)}
        warning={simulationResults?.warnings.totalDiscount}
      />
      {subsidies.map(({ subsidyType, subsidyAmount }) => (
        <SummaryItem
          key={subsidyType}
          title={getSubsidy(subsidyType).shortName}
          value={formatCurrency(-subsidyAmount)}
        />
      ))}
      <Divider variant="dashed" my={4} />
      <SummaryItem
        title="Prix net"
        value={formatCurrency(finalCostWithSubsidies)}
      />
      {simulationResults !== undefined && (
        <SummaryItem
          title="Prix net / kWh PV"
          value={formatCurrency(simulationResults.panelsEnergyProductionCost)}
        />
      )}
    </SummarySection>
  )
}
