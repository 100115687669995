import { Stack, Textarea, SimpleGrid, Input } from "@mantine/core"

import { GreendealStepInput } from "@ensol/types/forms/projects/greendeal"

import { Section } from "@ensol/shared/components/Section"
import { GreendealStep } from "@ensol/shared/entities/projects/processes"
import { getClientFileName } from "@ensol/shared/utils/files"
import { DISPLAY_DATE_FORMAT } from "@ensol/shared/utils/format"

import {
  StepActions,
  StepActionsProps,
} from "@ensol/entool/components/entities/Project/StepActions"
import { DateInput } from "@ensol/entool/components/form/DateInput"
import { FileField } from "@ensol/entool/components/form/File/FileField"
import { FormField } from "@ensol/entool/components/form/FormField"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import {
  ProcessFormProps,
  useProcessForm,
} from "@ensol/entool/utils/projects/useProcessForm"

export const GreendealForm = <Input extends GreendealStepInput>({
  schema,
  initialValues,
  project,
  processId,
  actions,
}: ProcessFormProps<Input> & StepActionsProps) => {
  const form = useProcessForm<Input>({
    schema,
    initialValues,
    projectId: project.id,
    processId,
  })

  const { client } = project.installation.house
  const currentStep = project[processId]

  return (
    <StepActions
      project={project}
      processId={processId}
      actions={actions}
      validateStep={() => schema.parse(form.values)}
    >
      <Section>
        <SimpleGrid cols={2} spacing="32" mt="16">
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="greendealNotes"
            >
              <Textarea
                autosize
                w={FIELD_WIDTH}
                minRows={3}
                {...form.getInputProps("greendealNotes")}
              />
            </FormField>
          </Stack>
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="greendealReference"
              isRequired={currentStep === GreendealStep.SUBMITTED}
            >
              <Input
                w={FIELD_WIDTH}
                {...form.getInputProps("greendealReference")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="greendealSubmissionDate"
              isRequired={currentStep === GreendealStep.SUBMITTED}
            >
              <DateInput
                w={FIELD_WIDTH}
                clearable
                valueFormat={DISPLAY_DATE_FORMAT}
                {...form.getInputProps("greendealSubmissionDate")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="greendealAcknowledgmentPath"
              isRequired={currentStep === GreendealStep.SUBMITTED}
            >
              <FileField
                previewProps={{
                  fileName: getClientFileName(
                    "accusé-reception-greendeal",
                    client,
                  ),
                }}
                inputProps={{
                  accept: ["application/pdf", "image/jpeg", "image/png"],
                }}
                {...form.getInputProps("greendealAcknowledgmentPath")}
              />
            </FormField>
          </Stack>
        </SimpleGrid>
      </Section>
    </StepActions>
  )
}
