import { Group, Stack } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import {
  IconLayoutSidebarRightCollapseFilled,
  IconTimelineEvent,
} from "@tabler/icons-react"

import { ProjectOperationsOverview } from "@ensol/types/entities/project/overview"

import { FundingSource } from "@ensol/shared/entities/projects/fundingSource"
import { devices } from "@ensol/shared/styles/theme"

import { IconButton } from "@ensol/entool/components/IconButton"
import { FundingSourceInfoIndicator } from "@ensol/entool/components/entities/Project/FundingSourceInfoIndicator"
import { DueDateReminder } from "@ensol/entool/pages/ProjectsOverview/Operations/Row/DueDateReminder"
import { ProjectKeyFigures } from "@ensol/entool/pages/ProjectsOverview/Operations/Row/ProjectKeyFigures"
import {
  OPS_PROJECT_TASK_CONFIG,
  STATUS_SECTION_CONFIG,
} from "@ensol/entool/pages/ProjectsOverview/Operations/config"
import { ProjectDrawerTab } from "@ensol/entool/pages/ProjectsOverview/Operations/useProjectDrawer"
import { ClientInfo } from "@ensol/entool/pages/ProjectsOverview/components/ClientInfo"
import { ProjectCard } from "@ensol/entool/pages/ProjectsOverview/components/ProjectCard"
import { ProjectIndicators } from "@ensol/entool/pages/ProjectsOverview/components/ProjectIndicators"
import { ProjectTaskDetails } from "@ensol/entool/pages/ProjectsOverview/components/ProjectTaskDetails"

type Props = {
  project: ProjectOperationsOverview
  isDrawerOpen: boolean
  openDrawer: (
    project: ProjectOperationsOverview,
    tab: ProjectDrawerTab,
  ) => void
}

export const ProjectRow = ({ project, isDrawerOpen, openDrawer }: Props) => {
  const { isActionNeeded, taskDetails, installation } = project
  const { house } = installation
  const { getReferenceDates } = STATUS_SECTION_CONFIG[project.status]
  const isMobile = useMediaQuery(devices.sm)

  return (
    <ProjectCard isActive={isDrawerOpen}>
      <Group align="flex-start" justify="space-between" flex="1" p="12">
        <Stack gap="4" flex={1} align="flex-start">
          <Group gap="4" align="center" flex={1}>
            <ProjectIndicators
              project={project}
              isActionNeeded={project.isActionNeeded}
            />
            {project.fundingSource &&
              project.fundingSource !== FundingSource.EQUITY && (
                <FundingSourceInfoIndicator
                  fundingSource={project.fundingSource}
                  fundingSourceStatus={project.fundingSourceStatus}
                />
              )}
            <ClientInfo
              to={`/projects/${project.id}${isMobile ? "/technical-overview" : ""}`}
              projectId={project.id}
              address={house.address}
              client={house.client}
            />
            {taskDetails?.dueDate && (
              <DueDateReminder
                project={project}
                dueDateConfig={taskDetails.dueDate}
                isActionNeeded={isActionNeeded}
              />
            )}
          </Group>
          {taskDetails !== null && (
            <ProjectTaskDetails
              taskDetails={OPS_PROJECT_TASK_CONFIG[taskDetails.task]}
              type={taskDetails.type}
              project={project}
            />
          )}
        </Stack>
        <ProjectKeyFigures
          project={project}
          getReferenceDates={getReferenceDates}
        />
      </Group>
      <Group gap="8" p="12">
        <IconButton
          Icon={<IconTimelineEvent size={18} />}
          onClick={() => openDrawer(project, ProjectDrawerTab.DETAILS)}
          ariaLabel="Ouvrir les details du projet"
        />
        <IconButton
          Icon={<IconLayoutSidebarRightCollapseFilled size={22} />}
          onClick={() => openDrawer(project, ProjectDrawerTab.NOTES)}
          ariaLabel="Ouvrir les notes du projet"
        />
      </Group>
    </ProjectCard>
  )
}
