import { Stack } from "@mantine/core"
import { ReactNode } from "react"

import { getZIndex } from "@ensol/shared/styles/zIndex"

type Props = {
  children: ReactNode
  top?: number
}

export const StickyHeader = ({ children, top = 0 }: Props) => {
  return (
    <Stack
      pos="sticky"
      top={top}
      bg="white"
      style={{ zIndex: getZIndex("stickyHeader") }}
      gap="12"
    >
      {children}
    </Stack>
  )
}
