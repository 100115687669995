import {
  MultiSelect,
  MultiSelectProps,
  Select,
  SelectProps,
} from "@mantine/core"

import { useInstallersOptions } from "@ensol/entool/queries/installers"

export const InstallerMultiSelect = (props: Omit<MultiSelectProps, "data">) => {
  const installersOptions = useInstallersOptions()

  return <MultiSelect {...props} data={installersOptions} />
}

export const InstallerSelect = (props: Omit<SelectProps, "data">) => {
  const installersOptions = useInstallersOptions()

  return <Select {...props} data={installersOptions} />
}
