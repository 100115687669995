import { computePanelsCapacity } from "@ensol/shared/entities/installations/energy"
import { roundDecimalNumber } from "@ensol/shared/utils/format"

import {
  PhotovoltaicSubsidyEligibilityInput,
  PhotovoltaicSubsidyAmountInput,
} from "."

export const isAutoConsumptionEligible = ({
  roofSections,
}: PhotovoltaicSubsidyEligibilityInput): boolean =>
  !roofSections.some((roofSection) => roofSection.hasGroundInstallation)

export const computeAutoConsumptionSubsidy = ({
  photovoltaicInstallation,
}: PhotovoltaicSubsidyAmountInput): number => {
  const installedPowerCapacity = computePanelsCapacity(photovoltaicInstallation)

  if (installedPowerCapacity <= 9)
    return roundDecimalNumber(installedPowerCapacity * 80)
  if (installedPowerCapacity <= 36)
    return roundDecimalNumber(installedPowerCapacity * 190)
  if (installedPowerCapacity <= 100)
    return roundDecimalNumber(installedPowerCapacity * 100)
  return 0
}
