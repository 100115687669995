import { z } from "zod"
import { transformSchemaToNullish } from "../utils"

export const toulouseMetropolisFormSchema = z.object({
  toulouseMetropolisPropertyCertificatePath: z.string({
    invalid_type_error: "Champ obligatoire",
  }),
})

export type ToulouseMetropolisFormInput = z.infer<
  typeof toulouseMetropolisFormSchema
>

export const toulouseMetropolisSchema = transformSchemaToNullish(
  toulouseMetropolisFormSchema,
)
