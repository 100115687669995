import { House, InverterType, Prisma, Client } from "@ensol/types/prisma-client"

export const DEFAULT_PROJECT_INCLUDES = {
  technicalManager: true,
  salesOwner: true,
  operationsOwner: true,
  adminOwner: true,
  technicalVisitOwner: true,
  installer: true,
  technicalVisitInstaller: true,
  supplier: true,
  kizeoProjectReports: {
    include: {
      user: true,
    },
  },
  installation: {
    include: {
      photovoltaicInstallation: {
        include: {
          roofSectionsWithPanels: {
            select: {
              id: true,
              panelsCount: true,
              roofSection: {
                select: { id: true },
              },
            },
          },
        },
      },
      batteryInstallation: true,
      evChargerInstallation: true,
      controlDeviceInstallations: true,
      activeQuote: true,
      referrer: true,
      house: {
        include: {
          client: true,
          roofSections: true,
          alerts: true,
        },
      },
    },
  },
} satisfies Prisma.ProjectInclude

type DefaultProjectIncludes = {
  include: typeof DEFAULT_PROJECT_INCLUDES
}

export type Project<
  Includes extends Prisma.ProjectDefaultArgs | null = DefaultProjectIncludes,
> = Prisma.ProjectGetPayload<Includes>

export type ProjectWithAlerts = {
  id: string
  inverterType: InverterType | null
  externalPlantIdentifier: string
  installer: Prisma.InstallerGetPayload<null> | null
  technicalVisitInstaller: Prisma.InstallerGetPayload<null> | null
  client: Pick<Client, "firstName" | "lastName">
  house: Pick<House, "postcode" | "city">
  alerts: Prisma.HouseAlertGetPayload<null>[]
}

export enum ProjectAlertCategory {
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
}

/**
 * This enum is used for document types generated from PandaDoc.
 */
export enum ProjectDocumentType {
  INSTALLATION_CERTIFICATE = "installation-certificate",
  EDF_OA_CERTIFICATE = "edf-oa-certificate",
  CETELEM_FINANCING_REQUEST = "cetelem-financing-request",
  CETELEM_REQUEST_FOR_ADVANCE_ON_FUNDING = "cetelem-request-for-advance-on-funding",
}

/**
 * This enum is used for report types generated directly from the Ensol backend.
 */
export enum ProjectReportType {
  HORIZON_PROFILE = "horizon-profile",
  TECHNICAL_VISIT = "technical-visit",
}

export const ProjectInstallerStatus = {
  TECHNICAL_VISIT_TO_COME: "TECHNICAL_VISIT_TO_COME",
  TECHNICAL_VISIT_SCHEDULED: "TECHNICAL_VISIT_SCHEDULED",
  TECHNICAL_VISIT_COMPLETED: "TECHNICAL_VISIT_COMPLETED",
  INSTALLATION_SCHEDULED: "INSTALLATION_SCHEDULED",
  INSTALLATION_IN_PROGRESS: "INSTALLATION_IN_PROGRESS",
  INSTALLATION_COMPLETED: "INSTALLATION_COMPLETED",
  ABORTED: "ABORTED",
} as const

export type ProjectInstallerStatus = keyof typeof ProjectInstallerStatus
