import { EquipmentType } from "@ensol/shared/entities/houses/equipments"
import { ControlDeviceBrandType } from "@ensol/shared/material/brands"

type ControlDeviceInfo = {
  name: string
  description: string
  brand?: ControlDeviceBrandType
  price: number
  installationCost: number
  controllableEquipments: EquipmentType[]
  maxControlledEquipments: number
  pictureUrl: string
  warranty: number
  techDocUrl?: string
}

export const ControlDeviceType = {
  SHELLY: "SHELLY",
  ENPHASE_ROUTER: "ENPHASE_ROUTER",
  ECOJOKO: "ECOJOKO",
  MODULAR_CLOCK: "MODULAR_CLOCK",
} as const

export type ControlDeviceType = keyof typeof ControlDeviceType

export const CONTROL_DEVICES: Record<ControlDeviceType, ControlDeviceInfo> = {
  SHELLY: {
    name: "Shelly Pro 2",
    description:
      "Ce Shelly permet de piloter en temps réel ou de planifier le fonctionnement de ses équipements selon un horaire. Il peut piloter 2 équipements (ex: deux chauffe-eau).",
    brand: ControlDeviceBrandType.SHELLY,
    price: 80,
    installationCost: 120,
    controllableEquipments: [EquipmentType.WATER_TANK],
    maxControlledEquipments: 2,
    pictureUrl: "/shellyDevice.png",
    warranty: 2,
    techDocUrl:
      "https://bdc.shelly.cloud/base-de-connaissances/shelly-pro-2pm-v-1",
  },
  ECOJOKO: {
    name: "Assistant Ecojoko",
    description:
      "L'Ecojoko est un boîtier intelligent qui permet surveiller sa consommation électrique et comprendre quelle consommation est attribuée à quel appareil.",
    brand: ControlDeviceBrandType.ECOJOKO,
    price: 200,
    installationCost: 0,
    controllableEquipments: [EquipmentType.LINKY],
    maxControlledEquipments: 1,
    pictureUrl: "/ecojokoDevice.png",
    warranty: 2,
    techDocUrl:
      "https://www.ecojoko.com/assistant-ecojoko-caracteristiques-techniques?srsltid=AfmBOootjqIvlFSPoCVMUI-SCZh6_daWVEie-dd4Q6a4qVcYJzRlBtcm",
  },
  ENPHASE_ROUTER: {
    name: "IQ Energy Router+ (beta)",
    description:
      "L'IQ Energy Router est un système de gestion énergétique (en beta) qui pilote automatiquement les appareils de la maison afin de maximiser l'autoconsommation.",
    brand: ControlDeviceBrandType.ENPHASE,
    price: 560,
    installationCost: 120,
    controllableEquipments: [
      EquipmentType.AIR_AIR_PUMP,
      EquipmentType.ELECTRIC_BOILER,
      EquipmentType.AIR_WATER_PUMP,
      EquipmentType.RADIATOR,
      EquipmentType.AIR_CONDITIONING,
      EquipmentType.BOILER,
      EquipmentType.WATER_TANK,
      EquipmentType.WATER_HEATER,
      EquipmentType.POOL,
      EquipmentType.PUMP,
      EquipmentType.CHARGING_POINT,
      EquipmentType.ELECTRIC_CAR,
    ],
    maxControlledEquipments: 5,
    pictureUrl: "/enphaseIQRouter.png",
    warranty: 2,
    techDocUrl:
      "https://enphase.com/fr-be/download/iq-energy-router-fiche-technique",
  },
  MODULAR_CLOCK: {
    name: "Horloge Modulaire",
    description:
      "L'horloge modulaire permet de planifier le fonctionnement de son équipement connecté selon un horaire. Elle peut piloter un seul équipement (ex: un chauffe-eau). Les horaires sont réglés manuellement directement sur l'horloge.",
    price: 80,
    installationCost: 120,
    controllableEquipments: [
      EquipmentType.AIR_AIR_PUMP,
      EquipmentType.ELECTRIC_BOILER,
      EquipmentType.AIR_WATER_PUMP,
      EquipmentType.RADIATOR,
      EquipmentType.AIR_CONDITIONING,
      EquipmentType.BOILER,
      EquipmentType.WATER_TANK,
      EquipmentType.WATER_HEATER,
      EquipmentType.POOL,
      EquipmentType.PUMP,
    ],
    maxControlledEquipments: 1,
    pictureUrl: "/modularClock.png",
    warranty: 2,
  },
}

export const getControlDevice = (type: ControlDeviceType): ControlDeviceInfo =>
  CONTROL_DEVICES[type]
