import { IconTrash } from "@tabler/icons-react"

import { completedStepFormSchema } from "@ensol/types/forms/projects/installation"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { pickDefinedProperties } from "@ensol/entool/utils/helpers/pick"
import { getInitialValues } from "@ensol/entool/utils/initialValues"

import { InstallationForm } from "./components/InstallationForm"
import { INSTALLATION_PROPERTIES } from "./config"

export const CertificateSent = ({ project, ...props }: StepComponentProps) => (
  <InstallationForm
    schema={completedStepFormSchema}
    initialValues={{
      ...pickDefinedProperties(INSTALLATION_PROPERTIES, project),
      ...getInitialValues(completedStepFormSchema, project),
    }}
    project={project}
    actions={[
      {
        action: "resetCertificate",
        label: "Remplacer le bon de fin de chantier",
        Icon: IconTrash,
        color: "red",
        confirmationMessage:
          "Cette action va supprimer le bon de fin de chantier actuel et nécessiter d'en signer un nouveau, êtes-vous sûr de vouloir continuer ?",
      },
      {
        action: "validate",
        label: "Bon de fin de chantier signé",
        confirmationMessage:
          "L'étape sera validée automatiquement à la signature du client, voulez-vous la valider manuellement ?",
      },
    ]}
    {...props}
  />
)
