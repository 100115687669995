import {
  FundingSource,
  FundingSourceStatus,
} from "@ensol/shared/entities/projects/fundingSource"
import { transformSchemaToNullish } from "@ensol/types/forms/utils"
import * as z from "zod"
import {
  cetelemFieldsSchema,
  conditionalCetelemCoBorrowerValidation,
} from "./cetelem"

export const equityPaymentStepsFormSchema = z.object({
  paymentNotes: z.string().optional(),
  paymentHoldEndDate: z.date().nullable().optional(),
  fundingSource: z.nativeEnum(FundingSource, {
    invalid_type_error: "Champ obligatoire",
  }),
  paymentHoldReason: z.string().nullable().optional(),
  fundingSourceStatus: z.nativeEnum(FundingSourceStatus).nullable().optional(),
})

export type EquityPaymentStepInput = z.infer<
  typeof equityPaymentStepsFormSchema
>

export const cetelemFormSchema = z
  .object({
    arePennylaneInvoicesGenerated: z.boolean().optional(),
    cetelemFinancingRequestPath: z.string({
      invalid_type_error: "Champ obligatoire",
    }),
    cetelemRequestForAdvanceOnFundingPath: z.string({
      invalid_type_error: "Champ obligatoire",
    }),
  })
  .merge(cetelemFieldsSchema)
  .superRefine((data, ctx) => {
    if (data.cetelemHasCoBorrower) {
      conditionalCetelemCoBorrowerValidation(data, ctx)
    }
  })

export const paymentFormSchema = transformSchemaToNullish(
  equityPaymentStepsFormSchema.merge(
    cetelemFormSchema
      .innerType()
      .extend({ cetelemReference: z.string().nullable().optional() }),
  ),
)

export type CetelemFormInput = z.infer<typeof cetelemFormSchema>
