import { ScrollArea, Stack, StackProps, Text } from "@mantine/core"

import { NoteForm } from "@ensol/entool/components/entities/Project/ProjectNotes/NoteForm"
import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"

import { Note } from "./Note"
import { useProjectNotes } from "./useProjectNotes"

type Props = {
  projectId: string
  inputPlaceholder?: string
} & StackProps

export const ProjectNotes = ({
  projectId,
  inputPlaceholder,
  ...props
}: Props) => {
  const { setViewport, notesQuery, groupNotesByRelativeTime } =
    useProjectNotes(projectId)

  return (
    <Stack mih={0} w="100%" {...props}>
      <QueryWrapper query={notesQuery}>
        {({ data: notes }) => {
          const groupedNotes = groupNotesByRelativeTime(notes)
          return (
            <ScrollArea viewportRef={(ref) => setViewport(ref)} pr={16}>
              {Object.entries(groupedNotes).map(([group, notes]) => (
                <div key={group}>
                  <Text size="xs" c="gray.7" ta="center" pt="12px">
                    {group}
                  </Text>
                  {notes.map((note) => (
                    <Note key={note.id} note={note} />
                  ))}
                </div>
              ))}
            </ScrollArea>
          )
        }}
      </QueryWrapper>
      <NoteForm projectId={projectId} inputPlaceholder={inputPlaceholder} />
    </Stack>
  )
}
