import { Box, Button, Divider, Group, Stack, Text } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { ReactNode, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { OnSiteInstallation } from "@ensol/types/entities/installation"
import { RoofSectionWithPanels } from "@ensol/types/forms/installations/photovoltaic"

import { Section } from "@ensol/shared/components/Section"
import { getCoords } from "@ensol/shared/entities/houses/coords"
import { getRecommendedPanelsCount } from "@ensol/shared/entities/installations/energy"
import { PanelType } from "@ensol/shared/material/photovoltaic/panels"

import { Map } from "@ensol/entool/components/Map"
import { StatCard } from "@ensol/entool/components/StatCard"
import { InfoBadge } from "@ensol/entool/pages/Installation/components/InfoBadge"
import { getSubformActions } from "@ensol/entool/utils/form/subforms"

import { RoofSectionsPanelsForm } from "./RoofSectionsPanelsForm"

type PanelsSectionForm = {
  panelsCount: number
  panelType: PanelType
  roofSectionsWithPanels: RoofSectionWithPanels[]
}

type Props<Values> = {
  title: string
  installationForm: UseFormReturnType<Values>
  house: HousesResponses.House<{
    include: { roofSections: true }
  }>
  totalPanelsCount: number
  yearlyConsumption?: number
  panelsLayout?: InstallationsResponses.Installation["panelsLayout"]
  onSiteInstallation?: OnSiteInstallation
  DeleteButton?: ReactNode
  isUpsell: boolean
}

export const PanelsSection = <Values extends PanelsSectionForm>({
  title,
  installationForm,
  house,
  totalPanelsCount,
  yearlyConsumption,
  panelsLayout,
  onSiteInstallation,
  DeleteButton,
  isUpsell,
}: Props<Values>) => {
  const { installationId } = useParams()
  const { panelsCount, panelType } = installationForm.values
  // Suggestions for panels count
  const [panelsCountSuggestion, setPanelsCountSuggestion] = useState<number>()
  useEffect(() => {
    if (yearlyConsumption) {
      const recommendedPanelsCount = getRecommendedPanelsCount({
        yearlyConsumption,
        panelType,
        shouldBeCapped: false,
      })

      setPanelsCountSuggestion(recommendedPanelsCount)
    }
  }, [panelType, yearlyConsumption])

  return (
    <Section title={title} Actions={DeleteButton}>
      <Stack gap={32}>
        <Stack gap={16}>
          <Text fw={600} fz="lg">
            Calepinage
          </Text>
          <Group gap={16} align="flex-start">
            <Stack w="calc(100% - 196px)" pos="relative">
              <Box pos="absolute" top={12} right={12} style={{ zIndex: 1000 }}>
                <Button
                  component={Link}
                  to={`/installations/${installationId}/panels-layout`}
                >
                  Éditer le calepinage
                </Button>
              </Box>
              <Map
                mapId="panels-section-map"
                coords={getCoords(house)}
                panelsLayout={panelsLayout}
                style={{ height: 250 }}
              />
            </Stack>
            <Stack gap={12} w={180}>
              <StatCard
                label="Nombre de panneaux installés"
                stat={
                  isUpsell
                    ? `${totalPanelsCount} (+${panelsCount})`
                    : panelsCount
                }
              />
            </Stack>
          </Group>
        </Stack>
        <Divider />
        <Stack>
          <Group justify="space-between">
            <Text fw={600} fz="lg" mr={8}>
              Panneaux
            </Text>
            {panelsCountSuggestion && (
              <InfoBadge
                color={
                  totalPanelsCount > panelsCountSuggestion ? "red" : "gray"
                }
              >
                Nombre de panneaux recommandé: {panelsCountSuggestion}
              </InfoBadge>
            )}
          </Group>
          <RoofSectionsPanelsForm
            house={house}
            roofSectionsWithPanels={
              installationForm.values.roofSectionsWithPanels
            }
            onSiteInstallation={onSiteInstallation}
            {...getSubformActions(installationForm, "roofSectionsWithPanels")}
          />
        </Stack>
      </Stack>
    </Section>
  )
}
