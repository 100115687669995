import {
  CopyButton,
  Button,
  MantineStyleProps,
  Text,
  ThemeIcon,
} from "@mantine/core"
import {
  IconClipboardCheck,
  IconClipboardText,
  TablerIcon,
} from "@tabler/icons-react"

type Props = {
  text: string
  Icon?: TablerIcon
} & MantineStyleProps

export const CopiableText = ({ text, Icon, ...props }: Props) => (
  <CopyButton value={text}>
    {({ copied, copy }) => (
      <Button
        onClick={copy}
        mih="fit-content"
        leftSection={
          <ThemeIcon size={20}>
            {copied ? (
              <IconClipboardCheck />
            ) : Icon ? (
              <Icon />
            ) : (
              <IconClipboardText />
            )}
          </ThemeIcon>
        }
        styles={({ colors }) => ({
          root: {
            color: "black",
            borderRadius: 4,
            backgroundColor: "white",
            border: `1px solid ${colors.gray[4]}`,
          },
          inner: { justifyContent: "flex-start" },
          label: {
            whiteSpace: "break-spaces",
            textAlign: "left",
            lineHeight: "20px",
          },
        })}
        {...props}
      >
        <Text size="md">{text}</Text>
      </Button>
    )}
  </CopyButton>
)
