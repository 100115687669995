import { Group, MantineStyleProps, TextInput } from "@mantine/core"
import { IconSearch } from "@tabler/icons-react"
import _ from "lodash"
import { ReactNode } from "react"

import { Team } from "@ensol/shared/entities/users"

import { InstallerMultiSelect } from "@ensol/entool/components/form/InstallerSelect"
import { SupplierMultiSelect } from "@ensol/entool/components/form/SupplierSelect"
import { UserMultiSelect } from "@ensol/entool/components/form/UserSelect"
import { ONE_LINE_MULTI_SELECT_TARGET_STYLE } from "@ensol/entool/styles/theme"
import {
  PROJECT_SEARCHABLE_PROPS,
  useProjectsSearch,
} from "@ensol/entool/utils/projects/search"

type Props = {
  filteredProps: (keyof typeof PROJECT_SEARCHABLE_PROPS)[]
  children?: ReactNode
} & MantineStyleProps

export const ProjectSearch = ({ filteredProps, children, ...props }: Props) => {
  const {
    search,
    setSearch,
    installerIds,
    setInstallerIds,
    supplierIds,
    setSupplierIds,
    salesOwnerIds,
    setSalesOwnerIds,
    operationsOwnerIds,
    setOperationsOwnerIds,
    adminOwnerIds,
    setAdminOwnerIds,
  } = useProjectsSearch()

  return (
    <Group
      justify="space-between"
      align="flex-end"
      mb={{ base: 12, md: 24 }}
      style={{ flexWrap: "nowrap" }}
      {...props}
    >
      <TextInput
        flex={1}
        leftSectionPointerEvents="none"
        leftSection={<IconSearch />}
        placeholder="Rechercher un projet par nom, email, téléphone, ou adresse"
        onChange={_.debounce((e) => setSearch(e.target.value), 250)}
        defaultValue={search}
      />
      {filteredProps.includes(PROJECT_SEARCHABLE_PROPS.salesOwnerIds) && (
        <UserMultiSelect
          flex={1}
          maw={200}
          display={{ base: "none", md: "block" }}
          label="Responsable AE"
          value={salesOwnerIds}
          teams={[Team.ACCOUNT_EXECUTIVE, Team.INSIDE_SALES]}
          onChange={setSalesOwnerIds}
          style={{ flexShrink: 0 }}
          comboboxProps={{ width: 300, position: "bottom-start" }}
          clearable
        />
      )}
      {filteredProps.includes(PROJECT_SEARCHABLE_PROPS.operationsOwnerIds) && (
        <UserMultiSelect
          flex={1}
          maw={200}
          display={{ base: "none", md: "block" }}
          label="Responsable Ops"
          value={operationsOwnerIds}
          teams={[Team.CUSTOMER_SUCCESS]}
          onChange={setOperationsOwnerIds}
          style={{ flexShrink: 0 }}
          comboboxProps={{ width: 300, position: "bottom-start" }}
          clearable
        />
      )}
      {filteredProps.includes(PROJECT_SEARCHABLE_PROPS.adminOwnerIds) && (
        <UserMultiSelect
          flex={1}
          maw={200}
          miw={125}
          display={{ base: "none", md: "block" }}
          label="Responsable Admin"
          value={adminOwnerIds}
          teams={[Team.ADMIN]}
          onChange={setAdminOwnerIds}
          style={{ flexShrink: 0 }}
          comboboxProps={{ width: 300, position: "bottom-start" }}
          multiple
          clearable
        />
      )}
      {filteredProps.includes(PROJECT_SEARCHABLE_PROPS.installerIds) && (
        <InstallerMultiSelect
          w={150}
          display={{ base: "none", md: "block" }}
          label="Installateur"
          value={installerIds}
          onChange={setInstallerIds}
          styles={{
            root: { flexShrink: 0 },
            ...ONE_LINE_MULTI_SELECT_TARGET_STYLE,
          }}
          clearable
        />
      )}
      {filteredProps.includes(PROJECT_SEARCHABLE_PROPS.supplierIds) && (
        <SupplierMultiSelect
          flex={1}
          maw={200}
          display={{ base: "none", md: "block" }}
          label="Fournisseur"
          value={supplierIds}
          onChange={setSupplierIds}
          styles={{
            root: { flexShrink: 0 },
            ...ONE_LINE_MULTI_SELECT_TARGET_STYLE,
          }}
          clearable
        />
      )}
      {children}
    </Group>
  )
}
