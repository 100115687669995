import dayjs from "dayjs"

import { MonthlyConsumptions } from "@ensol/types/entities/installation"
import { UnpackArray } from "@ensol/types/utils"

import { colors } from "@ensol/shared/styles/colors"

import { Chart } from "@ensol/entool/components/Chart"

type MonthlyConsumption = UnpackArray<MonthlyConsumptions>
type Props = {
  monthlyConsumptions: (MonthlyConsumption & { color: string })[]
  onMonthClick?: (monthlyConsumption: MonthlyConsumption) => void
  isLoading?: boolean
}

export const MonthlyProductionGraph = ({
  monthlyConsumptions,
  onMonthClick,
  isLoading = false,
}: Props) => (
  <Chart
    isLoading={isLoading}
    aspectRatio={7 / 3}
    onDataClick={(index) => {
      const clickedMonthlyConsumption = monthlyConsumptions[index]
      clickedMonthlyConsumption && onMonthClick?.(clickedMonthlyConsumption)
    }}
    data={{
      type: "bar",
      data: {
        labels: monthlyConsumptions.map(({ month }) =>
          dayjs(month).format("MMM"),
        ),
        datasets: [
          {
            data: monthlyConsumptions.map(({ value }) => value),
            maxBarThickness: 35,
            backgroundColor: monthlyConsumptions.map(({ color }) => color),
            hoverBackgroundColor: colors.blue[500],
          },
        ],
      },
      options: {
        animation: false,
        plugins: { legend: { display: false }, tooltip: { enabled: false } },
        scales: {
          x: {
            border: { display: false },
            grid: { display: false },
            ticks: {
              font: { weight: "bold", size: 12 },
              color: colors.gray[500],
            },
          },
          y: {
            ticks: {
              stepSize: 100,
              font: { weight: "bold", size: 12 },
              color: colors.gray[500],
            },
            border: { display: false, dash: [5, 5] },
            grid: { color: colors.gray[200], lineWidth: 2 },
            beginAtZero: true,
          },
        },
      },
    }}
  />
)
