import { Stack, Button, Textarea, SimpleGrid, Group } from "@mantine/core"
import { IconCurrencyEuro } from "@tabler/icons-react"

import { Project } from "@ensol/types/entities/project"
import {
  EquityPaymentStepInput,
  equityPaymentStepsFormSchema,
} from "@ensol/types/forms/projects/payment"

import { Section } from "@ensol/shared/components/Section"
import { FundingSource } from "@ensol/shared/entities/projects/fundingSource"
import { getClientFileName } from "@ensol/shared/utils/files"
import { DISPLAY_DATE_FORMAT, formatCurrency } from "@ensol/shared/utils/format"

import { NotFound } from "@ensol/entool/components/NotFound"
import { MultiFilePreview } from "@ensol/entool/components/entities/File/MultiFilePreview"
import { TextInfo } from "@ensol/entool/components/entities/Project/TextInfo"
import { DateInput } from "@ensol/entool/components/form/DateInput"
import { Field } from "@ensol/entool/components/form/Field"
import { FundingSourceField } from "@ensol/entool/components/form/Project/FundingSourceField"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { DeleteEstimateButton } from "@ensol/entool/pages/Project/processes/Payment/components/DeleteEstimateButton"
import { PennylaneLink } from "@ensol/entool/pages/Project/processes/Payment/components/PennylaneLink"
import { useCreateEstimateMutation } from "@ensol/entool/queries/projects"
import { getPennylaneEstimateLink } from "@ensol/entool/utils/pennylane"
import { useProcessForm } from "@ensol/entool/utils/projects/useProcessForm"

type Props = {
  project: Project
}

export const GenerateEstimateForm = ({ project }: Props) => {
  const { mutateAsync: createEstimate, isPending: isEstimatePending } =
    useCreateEstimateMutation(project.id)

  const form = useProcessForm<EquityPaymentStepInput>({
    schema: equityPaymentStepsFormSchema,
    initialValues: {
      paymentNotes: project.paymentNotes,
      paymentHoldEndDate: project.paymentHoldEndDate,
      fundingSource: project.fundingSource,
    },
    projectId: project.id,
    processId: "paymentStep",
  })

  const {
    activeQuote,
    house: { client },
  } = project.installation

  const quoteAmount = activeQuote?.details?.totals.finalCost
  const cetelemAmountDifference =
    project.fundingSource === FundingSource.CETELEM &&
    quoteAmount &&
    project.cetelemFinancingRequestAmount
      ? quoteAmount - project.cetelemFinancingRequestAmount
      : 0

  const hasCetelemAmountDifference = cetelemAmountDifference > 1

  return (
    <Section>
      <SimpleGrid cols={2} spacing={32}>
        <Stack gap="24">
          <FundingSourceField
            fundingSourceStatus={project.fundingSourceStatus}
            form={form}
          />
          <Field name="Note sur le statut du financement">
            <TextInfo value={project.fundingSourceStatusNotes} />
          </Field>
          <Field name="Notes">
            <Textarea
              autosize
              w={FIELD_WIDTH}
              minRows={3}
              {...form.getInputProps("paymentNotes")}
            />
          </Field>
          <Field
            name="Ne pas relancer avant le"
            subtitle="À remplir si la facture ne peut pas encore être envoyée."
          >
            <DateInput
              w={FIELD_WIDTH}
              clearable
              valueFormat={DISPLAY_DATE_FORMAT}
              minDate={new Date()}
              {...form.getInputProps("paymentHoldEndDate")}
            />
          </Field>
        </Stack>
        <Stack gap="24">
          <Field name="Devis">
            {project.pennylaneEstimateId === null ? (
              <Button
                loading={isEstimatePending}
                w="fit-content"
                onClick={() => createEstimate()}
                leftSection={<IconCurrencyEuro />}
                color="rgb(0, 102, 102)"
              >
                Générer le devis sur Pennylane
              </Button>
            ) : (
              <Group>
                <PennylaneLink
                  label="Voir sur Pennylane"
                  href={getPennylaneEstimateLink({
                    firstName: client.firstName,
                    lastName: client.lastName,
                  })}
                />
                <DeleteEstimateButton projectId={project.id} />
              </Group>
            )}
          </Field>
          <Field
            name="Montant total TTC devis"
            subtitle="Vérifiez que ce montant correspond à celui dans PennyLane."
            helpText={
              hasCetelemAmountDifference
                ? "⚠ Attention, le montant total ne correspond pas à celui de la demande Cetelem, vous devez facturer le reste au client."
                : undefined
            }
          >
            {quoteAmount ? (
              <TextInfo
                value={
                  hasCetelemAmountDifference
                    ? `${formatCurrency(quoteAmount)} (Cetelem : ${formatCurrency(quoteAmount - cetelemAmountDifference)} - Reste à payer client : ${formatCurrency(cetelemAmountDifference)})`
                    : formatCurrency(quoteAmount)
                }
              />
            ) : (
              <NotFound type="text" />
            )}
          </Field>
          <Field name="Devis">
            {activeQuote !== null && activeQuote.quotePath !== null ? (
              <MultiFilePreview
                files={[{ path: activeQuote.quotePath }]}
                fileName={getClientFileName("devis", client)}
              />
            ) : (
              <NotFound type="file" />
            )}
          </Field>
        </Stack>
      </SimpleGrid>
    </Section>
  )
}
