import {
  IconHome,
  IconBriefcase,
  IconHomeCog,
  IconAlertCircle,
  IconCalendarMonth,
  IconUsers,
  IconHomeMove,
} from "@tabler/icons-react"

import { Role, RoleGroups } from "@ensol/shared/entities/users"

import { AuthorizedMenuItem } from "@ensol/entool/components/layout/Menu/Provider"
import { PROJECTS_PROCESS_VIEWS } from "@ensol/entool/utils/projects/views"

export const HOME_MENU_ITEM: AuthorizedMenuItem = {
  id: "/$",
  to: "/",
  name: "Accueil",
  Icon: IconHome,
  authorizedGroups: [{ group: RoleGroups.ENSOL, minimumRole: Role.EMPLOYEE }],
}

export const GLOBAL_MENU_ITEMS: AuthorizedMenuItem[] = [
  HOME_MENU_ITEM,
  {
    id: "(projects$|projects\\?)",
    to: "/projects",
    name: "Projets",
    Icon: IconBriefcase,
    authorizedGroups: [
      { group: RoleGroups.PARTNER, minimumRole: Role.INSTALLER },
      { group: RoleGroups.ENSOL, minimumRole: Role.EMPLOYEE },
    ],
  },
  {
    id: "projects/",
    name: "Opérations",
    Icon: IconHomeCog,
    authorizedGroups: [{ group: RoleGroups.ENSOL, minimumRole: Role.EMPLOYEE }],
    subItems: [
      ...PROJECTS_PROCESS_VIEWS.map(({ id, name, color, Icon }) => ({
        id,
        to: `/projects/processes/${id}`,
        name,
        color,
        Icon,
      })),
      {
        id: "alerts",
        to: "/projects/alerts",
        name: "Alertes",
        color: "red",
        Icon: IconAlertCircle,
      },
    ],
  },
  {
    id: "events",
    to: "/events",
    name: "Calendrier",
    Icon: IconCalendarMonth,
    authorizedGroups: [{ group: RoleGroups.ENSOL, minimumRole: Role.EMPLOYEE }],
  },
  {
    id: "clients",
    to: "/clients",
    name: "Clients",
    Icon: IconUsers,
    authorizedGroups: [{ group: RoleGroups.ENSOL, minimumRole: Role.EMPLOYEE }],
  },
  {
    id: "sales",
    to: "/sales",
    name: "Tâches AE",
    Icon: IconHomeMove,
    authorizedGroups: [{ group: RoleGroups.ENSOL, minimumRole: Role.EMPLOYEE }],
  },
]
