export const EquipmentType = {
  LINKY: "LINKY",
  ELECTRIC_CAR: "ELECTRIC_CAR",
  BATTERY: "BATTERY",
  PANELS: "PANELS",
  AIR_AIR_PUMP: "AIR_AIR_PUMP",
  AIR_WATER_PUMP: "AIR_WATER_PUMP",
  WASHING_MACHINE: "WASHING_MACHINE",
  CHARGING_POINT: "CHARGING_POINT",
  WATER_TANK: "WATER_TANK",
  RADIATOR: "RADIATOR",
  AIR_CONDITIONING: "AIR_CONDITIONING",
  POOL: "POOL",
  PUMP: "PUMP",
  STOVE: "STOVE",
  ORGANIC_BOILER: "ORGANIC_BOILER",
  ELECTRIC_BOILER: "ELECTRIC_BOILER",
  BOILER: "BOILER",
  WATER_HEATER: "WATER_HEATER",
} as const

export type EquipmentType = keyof typeof EquipmentType

type EquipmentInfo = {
  label: string
  value: EquipmentType
  displayInSimulator?: boolean
}

type EquipmentCategory = {
  title: string
  equipments: EquipmentInfo[]
}

export const EQUIPMENT_CATEGORIES: EquipmentCategory[] = [
  {
    title: "Électricité",
    equipments: [
      {
        label: "Compteur Linky",
        value: EquipmentType.LINKY,
      },
    ],
  },
  {
    title: "Système de chauffage",
    equipments: [
      {
        label: "Chaudière à gaz / bois / granulés / fioul",
        value: EquipmentType.ORGANIC_BOILER,
      },
      {
        label: "Pompe à chaleur air-air",
        value: EquipmentType.AIR_AIR_PUMP,
        displayInSimulator: true,
      },
      {
        label: "Chaudière électrique",
        value: EquipmentType.ELECTRIC_BOILER,
      },
      {
        label: "Pompe à chaleur air-eau",
        value: EquipmentType.AIR_WATER_PUMP,
        displayInSimulator: true,
      },
      {
        label: "Radiateur électrique",
        value: EquipmentType.RADIATOR,
        displayInSimulator: true,
      },
      {
        label: "Cheminée / Poêle à Bois / Poêle à Granulés",
        value: EquipmentType.STOVE,
      },
    ],
  },
  {
    title: "Système de climatisation",
    equipments: [
      {
        label: "Air conditionné",
        value: EquipmentType.AIR_CONDITIONING,
        displayInSimulator: true,
      },
    ],
  },
  {
    title: "Eau chaude",
    equipments: [
      {
        label: "Chaudière",
        value: EquipmentType.BOILER,
      },
      {
        label: "Ballon électrique",
        value: EquipmentType.WATER_TANK,
        displayInSimulator: true,
      },
      {
        label: "Chauffe-eau thermodynamique",
        value: EquipmentType.WATER_HEATER,
      },
    ],
  },
  {
    title: "Photovoltaïque",
    equipments: [
      { label: "PV", value: EquipmentType.PANELS },
      { label: "Batterie", value: EquipmentType.BATTERY },
    ],
  },
  {
    title: "Mobilité",
    equipments: [
      {
        label: "Voiture électrique",
        value: EquipmentType.ELECTRIC_CAR,
        displayInSimulator: true,
      },
      {
        label: "Borne de recharge",
        value: EquipmentType.CHARGING_POINT,
      },
    ],
  },
  {
    title: "Autres équipements",
    equipments: [
      {
        label: "Piscine",
        value: EquipmentType.POOL,
        displayInSimulator: true,
      },
      {
        label: "Pompe de tirage",
        value: EquipmentType.PUMP,
      },
      { label: "Machine à laver", value: EquipmentType.WASHING_MACHINE },
    ],
  },
]

export const getEquipment = (equipmentType: EquipmentType) =>
  EQUIPMENT_CATEGORIES.flatMap(({ equipments }) => equipments).find(
    ({ value }) => value === equipmentType,
  )!
