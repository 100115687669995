import { SimpleGrid, Stack } from "@mantine/core"

import { ToulouseMetropolisFormInput } from "@ensol/types/forms/projects/toulouseMetropolis"

import { Link } from "@ensol/shared/components/Link"
import { Section } from "@ensol/shared/components/Section"
import { getClientFileName } from "@ensol/shared/utils/files"

import { NotFound } from "@ensol/entool/components/NotFound"
import { MultiFilePreview } from "@ensol/entool/components/entities/File/MultiFilePreview"
import {
  StepActions,
  StepActionsProps,
} from "@ensol/entool/components/entities/Project/StepActions"
import { Field } from "@ensol/entool/components/form/Field"
import { FileField } from "@ensol/entool/components/form/File/FileField"
import { FormField } from "@ensol/entool/components/form/FormField"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import {
  RGE_CERTIFICATE_URL,
  TOULOUSE_METROPOLIS_URL,
} from "@ensol/entool/config"
import { getProjectFieldLabel } from "@ensol/entool/utils/form/fieldLabels/projects"
import {
  ProcessFormProps,
  useProcessForm,
} from "@ensol/entool/utils/projects/useProcessForm"

export const ToulouseMetropolisForm = <
  Input extends ToulouseMetropolisFormInput,
>({
  schema,
  initialValues,
  project,
  ...props
}: ProcessFormProps<Input> & StepActionsProps) => {
  const form = useProcessForm<Input>({
    schema,
    initialValues,
    projectId: project.id,
    processId: props.processId,
  })
  const { house, activeQuote } = project.installation
  const { client } = house

  return (
    <StepActions
      project={project}
      validateStep={() => schema.parse(form.values)}
      {...props}
    >
      <Section>
        <Link
          label="Accéder au site de la métropole"
          to={TOULOUSE_METROPOLIS_URL}
          w="fit-content"
          isExternal
        />
        <SimpleGrid cols={2} spacing={{ base: 16, lg: 32 }} mt="16" mb="16">
          <Stack gap="24">
            <Field
              name={getProjectFieldLabel("preliminaryRequestApprovalPaths")}
            >
              {project.preliminaryRequestApprovalPaths.length > 0 ? (
                <MultiFilePreview
                  files={project.preliminaryRequestApprovalPaths.map(
                    (path) => ({
                      path,
                    }),
                  )}
                  fileName={getClientFileName("autorisation-DP", client)}
                />
              ) : (
                <NotFound type="file" />
              )}
            </Field>
            <Field
              name={getProjectFieldLabel("enedisRequestForConnectionPath")}
            >
              {project.enedisRequestForConnectionPath !== null ? (
                <MultiFilePreview
                  files={[{ path: project.enedisRequestForConnectionPath }]}
                  fileName={getClientFileName(
                    "demande-de-raccordement",
                    client,
                  )}
                />
              ) : (
                <NotFound type="file" />
              )}
            </Field>
            <FormField
              initialValues={initialValues}
              propertyName="toulouseMetropolisPropertyCertificatePath"
              isRequired
            >
              <FileField
                previewProps={{
                  fileName: getClientFileName("titre-de-propriete", client),
                }}
                inputProps={{ accept: ["application/pdf"] }}
                {...form.getInputProps(
                  "toulouseMetropolisPropertyCertificatePath",
                )}
              />
            </FormField>
          </Stack>
          <Stack gap="24">
            <Field name="Devis">
              {activeQuote !== null && activeQuote.quotePath !== null ? (
                <MultiFilePreview
                  files={[{ path: activeQuote.quotePath }]}
                  fileName={getClientFileName("devis", client)}
                />
              ) : (
                <NotFound type="file" />
              )}
            </Field>
            <Field name="Lien certificat RGE">
              <Link
                w={FIELD_WIDTH}
                label="Voir le certificat RGE"
                to={RGE_CERTIFICATE_URL}
                isExternal
              />
            </Field>
          </Stack>
        </SimpleGrid>
      </Section>
    </StepActions>
  )
}
