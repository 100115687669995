import { useEffect, useState } from "react"

import { EnergyResponses } from "@ensol/types/endpoints/energy"
import { HousesResponses } from "@ensol/types/endpoints/houses"
import { OnSiteInstallation } from "@ensol/types/entities/installation"
import { InstallationFormInput } from "@ensol/types/forms/installations"
import { SimulationResults } from "@ensol/types/simulation"

import { computeTotalDiscount } from "@ensol/shared/entities/installations/savings"
import { runSimulation } from "@ensol/shared/entities/installations/simulation"

type UseSimulationResultsProps = {
  installationDate: Date
  installationValues: InstallationFormInput
  energyStats?: EnergyResponses.Stats | null
  autoConsumptionPercent?: number
  house: HousesResponses.House<{ include: { roofSections: true } }>
  onSiteInstallation?: OnSiteInstallation
  isInstallationValid: boolean
}

export const useSimulationResults = ({
  installationDate,
  installationValues,
  energyStats,
  autoConsumptionPercent,
  house,
  onSiteInstallation,
  isInstallationValid,
}: UseSimulationResultsProps) => {
  const [simulationResults, setSimulationResults] =
    useState<SimulationResults>()

  useEffect(() => {
    if (
      isInstallationValid &&
      energyStats &&
      autoConsumptionPercent !== undefined
    ) {
      const result = runSimulation({
        electricityBuyPrice: installationValues.electricityBuyPrice,
        yearlyConsumption: energyStats.yearlyConsumption,
        autoConsumptionPercent,
        estimatedYearlyProduction: energyStats.yearlyProduction,
        photovoltaicInstallation: installationValues.photovoltaicInstallation,
        batteryInstallation: installationValues.batteryInstallation,
        evChargerInstallation: installationValues.evChargerInstallation,
        controlDeviceInstallations:
          installationValues.controlDeviceInstallations,
        productsCount: installationValues.productsCount,
        house,
        totalDiscount: computeTotalDiscount(installationValues.discount),
        onSiteInstallation,
        electricityPriceAnnualRaise:
          installationValues.electricityPriceAnnualRaise,
        isUpsell: installationValues.isUpsell,
      })
      setSimulationResults(result)
    }
  }, [
    energyStats,
    autoConsumptionPercent,
    house,
    installationDate,
    installationValues.discount,
    installationValues.electricityBuyPrice,
    installationValues.electricityPriceAnnualRaise,
    installationValues.photovoltaicInstallation,
    installationValues.evChargerInstallation,
    installationValues.batteryInstallation,
    installationValues.controlDeviceInstallations,
    installationValues.productsCount,
    installationValues.isUpsell,
    isInstallationValid,
    onSiteInstallation,
  ])

  return simulationResults
}
