import { Flex, Group, Stack, Text } from "@mantine/core"
import { IconInfoCircle, IconAlertCircle } from "@tabler/icons-react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

export type AdditionalInformationConfig = {
  condition: (
    house: ProjectResponses.ProjectTechnicalOverview["installation"]["house"],
  ) => boolean
  label: string
  getValue: (
    house: ProjectResponses.ProjectTechnicalOverview["installation"]["house"],
  ) => string
  getType: (
    house: ProjectResponses.ProjectTechnicalOverview["installation"]["house"],
  ) => "info" | "alert"
}

const TYPES_CONFIG = {
  info: {
    color: "gray",
    icon: <IconInfoCircle size={16} />,
  },

  alert: {
    color: "red",
    icon: <IconAlertCircle size={16} />,
  },
}

export const AdditionalInformation = ({
  config,
  house,
}: {
  config: AdditionalInformationConfig[]
  house: ProjectResponses.ProjectTechnicalOverview["installation"]["house"]
}) => {
  const badges = config.filter(({ condition }) => condition(house))

  if (badges.length === 0) {
    return null
  }

  return (
    <Group gap={8}>
      {badges.map(({ label, getValue, getType }) => {
        const type = getType(house)
        return (
          <Group
            align="flex-start"
            w={{ base: "100%", sm: "auto" }}
            style={{ borderRadius: 4 }}
            p={8}
            key={label}
            bg={`${TYPES_CONFIG[type].color}.0`}
            gap={4}
          >
            <Flex c={`${TYPES_CONFIG[type].color}.6`} mt={4}>
              {TYPES_CONFIG[type].icon}
            </Flex>
            <Stack gap={0}>
              <Text c={`${TYPES_CONFIG[type].color}.6`}>{label}</Text>
              <Text fw="bold">{getValue(house)}</Text>
            </Stack>
          </Group>
        )
      })}
    </Group>
  )
}
