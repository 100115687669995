import { Modal, ScrollArea, Title, Text, Stack, Group } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"

import { Section } from "@ensol/shared/components/Section"
import { ConsentAskStatus } from "@ensol/shared/entities/houses/switchgrid"

import { useGetHouseQuery } from "@ensol/entool/queries/houses"

import { ConsentAskButton } from "./ConsentAskButton"
import { ConsentAskStatusMessages } from "./ConsentAskStatus"
import { ConsentForm } from "./ConsentForm"
import { OrderButton } from "./OrderButton"
import { OrderStatusMessages } from "./OrderStatus"
import { useAutoRefreshWhilePending } from "./useAutoRefreshWhilePending"

type Props = {
  houseId: string
}

export const SwitchgridParameters = ({ houseId }: Props) => {
  const { data: house } = useGetHouseQuery(houseId)
  useAutoRefreshWhilePending(house)
  const [
    isConsentModalOpen,
    { open: openConsentModal, close: closeConsentModal },
  ] = useDisclosure(false)

  if (!house) {
    return null
  }

  const { switchgridConsent, switchgridOrder } = house

  return (
    <Section title="Paramètres">
      <Stack>
        <Group justify="space-between" wrap="nowrap">
          <Stack gap="4">
            <Text fw="bold" size="lg">
              Récupération des données Enedis
            </Text>
            <Text c="gray.5">
              Les données de consommation améliorent la simulation
            </Text>
          </Stack>
          <ConsentAskButton
            switchgridConsent={switchgridConsent}
            openConsentModal={openConsentModal}
          />
          {switchgridConsent !== null &&
            switchgridConsent.askStatus === ConsentAskStatus.ACCEPTED &&
            switchgridConsent.expirationDate >= new Date() && (
              <OrderButton
                switchgridOrder={switchgridOrder}
                houseId={house.id}
              />
            )}
        </Group>
        <ConsentAskStatusMessages switchgridConsent={switchgridConsent} />
        <OrderStatusMessages house={house} />
      </Stack>
      <Modal
        size={1000}
        radius="lg"
        padding="xl"
        opened={isConsentModalOpen}
        scrollAreaComponent={ScrollArea.Autosize}
        onClose={closeConsentModal}
        styles={{ body: { padding: 0 } }}
        title={
          <Stack gap="4">
            <Title order={2}>Demande de consentement</Title>
            <Text size="sm" c="gray.6">
              Vérifiez / complétez les informations et envoyez la demande de
              consentement pour obtenir un lien de signature à partager au
              client
            </Text>
          </Stack>
        }
      >
        <ConsentForm house={house} closeConsentModal={closeConsentModal} />
      </Modal>
    </Section>
  )
}
