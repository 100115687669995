import { SimpleGrid, Stack, Text } from "@mantine/core"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { NotFound } from "@ensol/entool/components/NotFound"
import { SubSection } from "@ensol/entool/components/SubSection"
import { MultiFilePreview } from "@ensol/entool/components/entities/File/MultiFilePreview"
import {
  PREVIEW_HEIGHT,
  PREVIEW_WIDTH,
} from "@ensol/entool/components/entities/File/constants"
import {
  AdditionalInformation,
  AdditionalInformationConfig,
} from "@ensol/entool/pages/ProjectTechnicalOverview/AdditionalInformation"

const FILE_PREVIEW_DIMENSIONS = {
  w: { ...PREVIEW_WIDTH, xl: 500 },
  h: { ...PREVIEW_HEIGHT, xl: 300 },
}

export const HouseSubSection = ({
  house,
  title,
  photos,
  additionalInformationConfig,
  notes,
}: {
  house: ProjectResponses.ProjectTechnicalOverview["installation"]["house"]
  title: string
  photos: string[]
  additionalInformationConfig: AdditionalInformationConfig[]
  notes: { label: string; content: string | null }[]
}) => {
  const filledNotes = notes.filter(
    ({ content }) => content !== null && content !== "",
  )
  const hasAdditionalInformation =
    additionalInformationConfig.filter(({ condition }) => condition(house))
      .length > 0

  return (
    <SubSection title={title}>
      <SimpleGrid cols={{ base: 1, md: 2 }}>
        <Stack>
          {photos.length > 0 ? (
            <MultiFilePreview
              files={photos.map((path) => ({ path }))}
              fileName={title}
              {...FILE_PREVIEW_DIMENSIONS}
            />
          ) : (
            <NotFound type="file" {...FILE_PREVIEW_DIMENSIONS} />
          )}
        </Stack>
        {(hasAdditionalInformation || filledNotes.length > 0) && (
          <Stack gap="24">
            {hasAdditionalInformation && (
              <Stack gap="8">
                <Text fw="500">Informations supplémentaires</Text>
                <AdditionalInformation
                  config={additionalInformationConfig}
                  house={house}
                />
              </Stack>
            )}
            {filledNotes.length > 0 && (
              <Stack gap="8">
                <Text fw="500">Notes</Text>
                {filledNotes.map(({ label, content }) => (
                  <Text key={content}>
                    {label}: {content}
                  </Text>
                ))}
              </Stack>
            )}
          </Stack>
        )}
      </SimpleGrid>
    </SubSection>
  )
}
