import { Stack } from "@mantine/core"
import { Helmet } from "react-helmet-async"
import { useParams } from "react-router-dom"

import { ContentContainer } from "@ensol/shared/components/layout/ContentContainer"

import { queryClient } from "@ensol/entool/backend/queryClient"
import { ClientHeader } from "@ensol/entool/components/entities/Client/ClientHeader"
import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { useGetProjectFeedbackQuery } from "@ensol/entool/queries/projects"

import { FeedbackForm } from "./FeedbackForm"

export const ProjectFeedbackPage = () => {
  const { projectId } = useParams()
  const feedbackQuery = useGetProjectFeedbackQuery(projectId!)

  return (
    <QueryWrapper query={feedbackQuery}>
      {({ data: { client, feedback, installationId } }) => (
        <Stack w="100%">
          <Helmet>
            <title>Feedback | Entool</title>
          </Helmet>
          <ClientHeader
            client={client}
            onClientEditSuccess={() => {
              queryClient.invalidateQueries({
                queryKey: ["projects", projectId],
              })
            }}
          />
          <ContentContainer flex="1">
            <FeedbackForm
              projectId={projectId!}
              feedback={feedback}
              installationId={installationId}
            />
          </ContentContainer>
        </Stack>
      )}
    </QueryWrapper>
  )
}
