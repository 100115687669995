import { z } from "zod"
import { checkField } from "./shared"

export const technicalOverviewSchema = z.object({
  technicalVisitInstallerConclusion: z.string(),
  technicalOverviewPhotos: z.array(z.string()),
})

export type TechnicalOverviewInput = z.infer<typeof technicalOverviewSchema>

export const technicalOverviewValidateInstallationSchema = z.discriminatedUnion(
  "isInstallationCompleted",
  [
    z.object({
      technicalManagerId: z.string({
        invalid_type_error: "Champ obligatoire",
      }),
      isInstallationCompleted: z.literal(true),
      isActivationDone: checkField,
    }),
    z.object({
      technicalManagerId: z.string({
        invalid_type_error: "Champ obligatoire",
      }),
      isInstallationCompleted: z.literal(false),
      isActivationDone: z.boolean(),
      installationNewVisitDate: z.coerce.date(),
      installationNewVisitReason: z.string({
        invalid_type_error: "Champ obligatoire",
      }),
      installationNewVisitNotes: z.string(),
    }),
  ],
)

export type TechnicalOverviewValidateInstallationInput = z.infer<
  typeof technicalOverviewValidateInstallationSchema
>
