import {
  StepActions,
  StepComponentProps,
} from "@ensol/entool/components/entities/Project/StepActions"
import { GenerateEstimateForm } from "@ensol/entool/pages/Project/processes/Payment/components/GenerateEstimateForm"

export const DepositToSend = ({ project, processId }: StepComponentProps) => {
  return (
    <StepActions
      project={project}
      processId={processId}
      actions={[{ action: "validate", label: "En attente paiement acompte" }]}
    >
      <GenerateEstimateForm project={project} />
    </StepActions>
  )
}
