import { Flex, Group } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"

import { ProjectInstallersOverview } from "@ensol/types/entities/project/overview"

import { devices } from "@ensol/shared/styles/theme"

import { ClientInfo } from "@ensol/entool/pages/ProjectsOverview/components/ClientInfo"
import { ProjectCard } from "@ensol/entool/pages/ProjectsOverview/components/ProjectCard"
import { ProjectIndicators } from "@ensol/entool/pages/ProjectsOverview/components/ProjectIndicators"
import { ProjectTaskDetails } from "@ensol/entool/pages/ProjectsOverview/components/ProjectTaskDetails"

import { INSTALLER_PROJECT_TASK_CONFIG } from "./config"

type Props = {
  project: ProjectInstallersOverview
}

export const ProjectRow = ({ project }: Props) => {
  const { taskDetails, isActionNeeded } = project
  const { house } = project.installation
  const { client } = house
  const isMobile = useMediaQuery(devices.sm)

  return (
    <ProjectCard>
      <Flex direction={isMobile ? "column" : "row"} gap={4} p="12">
        <Group>
          <ProjectIndicators
            project={project}
            isActionNeeded={isActionNeeded}
          />
          <ClientInfo
            to={`/projects/${project.id}/technical-overview`}
            projectId={project.id}
            address={house.address}
            client={client}
          />
        </Group>
        {taskDetails !== null && (
          <ProjectTaskDetails
            taskDetails={INSTALLER_PROJECT_TASK_CONFIG[taskDetails.task]}
            type={taskDetails.type}
          />
        )}
      </Flex>
    </ProjectCard>
  )
}
