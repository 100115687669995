import { useParams } from "react-router-dom"

import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import {
  useGetInstallationQuery,
  useHouseSetupQuery,
} from "@ensol/entool/queries/installations"
import { useInstallationMenu } from "@ensol/entool/utils/installations/useInstallationMenu"

import { Installation } from "./Installation"
import { useSaveHouseAndInstallationData } from "./useSaveHouseAndInstallationData"

export const InstallationPage = () => {
  const params = useParams()
  const installationId = params.installationId as string

  useInstallationMenu(installationId)

  const installationQuery = useGetInstallationQuery(installationId)
  const houseSetupQuery = useHouseSetupQuery(installationId)

  const { mutateAsync: onSave, isPending } =
    useSaveHouseAndInstallationData(installationId)

  return (
    <QueryWrapper query={installationQuery}>
      {({ data: installation }) => (
        <Installation
          installationId={installation.id}
          installationDate={installation.updatedAt}
          name={installation.name}
          slug={installation.slug}
          productionDataSource={installation.productionDataSource}
          consumptionDataSource={installation.consumptionDataSource}
          monthlyBill={installation.monthlyBill}
          electricityBuyPrice={installation.electricityBuyPrice}
          yearlyConsumption={installation.yearlyConsumption}
          monthlyConsumptions={installation.monthlyConsumptions}
          manualAutoConsumptionPercent={
            installation.manualAutoConsumptionPercent
          }
          computedAutoConsumptionPercent={
            installation.computedAutoConsumptionPercent
          }
          photovoltaicInstallation={installation.photovoltaicInstallation}
          batteryInstallation={installation.batteryInstallation}
          evChargerInstallation={installation.evChargerInstallation}
          controlDeviceInstallations={installation.controlDeviceInstallations}
          onSiteInstallation={
            installation.isUpsell
              ? houseSetupQuery.data?.onSiteInstallation
              : undefined
          }
          discount={installation.discount}
          referrerId={installation.referrerId}
          panelsLayout={installation.panelsLayout}
          client={installation.client}
          house={installation.house}
          projectId={installation.project?.id ?? null}
          enedisFileCompletionDate={
            installation.project?.enedisFileCompletionDate ?? undefined
          }
          onSave={onSave}
          isSaving={isPending}
          productsCount={installation.productsCount}
          isUpsell={installation.isUpsell}
        />
      )}
    </QueryWrapper>
  )
}
