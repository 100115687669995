import { ProjectInstallersTask } from "@ensol/types/entities/project/overview"

import { TaskDetails } from "@ensol/entool/pages/ProjectsOverview/components/ProjectTaskDetails"

export const INSTALLER_PROJECT_TASK_CONFIG: Record<
  ProjectInstallersTask,
  TaskDetails
> = {
  [ProjectInstallersTask.TECHNICAL_VISIT_CALENDAR_TO_VALIDATE]: {
    label: "VT - Valider calendrier",
    color: "orange",
  },
  [ProjectInstallersTask.TECHNICAL_VISIT_REPORT_TO_SEND]: {
    label: "VT - CR à envoyer",
    color: "orange",
  },
  [ProjectInstallersTask.INSTALLATION_TO_VALIDATE]: {
    label: "Installation - Remplir la validation de l'installation",
    color: "orange",
  },
  [ProjectInstallersTask.INSTALLATION_REPORT_TO_SEND]: {
    label: "Installation - CR à envoyer",
    color: "orange",
  },
}
