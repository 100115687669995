import { completedStepFormSchema } from "@ensol/types/forms/projects/installation"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { pickDefinedProperties } from "@ensol/entool/utils/helpers/pick"
import { getInitialValues } from "@ensol/entool/utils/initialValues"

import { InstallationForm } from "./components/InstallationForm"
import { INSTALLATION_PROPERTIES } from "./config"

export const UpdateInstallation = ({
  project,
  ...props
}: StepComponentProps) => (
  <InstallationForm
    schema={completedStepFormSchema}
    initialValues={{
      ...pickDefinedProperties(INSTALLATION_PROPERTIES, project),
      ...getInitialValues(completedStepFormSchema, project),
    }}
    project={project}
    actions={[
      {
        action: "validate",
        label: "Bon de fin de chantier envoyé",
      },
    ]}
    {...props}
  />
)
