import { useMemo } from "react"

import { EnergyResponses } from "@ensol/types/endpoints/energy"
import { typedKeys } from "@ensol/types/utils"

import {
  MOUNTING_TYPE_LABELS,
  MountingType,
} from "@ensol/shared/entities/installations/batteries"
import {
  BATTERIES,
  BatteryType,
  findRecommendedBatteryType,
} from "@ensol/shared/material/batteries"
import { BrandType } from "@ensol/shared/material/brands"
import { InverterInfo } from "@ensol/shared/material/photovoltaic/inverters"

import { Option } from "@ensol/entool/utils/form/radio"

const BATTERY_OPTIONS: Option<BatteryType>[] = typedKeys(BATTERIES).map(
  (batteryType) => {
    const { name } = BATTERIES[batteryType]

    return {
      label: name,
      value: batteryType,
    }
  },
)

type BatteryMaterialOptionsInput = {
  inverterInfo?: InverterInfo
  batteryType: BatteryType | null
  batteryStats?: EnergyResponses.BatteryStats
}

export type BatteryMaterialOptions = Option<
  BatteryType,
  {
    incompatible: boolean
    mountingTypeOptions: Option<MountingType>[]
    isRecommended: boolean
    recommendationReason?: string
  }
>[]

export const useBatteryMaterialOptions = ({
  inverterInfo,
  batteryType,
  batteryStats,
}: BatteryMaterialOptionsInput): BatteryMaterialOptions => {
  const batteryOptions = useMemo(() => {
    const recommendedCapacity = batteryStats
      ? Math.min(
          batteryStats.averageNightConsumption,
          batteryStats.averageDayExcess,
        )
      : 0

    const recommendedDescription = batteryStats
      ? `Recommandée pour votre ${batteryStats.averageNightConsumption > batteryStats.averageDayExcess ? "excès de production" : "consommation nocturne"} (${recommendedCapacity} kWh)`
      : undefined

    const recommendedBatteryType = findRecommendedBatteryType({
      batteryStats,
      inverterBrand: inverterInfo?.brand ?? BrandType.HUAWEI,
    })

    return BATTERY_OPTIONS.map((battery) => {
      const batteryInfo = BATTERIES[battery.value]
      const isWrongBrand =
        inverterInfo !== undefined && batteryInfo.brand !== inverterInfo.brand

      const disabled = isWrongBrand || batteryInfo.disabled

      const mountingTypeOptions: Option<MountingType>[] =
        batteryInfo.mountingTypes.map((mountingType) => ({
          label: MOUNTING_TYPE_LABELS[mountingType],
          value: mountingType,
        }))
      return {
        ...battery,
        disabled,
        metadata: {
          incompatible: isWrongBrand,
          mountingTypeOptions,
          isRecommended: battery.value === recommendedBatteryType,
          recommendationReason:
            battery.value === recommendedBatteryType
              ? recommendedDescription
              : undefined,
        },
      }
    }).filter((battery) => !battery.disabled || battery.value === batteryType)
  }, [batteryStats, batteryType, inverterInfo])

  return batteryOptions
}
