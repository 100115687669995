import {
  FundingSource,
  FundingSourceStatus,
} from "@ensol/shared/entities/projects/fundingSource"
import * as z from "zod"

import { transformSchemaToNullish } from "@ensol/types/forms/utils"
import { cetelemFieldsSchema } from "./cetelem"

export const baseSalesVisitFormSchema = z.object({
  salesOwnerId: z.string({
    invalid_type_error: "Champ obligatoire",
  }),
  salesVisitPanelsLayoutPath: z.string({
    invalid_type_error: "Champ obligatoire",
  }),
  salesVisitK2ReportPath: z.string().nullable(),
  salesVisitClientMotivation: z.string({
    invalid_type_error: "Champ obligatoire",
  }),
  fundingSource: z.nativeEnum(FundingSource, {
    invalid_type_error: "Champ obligatoire",
  }),
  technicalVisitStartDate: z.date().nullable(),
  technicalVisitInstallerId: z.string().nullable(),
  technicalVisitOwnerId: z.string().nullable(),
  technicalVisitSalesComments: z.string().optional(),
  fundingSourceStatus: z.nativeEnum(FundingSourceStatus).nullable().optional(),
})

const cetelemSalesVisitFormSchema = baseSalesVisitFormSchema
  .merge(transformSchemaToNullish(cetelemFieldsSchema))
  .extend({
    fundingSource: z.literal(FundingSource.CETELEM),
    cetelemReference: z
      .string()
      .refine(
        (value) => !value || /^[0-9\s]+$/.test(value),
        "Référence invalide",
      ),
  })

const otherFundingSourceSalesVisitFormSchema = baseSalesVisitFormSchema.extend({
  fundingSource: z.enum([
    FundingSource.ZILO,
    FundingSource.ALMA,
    FundingSource.EQUITY,
  ]),
})

export const salesVisitFormSchema = z
  .discriminatedUnion("fundingSource", [
    cetelemSalesVisitFormSchema,
    otherFundingSourceSalesVisitFormSchema,
  ])
  .superRefine((data, context) => {
    const schedulingFields = {
      technicalVisitStartDate: data.technicalVisitStartDate,
      technicalVisitInstallerId: data.technicalVisitInstallerId,
      technicalVisitOwnerId: data.technicalVisitOwnerId,
    }
    if (Object.values(schedulingFields).some((field) => field !== null)) {
      return Object.entries(schedulingFields).map(([property, value]) => {
        if (value === null) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Champ obligatoire",
            path: [property],
          })
        }
      })
    }
  })

export const salesVisitFormSchemaForApi = z.discriminatedUnion(
  "fundingSource",
  [
    transformSchemaToNullish(
      cetelemSalesVisitFormSchema.extend({
        cetelemReference: z.string().nullable().optional(),
      }),
    ),
    transformSchemaToNullish(otherFundingSourceSalesVisitFormSchema),
  ],
)

export type SalesVisitStepInput = z.infer<typeof salesVisitFormSchema>
