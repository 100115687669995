import { LoadingOverlay, Stack } from "@mantine/core"

import { Location } from "@ensol/types/forms/houses"
import { InstallationFormInput } from "@ensol/types/forms/installations"

import { getAutoConsumptionGraphConfig } from "@ensol/shared/entities/installations/reports/autoConsumption"
import { getZIndex } from "@ensol/shared/styles/zIndex"

import { Chart } from "@ensol/entool/components/Chart"
import { useGetEnergyReportQuery } from "@ensol/entool/queries/energy"

type Props = {
  slug: string
  startDate: Date
  endDate: Date
  installationValues: InstallationFormInput
  coords: Pick<Location, "lat" | "lng">
}

export const AutoConsumptionReport = ({
  slug,
  startDate,
  endDate,
  installationValues,
  coords,
}: Props) => {
  const { data, isPending } = useGetEnergyReportQuery({
    slug,
    startDate,
    endDate,
    installationValues,
    coords,
  })

  return (
    <Stack pos="relative" mih={300} justify="center">
      <LoadingOverlay visible={isPending} zIndex={getZIndex("overlay")} />
      {data && (
        <Chart
          aspectRatio={6 / 3}
          data={getAutoConsumptionGraphConfig({
            ...data,
            hasBattery: installationValues.batteryInstallation !== null,
          })}
        />
      )}
    </Stack>
  )
}
