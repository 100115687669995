export const PENNYLANE_URL =
  "https://app.pennylane.com/companies/22015090/clients/customer_estimates"
export const HUBSPOT_CONTACTS_URL =
  "https://app-eu1.hubspot.com/contacts/142467012/record/0-1"
export const K2_LOGIN_URL = "https://my.k2-systems.com/login"
export const RGE_CERTIFICATE_URL = "https://ensol.link/rge"
export const INTERCOM_USERS_URL =
  "https://app.intercom.com/a/apps/cxyiyexe/users"
export const INTERCOM_INBOX_URL =
  "https://app.intercom.com/a/inbox/cxyiyexe/inbox"
export const CETELEM_PORTAL_URL = "https://portail-outils-partenaires.fr/"
export const TOULOUSE_METROPOLIS_URL =
  "https://demarches-tm.eservices.toulouse-metropole.fr/habitat/prime-solaire/"
