import { BlockNoteView } from "@blocknote/mantine"
import { useCreateBlockNote } from "@blocknote/react"
import { Avatar, Group, Stack, Text } from "@mantine/core"
import { extend } from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import {
  formatFullName,
  formatDate,
  SHORT_DISPLAY_DATE_TIME_FORMAT,
} from "@ensol/shared/utils/format"

import { useAuthenticatedUser } from "@ensol/entool/queries/users"

import { schema } from "./schema"

extend(relativeTime)

type Props = {
  note: ProjectResponses.ProjectNote
}

export const Note = ({ note }: Props) => {
  const { data: currentUser } = useAuthenticatedUser()
  const isCurrentUserNote = note.userId === currentUser?.id

  const editor = useCreateBlockNote({
    schema,
    initialContent: note.content,
  })

  return (
    <Stack
      pt={12}
      key={note.id}
      gap={2}
      pl={isCurrentUserNote ? 24 : 0}
      pr={isCurrentUserNote ? 0 : 24}
      align={isCurrentUserNote ? "flex-end" : "flex-start"}
    >
      <Group gap="4">
        <Avatar
          src={note.user ? note.user.avatarUrl : "/ensolLogo.svg"}
          size="16"
        />
        <Text>{note.user ? formatFullName(note.user) : <i>Entool</i>}</Text>
        <Text size="xs" c="gray.7">
          {formatDate(note.createdAt, SHORT_DISPLAY_DATE_TIME_FORMAT)}
        </Text>
      </Group>
      <BlockNoteView
        theme="light"
        editor={editor}
        editable={false}
        formattingToolbar={false}
        sideMenu={false}
        slashMenu={false}
      />
    </Stack>
  )
}
