import { Text, Anchor, ThemeIcon, Tooltip, Group } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import {
  IconAlertTriangle,
  IconExternalLink,
  IconHeartHandshake,
  IconMail,
  IconMapPin,
  IconPhone,
} from "@tabler/icons-react"
import { ReactNode } from "react"
import { Link as ReactRouterLink } from "react-router-dom"

import { OWNER_SELECT } from "@ensol/types/endpoints/projects/technicalOverview"
import { Project } from "@ensol/types/entities/project"

import { Header } from "@ensol/shared/components/layout/Header"
import { checkHouseHasGroundInstallation } from "@ensol/shared/entities/houses/roofSection"
import { PROJECT_HEADER_HEIGHT_IN_PX } from "@ensol/shared/styles/constants"
import { devices } from "@ensol/shared/styles/theme"
import { formatFullName } from "@ensol/shared/utils/format"

import { IconButton } from "@ensol/entool/components/IconButton"
import { InfosIconPopover } from "@ensol/entool/components/InfosIconPopover"
import { InstallationCharacteristics } from "@ensol/entool/components/entities/Installation/InstallationCharacteristics"
import { InstallationProducts } from "@ensol/entool/components/entities/Installation/InstallationProducts"
import { ClientMotivationBadges } from "@ensol/entool/components/entities/Project/ClientMotivationBadges"
import { WorksAndChangesIndicators } from "@ensol/entool/components/entities/Project/WorksAndChangesIndicators"
import { getProjectInstallers } from "@ensol/entool/utils/projects/installers"

import { ProjectOwners } from "./ProjectOwners"

type Props = {
  project: Project<{
    select: {
      id: true
      technicalVisitEndOfWorksDate: true
      technicalVisitRequiredChanges: true
      technicalVisitRequiredWorks: true
      status: true
      salesVisitClientMotivation: true
      technicalVisitClientMotivation: true
      installer: { select: { name: true } }
      technicalVisitInstaller: { select: { name: true } }
      salesOwner: { select: typeof OWNER_SELECT }
      operationsOwner: { select: typeof OWNER_SELECT }
      adminOwner: { select: typeof OWNER_SELECT }
      technicalVisitOwner: { select: typeof OWNER_SELECT }
      installation: {
        select: {
          isUpsell: true
          house: {
            select: {
              lat: true
              lng: true
              isAtticAccessible: true
              currentType: true
              address: true
              roofSections: {
                select: {
                  hasGroundInstallation: true
                }
              }
              client: {
                select: {
                  email: true
                  phone: true
                }
              }
            }
          }
          photovoltaicInstallation: {
            select: {
              panelType: true
              panelsCount: true
              inverterType: true
              optimizerType: true
              optimizerCount: true
              extraWorks: true
            }
          }
          batteryInstallation: {
            select: {
              type: true
              extraWorks: true
              count: true
            }
          }
          evChargerInstallation: {
            select: {
              type: true
              extraWorks: true
            }
          }
          controlDeviceInstallations: {
            select: {
              deviceType: true
            }
          }
          referrer: {
            select: {
              id: true
              firstName: true
              lastName: true
            }
          }
        }
      }
    }
  }>
  BreadCrumbs?: ReactNode[]
  Actions?: ReactNode
  Infos?: ReactNode
}
export const ProjectHeader = ({
  project,
  BreadCrumbs,
  Actions,
  Infos,
}: Props) => {
  const { installation } = project
  const { house, referrer } = installation
  const { client } = house

  const installers = getProjectInstallers(project)
  const isMobile = useMediaQuery(devices.sm)

  return (
    <Header
      height={PROJECT_HEADER_HEIGHT_IN_PX}
      BreadCrumbs={BreadCrumbs}
      Actions={
        !isMobile && (
          <>
            {Actions}
            <InfosIconPopover
              infos={[
                {
                  Icon: IconMail,
                  name: "Email",
                  value: client.email,
                },
                {
                  Icon: IconPhone,
                  name: "Téléphone",
                  value: client.phone,
                },
                {
                  Icon: IconMapPin,
                  name: "Adresse",
                  value: house.address,
                  Link: (
                    <IconButton
                      Icon={
                        <ThemeIcon size={20}>
                          <IconExternalLink />
                        </ThemeIcon>
                      }
                      href={`https://maps.google.com/?q=${house.address}`}
                      ariaLabel="Ouvrir l'adresse dans Google Maps"
                      isExternal
                    />
                  ),
                },
              ]}
            />
          </>
        )
      }
      Infos={
        <>
          {Infos}
          <WorksAndChangesIndicators {...project} />
          {!isMobile && (
            <>
              {checkHouseHasGroundInstallation(house.roofSections) && (
                <Tooltip label="A une installation en pose au sol">
                  <ThemeIcon color="yellow">
                    <IconAlertTriangle size={20} />
                  </ThemeIcon>
                </Tooltip>
              )}
              {referrer && (
                <>
                  <ThemeIcon color="blue">
                    <IconHeartHandshake size={20} />
                  </ThemeIcon>
                  <Text size="sm">Parrainé par</Text>
                  <Anchor
                    component={ReactRouterLink}
                    size="sm"
                    fw="bold"
                    to={`/clients/${referrer.id}`}
                  >
                    {formatFullName(referrer)}
                  </Anchor>
                </>
              )}
            </>
          )}
        </>
      }
      BottomSection={
        <Group gap="4 16">
          <Group gap="8">
            <InstallationProducts installation={project.installation} />
          </Group>
          <InstallationCharacteristics
            installation={installation}
            installers={installers}
          />
        </Group>
      }
      RightSection={
        <Group>
          <ClientMotivationBadges project={project} />
          <ProjectOwners project={project} />
        </Group>
      }
    />
  )
}
