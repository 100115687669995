import {
  Group,
  Title,
  TitleOrder,
  Collapse,
  Button,
  Paper,
  PaperProps,
  Box,
  ThemeIcon,
  Loader,
  Stack,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconChevronDown, IconChevronUp, TablerIcon } from "@tabler/icons-react"
import _ from "lodash"
import { ReactNode } from "react"

import { ColorShade } from "@ensol/shared/styles/colors"
import { CONTENT_PADDING_IN_PX } from "@ensol/shared/styles/constants"

type Props = {
  title?: string | ReactNode
  Actions?: ReactNode
  titleOrder?: TitleOrder
  icon?: TablerIcon
  iconColor?: ColorShade
  isCollapsible?: boolean
  isCollapsed?: boolean
  isContentLoading?: boolean
  children?: ReactNode
} & PaperProps

export const Section = ({
  title,
  titleOrder,
  icon,
  iconColor,
  isCollapsible,
  isCollapsed,
  isContentLoading,
  Actions,
  children,
  ...props
}: Props) => {
  const [isOpened, { toggle: toggleSection }] = useDisclosure(!isCollapsed)

  return (
    <Paper
      shadow="xs"
      p={CONTENT_PADDING_IN_PX}
      radius={8}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 32,
      }}
      {...props}
    >
      {title && (
        <Group justify="space-between" wrap="nowrap">
          {isCollapsible ? (
            <Button
              w="100%"
              styles={{ label: { width: "100%" } }}
              onClick={toggleSection}
              variant="transparent"
              color="black"
              p="0"
            >
              <SectionTitle
                title={title}
                titleOrder={titleOrder}
                isCollapsible={isCollapsible}
                icon={icon}
                iconColor={iconColor}
                isOpened={isOpened}
              />
            </Button>
          ) : (
            <SectionTitle
              title={title}
              titleOrder={titleOrder}
              isCollapsible={isCollapsible}
              icon={icon}
              iconColor={iconColor}
              isOpened={false}
            />
          )}
          <Box style={{ flexShrink: 0 }}>{Actions}</Box>
        </Group>
      )}
      {isCollapsible ? (
        <Collapse in={isOpened}>
          <Box>{children}</Box>
        </Collapse>
      ) : (
        <Box>
          {isContentLoading ? (
            <Stack align="center" justify="center" mih={props.mih}>
              <Loader />
            </Stack>
          ) : (
            children
          )}
        </Box>
      )}
    </Paper>
  )
}

const SectionTitle = ({
  title,
  icon: Icon,
  iconColor,
  titleOrder = 2,
  isCollapsible,
  isOpened,
}: Pick<
  Props,
  "title" | "icon" | "iconColor" | "titleOrder" | "isCollapsible"
> & {
  isOpened: boolean
}) => (
  <Group gap={8} flex={1}>
    <Group gap={8}>
      {Icon && (
        <ThemeIcon c={iconColor}>
          <Icon />
        </ThemeIcon>
      )}
      {_.isString(title) ? <Title order={titleOrder}>{title}</Title> : title}
    </Group>
    {isCollapsible && (
      <>
        {isOpened ? <IconChevronUp size={20} /> : <IconChevronDown size={20} />}
      </>
    )}
  </Group>
)
