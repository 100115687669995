import { Project } from "@ensol/types/entities/project"

export const PRELIMINARY_REQUEST_PROPERTIES: (keyof Project)[] = [
  "preliminaryRequestNotes",
  "preliminaryRequestLastContactDate",
  "preliminaryRequestReference",
  "preliminaryRequestSubmissionDate",
  "preliminaryRequestExpectedValidationDate",
  "preliminaryRequestDeadlineDate",
  "preliminaryRequestRequestedChangesPaths",
  "preliminaryRequestAcknowledgmentPath",
  "preliminaryRequestABFAcknowledgmentPath",
  "preliminaryRequestAcknowledgmentExtraPaths",
  "preliminaryRequestAcknowledgedDate",
  "preliminaryRequestApprovalPaths",
  "preliminaryRequestRejectionPaths",
  "preliminaryRequestApprovalDate",
]
