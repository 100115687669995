import { StepActionsProps } from "@ensol/entool/components/entities/Project/StepActions"
import { useClientEventsQuery } from "@ensol/entool/queries/intercom"

export const useEmailsToSend = (
  clientId: string,
  emails: StepActionsProps["emails"],
) => {
  const {
    data: clientEvents,
    isPending,
    error,
  } = useClientEventsQuery(clientId)

  if (emails === undefined || emails.length === 0) {
    return { emailsToSend: [], areEmailsLoading: false }
  }

  if (isPending) {
    return { emailsToSend: [], areEmailsLoading: true }
  }

  if (error) {
    return { emailsToSend: [], areEmailsLoading: false }
  }

  return {
    emailsToSend: emails.filter(
      ({ eventName }) =>
        !clientEvents.events.some(
          ({ name, count }) => name === eventName && count > 0,
        ),
    ),
    areEmailsLoading: false,
  }
}
