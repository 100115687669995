import dayjs from "dayjs"

import { EnergyResponses } from "@ensol/types/endpoints/energy"
import { Location } from "@ensol/types/forms/houses"
import { InstallationFormInput } from "@ensol/types/forms/installations"

import { Section } from "@ensol/shared/components/Section"

import { StatCard } from "@ensol/entool/components/StatCard"
import { ReportWrapper } from "@ensol/entool/pages/Installation/components/ReportWrapper"
import { StickyStatsSection } from "@ensol/entool/pages/Installation/components/StickyStatsSection"
import { AutoConsumptionReport } from "@ensol/entool/pages/Installation/sections/9-AutoConsumption/AutoConsumptionReport"
import { YearlyEnergyGraph } from "@ensol/entool/pages/Installation/sections/9-AutoConsumption/YearlyEnergyGraph"

type Props = {
  slug: string
  energyStats?: EnergyResponses.Stats
  isEnergyStatsLoading: boolean
  installationValues: InstallationFormInput
  coords: Pick<Location, "lat" | "lng">
}

export const AutoConsumption = ({
  slug,
  energyStats,
  isEnergyStatsLoading,
  installationValues,
  coords,
}: Props) => {
  if (!isEnergyStatsLoading && !energyStats) {
    return null
  }

  const dataTimeFrame = energyStats?.dataTimeFrame

  return (
    <>
      <StickyStatsSection>
        <StatCard
          label="Taux d’auto-consommation"
          stat={energyStats?.autoConsumptionPercent}
          unit="%"
          color="purple.4"
          isLoading={isEnergyStatsLoading}
        />
        <StatCard
          label="Taux d’indépendance"
          stat={energyStats?.autoProductionPercent}
          unit="%"
          isLoading={isEnergyStatsLoading}
        />
      </StickyStatsSection>
      <ReportWrapper
        title="Détail de l’autoconsommation"
        isLoading={isEnergyStatsLoading}
        timeFrame={dataTimeFrame}
        ReportComponent={(props) => (
          <AutoConsumptionReport
            coords={coords}
            slug={slug}
            {...props}
            installationValues={installationValues}
          />
        )}
      />
      <Section
        title="Profil énergétique annuel"
        mih={300}
        isContentLoading={isEnergyStatsLoading}
      >
        {dataTimeFrame && (
          <YearlyEnergyGraph
            coords={coords}
            slug={slug}
            startDate={dayjs.utc(dataTimeFrame.startDate)}
            installationValues={installationValues}
          />
        )}
      </Section>
    </>
  )
}
