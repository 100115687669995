import { certificateToSendStepFormSchema } from "@ensol/types/forms/projects/edfOa"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { EdfOaForm } from "@ensol/entool/pages/Project/processes/EdfOa/components/EdfOaForm"
import { getInitialValues } from "@ensol/entool/utils/initialValues"

export const CertificateToSend = ({
  project,
  ...props
}: StepComponentProps) => {
  return (
    <EdfOaForm
      schema={certificateToSendStepFormSchema}
      initialValues={getInitialValues(certificateToSendStepFormSchema, project)}
      project={project}
      actions={[
        {
          action: "validate",
          label: "Envoyer l'attestation",
          confirmationMessage:
            "Cette action va envoyer un lien de signature de l'attestation BTA au client, êtes-vous sûr de vouloir continuer ?",
        },
      ]}
      {...props}
    />
  )
}
