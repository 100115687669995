import dayjs from "dayjs"
import _ from "lodash"

import { FinancialData, YearlyEnergySavings } from "@ensol/types/simulation"

import {
  estimateEnergyPrice,
  YEARLY_MATERIAL_EFFICIENCY_LOSS,
  DEFAULT_ELECTRICITY_PRICE_ANNUAL_RAISE,
  computeYearlyEnergyUsage,
} from "@ensol/shared/entities/installations/energy"
import { computeEnergyResellPrice } from "@ensol/shared/entities/installations/resell"
import { PhotovoltaicSubsidyType } from "@ensol/shared/entities/installations/subsidies/photovoltaic"
import { CurrentType } from "@ensol/shared/material/currentType"
import { roundDecimalNumber } from "@ensol/shared/utils/format"

export const REFERRAL_DISCOUNT = 250

type YearlySavingsInput = {
  year: number
  installedCapacity: number
  autoConsumptionPercent: number
  estimatedYearlyProduction: number
  subsidyType?: PhotovoltaicSubsidyType
  electricityPriceAnnualRaise?: number
  electricityBuyPrice: number
}

export const computeYearlySavings = ({
  year,
  electricityBuyPrice,
  installedCapacity,
  autoConsumptionPercent,
  estimatedYearlyProduction,
  subsidyType,
  electricityPriceAnnualRaise,
}: YearlySavingsInput): YearlyEnergySavings => {
  const { autoConsumedEnergy, resellableEnergy } = computeYearlyEnergyUsage({
    year,
    autoConsumptionPercent,
    estimatedYearlyProduction,
  })

  const autoConsumedSavings = roundDecimalNumber(
    autoConsumedEnergy *
      estimateEnergyPrice({
        year,
        electricityBuyPrice,
        electricityPriceAnnualRaise,
      }),
  )

  const energyResellPrice = computeEnergyResellPrice({
    subsidyType,
    installedCapacity,
    currentDate: dayjs().set("year", year).toDate(),
  })

  const resellSavings = roundDecimalNumber(resellableEnergy * energyResellPrice)

  return {
    totalSavings: autoConsumedSavings + resellSavings,
    resellSavings,
    autoConsumedSavings,
  }
}

type BreakEvenDateInput = {
  electricityBuyPrice: number
  installedCapacity: number
  estimatedYearlyProduction: number
  autoConsumptionPercent: number
  finalCostForPhotovoltaicAndBatteryWithSubsidies: number
  subsidyType?: PhotovoltaicSubsidyType
  electricityPriceAnnualRaise?: number
}

export const computeMonthsBeforeBreakEvenDate = ({
  installedCapacity,
  electricityBuyPrice,
  estimatedYearlyProduction,
  autoConsumptionPercent,
  finalCostForPhotovoltaicAndBatteryWithSubsidies,
  subsidyType,
  electricityPriceAnnualRaise,
}: BreakEvenDateInput): number => {
  let loopDate = dayjs.utc()

  let monthsBeforeBreakEvenDate = 0
  let leftToBreakEven = finalCostForPhotovoltaicAndBatteryWithSubsidies

  while (leftToBreakEven > 0) {
    const { totalSavings } = computeYearlySavings({
      electricityBuyPrice,
      year: loopDate.year(),
      installedCapacity,
      autoConsumptionPercent,
      estimatedYearlyProduction,
      subsidyType,
      electricityPriceAnnualRaise,
    })

    const nextLoopDate = dayjs.utc(loopDate).add(1, "year").startOf("year")
    const loopDurationInYear = nextLoopDate.diff(loopDate, "year", true)
    const loopDurationInMonth = loopDurationInYear * 12

    if (totalSavings > leftToBreakEven) {
      monthsBeforeBreakEvenDate += roundDecimalNumber(
        loopDurationInMonth * (leftToBreakEven / totalSavings),
        0,
      )
    } else {
      monthsBeforeBreakEvenDate += loopDurationInMonth
    }

    leftToBreakEven -= totalSavings * loopDurationInYear
    loopDate = nextLoopDate
  }

  return roundDecimalNumber(monthsBeforeBreakEvenDate, 0)
}

type FinancialDataInput = {
  electricityBuyPrice: number
  yearlyConsumption: number
  installedCapacity: number
  estimatedYearlyProduction: number
  autoConsumptionPercent: number
  finalCostWithSubsidies: number
  currentType: CurrentType
  subsidyType?: PhotovoltaicSubsidyType
  electricityPriceAnnualRaise?: number
}

export const computeFinancialData = ({
  electricityBuyPrice,
  yearlyConsumption,
  installedCapacity,
  estimatedYearlyProduction,
  autoConsumptionPercent,
  finalCostWithSubsidies,
  subsidyType,
  electricityPriceAnnualRaise = DEFAULT_ELECTRICITY_PRICE_ANNUAL_RAISE,
}: FinancialDataInput): FinancialData => {
  const year = new Date().getFullYear()

  const twentyFiveYearsEnergyProduction = _.sum(
    _.times(
      25,
      (i) => estimatedYearlyProduction * YEARLY_MATERIAL_EFFICIENCY_LOSS ** i,
    ),
  )
  const panelsEnergyProductionCost = roundDecimalNumber(
    finalCostWithSubsidies / twentyFiveYearsEnergyProduction,
  )

  const yearlyBillsWithoutPanels = _.times(31, (i) => {
    const electricityPrice = estimateEnergyPrice({
      year: year + i,
      electricityBuyPrice,
    })

    return {
      year: year + i,
      amount: electricityPrice * yearlyConsumption,
    }
  })

  const yearlyBillsWithPanels = _.times(31, (i) => {
    const { totalSavings } = computeYearlySavings({
      year: year + i,
      electricityBuyPrice,
      installedCapacity,
      autoConsumptionPercent,
      estimatedYearlyProduction,
      subsidyType,
      electricityPriceAnnualRaise,
    })

    return {
      year: year + i,
      amount: yearlyBillsWithoutPanels[i].amount - totalSavings,
    }
  })

  const billReductionRatio =
    1 -
    Math.min(
      yearlyBillsWithPanels[0].amount / yearlyBillsWithoutPanels[0].amount,
      1,
    )

  const savingsPerYear = _.times(31, (i) => {
    return {
      year: year + i,
      amount:
        _.sum(
          _.times(i, (j) => {
            return (
              yearlyBillsWithoutPanels[j].amount -
              yearlyBillsWithPanels[j].amount
            )
          }),
        ) - finalCostWithSubsidies,
    }
  })

  const firstYearSavings = computeYearlySavings({
    year,
    electricityBuyPrice,
    installedCapacity,
    estimatedYearlyProduction,
    autoConsumptionPercent,
    subsidyType,
    electricityPriceAnnualRaise,
  })

  return {
    billReductionRatio,
    panelsEnergyProductionCost,
    firstYearSavings,
    tenthYearSavings: savingsPerYear[10].amount + finalCostWithSubsidies,
    thirtiethYearSavings: savingsPerYear[30].amount + finalCostWithSubsidies,
    graphs: {
      savingsPerYear,
      yearlyBillsWithoutPanels,
      yearlyBillsWithPanels,
    },
  }
}

export const computeTotalDiscount = (discount?: number | null): number =>
  discount ?? 0

type YearlyBillsForecastInput = {
  yearlyConsumption: number
  electricityBuyPrice: number
  electricityPriceAnnualRaise: number
  config: {
    dataPointsCount: number
    stepInYears: number
  }
}

export const computeYearlyBillsForecast = ({
  yearlyConsumption,
  electricityBuyPrice,
  electricityPriceAnnualRaise,
  config,
}: YearlyBillsForecastInput) => {
  const currentYear = new Date().getFullYear()

  return Array.from(
    { length: config.dataPointsCount },
    (_, index) => 1 + index * config.stepInYears,
  ).map((extraYears) => {
    const year = currentYear + extraYears
    return {
      year,
      value: roundDecimalNumber(
        estimateEnergyPrice({
          year,
          electricityBuyPrice,
          electricityPriceAnnualRaise,
        }) * yearlyConsumption,
        0,
      ),
    }
  })
}
