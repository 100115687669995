import { Badge } from "@mantine/core"

import { ProjectStatus as PrismaProjectStatus } from "@ensol/types/prisma-client"

import {
  getStatusIndex,
  ProjectStatus,
} from "@ensol/shared/entities/projects/statuses"

type Props = {
  status?: PrismaProjectStatus
}

export const ProjectStatusBadge = ({ status }: Props) => {
  if (status === undefined || status === ProjectStatus.CREATED) {
    return <Badge color="gray">Non commencé</Badge>
  }

  if (status === ProjectStatus.ABORTED) {
    return <Badge color="red">Annulé</Badge>
  }

  if (
    getStatusIndex(status) >=
    getStatusIndex(ProjectStatus.INSTALLATION_COMPLETED)
  ) {
    return <Badge color="green">Installé</Badge>
  }

  return <Badge color="green">Signé</Badge>
}
