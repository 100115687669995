import { submittedStepFormSchema } from "@ensol/types/forms/projects/consuel"

import { EmailEvent } from "@ensol/shared/entities/projects/emails"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { CONSUEL_PROPERTIES } from "@ensol/entool/pages/Project/processes/Consuel/config"
import { pickDefinedProperties } from "@ensol/entool/utils/helpers/pick"
import { getInitialValues } from "@ensol/entool/utils/initialValues"

import { ConsuelForm } from "./components/ConsuelForm"

export const Validated = ({ project, processId }: StepComponentProps) => (
  <ConsuelForm
    schema={submittedStepFormSchema}
    initialValues={{
      ...getInitialValues(submittedStepFormSchema, project),
      ...pickDefinedProperties(CONSUEL_PROPERTIES, project),
    }}
    project={project}
    processId={processId}
    emails={[
      {
        eventName: EmailEvent.CONSUEL_VALIDATED,
        label: "Consuel validé",
      },
    ]}
    actions={[]}
  />
)
