import { certificateToSendStepFormSchema } from "@ensol/types/forms/projects/edfOa"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { EdfOaForm } from "@ensol/entool/pages/Project/processes/EdfOa/components/EdfOaForm"
import { getInitialValues } from "@ensol/entool/utils/initialValues"

export const CertificateSent = ({ project, ...props }: StepComponentProps) => {
  return (
    <EdfOaForm
      schema={certificateToSendStepFormSchema}
      initialValues={getInitialValues(certificateToSendStepFormSchema, project)}
      project={project}
      actions={[]}
      {...props}
    />
  )
}
