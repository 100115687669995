import { Divider, Stack } from "@mantine/core"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { Section } from "@ensol/shared/components/Section"
import {
  ElectricMeterType,
  getElectricMeter,
  getRoofAccessibility,
  RoofAccessibility,
} from "@ensol/shared/entities/projects/technicalVisit"
import { getRoofType } from "@ensol/shared/material/roofType"

import { HouseSubSection } from "./HouseSubSection"

type Props = {
  project: ProjectResponses.ProjectTechnicalOverview
}

export const HouseSection = ({ project }: Props) => {
  const { installation, isPLCNeeded, hasAlreadyPLC } = project
  const {
    photovoltaicInstallation,
    batteryInstallation,
    evChargerInstallation,
    house,
  } = installation
  const {
    fromStreetPhotos,
    fromFrontPhotos,
    electricMeterPhotos,
    electricalPanelPhotos,
    roofPhotos,
    atticPhotos,
    accessibilityComments,
    unRegulatedConstructionNote,
    roofSections,
    tilesComments,
    tileSupportPlatesPhotos,
    tileSupportPlatesComments,
    groundingConnectionPhotos,
    waterHeaterPhotos,
  } = house

  return (
    <Section title="Maison" isCollapsible isCollapsed={true}>
      <Stack gap="32">
        <HouseSubSection
          house={house}
          title="Accessibilité"
          photos={[...fromStreetPhotos, ...fromFrontPhotos]}
          additionalInformationConfig={[
            {
              condition: (house) => house.parkingEase !== null,
              label: "Facilité de stationnement",
              getValue: (house) => `${house.parkingEase}`,
              getType: () => "info",
            },
            {
              condition: (house) => house.buildingHeight !== null,
              label: "Hauteur du bâtiment",
              getValue: (house) => `${house.buildingHeight}m`,
              getType: () => "info",
            },
            {
              condition: (house) => house.maxParkableVehicle !== null,
              label: "Accessibilité chantier",
              getValue: (house) => `${house.maxParkableVehicle}`,
              getType: () => "info",
            },
            {
              condition: (house) =>
                house.roofAccessibility === RoofAccessibility.POD ||
                house.roofAccessibility === RoofAccessibility.SCAFFOLDING,
              label: "Accessibilité toiture",
              getValue: (house) =>
                `${getRoofAccessibility(house.roofAccessibility)}`,
              getType: () => "alert",
            },
          ]}
          notes={[
            {
              label: "Commentaires extérieur",
              content: accessibilityComments,
            },
            {
              label: "Constructions non régularisées",
              content: unRegulatedConstructionNote,
            },
          ]}
        />
        <Divider />
        <HouseSubSection
          house={house}
          title="Électricité"
          photos={[
            ...electricMeterPhotos,
            ...electricalPanelPhotos,
            ...groundingConnectionPhotos,
            ...waterHeaterPhotos,
          ]}
          additionalInformationConfig={[
            {
              condition: (house) =>
                house.electricMeterType === ElectricMeterType.DISK ||
                house.electricMeterType === ElectricMeterType.ELECTRONIC,
              label: "Type de compteur",
              getValue: (house) =>
                `${getElectricMeter(house.electricMeterType)}`,
              getType: () => "alert",
            },
            {
              condition: () => isPLCNeeded === true,
              label: "Besoin de CPL",
              getValue: () => "Oui",
              getType: () => "alert",
            },
            {
              condition: () => hasAlreadyPLC === true,
              label: "Le client a déjà un CPL?",
              getValue: () => "Oui",
              getType: () => "alert",
            },
            {
              condition: (house) => house.isElectricalPanelAccessible === false,
              label: "Besoin de répartiteur de phase",
              getValue: () => "Oui",
              getType: () => "alert",
            },
            {
              condition: (house) =>
                house.groundMeasurementInOhm !== null &&
                house.groundMeasurementInOhm > 100,
              label: "Mesure de la terre",
              getValue: (house) => `${house.groundMeasurementInOhm}Ω`,
              getType: () => "alert",
            },
            {
              condition: (house) => house.waterHeaterType !== null,
              label: "Type de chauffe-eau",
              getValue: (house) => `${house.waterHeaterType}`,
              getType: () => "info",
            },
          ]}
          notes={[]}
        />
        <Divider />
        <HouseSubSection
          house={house}
          title="Toiture"
          photos={[
            ...roofPhotos,
            ...atticPhotos,
            ...roofSections.flatMap(({ photos }) => photos),
            ...tileSupportPlatesPhotos,
          ]}
          additionalInformationConfig={[
            {
              condition: (house) => house.roofType !== null,
              label: "Type de couverture",
              getValue: (house) => `${getRoofType(house.roofType).name}`,
              getType: () => "info",
            },
            {
              condition: (house) => house.hasVentTiles === true,
              label: "Présence de tuiles chatières",
              getValue: () => "Oui",
              getType: () => "alert",
            },
          ]}
          notes={[
            { label: "Commentaires tuiles", content: tilesComments },
            {
              label: "Commentaires PST",
              content: tileSupportPlatesComments,
            },
          ]}
        />
        {photovoltaicInstallation !== null && (
          <>
            <Divider />
            <HouseSubSection
              house={house}
              title="Photovoltaïque"
              photos={photovoltaicInstallation.cableRoutingPhotos}
              additionalInformationConfig={[
                {
                  condition: () =>
                    photovoltaicInstallation.hasPanelsDistanceWarning === true,
                  label: "Distance entre TGPV / TGBT",
                  getValue: () => "> 3m",
                  getType: () => "alert",
                },
              ]}
              notes={[
                {
                  label: "Commentaires cheminement des câbles",
                  content: photovoltaicInstallation.cableRoutingComments,
                },
              ]}
            />
          </>
        )}
        {batteryInstallation !== null && (
          <>
            <Divider />
            <HouseSubSection
              house={house}
              title="Batterie"
              photos={batteryInstallation.locationPhotos}
              additionalInformationConfig={[]}
              notes={[
                {
                  label: "Commentaires",
                  content: batteryInstallation.comments,
                },
              ]}
            />
          </>
        )}
        {evChargerInstallation !== null && (
          <>
            <Divider />
            <HouseSubSection
              house={house}
              title="Borne de recharge"
              photos={evChargerInstallation.locationPhotos}
              additionalInformationConfig={[
                {
                  condition: () =>
                    evChargerInstallation.connectionCableLength !== null,
                  label: "Métrage réel cheminement câbles",

                  getValue: () =>
                    `${evChargerInstallation.connectionCableLength}m`,
                  getType: () => "info",
                },
                {
                  condition: () => evChargerInstallation.canAccessWifi !== null,
                  label: "Accès au wifi",
                  getValue: () =>
                    `${evChargerInstallation.canAccessWifi ? "Oui" : "Non"}`,
                  getType: () =>
                    evChargerInstallation.canAccessWifi === true
                      ? "info"
                      : "alert",
                },
              ]}
              notes={[
                {
                  label: "Commentaires",
                  content: evChargerInstallation.comments,
                },
              ]}
            />
          </>
        )}
      </Stack>
    </Section>
  )
}
