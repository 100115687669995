import { useState } from "react"

import { ProjectOperationsOverview } from "@ensol/types/entities/project/overview"

export enum ProjectDrawerTab {
  NOTES = "NOTES",
  DETAILS = "DETAILS",
}

export const useProjectDrawer = () => {
  const [selectedProject, setSelectedProject] =
    useState<ProjectOperationsOverview | null>(null)
  const [activeDrawerTab, setActiveDrawerTab] = useState<ProjectDrawerTab>(
    ProjectDrawerTab.NOTES,
  )

  return {
    selectedProject,
    activeDrawerTab,
    setActiveDrawerTab,
    openDrawer: (project: ProjectOperationsOverview, tab: ProjectDrawerTab) => {
      setSelectedProject(project)
      setActiveDrawerTab(tab)
    },
    closeDrawer: () => setSelectedProject(null),
  }
}
