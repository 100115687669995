import { IconBarrierBlock } from "@tabler/icons-react"
import * as z from "zod"

import { visitScheduledStepFormSchema } from "@ensol/types/forms/projects/consuel"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { CONSUEL_PROPERTIES } from "@ensol/entool/pages/Project/processes/Consuel/config"
import { pickDefinedProperties } from "@ensol/entool/utils/helpers/pick"
import { getInitialValues } from "@ensol/entool/utils/initialValues"
import { validateAction } from "@ensol/entool/utils/projects/validateAction"

import { ConsuelForm } from "./components/ConsuelForm"
import { validateConsuelAction } from "./validation"

export const VisitSubmitted = ({ project, processId }: StepComponentProps) => (
  <ConsuelForm
    schema={visitScheduledStepFormSchema}
    initialValues={{
      ...getInitialValues(visitScheduledStepFormSchema, project),
      ...pickDefinedProperties(CONSUEL_PROPERTIES, project),
    }}
    project={project}
    processId={processId}
    actions={[
      {
        action: "workNeeded",
        label: "Travaux nécessaires",
        color: "orange",
        Icon: IconBarrierBlock,
        validateAction: validateAction(
          z.object({
            consuelNonComplianceReportPaths: z
              .array(z.string())
              .min(1, "Champ obligatoire"),
          }),
        ),
      },
      {
        action: "validate",
        label: "Valider le Consuel",
        validateAction: validateConsuelAction,
      },
    ]}
  />
)
