import { useContext } from "react"

import { Menu as SharedMenu } from "@ensol/shared/components/layout/Menu"
import { isUserAuthorized } from "@ensol/shared/entities/users/roles"

import { useAuthenticatedUser } from "@ensol/entool/queries/users"

import { MenuProviderContext } from "./Provider"

type MenuProps = {
  closeNavbar?: () => void
  toggleDesktop?: () => void
  desktopOpened?: boolean
}

export const Menu = ({
  desktopOpened,
  closeNavbar,
  toggleDesktop,
}: MenuProps) => {
  const { menuItems } = useContext(MenuProviderContext)
  const { data: user, isPending } = useAuthenticatedUser()

  if (menuItems === null || !user || isPending) {
    return null
  }

  return (
    <SharedMenu
      desktopOpened={desktopOpened}
      closeNavbar={closeNavbar}
      toggleDesktop={toggleDesktop}
      menuItems={menuItems.filter(({ authorizedGroups }) =>
        isUserAuthorized(authorizedGroups, user),
      )}
    />
  )
}
