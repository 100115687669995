import { HouseAlert } from "@ensol/types/prisma-client"

type HouseAlertInfo = {
  name: string
  shortName: string
}

export const HouseAlertType = {
  MANUFACTURER_CRITICAL: "MANUFACTURER_CRITICAL",
  MANUFACTURER_RECURRENT: "MANUFACTURER_RECURRENT",
  MANUFACTURER_CLAMPS: "MANUFACTURER_CLAMPS",
  LOW_PRODUCTION: "LOW_PRODUCTION",
  INTERNAL_CLAMPS: "INTERNAL_CLAMPS",
  MISSING_DATA: "MISSING_DATA",
} as const

export type HouseAlertType = keyof typeof HouseAlertType

const HOUSE_ALERTS: Record<HouseAlertType, HouseAlertInfo> = {
  [HouseAlertType.MANUFACTURER_CRITICAL]: {
    name: "Alerte constructeur critique",
    shortName: "Critique",
  },
  [HouseAlertType.MANUFACTURER_RECURRENT]: {
    name: "Alerte communication récurrente",
    shortName: "Communication",
  },
  [HouseAlertType.MANUFACTURER_CLAMPS]: {
    name: "Problème de pinces (constructeur)",
    shortName: "Pinces (constructeur)",
  },
  [HouseAlertType.LOW_PRODUCTION]: {
    name: "Production faible",
    shortName: "Production faible",
  },
  [HouseAlertType.INTERNAL_CLAMPS]: {
    name: "Problème de pinces (interne)",
    shortName: "Pinces (interne)",
  },
  [HouseAlertType.MISSING_DATA]: {
    name: "Données manquantes",
    shortName: "Données manquantes",
  },
}

export const getHouseAlert = (type: HouseAlertType) => HOUSE_ALERTS[type]

export const isAlertOpened = (alert: HouseAlert) =>
  !alert.isResolved &&
  (alert.ignoredUntil === null || alert.ignoredUntil < new Date())

export const isAlertInProgress = (alert: HouseAlert) =>
  alert.intercomConversationId !== null
