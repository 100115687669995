import { Button, Popover, ThemeIcon } from "@mantine/core"
import { DateTimePicker } from "@mantine/dates"
import { useDisclosure, useClickOutside } from "@mantine/hooks"
import { IconAlarmFilled } from "@tabler/icons-react"
import * as z from "zod"

import {
  ProjectOperationsOverview,
  TaskDueDateProperty,
} from "@ensol/types/entities/project/overview"

import { ProcessId } from "@ensol/shared/entities/projects/processes"
import {
  DISPLAY_DATE_TIME_FORMAT,
  formatDate,
  SHORT_DISPLAY_DATE_TIME_FORMAT,
} from "@ensol/shared/utils/format"

import { PopoverContent } from "@ensol/entool/components/PopoverContent"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { useProcessForm } from "@ensol/entool/utils/projects/useProcessForm"

type Props = {
  project: ProjectOperationsOverview
  dueDateConfig: {
    property: TaskDueDateProperty
    processId: ProcessId
  }
  isActionNeeded: boolean
}
export const DueDateReminder = ({
  project,
  dueDateConfig,
  isActionNeeded,
}: Props) => {
  const { property, processId } = dueDateConfig
  const dueDate = project[property]

  const form = useProcessForm({
    schema: z.object({ [property]: z.date().nullable() }),
    initialValues: { [property]: dueDate },
    projectId: project.id,
    processId,
  })

  const [opened, { open, close }] = useDisclosure(false)
  const [
    isDatePickerOpened,
    { toggle: toggleDatePicker, close: closeDatePicker },
  ] = useDisclosure(false)
  const ref = useClickOutside(() => !isDatePickerOpened && close())

  return (
    <Popover withArrow position="top" offset={0} opened={opened}>
      <Popover.Target>
        <Button
          variant="transparent"
          onClick={open}
          c={isActionNeeded ? "red.5" : "gray.9"}
          fw={isActionNeeded ? 600 : 400}
          size="sm"
          p="0"
          leftSection={
            <ThemeIcon c={isActionNeeded ? "red.5" : "gray.9"}>
              <IconAlarmFilled />
            </ThemeIcon>
          }
          styles={{ section: { margin: 4 } }}
        >
          {formatDate(dueDate, SHORT_DISPLAY_DATE_TIME_FORMAT)}
        </Button>
      </Popover.Target>
      <PopoverContent ref={ref} title="Date du rappel">
        <DateTimePicker
          w={FIELD_WIDTH}
          valueFormat={DISPLAY_DATE_TIME_FORMAT}
          onClick={toggleDatePicker}
          submitButtonProps={{ onClick: closeDatePicker }}
          popoverProps={{ closeOnClickOutside: false, closeOnEscape: false }}
          {...form.getInputProps(property)}
        />
      </PopoverContent>
    </Popover>
  )
}
