import { IconHomeCog } from "@tabler/icons-react"

import { SimulationResults } from "@ensol/types/simulation"

import { formatNumber } from "@ensol/shared/utils/format"

import { SummaryItem, SummarySection } from "../shared"

import { AutoConsumptionSummary } from "./AutoConsumptionSummary"
import { SavingsSummary } from "./SavingsSummary"

type Props = {
  simulationResults: SimulationResults
  hasBattery: boolean
}

export const SimulationSummary = ({ simulationResults, hasBattery }: Props) => {
  const {
    estimatedYearlyProduction,
    yearlyConsumption,
    autoConsumptionPercent,
    firstYearSavings,
    billReductionRatio,
    graphs: { yearlyBillsWithoutPanels },
  } = simulationResults

  return (
    <>
      <SummarySection title="Votre projet" Icon={IconHomeCog}>
        <SummaryItem
          title="Puissance"
          value={`${formatNumber(simulationResults.installedCapacity)} kWc`}
          warning={simulationResults.warnings.installedCapacity}
        />
      </SummarySection>
      <SavingsSummary
        billReductionRatio={billReductionRatio}
        firstYearSavings={firstYearSavings.totalSavings}
        firstYearBill={yearlyBillsWithoutPanels[0].amount}
      />
      <AutoConsumptionSummary
        yearlyProduction={estimatedYearlyProduction}
        yearlyConsumption={yearlyConsumption}
        autoConsumptionPercent={autoConsumptionPercent}
        hasBattery={hasBattery}
      />
    </>
  )
}
