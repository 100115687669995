import { Divider, Stack, Text } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"

import { BatteryInstallationWithExtraWorksInput } from "@ensol/types/forms/installations/battery"

import { Section } from "@ensol/shared/components/Section"

import { NumberInput } from "@ensol/entool/components/form/NumberInput"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { ExtraWorksForm } from "@ensol/entool/pages/Installation/components/ExtraWorksForm"
import { findSelectedOption } from "@ensol/entool/utils/form/radio"
import { BatteryMaterialOptions } from "@ensol/entool/utils/installations/battery"

type Props = {
  form: UseFormReturnType<BatteryInstallationWithExtraWorksInput>
  batteryOptions: BatteryMaterialOptions
}

export const SettingsSection = ({ form, batteryOptions }: Props) => {
  const availableMountingTypeOptions = findSelectedOption(
    form.values.type,
    batteryOptions,
  )?.metadata?.mountingTypeOptions

  return (
    <Section title="Paramètres" isCollapsible>
      <Stack gap={32}>
        <Stack gap={12}>
          <Text fw={600} fz="lg">
            Quantité
          </Text>
          <NumberInput
            min={1}
            step={1}
            w="200"
            {...form.getInputProps("count")}
          />
        </Stack>
        <Divider />
        {availableMountingTypeOptions && (
          <Stack gap={12}>
            <Text fw={600} fz="lg">
              Montage
            </Text>
            <RadioGroup
              options={availableMountingTypeOptions}
              {...form.getInputProps("mountingType")}
            />
          </Stack>
        )}
        <Divider />
        <ExtraWorksForm {...form.getInputProps("extraWorks")} />
      </Stack>
    </Section>
  )
}
