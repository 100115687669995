import { Divider, SimpleGrid, Stack } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"

import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { GeneralUpdateHouseInput } from "@ensol/types/forms/houses"

import { AddressAutocomplete } from "@ensol/shared/components/AddressAutocomplete"
import { Section } from "@ensol/shared/components/Section"
import { getCoords } from "@ensol/shared/entities/houses/coords"

import { Map } from "@ensol/entool/components/Map"
import { Field } from "@ensol/entool/components/form/Field"
import { HasLinkyField } from "@ensol/entool/components/form/House/HasLinkyField"
import { NumberInput } from "@ensol/entool/components/form/NumberInput"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { booleanOptions } from "@ensol/entool/utils/form/options"
import {
  CURRENT_TYPE_OPTIONS,
  FLOORS_OPTIONS,
  ROOF_FRAMING_MATERIALS_OPTIONS,
  ROOF_TYPE_OPTIONS,
} from "@ensol/entool/utils/house/options"

type Props = {
  form: UseFormReturnType<GeneralUpdateHouseInput>
  panelsLayout?: InstallationsResponses.Installation["panelsLayout"]
}

export const HouseDescriptionForm = ({ form, panelsLayout }: Props) => (
  <>
    <Section title="Localisation">
      <Stack w="100%">
        <Map
          mapId="house-description-map"
          coords={getCoords(form.values)}
          panelsLayout={panelsLayout}
          onPlacePinPoint={(coords) => form.setValues(coords)}
          withSunshineRastersSwitch
          style={{ height: 320 }}
        />
        <SimpleGrid cols={2}>
          <Field name="Latitude">
            <NumberInput
              flex={1}
              suffix="°"
              disabled
              isNullable={false}
              {...form.getInputProps("lat")}
            />
          </Field>
          <Field name="Longitude">
            <NumberInput
              flex={1}
              suffix="°"
              disabled
              isNullable={false}
              {...form.getInputProps("lng")}
            />
          </Field>
        </SimpleGrid>
        <Field name="Adresse" w="100%">
          <AddressAutocomplete
            googleApiKey={import.meta.env.VITE_GOOGLE_API_KEY}
            w="100%"
            defaultValue={form.values.address}
            handleSelectOption={(placeDetails) => form.setValues(placeDetails)}
          />
        </Field>
      </Stack>
    </Section>
    <Section title="Énergie">
      <SimpleGrid cols={2}>
        <Field name="Type de courant">
          <RadioGroup
            options={CURRENT_TYPE_OPTIONS}
            data-test="currentType"
            {...form.getInputProps("currentType")}
          />
        </Field>
        <HasLinkyField form={form} />
      </SimpleGrid>
    </Section>
    <Section title="Toit">
      <SimpleGrid cols={2}>
        <Field name="Accès aux combles possible ?">
          <RadioGroup
            options={booleanOptions}
            {...form.getInputProps("isAtticAccessible")}
          />
        </Field>
        <Field name="Matériau de la charpente">
          <RadioGroup
            options={ROOF_FRAMING_MATERIALS_OPTIONS}
            {...form.getInputProps("roofFramingMaterial")}
          />
        </Field>
      </SimpleGrid>
      <Divider my={20} />
      <Field name="Couverture">
        <RadioGroup
          options={ROOF_TYPE_OPTIONS}
          OptionsWrapper={({ children }) => (
            <SimpleGrid cols={2}>{children}</SimpleGrid>
          )}
          {...form.getInputProps("roofType")}
        />
      </Field>
    </Section>
    <Section title="Caractéristiques">
      <SimpleGrid cols={2}>
        <Field name="Maison individuelle ?">
          <RadioGroup
            options={booleanOptions}
            {...form.getInputProps("isIndividualHouse")}
          />
        </Field>
        <Field name="Année de construction">
          <NumberInput
            w={200}
            {...form.getInputProps("constructionYear")}
            isNullable={false}
          />
        </Field>
      </SimpleGrid>
      <Divider my={20} />
      <SimpleGrid cols={2}>
        <Field name="Nombre d'étages">
          <RadioGroup
            options={FLOORS_OPTIONS}
            {...form.getInputProps("floors")}
          />
        </Field>
        <Field name="Surface">
          <NumberInput w={200} suffix="m²" {...form.getInputProps("surface")} />
        </Field>
      </SimpleGrid>
      <Divider my={20} />
      <SimpleGrid cols={2}>
        <Field name="Présence d'amiante ?">
          <RadioGroup
            options={booleanOptions}
            {...form.getInputProps("hasAsbestos")}
          />
        </Field>
        <Field name="Zone ABF ?">
          <RadioGroup
            options={booleanOptions}
            {...form.getInputProps("isHistoricBuilding")}
          />
        </Field>
      </SimpleGrid>
    </Section>
  </>
)
