import { SimpleGrid, Stack } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { KizeoProjectReportType } from "@ensol/types/entities/project/kizeoProjectReport"
import {
  TechnicalOverviewInput,
  technicalOverviewSchema,
} from "@ensol/types/forms/projects/technicalOverview"

import { Section } from "@ensol/shared/components/Section"
import { EquipmentType } from "@ensol/shared/entities/houses/equipments"
import { getKizeoProjectReportPathsFromType } from "@ensol/shared/entities/projects/kizeoProjectReport"
import { formatFullName } from "@ensol/shared/utils/format"

import { SubSection } from "@ensol/entool/components/SubSection"
import { MultiFileField } from "@ensol/entool/components/form/File/MultiFileField"
import { useUpdateProcessDataMutation } from "@ensol/entool/queries/projects"
import { findSelectedOption } from "@ensol/entool/utils/form/radio"
import { useDebounce } from "@ensol/entool/utils/helpers/useDebounce"
import { POTENTIAL_SHADINGS_OPTIONS } from "@ensol/entool/utils/house/options"
import { getInitialValues } from "@ensol/entool/utils/initialValues"

import {
  AdditionalInformation,
  AdditionalInformationConfig,
} from "../AdditionalInformation"
import { DocumentsSubSection } from "../DocumentsSubSection"

import { Notes } from "./Notes"
import { PanelsLayout } from "./PanelsLayout"
import { RequiredChanges } from "./RequiredChanges"
import { RequiredWorks } from "./RequiredWorks"

type Props = {
  project: ProjectResponses.ProjectTechnicalOverview
}

const DOCUMENTS_CONFIG = [
  {
    getFilePaths: (project: ProjectResponses.ProjectTechnicalOverview) =>
      project.technicalVisitK2ReportsPath,
    fileName: "Rapport K2 / ESDEC VT",
  },
  {
    getFilePaths: (project: ProjectResponses.ProjectTechnicalOverview) =>
      project.technicalVisitClientReportPath
        ? [project.technicalVisitClientReportPath]
        : [],
    fileName: "CR VT Client",
  },
  {
    getFilePaths: (project: ProjectResponses.ProjectTechnicalOverview) => [
      ...getKizeoProjectReportPathsFromType(
        project.kizeoProjectReports,
        KizeoProjectReportType.TECHNICAL_VISIT,
      ),
      ...project.technicalVisitReportsPath,
    ],
    fileName: "CR VT Interne",
  },
  {
    getFilePaths: (project: ProjectResponses.ProjectTechnicalOverview) => [
      ...getKizeoProjectReportPathsFromType(
        project.kizeoProjectReports,
        KizeoProjectReportType.INSTALLATION,
      ),
      ...(project.installationReportPath
        ? [project.installationReportPath]
        : []),
    ],
    fileName: "CR Installation",
  },
  {
    getFilePaths: (project: ProjectResponses.ProjectTechnicalOverview) =>
      getKizeoProjectReportPathsFromType(
        project.kizeoProjectReports,
        KizeoProjectReportType.CUSTOMER_SERVICE,
      ),
    fileName: "CR SAV",
  },
  {
    getFilePaths: (project: ProjectResponses.ProjectTechnicalOverview) =>
      getKizeoProjectReportPathsFromType(
        project.kizeoProjectReports,
        KizeoProjectReportType.AUDIT,
      ),
    fileName: "CR Audit",
  },
  {
    getFilePaths: (project: ProjectResponses.ProjectTechnicalOverview) =>
      project.hardwareOrderFormPath ? [project.hardwareOrderFormPath] : [],
    fileName: "Bon de commande",
  },
]

const ADDITIONAL_INFORMATIONS_CONFIG: AdditionalInformationConfig[] = [
  {
    condition: (house) =>
      house.existingInstallationCapacity !== null ||
      house.existingInstallationContract !== null ||
      house.existingInstallationAge !== null,
    label: "Panneaux existants ?",
    getValue: () => "Oui",
    getType: () => "alert",
  },
  {
    condition: (house) => house.isPowerLineNearby === false,
    label: "Ligne électrique",
    getValue: () => "Pas à moins de 5m de la toiture",
    getType: () => "alert",
  },
  {
    condition: (house) =>
      house.potentialShading !== null && house.potentialShading !== "no",
    label: "Ombrage",
    getValue: (house) =>
      findSelectedOption(house.potentialShading, POTENTIAL_SHADINGS_OPTIONS)
        ?.label ?? "Oui",
    getType: () => "alert",
  },
  {
    condition: (house) => house.isAtticAccessible !== null,
    label: "Accès aux combles",
    getValue: (house) => (house.isAtticAccessible ? "Oui" : "Non"),
    getType: (house) => (house.isAtticAccessible ? "info" : "alert"),
  },
  {
    condition: (house) => !house.equipments.includes(EquipmentType.LINKY),
    label: "Compteur Linky",
    getValue: () => "Non",
    getType: () => "alert",
  },
  {
    condition: (house) => house.floors !== null,
    label: "Nombre d'étages",
    getValue: (house) => `R${house.floors}`,
    getType: () => "info",
  },
]

export const ProjectSection = ({ project }: Props) => {
  const { mutateAsync: updateProjectTechnicalOverview } =
    useUpdateProcessDataMutation("technical-overview", project.id)

  const debouncedUpdateProjectTechnicalOverview = useDebounce(
    updateProjectTechnicalOverview,
    250,
  )

  const initialValues = getInitialValues(technicalOverviewSchema, project)
  const form = useForm<TechnicalOverviewInput>({
    validateInputOnChange: true,
    validate: zodResolver(technicalOverviewSchema),
    initialValues,
    onValuesChange: async (values) =>
      debouncedUpdateProjectTechnicalOverview(values),
  })
  return (
    <Section title="Projet" isCollapsible isCollapsed={true}>
      <SimpleGrid
        spacing={24}
        cols={{ base: 1, sm: 2 }}
        style={{ alignItems: "flex-start" }}
      >
        <Stack gap={24}>
          <PanelsLayout project={project} />
          <SubSection title="Informations supplémentaires">
            <AdditionalInformation
              config={ADDITIONAL_INFORMATIONS_CONFIG}
              house={project.installation.house}
            />
          </SubSection>
          {project.technicalVisitRequiredWorks.length > 0 && (
            <RequiredWorks
              requiredWorks={project.technicalVisitRequiredWorks}
            />
          )}
          {project.technicalVisitRequiredChanges.length > 0 && (
            <RequiredChanges
              requiredChanges={project.technicalVisitRequiredChanges}
            />
          )}
          <DocumentsSubSection project={project} config={DOCUMENTS_CONFIG} />
          <SubSection title="Bibliothèque multimédia">
            <MultiFileField
              previewProps={{
                fileName: formatFullName(project.installation.house.client),
              }}
              inputProps={{
                accept: ["image/png", "image/jpeg", "image/heic", "video/mp4"],
              }}
              {...form.getInputProps("technicalOverviewPhotos")}
            />
          </SubSection>
        </Stack>
        <Notes project={project} form={form} />
      </SimpleGrid>
    </Section>
  )
}
