import { CurrentType } from "@ensol/shared/material/currentType"

type ElectricalSafeGuardPrices = {
  fixedPrice: number
  capacityVariablePrice: number
  panelVariablePrice: number
}

export const ELECTRICAL_SAFE_GUARDS_PRICES: Record<
  CurrentType,
  Record<"central" | "micro", ElectricalSafeGuardPrices>
> = {
  [CurrentType.SINGLE_PHASE]: {
    central: {
      fixedPrice: 200,
      capacityVariablePrice: 46,
      panelVariablePrice: 0,
    },
    micro: {
      fixedPrice: 130,
      capacityVariablePrice: 22,
      panelVariablePrice: 20,
    },
  },
  [CurrentType.TRI_PHASE]: {
    central: {
      fixedPrice: 509,
      capacityVariablePrice: 46,
      panelVariablePrice: 0,
    },
    micro: {
      fixedPrice: 600,
      capacityVariablePrice: 0,
      panelVariablePrice: 30,
    },
  },
}
