import { Pagination, Stack, Table } from "@mantine/core"
import _ from "lodash"
import { useState } from "react"

import { ClientResponses } from "@ensol/types/endpoints/clients"
import { ListClientsFilters } from "@ensol/types/forms/clients"

import { SortedTh } from "@ensol/entool/components/table/SortedTh"

import { ClientRow } from "./ClientRow"

type Props = {
  clients: ClientResponses.ClientsList
  orderBy: NonNullable<ListClientsFilters["orderBy"]>
  onOrderChange: (
    orderBy: NonNullable<ListClientsFilters["orderBy"]>["field"],
  ) => void
}

const PAGE_SIZE = 20

export const ClientsList = ({ clients, orderBy, onOrderChange }: Props) => {
  const [activePage, setPage] = useState(1)

  return (
    <Stack pb="24" data-test="clientsList">
      <Table highlightOnHover>
        <Table.Thead>
          <Table.Tr>
            <SortedTh
              display={{ base: "none", md: "table-cell" }}
              reversed={
                orderBy.field === "createdAt" && orderBy.direction === "desc"
              }
              sorted={orderBy.field === "createdAt"}
              onSort={() => {
                onOrderChange("createdAt")
              }}
            >
              Date de création
            </SortedTh>
            <Table.Th>Nom</Table.Th>
            <Table.Th>Email</Table.Th>
            <Table.Th display={{ base: "none", md: "table-cell" }}>
              Téléphone
            </Table.Th>
            <Table.Th display={{ base: "none", md: "table-cell" }}>
              # Installations
            </Table.Th>
            <Table.Th>Contact</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {_.slice(
            clients,
            (activePage - 1) * PAGE_SIZE,
            activePage * PAGE_SIZE,
          ).map((client) => (
            <ClientRow
              client={client}
              installationsCount={client._count.installations}
              key={client.id}
            />
          ))}
        </Table.Tbody>
      </Table>
      <Pagination
        value={activePage}
        onChange={setPage}
        total={Math.ceil(clients.length / PAGE_SIZE)}
      />
    </Stack>
  )
}
