import _ from "lodash"

import { EnergyResponses } from "@ensol/types/endpoints/energy"
import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import {
  ProductionDataSource,
  ConsumptionDataSource,
} from "@ensol/types/simulation"

import { DEFAULT_HOUSE_CONSTRUCTION_YEAR } from "@ensol/shared/entities/houses"
import { MountingType } from "@ensol/shared/entities/installations/batteries"
import {
  computePanelsCapacity,
  computeYearlyConsumption,
  computeYearlyEnergyPrice,
} from "@ensol/shared/entities/installations/energy"
import { computeEnergyResellPrice } from "@ensol/shared/entities/installations/resell"
import { EvChargerSubsidyType } from "@ensol/shared/entities/installations/subsidies/evCharger"
import { computePhotovoltaicSubsidyType } from "@ensol/shared/entities/installations/subsidies/photovoltaic"
import {
  BatteryType,
  findRecommendedBatteryType,
  getBattery,
} from "@ensol/shared/material/batteries"
import { CurrentType } from "@ensol/shared/material/currentType"
import { EvChargerType } from "@ensol/shared/material/evCharger"
import {
  getInverter,
  InverterType,
} from "@ensol/shared/material/photovoltaic/inverters"
import { OptimizerType } from "@ensol/shared/material/photovoltaic/optimizers"
import { PanelType } from "@ensol/shared/material/photovoltaic/panels"
import { RoofType } from "@ensol/shared/material/roofType"

const DEFAULT_MONTHLY_BILL_IN_EUROS = 120

const DEFAULT_PANELS_COUNT = 6
export const DEFAULT_PHOTOVOLTAIC_INSTALLATION = {
  panelType: PanelType.DUALSUN_500_TOPCON,
  inverterType: InverterType.HUAWEI_SUN_2000_3_KTL_L_1,
  optimizerType: OptimizerType.HUAWEI_SUN_2000_600,
  optimizerCount: DEFAULT_PANELS_COUNT,
  extraWorks: [],
}

export const getDefaultInstallationBody = async (
  isUpsell: boolean,
  house: HousesResponses.House<{
    include: { roofSections: true; installations: true }
  }>,
  houseSetup?: InstallationsResponses.HouseSetup,
) => {
  const properties = houseSetup
    ? {
        productionDataSource: houseSetup.productionDataSource,
        consumptionDataSource: houseSetup.consumptionDataSource,
        monthlyBill: houseSetup.monthlyBill,
        yearlyConsumption: houseSetup.yearlyConsumption,
        electricityBuyPrice: houseSetup.electricityBuyPrice,
      }
    : {
        productionDataSource: ProductionDataSource.PVGIS,
        consumptionDataSource: ConsumptionDataSource.BILL,
        monthlyBill: DEFAULT_MONTHLY_BILL_IN_EUROS,
        yearlyConsumption: computeYearlyConsumption(
          DEFAULT_MONTHLY_BILL_IN_EUROS,
        ),
        electricityBuyPrice: computeYearlyEnergyPrice({
          yearlyConsumption: computeYearlyConsumption(
            DEFAULT_MONTHLY_BILL_IN_EUROS,
          ),
          monthlyBill: DEFAULT_MONTHLY_BILL_IN_EUROS,
        }),
      }

  return {
    ...properties,
    manualAutoConsumptionPercent: 0,
    computedAutoConsumptionPercent: 0,
    monthlyConsumptions: houseSetup?.monthlyConsumptions ?? [],
    productsCount: 1,
    photovoltaicInstallation: isUpsell
      ? null
      : getDefaultPhotovoltaicInstallation({
          house,
        }),
    name: `Offre ${house.installations.length + 1}`,
    houseId: house.id,
    clientId: house.clientId,
    batteryInstallation: null,
    evChargerInstallation: null,
    controlDeviceInstallations: [],
    isUpsell,
  }
}

export const getDefaultPhotovoltaicInstallation = ({
  house,
  panelsCount,
}: {
  house: HousesResponses.House<{ include: { roofSections: true } }>
  panelsCount?: number
}) => {
  const subsidyType = computePhotovoltaicSubsidyType(house)
  const panelType = DEFAULT_PHOTOVOLTAIC_INSTALLATION.panelType

  return {
    ...DEFAULT_PHOTOVOLTAIC_INSTALLATION,
    subsidyType,
    energyResellPrice: computeEnergyResellPrice({
      subsidyType,
      installedCapacity: computePanelsCapacity({
        panelType,
        panelsCount: panelsCount ?? DEFAULT_PANELS_COUNT,
      }),
    }),
    optimizerCount: panelsCount ?? DEFAULT_PANELS_COUNT,
    panelsCount: panelsCount ?? DEFAULT_PANELS_COUNT,
    roofSectionsWithPanels: _.sortBy(house.roofSections, (roofSection) =>
      Math.abs(roofSection.orientation - 180),
    ).map((section, index) => ({
      roofSection: section,
      panelsCount: index === 0 ? DEFAULT_PANELS_COUNT : 0,
    })),
  }
}

export const getDefaultExtraPanelsInstallation = (
  onSitePhotovoltaicInstallation?: InstallationsResponses.PhotovoltaicInstallation | null,
): InstallationsResponses.PhotovoltaicInstallation | null => {
  if (!onSitePhotovoltaicInstallation) {
    return null
  }

  return {
    subsidyType: null,
    energyResellPrice: 0,
    panelsCount: 0,
    optimizerCount: null,
    roofSectionsWithPanels: [],
    panelType: onSitePhotovoltaicInstallation.panelType,
    inverterType: onSitePhotovoltaicInstallation.inverterType,
    optimizerType: onSitePhotovoltaicInstallation.optimizerType,
    extraWorks: [],
  }
}

const DEFAULT_BATTERY_INSTALLATION = {
  type: BatteryType.ENPHASE_5P,
  count: 1,
  mountingType: MountingType.ON_FLOOR,
  extraWorks: [],
}

export const getDefaultBatteryInstallation = (
  inverterType?: InverterType,
  batteryStats?: EnergyResponses.BatteryStats,
): InstallationsResponses.BatteryInstallation => {
  if (!inverterType || !batteryStats) {
    return DEFAULT_BATTERY_INSTALLATION
  }

  const recommendedBattery = findRecommendedBatteryType({
    batteryStats,
    inverterBrand: getInverter(inverterType).brand,
  })
  return {
    ...DEFAULT_BATTERY_INSTALLATION,
    mountingType: getBattery(recommendedBattery).mountingTypes[0],
    type: recommendedBattery,
  }
}

export const DEFAULT_EV_CHARGER_INSTALLATION = {
  type: EvChargerType.WALLBOX_PULSAR_PLUS_SOCKET_MONO,
  additionalCost: 0,
  subsidyType: EvChargerSubsidyType.TAX_REDUCTION,
  extraWorks: [],
}

export const DEFAULT_HOUSE_CONFIG = {
  constructionYear: DEFAULT_HOUSE_CONSTRUCTION_YEAR,
  surface: null,
  floors: null,
  isIndividualHouse: null,
  hasAsbestos: null,
  isHistoricBuilding: null,
  isAtticAccessible: null,
  roofFramingMaterial: null,
  currentType: CurrentType.SINGLE_PHASE,
  roofType: RoofType.OTHER,
  pdl: null,
  equipments: [],
}
