import { Stack } from "@mantine/core"
import { useParams } from "react-router-dom"

import { BreadCrumb } from "@ensol/shared/components/BreadCrumb"
import { CONTENT_PADDING_IN_PX } from "@ensol/shared/styles/constants"
import { formatFullName } from "@ensol/shared/utils/format"

import { ProjectHeader } from "@ensol/entool/components/entities/Project/ProjectHeader"
import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { useGetProjectTechnicalOverviewQuery } from "@ensol/entool/queries/projects/technicalOverview"

import { ProjectTechnicalOverview } from "./ProjectTechnicalOverview"

export const ProjectTechnicalOverviewPage = () => {
  const { projectId } = useParams()
  const projectQuery = useGetProjectTechnicalOverviewQuery(projectId!)

  return (
    <Stack flex="1" gap={0} bg="gray.0">
      <QueryWrapper query={projectQuery}>
        {({ data: project }) => (
          <>
            <ProjectHeader
              project={project}
              BreadCrumbs={[
                <BreadCrumb
                  c={
                    project.installation.house.client.isComplexCase
                      ? "red.3"
                      : "inherit"
                  }
                  key="clientName"
                  label={formatFullName(project.installation.house.client)}
                />,
              ]}
            />
            <ProjectTechnicalOverview
              project={project}
              p={CONTENT_PADDING_IN_PX}
            />
          </>
        )}
      </QueryWrapper>
    </Stack>
  )
}
