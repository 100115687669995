import "@mantine/core/styles.css"
import "@mantine/dates/styles.css"
import "@mantine/notifications/styles.css"
import "@mantine/carousel/styles.css"
import "reactflow/dist/style.css"
import "@blocknote/mantine/style.css"

import "dayjs/locale/fr"

import { MantineProvider } from "@mantine/core"
import { DatesProvider } from "@mantine/dates"
import { ModalsProvider } from "@mantine/modals"
import { Notifications } from "@mantine/notifications"
import { QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { extend, locale } from "dayjs"
import CustomParseFormat from "dayjs/plugin/customParseFormat"
import LocalizedFormat from "dayjs/plugin/localizedFormat"
import utc from "dayjs/plugin/utc"
import { HelmetProvider } from "react-helmet-async"
import { pdfjs } from "react-pdf"
import { RouterProvider } from "react-router-dom"

import { NotificationsProvider } from "@ensol/shared/components/layout/Notifications/Provider"
import { theme } from "@ensol/shared/styles/theme"

import { queryClient } from "@ensol/entool/backend/queryClient"
import { AutoReload } from "@ensol/entool/components/AutoReload"
import { ProjectSearchProvider } from "@ensol/entool/components/entities/Project/ProjectSearchProvider"
import { MenuProvider } from "@ensol/entool/components/layout/Menu/Provider"
import { router } from "@ensol/entool/pages/router"

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url,
).toString()

extend(LocalizedFormat)
extend(CustomParseFormat)
extend(utc)
locale("fr")

const App = () => (
  <MantineProvider theme={theme}>
    <DatesProvider settings={{ locale: "fr" }}>
      <ModalsProvider>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            <Notifications />
            <AutoReload>
              <MenuProvider>
                <NotificationsProvider>
                  <ProjectSearchProvider>
                    <RouterProvider router={router} />
                  </ProjectSearchProvider>
                </NotificationsProvider>
              </MenuProvider>
            </AutoReload>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </HelmetProvider>
      </ModalsProvider>
    </DatesProvider>
  </MantineProvider>
)

export default App
