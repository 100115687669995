import { UseFormReturnType } from "@mantine/form"
import _ from "lodash"

import { EquipmentType } from "@ensol/shared/entities/houses/equipments"

import { Field } from "@ensol/entool/components/form/Field"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { booleanOptions } from "@ensol/entool/utils/form/options"

type Props<Values> = {
  form: UseFormReturnType<Values>
}

export const HasLinkyField = <Values extends { equipments: EquipmentType[] }>({
  form,
}: Props<Values>) => {
  const equipments = form.values.equipments
  const updateEquipments = form.getInputProps("equipments").onChange

  return (
    <Field name="Compteur Linky ?">
      <RadioGroup
        options={booleanOptions}
        value={equipments.includes(EquipmentType.LINKY)}
        onChange={(value) => {
          if (value === true) {
            updateEquipments(_.uniq([...equipments, EquipmentType.LINKY]))
          } else {
            updateEquipments(
              equipments.filter(
                (equipment) => equipment !== EquipmentType.LINKY,
              ),
            )
          }
        }}
      />
    </Field>
  )
}
