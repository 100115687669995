import { useMediaQuery } from "@mantine/hooks"

import { ProjectStatus } from "@ensol/shared/entities/projects/statuses"
import { devices } from "@ensol/shared/styles/theme"

import { useGetProjectsOperationsOverviewQuery } from "@ensol/entool/queries/projects/overview"
import { useProjectsSearch } from "@ensol/entool/utils/projects/search"

export const useProjectsOperationsOverview = (
  currentTab: string,
  showOnlyActionNeeded: boolean,
) => {
  const isMobile = useMediaQuery(devices.sm)
  const { search, operationsOwnerIds, installerIds } = useProjectsSearch()
  const statusesFilters = isProjectStatus(currentTab)
    ? [{ status: currentTab, isIncluded: true }]
    : [
        { status: ProjectStatus.CREATED, isIncluded: false },
        ...(search === ""
          ? [
              { status: ProjectStatus.DONE, isIncluded: false },
              { status: ProjectStatus.ABORTED, isIncluded: false },
            ]
          : []),
      ]

  return useGetProjectsOperationsOverviewQuery({
    filters: {
      search,
      operationsOwnerIds,
      installerIds,
      statuses: statusesFilters,
      onlyActionNeeded: showOnlyActionNeeded,
    },
    enabled: isMobile !== undefined && (isMobile ? search !== "" : true),
  })
}

const isProjectStatus = (tab: string): tab is ProjectStatus =>
  tab in ProjectStatus
