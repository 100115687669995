import { Popover as MantinePopover, Text } from "@mantine/core"
import { forwardRef, ReactNode, Ref } from "react"

type Props = {
  title?: string
  children: ReactNode
}

// eslint-disable-next-line react/display-name
export const PopoverContent = forwardRef(
  ({ title, children }: Props, ref: Ref<HTMLDivElement>) => (
    <MantinePopover.Dropdown
      ref={ref}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        maxWidth: "90dvw",
      }}
    >
      {title && <Text size="md">{title}</Text>}
      {children}
    </MantinePopover.Dropdown>
  ),
)
