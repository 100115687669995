import { PhotovoltaicCostsBuilder } from "@ensol/shared/entities/installations/costs/products/PhotovoltaicCostsBuilder"
import { computePanelsCapacity } from "@ensol/shared/entities/installations/energy"
import { roundDecimalNumber } from "@ensol/shared/utils/format"

import {
  PhotovoltaicSubsidyAmountInput,
  PhotovoltaicSubsidyEligibilityInput,
} from "."

export const isToulouseMetropolisEligible = ({
  postcode,
}: PhotovoltaicSubsidyEligibilityInput): boolean => postcode.startsWith("31")

type InstallationPowerInKWc = number
type SubsidyCapInEuros = number

const MAX_SUBSIDY_CAP = 15000

const POWER_SUBSIDY_CAPS: Record<InstallationPowerInKWc, SubsidyCapInEuros> = {
  0: 0,
  1: 550,
  2: 1100,
  3: 1650,
  4: 2117,
  5: 2563,
  6: 2975,
  7: 3354,
  8: 3700,
  9: 4050,
  10: 4444,
  11: 4828,
  12: 5200,
  13: 5561,
  14: 5911,
  15: 6250,
  16: 6578,
  17: 6894,
  18: 7200,
  19: 7494,
  20: 7778,
  21: 8050,
  22: 8311,
  23: 8561,
  24: 8800,
  25: 9028,
  26: 9244,
  27: 9450,
  28: 9644,
  29: 9828,
  30: 10000,
  31: 10161,
  32: 10311,
  33: 10450,
  34: 10578,
  35: 10694,
  36: 10800,
  37: 11071,
  38: 11341,
  39: 11609,
  40: 11875,
  41: 12140,
  42: 12403,
  43: 12665,
  44: 12925,
  45: 13184,
  46: 13441,
  47: 13696,
  48: 13950,
  49: 14202,
  50: 14453,
  51: 14702,
  52: 14950,
  53: MAX_SUBSIDY_CAP,
}

export const computeToulouseMetropolisSubsidy = ({
  photovoltaicInstallation,
  house,
  totalDiscount,
}: PhotovoltaicSubsidyAmountInput): number => {
  const costsBuilder = new PhotovoltaicCostsBuilder(
    photovoltaicInstallation,
    house,
    totalDiscount,
    false, // It cannot be upsell here as upsell cannot have subsidies
  )
  const installationCost = costsBuilder.computeInstallationCost()
  const vatRate = costsBuilder.computeVatRate()

  const power = computePanelsCapacity(photovoltaicInstallation)
  const floorPower = Math.floor(power)
  const subsidyCap = Number.isInteger(power)
    ? POWER_SUBSIDY_CAPS[power]
    : POWER_SUBSIDY_CAPS[floorPower] +
      (POWER_SUBSIDY_CAPS[floorPower + 1] - POWER_SUBSIDY_CAPS[floorPower]) *
        (power % 1)

  const installationCostWithDiscountBeforeTax =
    (installationCost - totalDiscount) / (1 + vatRate)

  return Math.min(
    !subsidyCap ? MAX_SUBSIDY_CAP : subsidyCap,
    roundDecimalNumber(installationCostWithDiscountBeforeTax * 0.25),
  )
}
