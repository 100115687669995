import {
  EventStreamContentType,
  fetchEventSource,
} from "@microsoft/fetch-event-source"
import { useMutation } from "@tanstack/react-query"
import _ from "lodash"
import { useState, useEffect, useRef } from "react"

import {
  Notification,
  UpdateNotificationStatusInput,
} from "@ensol/types/entities/notifications"

import { httpClient } from "@ensol/entool/backend/axios"
import { clerk } from "@ensol/entool/backend/clerk"

export const useNotificationsQuery = () => {
  const [notifications, setNotifications] = useState<Notification[]>(
    new Array<Notification>(),
  )
  const abortControllerRef = useRef<AbortController | null>(null)

  useEffect(() => {
    const disconnect = () => abortControllerRef.current?.abort()

    const connect = async () => {
      abortControllerRef.current = new AbortController()
      const token = await clerk.session?.getToken()
      let retryCount = 0

      const reconnect = async () => {
        disconnect()
        return await connect()
      }

      await fetchEventSource(`${import.meta.env.VITE_API_URL}/notifications`, {
        method: "GET",
        signal: abortControllerRef.current.signal,
        headers: {
          Accept: "text/event-stream",
          Authorization: `Bearer ${token}`,
        },
        onopen: async (response) => {
          if (
            response.ok &&
            response.headers
              .get("content-type")
              ?.includes(EventStreamContentType)
          ) {
            return
          }

          // This means token has expired, so we need to fetch a new one
          if (response.status === 401) {
            return await reconnect()
          }

          throw new Error("Unexpected response from server")
        },
        onmessage: (event) => {
          const { type, data } = JSON.parse(event.data)

          if (type === "init") {
            setNotifications(data)
          }

          if (type === "add") {
            setNotifications((prev) =>
              _.uniqBy([...prev, data], ({ id }) => id),
            )
          }

          if (type === "update") {
            setNotifications((prev) => [
              ...prev.filter(({ id }) => id !== data.id),
              data,
            ])
          }
        },
        onclose: () => reconnect(),
        onerror: (error) => {
          disconnect()

          if (retryCount < 3) {
            retryCount++
            return
          }

          console.error("Exceeded max retries, failed with error", error)
          throw error
        },
      })
    }
    connect()

    return () => disconnect()
  }, [])

  return { notifications }
}

export const useUpdateNotificationStatusMutation = () =>
  useMutation<void, string, UpdateNotificationStatusInput>({
    mutationFn: async ({ notificationId, status }) =>
      await httpClient.put(`notifications/${notificationId}/status`, {
        status,
      }),
  })
