import { Stack } from "@mantine/core"
import { Helmet } from "react-helmet-async"

import { ProjectAlertCategory } from "@ensol/types/entities/project"

import { PageLayout } from "@ensol/shared/components/layout/PageLayout"

import { InfoBox } from "@ensol/entool/components/InfoBox"
import { ProjectAccordion } from "@ensol/entool/components/entities/Project/ProjectAccordion"
import { ProjectSearch } from "@ensol/entool/components/entities/Project/ProjectSearch"
import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { useProjectsAlerts } from "@ensol/entool/pages/ProjectsAlerts/useProjectsAlerts"
import { PROJECT_SEARCHABLE_PROPS } from "@ensol/entool/utils/projects/search"

import { ProjectRow } from "./ProjectRow"
import { PROJECT_ALERT_LABEL } from "./config"

export const ProjectsAlerts = () => {
  const projectsAlertsQuery = useProjectsAlerts()

  return (
    <PageLayout
      title="Alertes"
      contentContainerProps={{ px: 20, mih: "unset" }}
    >
      <Helmet>
        <title>Alertes | Entool</title>
      </Helmet>
      <Stack flex="1">
        <ProjectSearch
          filteredProps={[
            PROJECT_SEARCHABLE_PROPS.search,
            PROJECT_SEARCHABLE_PROPS.installerIds,
          ]}
        />
        <QueryWrapper query={projectsAlertsQuery}>
          {({ data }) => (
            <ProjectAccordion
              data={data.map((projectAlerts) => ({
                id: projectAlerts.category,
                title: PROJECT_ALERT_LABEL[projectAlerts.category],
                items: projectAlerts.alerts,
              }))}
              render={(items, id) => (
                <>
                  {items.length > 0 ? (
                    items.map((project) => (
                      <ProjectRow key={project.id} project={project} />
                    ))
                  ) : id === ProjectAlertCategory.PENDING ? (
                    <InfoBox message="Aucune nouvelle alerte  🎉" />
                  ) : (
                    <InfoBox message="Aucun SAV en cours" />
                  )}
                </>
              )}
            />
          )}
        </QueryWrapper>
      </Stack>
    </PageLayout>
  )
}
