import { SegmentedControl, Stack, Tabs } from "@mantine/core"
import dayjs, { Dayjs } from "dayjs"
import { useEffect, useState } from "react"

import { TimeFrame } from "@ensol/types/reports"

import { Section } from "@ensol/shared/components/Section"
import { formatDate, ISO_DATE_FORMAT } from "@ensol/shared/utils/format"

type Props = {
  title: string
  isLoading: boolean
  timeFrame?: TimeFrame
  ReportComponent: React.ComponentType<{ startDate: Date; endDate: Date }>
}

const getMonthOptions = (timeFrame: TimeFrame) => {
  const startDate = dayjs.utc(timeFrame.startDate)
  const endDate = dayjs.utc(timeFrame.endDate)

  return [
    ...Array(Math.round(endDate.diff(startDate, "month", true))).keys(),
  ].map((index) => {
    const option = startDate.add(index, "month")

    return {
      value: formatDate(option, ISO_DATE_FORMAT)!,
      label: formatDate(option, "MMM"),
    }
  })
}

export const ReportWrapper = ({
  title,
  isLoading,
  timeFrame,
  ReportComponent,
}: Props) => {
  const startDate = timeFrame ? dayjs.utc(timeFrame.startDate) : dayjs.utc()
  const [isYearlyReport, setIsYearlyReport] = useState(true)
  const [reportDate, setReportDate] = useState<Dayjs>(startDate)

  useEffect(() => {
    if (timeFrame) {
      setReportDate(dayjs.utc(timeFrame.startDate))
    }
  }, [timeFrame])

  if (isLoading) {
    return <Section title={title} isContentLoading={isLoading} mih={400} />
  }

  if (!timeFrame) {
    return null
  }

  return (
    <Section
      title={title}
      Actions={
        <SegmentedControl
          w={180}
          size="sm"
          data={[
            { value: "yearly", label: "Annuel" },
            { value: "monthly", label: "Mensuel" },
          ]}
          value={isYearlyReport ? "yearly" : "monthly"}
          onChange={(value) => {
            setIsYearlyReport(value === "yearly")
            setReportDate(startDate)
          }}
        />
      }
    >
      <Stack pos="relative">
        {!isYearlyReport && (
          <Tabs
            variant="pills"
            onChange={(value) => setReportDate(dayjs.utc(value))}
            value={formatDate(reportDate, ISO_DATE_FORMAT)}
          >
            <Tabs.List grow>
              {getMonthOptions(timeFrame).map((option) => (
                <Tabs.Tab
                  key={option.value}
                  value={option.value}
                  p="12"
                  fz="md"
                >
                  {option.label}
                </Tabs.Tab>
              ))}
            </Tabs.List>
          </Tabs>
        )}
        <ReportComponent
          startDate={reportDate.toDate()}
          endDate={
            isYearlyReport
              ? dayjs.utc(timeFrame.endDate).toDate()
              : dayjs.utc(reportDate).add(1, "month").toDate()
          }
        />
      </Stack>
    </Section>
  )
}
