import { PhotovoltaicCostsBuilder } from "@ensol/shared/entities/installations/costs/products/PhotovoltaicCostsBuilder"
import { roundDecimalNumber } from "@ensol/shared/utils/format"

import {
  PhotovoltaicSubsidyEligibilityInput,
  PhotovoltaicSubsidyAmountInput,
} from "."

export const isGreenDealEligible = ({
  postcode,
}: PhotovoltaicSubsidyEligibilityInput): boolean => postcode.startsWith("06")

export const computeGreenDealSubsidy = ({
  photovoltaicInstallation,
  house,
  totalDiscount,
}: PhotovoltaicSubsidyAmountInput): number => {
  const costsBuilder = new PhotovoltaicCostsBuilder(
    photovoltaicInstallation,
    house,
    totalDiscount,
    false, // It cannot be upsell here as upsell cannot have subsidies
  )
  const installationCost = costsBuilder.computeInstallationCost()
  const vatRate = costsBuilder.computeVatRate()

  const installationCostWithDiscountBeforeTax =
    (installationCost - totalDiscount) / (1 + vatRate)

  return Math.min(
    5000,
    roundDecimalNumber(installationCostWithDiscountBeforeTax * 0.25),
  )
}
