import { Stack } from "@mantine/core"
import dayjs, { Dayjs } from "dayjs"

import { Location } from "@ensol/types/forms/houses"
import { InstallationFormInput } from "@ensol/types/forms/installations"

import { createHatchedPattern } from "@ensol/shared/entities/installations/reports/shared"
import { colors } from "@ensol/shared/styles/colors"
import { formatNumber, roundDecimalNumber } from "@ensol/shared/utils/format"

import { Chart } from "@ensol/entool/components/Chart"
import { useGetEnergyReportQuery } from "@ensol/entool/queries/energy"

type Props = {
  slug: string
  startDate: Dayjs
  installationValues: InstallationFormInput
  coords: Pick<Location, "lat" | "lng">
}

export const YearlyEnergyGraph = ({
  slug,
  startDate,
  installationValues,
  coords,
}: Props) => {
  const { data, isPending } = useGetEnergyReportQuery({
    slug,
    startDate: startDate.toDate(),
    endDate: dayjs(startDate).add(1, "year").toDate(),
    installationValues,
    coords,
  })

  return (
    <Stack pos="relative">
      <Chart
        aspectRatio={6 / 3}
        isLoading={isPending}
        data={{
          type: "bar",
          data: {
            datasets: [
              {
                label: "Production",
                data: data?.monthlyProduction ?? [],
                backgroundColor: colors.blue[500],
                stack: "production",
              },
              {
                label: "Auto-consommation",
                data: data?.monthlyAutoConsumption ?? [],
                backgroundColor: colors.purple[500],
                stack: "consumption",
              },
              ...(installationValues.batteryInstallation !== null
                ? [
                    {
                      label: "Charge batterie",
                      data: (data?.monthlyBatteryCharge ?? []).map(
                        (batteryCharge, index) => ({
                          x: batteryCharge.x,
                          y:
                            (data ? data.monthlyAutoConsumption[index].y : 0) +
                            batteryCharge.y,
                        }),
                      ),
                      backgroundColor: createHatchedPattern({
                        stripeColor: colors.gray[200],
                        backgroundColor: colors.blue[500],
                      }),
                      stack: "consumption",
                    },
                  ]
                : []),
              {
                label: "Consommation",
                data: data?.monthlyConsumption ?? [],
                backgroundColor: colors.pink[300],
                stack: "consumption",
              },
            ],
          },
          options: {
            animation: false,
            datasets: {
              bar: {
                barPercentage: 0.85,
                categoryPercentage: 0.8,
              },
            },
            plugins: {
              tooltip: {
                enabled: true,
                backgroundColor: "white",
                titleColor: "black",
                bodyColor: "black",
                titleFont: { weight: "bold", size: 16 },
                bodyFont: { weight: "normal", size: 14 },
                padding: 16,
                cornerRadius: 6,
                displayColors: true,
                borderColor: colors.gray[200],
                borderWidth: 1,
                callbacks: {
                  title: ([{ parsed }]) =>
                    dayjs()
                      .month(parsed.x - 1)
                      .format("MMMM"),
                  label: ({ parsed }) =>
                    `${roundDecimalNumber(parsed.y / 1000, 0)} kWh`,
                },
              },
              legend: {
                display: true,
                position: "bottom",
                align: "center",
                labels: {
                  usePointStyle: true,
                  pointStyle: "circle",
                  boxWidth: 12,
                  boxHeight: 12,
                  padding: 24,
                  font: { weight: "bold", size: 14 },
                },
              },
            },
            scales: {
              y: {
                title: {
                  display: true,
                  text: "Énergie (kWh)",
                  font: { weight: "bold", size: 14 },
                  padding: 8,
                },
                stacked: false,
                border: { display: false },
                grid: { display: false },
                min: 0,
                ticks: {
                  stepSize: 100000,
                  callback: (value: string | number) =>
                    formatNumber(Number(value) / 1000),
                },
              },
              x: {
                type: "linear",
                display: true,
                border: { display: false },
                grid: { display: false },
                ticks: {
                  stepSize: 1,
                  callback: (value: string | number) =>
                    dayjs()
                      .month(Number(value) - 1)
                      .format("MMM"),
                },
              },
            },
          },
        }}
      />
    </Stack>
  )
}
