import _ from "lodash"

import { KizeoProjectReportType } from "@ensol/types/entities/project/kizeoProjectReport"
import { typedKeys } from "@ensol/types/utils"

import { HardwareDeliveryMode } from "@ensol/shared/entities/projects/delivery"
import { ProjectEventType } from "@ensol/shared/entities/projects/events"
import { FundingSource } from "@ensol/shared/entities/projects/fundingSource"
import { InstallationNewVisitReason } from "@ensol/shared/entities/projects/installation"
import {
  INTEGRATION_KITS,
  IntegrationKitType,
  REQUIRED_CHANGES,
  REQUIRED_WORKS,
} from "@ensol/shared/entities/projects/technicalVisit"

import { getOptionsFromList } from "@ensol/entool/utils/form/options"
import { GroupedOptions, Option } from "@ensol/entool/utils/form/radio"

import {
  SCHEDULING_ISSUES_MAIN_REASONS_LABEL,
  SCHEDULING_ISSUES_SUB_REASONS_GROUP_BY_MAIN,
  SchedulingIssuesMainReason,
} from "./schedulingIssues"

export const INTEGRATION_KITS_OPTIONS: Option<IntegrationKitType>[] = _.map(
  INTEGRATION_KITS,
  (integrationKit, integrationKitType) => ({
    label: integrationKit.name,
    value: integrationKitType as IntegrationKitType,
  }),
)

export const TECHNICAL_VISIT_DIFFICULTY_OPTIONS = [
  { label: "Facile", value: "easy" },
  { label: "Moyen", value: "medium" },
  { label: "Difficile", value: "hard" },
]

export const HARDWARE_DELIVERY_MODE_OPTIONS = [
  { label: "Livraison", value: HardwareDeliveryMode.ADDRESS },
  { label: "Retrait", value: HardwareDeliveryMode.PICKUP },
]

export const HARDWARE_DELIVERY_ADDRESS_OPTIONS = [
  "Ensol - 490 Avenue des templiers 13400 Aubagne",
  "Solutions30 - 571 Avenue Pierre Mendès 83340 Le Luc",
  "Domopower - 373 chemin des Gypières, Park Valrose - 13390 Auriol",
  "Sun Watt - 437 Avenue des Arlucs, 06150 Cannes",
  "EnerJCiel - 60 Avenue Émile Cotte 13220 Châteauneuf-les-Martigues",
  "Sud Nouvelle Energie - 66 rue Jean Tirolle, 30126 Saint-Laurent-des-Arbres",
  "Autosun - 345 route d’Avignon 30320 Bezouce",
  "Conseil Energie Habitat - 131 Av. de la Maximinoise, 83470 Saint-Maximin-la-Sainte-Baume",
  "EST Habitat Renovation - 49 impasse des lauriers, 83600 les Adrets l'Esterel",
  "Terra ENR - 5 All. des Glaieuls 83600 Fréjus",
  "AM PM - 311 avenue des Pujets, Saint Laurent du Vars 06700",
]

export const HARDWARE_DELIVERY_PICKUP_OPTIONS = [
  "NED - 244 Rue de la Manade 34160 Castries",
  "Alliantz Montpellier - 148 Rue Mehdi Ben Barka 34070 Montpellier",
  "Alliantz La Garde - 1523 Avenue de Draguignan 83130 La Garde",
  "Alliantz Aix - 5 Chemin de Barlatier Le Canet 13590 Meyreuil",
  "Alliantz Nîmes - 156 Av. Pavlov, 30900 Nîmes",
]

export const ENEDIS_INCOMPLETE_FILE_OPTIONS = [
  "Autres",
  "M007",
  "PDL",
  "Facture",
  "Consuel (Greendeal)",
  "Taxe foncière",
  "Certificat d'adressage",
  "Changement de puissance",
  "Infos clients",
  "Doublons",
]

export const PROJECT_EVENT_TYPES_OPTIONS = [
  { label: "Visite technique", value: ProjectEventType.TECHNICAL_VISIT },
  { label: "Installation", value: ProjectEventType.INSTALLATION },
  {
    label: "Repasse installation",
    value: ProjectEventType.INSTALLATION_NEW_VISIT,
  },
]

export const PRELIMINARY_REQUEST_REQUESTED_CHANGES_REASONS_OPTIONS = [
  "Régularisation PC",
  "DP1",
  "DP2",
  "DP3",
  "DP4",
  "DP5",
  "DP6",
  "DP11",
  "Demandes de l'ABF",
  "Existence légale",
  "Photos",
  "Autres",
]

export const PRELIMINARY_REQUEST_REQUESTED_CHANGES_OPTIONS = [
  "Pans de toiture",
  "Nombre de panneaux",
  "Disposition des panneaux",
  "Emplacement panneaux",
  "Couleurs panneaux",
]

export const PRELIMINARY_REQUEST_AWAITED_STAKEHOLDER_OPTIONS =
  getOptionsFromList(["Client", "Solteo", "Mairie"])

export const NPS_RATING_OPTIONS = getOptionsFromList([
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
])

export const REVIEW_PLATFORM_OPTIONS = getOptionsFromList([
  "Google",
  "PagesJaunes",
])

export const TECHNICAL_VISIT_REQUIRED_WORKS_OPTIONS = Object.entries(
  REQUIRED_WORKS,
).map(([value, label]) => ({
  value,
  label,
}))

export const TECHNICAL_VISIT_REQUIRED_CHANGES_OPTIONS = Object.entries(
  REQUIRED_CHANGES,
).map(([value, label]) => ({
  value,
  label,
}))

export const SCHEDULING_ISSUES_REASONS_OPTIONS: GroupedOptions<string>[] =
  typedKeys(SCHEDULING_ISSUES_SUB_REASONS_GROUP_BY_MAIN).map((mainReason) => ({
    group: SCHEDULING_ISSUES_MAIN_REASONS_LABEL[mainReason],
    items: SCHEDULING_ISSUES_SUB_REASONS_GROUP_BY_MAIN[mainReason],
  }))

export const ABORT_REASONS_OPTIONS = SCHEDULING_ISSUES_REASONS_OPTIONS.filter(
  ({ group }) =>
    group !==
    SCHEDULING_ISSUES_MAIN_REASONS_LABEL[
      SchedulingIssuesMainReason.IN_PROGRESS
    ],
)

export const INSTALLATION_NEW_VISIT_REASONS_OPTIONS: InstallationNewVisitReason[] =
  Object.values(InstallationNewVisitReason)

export const CONSUEL_WORKS_LOCATION_OPTIONS = [
  "AGCP",
  "TGBT",
  "Étiquetage",
  "Câblage",
]

export const CONSUEL_WORKS_REASON_OPTIONS = [
  "Protection contre les contacts directs (73)",
  "Mise en œuvre (85)",
  " Protection contre les surintensités (28)",
  "Signalisation (80)",
  "Cohérence DT (49)",
  "Protection contre les contacts indirects (17)",
]

export const IGNORE_ALERT_REASON_OPTIONS: GroupedOptions<string>[] = [
  {
    group: "Responsabilité Enedis",
    items: ["Instabilité réseau"],
  },
  {
    group: "Responsabilité client",
    items: ["Instabilité internet", "Installation non standard"],
  },
  {
    group: "Résolution en cours",
    items: ["Action Ensol terminée"],
  },
]

export const FUNDING_SOURCE_OPTIONS: Option<FundingSource>[] = [
  { label: "Fonds propres", value: FundingSource.EQUITY },
  { label: "Alma", value: FundingSource.ALMA },
  { label: "Cetelem", value: FundingSource.CETELEM },
  { label: "Zilo", value: FundingSource.ZILO },
]

export const CLIENT_MOTIVATION_OPTIONS = getOptionsFromList([
  "Faible",
  "Moyen",
  "Élevé",
])

export const KIZEO_PROJECT_REPORT_TYPE_OPTIONS: Option<
  KizeoProjectReportType,
  { canBeMany: boolean }
>[] = [
  {
    label: "Audit",
    value: KizeoProjectReportType.AUDIT,
    metadata: { canBeMany: true },
  },
  {
    label: "SAV ",
    value: KizeoProjectReportType.CUSTOMER_SERVICE,
    metadata: { canBeMany: true },
  },
  {
    label: "Visite technique",
    value: KizeoProjectReportType.TECHNICAL_VISIT,
    metadata: { canBeMany: false },
  },
  {
    label: "Installation",
    value: KizeoProjectReportType.INSTALLATION,
    metadata: { canBeMany: false },
  },
]

export const PAYMENT_HOLD_REASONS_OPTIONS = getOptionsFromList([
  "Attente validation Consuel",
  "Attente validation DP",
  "Attente finalisation SAV",
  "Déblocage des fonds banque",
  "Paiement en plusieurs fois",
])
