import { RoofSectionConfig } from "@ensol/types/forms/houses"

import {
  getOrientation,
  ORIENTATIONS,
} from "@ensol/shared/entities/houses/orientation"

export const DEFAULT_INCLINATION = 20
export const DEFAULT_ORIENTATION = 180

export const FLAT_ROOF_INCLINATION_THRESHOLD = 5 // °

export const DEFAULT_ROOF_SECTION: RoofSectionConfig = {
  orientation: DEFAULT_ORIENTATION,
  inclination: DEFAULT_INCLINATION,
  hasFlatRoof: false,
  hasGroundInstallation: false,
  horizonProfileFilePath: null,
}

/**
 * Used for flat roof section + ground installations
 */
export const FREE_INCLINATION_ROOF_SECTION = {
  inclination: 10,
  orientation: DEFAULT_ORIENTATION,
}

export const formatRoofSectionName = (
  roofSection: RoofSectionConfig,
): string => {
  if (roofSection.hasGroundInstallation) {
    return "Pose au sol"
  }

  if (roofSection.hasFlatRoof) {
    return "Toit plat"
  }

  return `${
    ORIENTATIONS[getOrientation(roofSection.orientation)].name
  } - ${roofSection.inclination}°`
}

export const checkHouseHasFlatRoof = (
  roofSections: RoofSectionConfig[],
): boolean => roofSections.some((roofSection) => roofSection.hasFlatRoof)

export const checkHouseHasGroundInstallation = (
  roofSections: { hasGroundInstallation: boolean }[],
): boolean =>
  roofSections.some((roofSection) => roofSection.hasGroundInstallation)
