import dayjs from "dayjs"

import { KizeoProjectReportType } from "@ensol/types/entities/project/kizeoProjectReport"

import { findFirstKizeoProjectReportFromType } from "@ensol/shared/entities/projects/kizeoProjectReport"
import { TechnicalVisitStep } from "@ensol/shared/entities/projects/processes"
import {
  SHORT_DISPLAY_DATE_FORMAT,
  formatDate,
} from "@ensol/shared/utils/format"

import { IssuesBadges } from "@ensol/entool/components/entities/Project/IssuesBadges"
import { ProcessInfos } from "@ensol/entool/components/entities/Project/ProcessInfos"

import { CheckBadge } from "../components/CheckBadge"
import { InfoComponentProps } from "../config"

export const TechnicalVisitInfo = ({
  project: {
    technicalVisitInstaller,
    technicalVisitStep,
    technicalVisitStartDate,
    isClientIntroductoryCallDone,
    isTechnicalVisitReady,
    schedulingIssueMainReason,
    technicalVisitSchedulingHoldEndDate,
    technicalVisitLastContactDate,
    kizeoProjectReports,
  },
}: InfoComponentProps) => {
  const technicalVisitKizeoProjectReport = findFirstKizeoProjectReportFromType(
    kizeoProjectReports,
    KizeoProjectReportType.TECHNICAL_VISIT,
  )
  const daysSinceTechnicalVisit =
    technicalVisitStartDate !== null
      ? dayjs().diff(technicalVisitStartDate, "days")
      : 0

  return (
    <ProcessInfos
      infos={[
        {
          name: "Installateur",
          value: technicalVisitInstaller?.name,
        },
        {
          name: "Date visite",
          value: formatDate(technicalVisitStartDate, SHORT_DISPLAY_DATE_FORMAT),
          isHidden: technicalVisitStep !== TechnicalVisitStep.SCHEDULED,
        },
        {
          name: "Appel CSM effectué",
          dataType: "component",
          value: <CheckBadge isChecked={isClientIntroductoryCallDone} />,
          isHidden: technicalVisitStep !== TechnicalVisitStep.SCHEDULED,
        },
        {
          name: "Visite prête",
          dataType: "component",
          value: <CheckBadge isChecked={isTechnicalVisitReady} />,
          isHidden: technicalVisitStep !== TechnicalVisitStep.SCHEDULED,
        },
        {
          name: "Visite terminée depuis",
          value: `${daysSinceTechnicalVisit} jour${daysSinceTechnicalVisit > 1 ? "s" : ""}`,
          color: daysSinceTechnicalVisit >= 5 ? "red" : undefined,
          isHidden: technicalVisitStep !== TechnicalVisitStep.COMPLETED,
        },
        {
          name: "CR VT reçu",
          dataType: "component",
          value: (
            <CheckBadge
              isChecked={
                technicalVisitKizeoProjectReport !== null &&
                technicalVisitKizeoProjectReport.reportPath !== null
              }
            />
          ),
          isHidden: technicalVisitStep !== TechnicalVisitStep.COMPLETED,
        },
        {
          name: "Problème",
          dataType: "component",
          value: (
            <IssuesBadges
              issues={
                schedulingIssueMainReason ? [schedulingIssueMainReason] : []
              }
            />
          ),
          isHidden:
            technicalVisitStep !== TechnicalVisitStep.SCHEDULING_ON_HOLD &&
            technicalVisitStep !== TechnicalVisitStep.UNSCHEDULED,
        },
        {
          name: "À rappeler le",
          value: formatDate(
            technicalVisitSchedulingHoldEndDate,
            SHORT_DISPLAY_DATE_FORMAT,
          ),
          isHidden:
            technicalVisitStep !== TechnicalVisitStep.SCHEDULING_ON_HOLD &&
            technicalVisitStep !== TechnicalVisitStep.UNSCHEDULED,
          color: dayjs().isAfter(technicalVisitSchedulingHoldEndDate)
            ? "red.5"
            : undefined,
        },
        {
          name: "Dernier contact",
          value: formatDate(
            technicalVisitLastContactDate,
            SHORT_DISPLAY_DATE_FORMAT,
          ),
          isHidden:
            technicalVisitStep !== TechnicalVisitStep.SCHEDULING_ON_HOLD &&
            technicalVisitStep !== TechnicalVisitStep.UNSCHEDULED,
        },
      ]}
    />
  )
}
