import { IconX } from "@tabler/icons-react"
import { z } from "zod"

import { acknowledgmentStepFormSchema } from "@ensol/types/forms/projects/preliminaryRequest"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { AbortProjectReasonsModal } from "@ensol/entool/pages/Project/AbortProjectReasonsModal"
import { PreliminaryRequestForm } from "@ensol/entool/pages/Project/processes/PreliminaryRequest/components/PreliminaryRequestForm"
import { useCompleteProcessStepMutation } from "@ensol/entool/queries/projects"
import { getInitialValues } from "@ensol/entool/utils/initialValues"
import { validateAction } from "@ensol/entool/utils/projects/validateAction"

export const RejectedSubmitted = ({
  project,
  ...props
}: StepComponentProps) => {
  const { mutateAsync: completeStep } = useCompleteProcessStepMutation()

  return (
    <PreliminaryRequestForm
      schema={acknowledgmentStepFormSchema}
      initialValues={getInitialValues(acknowledgmentStepFormSchema, project)}
      project={project}
      actions={[
        {
          label: "Rejeter définitivement la demande ",
          color: "red",
          validateAction: validateAction(
            z.object({
              preliminaryRequestRejectionPaths: z
                .array(z.string())
                .min(1, "Champ obligatoire"),
            }),
          ),
          CustomAction: {
            Modal: AbortProjectReasonsModal,
            onValidate: () =>
              completeStep({
                projectId: project.id,
                processId: "preliminaryRequestStep",
                action: "abort",
              }),
          },
          Icon: IconX,
        },
        {
          action: "validate",
          label: "Approuver la demande préalable",
          validateAction: validateAction(
            z.object({
              preliminaryRequestApprovalDate: z.date({
                invalid_type_error: "Champ obligatoire",
              }),
            }),
          ),
        },
      ]}
      {...props}
    />
  )
}
