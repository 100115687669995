import {
  ActionIcon,
  Button,
  Group,
  Loader,
  MantineStyleProps,
  Select,
  TextInput,
} from "@mantine/core"
import { IconSearch, IconX } from "@tabler/icons-react"
import { useMemo, useState } from "react"

import { BrandType, getBrand } from "@ensol/shared/material/brands"

import { useListExternalPlantsQuery } from "@ensol/entool/queries/installations"

type Props = {
  installationId: string
  inverterBrand: BrandType
  value?: string | null
  error?: string | null
  onChange: (plantId: string | null) => void
} & MantineStyleProps

export const ExternalPlantSelect = ({
  installationId,
  inverterBrand,
  value,
  error,
  onChange,
  ...props
}: Props) => {
  const externalProviderName = getBrand(inverterBrand).name

  // Huawei API is rated limited, so:
  // We don't launch the query until the user clicks on the CTA to display the possibilities
  const [isQueryEnabled, setIsQueryEnabled] = useState(false)

  const { data: plants, isLoading } = useListExternalPlantsQuery(
    installationId,
    isQueryEnabled,
    setIsQueryEnabled,
  )

  const plantOptions = useMemo(() => {
    if (!plants) return []
    return plants.map((plant) => ({
      label: `${plant.plantName} - ${plant.address}`,
      value: plant.plantId,
    }))
  }, [plants])

  if (value) {
    return (
      <Group gap="0" pos="relative" w="fit-content">
        <TextInput value={value} disabled error={error} {...props} />
        <ActionIcon
          aria-label="Supprimer"
          pos="absolute"
          right="0"
          onClick={() => onChange(null)}
          color="gray.5"
        >
          <IconX />
        </ActionIcon>
      </Group>
    )
  }

  return isQueryEnabled ? (
    <Select
      searchable
      placeholder={`Choisissez le système ${externalProviderName} de l'installation`}
      disabled={isLoading}
      leftSection={isLoading && <Loader size="sm" />}
      data={plantOptions}
      onChange={onChange}
      {...props}
    />
  ) : (
    <Button
      {...props}
      variant="light"
      onClick={() => setIsQueryEnabled(true)}
      leftSection={<IconSearch width={18} />}
    >
      Retrouver le système {externalProviderName} correspondant
    </Button>
  )
}
