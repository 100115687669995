import { waitingForStakeholderStepFormSchema } from "@ensol/types/forms/projects/preliminaryRequest"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { PreliminaryRequestForm } from "@ensol/entool/pages/Project/processes/PreliminaryRequest/components/PreliminaryRequestForm"
import { getInitialValues } from "@ensol/entool/utils/initialValues"

export const IncompleteWaitingForStakeholder = ({
  project,
  ...props
}: StepComponentProps) => (
  <PreliminaryRequestForm
    schema={waitingForStakeholderStepFormSchema}
    initialValues={getInitialValues(
      waitingForStakeholderStepFormSchema,
      project,
    )}
    project={project}
    actions={[
      {
        action: "validate",
        label: "À redéposer en mairie",
      },
    ]}
    {...props}
  />
)
