import { AppShell as BaseAppShell } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { useLocalStorage } from "@uidotdev/usehooks"
import { Outlet } from "react-router-dom"

import {
  DESKTOP_NAVBAR_WIDTH_IN_PX,
  MOBILE_NAVBAR_HEIGHT_IN_PX,
  MOBILE_NAVBAR_WIDTH_IN_PX,
} from "@ensol/shared/styles/constants"
import { devices } from "@ensol/shared/styles/theme"

import { Navbar } from "./Navbar"

export type AppShellProps = {
  environment: string
  Menu: React.ComponentType<{
    closeNavbar?: () => void
    toggleDesktop?: () => void
    desktopOpened?: boolean
  }>
  SignOutButton: React.ComponentType<{
    children: React.ReactNode
  }>
  user?: {
    firstName: string | null
    lastName: string | null
    imageUrl: string | null
  } | null
}

export const AppShell = ({
  environment,
  Menu,
  SignOutButton,
  user,
}: AppShellProps) => {
  const isSmallScreen = useMediaQuery(devices.md)
  const [desktopOpened, setDesktopOpened] = useLocalStorage(
    "desktopOpened",
    true,
  )
  const toggleDesktop = () => setDesktopOpened((prev) => !prev)

  return (
    <BaseAppShell
      transitionDuration={300}
      header={{
        height: MOBILE_NAVBAR_HEIGHT_IN_PX,
        collapsed: !isSmallScreen,
      }}
      navbar={{
        width: desktopOpened
          ? DESKTOP_NAVBAR_WIDTH_IN_PX
          : MOBILE_NAVBAR_WIDTH_IN_PX,
        breakpoint: "md",
        collapsed: { mobile: true },
      }}
      padding="0"
    >
      <Navbar
        environment={environment}
        desktopOpened={desktopOpened}
        toggleDesktop={toggleDesktop}
        Menu={Menu}
        SignOutButton={SignOutButton}
        user={user}
      />
      <BaseAppShell.Main display="flex" mah="100dvh">
        <Outlet />
      </BaseAppShell.Main>
    </BaseAppShell>
  )
}
