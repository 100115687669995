import { Group, Stack, Text } from "@mantine/core"
import { IconAlertTriangle } from "@tabler/icons-react"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationFormInput } from "@ensol/types/forms/installations"
import { SimulationResults } from "@ensol/types/simulation"

import { colors } from "@ensol/shared/styles/colors"

import { FoldableBox } from "@ensol/entool/components/FoldableBox"

import { CostsSummary } from "./CostsSummary"
import { SimulationSummary } from "./SimulationSummary"

type Props = {
  house: HousesResponses.House<{ include: { roofSections: true } }>
  simulationResults?: SimulationResults
  installationValues: InstallationFormInput
  areEnergyStatsAvailable: boolean
  areCostsVisible: boolean
  hasBattery: boolean
}

export const Summary = ({
  house,
  simulationResults,
  installationValues,
  areEnergyStatsAvailable,
  areCostsVisible,
  hasBattery,
}: Props) => {
  if (!areEnergyStatsAvailable) {
    return (
      <FoldableBox
        isOpened={false}
        bg="red.0"
        name={
          <Group gap="8">
            <IconAlertTriangle size={18} color={colors.red[600]} />
            <Text c="red.5" fw="bold">
              La simulation énergétique a échoué
            </Text>
          </Group>
        }
      >
        <Text p="0 16px 16px 16px">
          La production et la consommation de l&apos;installation n&apos;ont pas
          pu être calculées. Cela peut venir d&apos;un problème temporaire sur
          le service PVGIS, contactez l&apos;équipe énergie ou l&apos;équipe
          technique si le problème persiste.
        </Text>
      </FoldableBox>
    )
  }

  return (
    <Stack gap="0">
      {simulationResults !== undefined && (
        <SimulationSummary
          simulationResults={simulationResults}
          hasBattery={hasBattery}
        />
      )}
      {areCostsVisible && (
        <CostsSummary
          installationValues={installationValues}
          house={house}
          simulationResults={simulationResults}
        />
      )}
    </Stack>
  )
}
