import { z } from "zod"

import { Project } from "@ensol/types/entities/project"

import { FundingSource } from "@ensol/shared/entities/projects/fundingSource"

import { validateAction } from "@ensol/entool/utils/projects/validateAction"

export const validateConsuelAction = (project: Project) => {
  return validateAction(
    z.object({
      consuelApprovalDate: z.date({
        invalid_type_error: "Champ obligatoire",
      }),
      consuelCertificatePath: z.string({
        invalid_type_error: "Champ obligatoire",
      }),
      ...(project.fundingSource === FundingSource.CETELEM && {
        enedisRequestForConnectionPath: z.string({
          invalid_type_error: "Champ obligatoire pour le financement Cetelem",
        }),
      }),
    }),
  )(project)
}
