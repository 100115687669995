import { Group, Text } from "@mantine/core"
import { IconTools } from "@tabler/icons-react"

import { Link } from "@ensol/shared/components/Link"
import { Section } from "@ensol/shared/components/Section"

type Props = {
  installationId: string
}

export const UpdateInstallationSection = ({ installationId }: Props) => (
  <Section
    title="Mise à jour de l'installation"
    icon={IconTools}
    iconColor="yellow.4"
    titleOrder={3}
  >
    <Group gap="4">
      <Text>Rendez-vous sur</Text>
      <Link
        to={`/installations/${installationId}?section=description`}
        label="la page de l'installation"
        variant="transparent"
        p="0"
        mt="2"
        fz="md"
      />
      <Text>pour mettre à jour le matériel et les caractéristiques.</Text>
    </Group>
  </Section>
)
