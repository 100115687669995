import { toulouseMetropolisFormSchema } from "@ensol/types/forms/projects/toulouseMetropolis"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/utils/initialValues"

import { ToulouseMetropolisForm } from "./components/ToulouseMetropolisForm"

export const ToSent = ({ project, processId }: StepComponentProps) => (
  <ToulouseMetropolisForm
    schema={toulouseMetropolisFormSchema}
    initialValues={getInitialValues(toulouseMetropolisFormSchema, project)}
    project={project}
    processId={processId}
    actions={[
      {
        action: "validate",
        label: "Demande déposée",
      },
    ]}
  />
)
