import { useMediaQuery } from "@mantine/hooks"

import { devices } from "@ensol/shared/styles/theme"

export const MOBILE_NAVBAR_HEIGHT_IN_PX = 60
export const MOBILE_NAVBAR_WIDTH_IN_PX = 80
export const DESKTOP_NAVBAR_WIDTH_IN_PX = 218
export const PROJECT_HEADER_HEIGHT_IN_PX = 100
export const INSTALLATION_HEADER_HEIGHT_IN_PX = 60
export const CONTAINER_MIN_WIDTH = "100%"
export const CONTENT_PADDING_IN_PX = { base: 16, md: 24 }

export const useResponsiveDimensions = () => {
  const isSmallScreen = useMediaQuery(devices.md)

  return {
    navbarHeight: isSmallScreen ? MOBILE_NAVBAR_HEIGHT_IN_PX : 0,
    contentPadding: isSmallScreen
      ? CONTENT_PADDING_IN_PX.base
      : CONTENT_PADDING_IN_PX.md,
  }
}
