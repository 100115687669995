import { pendingStepFormSchema } from "@ensol/types/forms/projects/consuel"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/utils/initialValues"

import { ConsuelForm } from "./components/ConsuelForm"

export const Pending = ({ project, processId }: StepComponentProps) => (
  <ConsuelForm
    schema={pendingStepFormSchema}
    initialValues={getInitialValues(pendingStepFormSchema, project)}
    project={project}
    processId={processId}
    actions={[{ action: "validate", label: "Demandé à Solteo" }]}
  />
)
