import { Container, Stack, Loader } from "@mantine/core"
import { Navigate, useParams } from "react-router-dom"

import { Role, RoleGroups } from "@ensol/shared/entities/users"
import { isUserAuthorized } from "@ensol/shared/entities/users/roles"

import { useAuthenticatedUser } from "@ensol/entool/queries/users"

import { ProjectPage } from "./Project"

export const DefaultProjectRedirect = () => {
  const { data: user, isPending } = useAuthenticatedUser()
  const { projectId } = useParams()
  if (isPending) {
    return (
      <Container mt="5%">
        <Stack align="center">
          <Loader />
        </Stack>
      </Container>
    )
  }

  if (
    isUserAuthorized(
      [{ group: RoleGroups.ENSOL, minimumRole: Role.EMPLOYEE }],
      user,
    )
  ) {
    return <ProjectPage />
  }

  return <Navigate to={`/projects/${projectId}/technical-overview`} replace />
}
