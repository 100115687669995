import {
  Alert,
  Badge,
  Button,
  Group,
  MantineStyleProps,
  Modal,
  Stack,
  Text,
} from "@mantine/core"
import {
  IconAlertTriangle,
  IconChevronsRight,
  IconExternalLink,
  IconInfoCircle,
} from "@tabler/icons-react"
import { Link } from "react-router-dom"

import {
  ProjectEventProcessStep,
  ProjectEventStatus,
  ProjectEventType,
} from "@ensol/shared/entities/projects/events"

import { MapLink } from "@ensol/entool/components/MapLink"
import { TextInfo } from "@ensol/entool/components/entities/Project/TextInfo"
import { useScheduleInstallation } from "@ensol/entool/pages/ProjectsEvents/Calendar/useScheduleInstallation"
import { findSelectedOption } from "@ensol/entool/utils/form/radio"
import { PROJECT_EVENT_TYPES_OPTIONS } from "@ensol/entool/utils/projects/options"
import { getProcessStepConfig } from "@ensol/entool/utils/projects/processes"

type Props = {
  event: {
    start: Date
    end: Date
    extendedProps: {
      projectId: string
      type: ProjectEventType
      status: ProjectEventStatus
      clientName: string
      operationsOwnerName: string | null
      installerName: string | null
      address: string
      postcode: string
      installedPowerCapacity: number | null
      expectedStartDateSource?: string
      processStep: ProjectEventProcessStep | null
      warning?: string
    }
  }
  onClose: () => void
}

export const EventModal = ({
  event: {
    start,
    end,
    extendedProps: {
      type,
      status,
      projectId,
      clientName,
      operationsOwnerName,
      installerName,
      address,
      postcode,
      installedPowerCapacity,
      expectedStartDateSource,
      processStep,
      warning,
    },
  },
  onClose,
}: Props) => {
  const { scheduleInstallation, isScheduling } = useScheduleInstallation({
    projectId,
    start,
    end,
    installerName,
    onClose,
  })

  return (
    <Modal
      opened={projectId !== null}
      onClose={onClose}
      centered
      size="500"
      title={
        <Text fw="bold" fz={18}>
          {clientName}
        </Text>
      }
    >
      <Stack gap="8" align="flex-start">
        <Group gap="8" justify="space-between" w="100%">
          <Badge>
            {findSelectedOption(type, PROJECT_EVENT_TYPES_OPTIONS)!.label}
          </Badge>
          <Button
            component={Link}
            target="_blank"
            variant="transparent"
            to={`/projects/${projectId}?tab=${type === ProjectEventType.TECHNICAL_VISIT ? "technicalVisitStep" : "installationStep"}`}
            p="0"
            leftSection={<IconExternalLink size={14} />}
          >
            Ouvrir le projet
          </Button>
        </Group>
        {expectedStartDateSource && (
          <Alert
            w="100%"
            p="8"
            variant="outline"
            title="Source utilisée pour calculer la date proposée"
            icon={<IconInfoCircle />}
          >
            {expectedStartDateSource}
          </Alert>
        )}
        {warning && (
          <Alert
            w="100%"
            p="8"
            variant="outline"
            title="Avertissement"
            icon={<IconAlertTriangle />}
            color="red"
          >
            {warning}
          </Alert>
        )}
        {type === ProjectEventType.INSTALLATION &&
          status === ProjectEventStatus.TO_SCHEDULE && (
            <Button
              leftSection={<IconChevronsRight size={14} />}
              onClick={scheduleInstallation}
              loading={isScheduling}
              w="100%"
            >
              Planifier l&apos;installation
            </Button>
          )}
        <EventInfo name="Installateur" value={installerName ?? "-"} mt="16" />
        <EventInfo name="Responsable Ops" value={operationsOwnerName} />
        {processStep && (
          <EventInfo
            name="Status"
            value={
              getProcessStepConfig(
                processStep,
                type === ProjectEventType.TECHNICAL_VISIT
                  ? "technicalVisitStep"
                  : "installationStep",
              ).label
            }
          />
        )}
        <EventInfo name="Adresse" value={<MapLink address={address} />} />
        <EventInfo name="Code postal" value={postcode} />
        {installedPowerCapacity && (
          <EventInfo
            name="Capacité de l'installation"
            value={`${installedPowerCapacity}kWc`}
          />
        )}
      </Stack>
    </Modal>
  )
}

type EventInfoProps = {
  name: string
  value: string | React.ReactNode | null
} & MantineStyleProps

const EventInfo = ({ name, value, ...props }: EventInfoProps) => (
  <Group justify="space-between" w="100%" {...props}>
    <Text fw={500} c="gray.7">
      {name}
    </Text>
    {typeof value === "string" ? <TextInfo value={value} /> : (value ?? "-")}
  </Group>
)
