import * as z from "zod"

import { newVisitNeededStepFormSchema } from "@ensol/types/forms/projects/installation"
import { checkField } from "@ensol/types/forms/projects/shared"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/utils/initialValues"
import { validateAction } from "@ensol/entool/utils/projects/validateAction"

import { InstallationForm } from "./components/InstallationForm"

export const NewVisitNeeded = ({ project, ...props }: StepComponentProps) => (
  <InstallationForm
    schema={newVisitNeededStepFormSchema}
    initialValues={getInitialValues(newVisitNeededStepFormSchema, project)}
    project={project}
    actions={[
      {
        action: "validate",
        label: "Installation complétée",
        validateAction: validateAction(
          z.object({ isActivationDone: checkField }),
        ),
      },
    ]}
    {...props}
  />
)
