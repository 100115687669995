import { Button, Group, Stack, TextInput } from "@mantine/core"
import { IconPlus, IconSearch } from "@tabler/icons-react"
import _ from "lodash"
import { useState } from "react"
import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"

import { ListClientsFilters } from "@ensol/types/forms/clients"

import { PageLayout } from "@ensol/shared/components/layout/PageLayout"

import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { ClientsList } from "@ensol/entool/pages/Clients/ClientsList"
import { useListClientsQuery } from "@ensol/entool/queries/clients"

export const ClientsPage = () => {
  const [search, setSearch] = useState<string>("")
  const [orderBy, setOrderBy] = useState<
    NonNullable<ListClientsFilters["orderBy"]>
  >({
    field: "createdAt",
    direction: "desc",
  })

  const clientsQuery = useListClientsQuery({ filters: { search, orderBy } })

  const handleOrderChange = (
    field: NonNullable<ListClientsFilters["orderBy"]>["field"],
  ) => {
    setOrderBy({
      field,
      direction:
        orderBy.field === field
          ? orderBy.direction === "asc"
            ? "desc"
            : "asc"
          : "asc",
    })
  }
  return (
    <PageLayout title="Clients">
      <Helmet>
        <title>Clients | Entool</title>
      </Helmet>
      <Stack flex="1" mt="24">
        <Group mb={8}>
          <TextInput
            flex={1}
            leftSectionPointerEvents="none"
            leftSection={<IconSearch />}
            placeholder="Rechercher un client par nom, email ou téléphone"
            onChange={_.debounce((e) => setSearch(e.target.value), 250)}
          />
          <Button
            leftSection={<IconPlus />}
            component={Link}
            to="new"
            variant="light"
          >
            Nouveau client
          </Button>
        </Group>
        <QueryWrapper query={clientsQuery}>
          {({ data }) => (
            <ClientsList
              clients={data}
              orderBy={orderBy}
              onOrderChange={handleOrderChange}
            />
          )}
        </QueryWrapper>
      </Stack>
    </PageLayout>
  )
}
