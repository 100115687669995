import { Group, Stack, Switch, Text } from "@mantine/core"
import _ from "lodash"

import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { UnpackArray } from "@ensol/types/utils"

import { Link } from "@ensol/shared/components/Link"
import {
  EquipmentType,
  getEquipment,
} from "@ensol/shared/entities/houses/equipments"
import { getControlDevice } from "@ensol/shared/material/controlDevices"

import { InstallationPageSection } from "@ensol/entool/utils/installations/useInstallationMenu"

type Props = {
  installationId: string
  controlDeviceInstallation: UnpackArray<InstallationsResponses.ControlDeviceInstallations>
  houseEquipments: EquipmentType[]
  onChange: (
    data: UnpackArray<InstallationsResponses.ControlDeviceInstallations>,
  ) => void
}

export const ControlledEquipmentsSelector = ({
  installationId,
  controlDeviceInstallation,
  houseEquipments,
  onChange,
}: Props) => {
  const { deviceType, controlledEquipments } = controlDeviceInstallation
  const { controllableEquipments, maxControlledEquipments } =
    getControlDevice(deviceType)

  const eligibleEquipments = _.intersection(
    houseEquipments,
    controllableEquipments,
  )

  if (eligibleEquipments.length === 0) {
    return (
      <Group gap="0">
        <Text c="gray.6">
          Aucun équipement compatible n&apos;a été trouvé dans la maison,{" "}
        </Text>
        <Link
          p="0"
          h="24"
          size="md"
          variant="transparent"
          to={`/installations/${installationId}?section=${InstallationPageSection.EQUIPMENTS}`}
          label="cliquer ici pour ajouter des équipements."
        />
      </Group>
    )
  }

  return (
    <Switch.Group
      value={controlledEquipments}
      onChange={(value) =>
        onChange({
          ...controlDeviceInstallation,
          controlledEquipments: value as EquipmentType[],
        })
      }
    >
      <Group gap="8">
        {eligibleEquipments.map((equipmentType) => {
          const equipment = getEquipment(equipmentType)

          return (
            <Stack
              p="12"
              bg="gray.0"
              key={equipmentType}
              style={{ borderRadius: 4 }}
              data-test={_.camelCase(`${deviceType};${equipmentType}`)}
            >
              <Switch
                label={equipment.label}
                labelPosition="left"
                value={equipmentType}
                color="green.5"
                disabled={
                  controlledEquipments.length >= maxControlledEquipments &&
                  !controlledEquipments.includes(equipmentType)
                }
              />
            </Stack>
          )
        })}
      </Group>
    </Switch.Group>
  )
}
