import { beingProcessedStepFormSchema } from "@ensol/types/forms/projects/technicalVisit"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/utils/initialValues"

import { TechnicalVisitForm } from "./components/TechnicalVisitForm"
import { ValidateActionModal } from "./components/ValidateActionModal"

export const BeingProcessed = ({ project, ...props }: StepComponentProps) => (
  <TechnicalVisitForm
    schema={beingProcessedStepFormSchema}
    initialValues={getInitialValues(beingProcessedStepFormSchema, project)}
    project={project}
    actions={[
      {
        label: "Valider",
        CustomAction: { Modal: ValidateActionModal },
      },
    ]}
    {...props}
  />
)
