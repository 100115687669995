import { Stack } from "@mantine/core"
import dayjs from "dayjs"

import { Project } from "@ensol/types/entities/project"

import { getClientFileName } from "@ensol/shared/utils/files"
import { formatDate } from "@ensol/shared/utils/format"

import { NotFound } from "@ensol/entool/components/NotFound"
import { MultiFilePreview } from "@ensol/entool/components/entities/File/MultiFilePreview"
import { TextInfo } from "@ensol/entool/components/entities/Project/TextInfo"
import { Field } from "@ensol/entool/components/form/Field"
import { getProjectFieldLabel } from "@ensol/entool/utils/form/fieldLabels/projects"

type Props = {
  project: Project
}

export const HardwareInfo = ({ project }: Props) => {
  const { client } = project.installation.house

  return (
    <Stack gap="24">
      <Field name="Date d'installation prévue">
        <TextInfo
          value={formatDate(
            project.installationStartDate ??
              project.installationExpectedStartDate,
          )}
        />
      </Field>
      <Field name="Date de livraison souhaitée">
        <TextInfo
          value={
            project.installationStartDate !== null
              ? formatDate(
                  dayjs(project.installationStartDate).subtract(7, "days"),
                )
              : null
          }
        />
      </Field>
      <Field name="Installateur">
        <TextInfo value={project.installer?.name} />
      </Field>
      <Field name={getProjectFieldLabel("technicalVisitPanelsLayoutPath")}>
        {project.technicalVisitPanelsLayoutPath !== null ? (
          <MultiFilePreview
            files={[{ path: project.technicalVisitPanelsLayoutPath }]}
            fileName={getClientFileName("calepinage-VT", client)}
          />
        ) : (
          <NotFound type="file" />
        )}
      </Field>
      <Field name={getProjectFieldLabel("technicalVisitK2ReportsPath")}>
        {project.technicalVisitK2ReportsPath.length > 0 ? (
          <MultiFilePreview
            files={project.technicalVisitK2ReportsPath.map((path) => ({
              path,
            }))}
            fileName={getClientFileName("rapport-k2-VT", client)}
          />
        ) : (
          <NotFound type="file" />
        )}
      </Field>
    </Stack>
  )
}
