import { Checkbox, SimpleGrid, Stack, Text } from "@mantine/core"
import _ from "lodash"

import {
  EQUIPMENT_CATEGORIES,
  EquipmentType,
} from "@ensol/shared/entities/houses/equipments"

type Props = {
  value?: EquipmentType[]
  onChange: (value: string[]) => void
}

export const HouseEquipments = ({ value, onChange }: Props) => (
  <Checkbox.Group value={value} onChange={onChange}>
    <Stack gap={32}>
      {EQUIPMENT_CATEGORIES.map((category) => (
        <Stack gap={12} key={category.title}>
          <Text fz="lg" fw={500}>
            {category.title}
          </Text>
          <SimpleGrid cols={2}>
            {category.equipments.map((equipment) => (
              <Checkbox
                key={equipment.value}
                label={equipment.label}
                value={equipment.value}
                data-test={_.camelCase(equipment.value)}
                styles={{ label: { fontSize: 14 } }}
              />
            ))}
          </SimpleGrid>
        </Stack>
      ))}
    </Stack>
  </Checkbox.Group>
)
