import { useDisclosure } from "@mantine/hooks"
import { IconBolt, IconTrendingUp } from "@tabler/icons-react"

import { getBrand } from "@ensol/shared/material/brands"
import { getPanel, PanelType } from "@ensol/shared/material/photovoltaic/panels"
import { formatNumber, roundDecimalNumber } from "@ensol/shared/utils/format"

import { MaterialCard } from "@ensol/entool/pages/Installation/components/MaterialCard"
import { MaterialSelectorModal } from "@ensol/entool/pages/Installation/components/MaterialSelectorModal"
import { PanelOption } from "@ensol/entool/utils/installations/photovoltaic"

type Props = {
  panelType: PanelType
  panelsCount: number
  panelsOptions: PanelOption[]
  onChange: (panelType: PanelType) => void
}

export const PanelSelector = ({
  panelType,
  panelsCount,
  panelsOptions,
  onChange,
}: Props) => {
  const [opened, { open, close }] = useDisclosure(false)

  const panel = getPanel(panelType)
  return (
    <>
      <MaterialCard
        {...panel}
        badge={`${panelsCount} panneaux`}
        pictureUrl={panel.pictureUrl}
        ctaLabel="Changer panneau"
        dataTest="updatePanelTypeButton"
        onClick={open}
        properties={[
          {
            icon: IconBolt,
            label: `${formatNumber(1000 * panel.power)} Wc`,
          },
          {
            icon: IconTrendingUp,
            label: `Rendement ${roundDecimalNumber(panel.efficiency * 100, 0)}%`,
          },
        ]}
      />
      <MaterialSelectorModal<PanelType>
        title="Sélectionner un type de borne"
        isOpened={opened}
        onClose={close}
        onChange={onChange}
        extraColumnsLabel={["Puissance nominale", "Rendement"]}
        options={panelsOptions.map(({ value, disabled }) => {
          const isSelected = value === panelType
          const panelInfo = getPanel(value)
          const brandInfo = getBrand(panelInfo.brand)

          return {
            value,
            brandInfo,
            ...panelInfo,
            isSelected,
            isCompatible: !disabled,
            extraColumnsValues: [
              `${panelInfo.power * 1000} Wc`,
              `${roundDecimalNumber(panelInfo.efficiency * 100, 0)}%`,
            ],
          }
        })}
      />
    </>
  )
}
