import { ActionIcon, Badge, Button, Group, Input } from "@mantine/core"
import { useForm, UseFormReturnType, zodResolver } from "@mantine/form"
import { useMediaQuery } from "@mantine/hooks"
import {
  IconBriefcase,
  IconBulb,
  IconChevronLeft,
  IconChevronRight,
  IconDeviceFloppy,
} from "@tabler/icons-react"
import _ from "lodash"

import { Client } from "@ensol/types/entities/clients"
import { InstallationFormInput } from "@ensol/types/forms/installations"
import { extraWorksSchema } from "@ensol/types/forms/installations/extraWorks"

import { Link } from "@ensol/shared/components/Link"
import { computeInstallationName } from "@ensol/shared/entities/installations/name"
import { colors } from "@ensol/shared/styles/colors"
import { devices } from "@ensol/shared/styles/theme"

import { queryClient } from "@ensol/entool/backend/queryClient"
import { Tooltip } from "@ensol/entool/components/Tooltip"
import { ClientHeader } from "@ensol/entool/components/entities/Client/ClientHeader"
import { INSTALLATION_FIELDS_LABELS } from "@ensol/entool/utils/form/fieldLabels/installations"

type Props = {
  installationId: string
  projectId: string | null
  name: string
  address: string
  client: Client
  form: UseFormReturnType<InstallationFormInput>
  isInstallationValid: boolean
  onSave: () => void
  isSaving: boolean
  isOverviewVisible: boolean
  toggleOverview: () => void
  isEnergyStatsLoading: boolean
}

export const InstallationHeader = ({
  installationId,
  projectId,
  name,
  client,
  form,
  isInstallationValid,
  onSave,
  isSaving,
  isOverviewVisible,
  toggleOverview,
  isEnergyStatsLoading,
}: Props) => {
  const headerForm = useForm({
    onValuesChange: _.debounce(
      ({ name }) => form.getInputProps("name").onChange(name),
      350,
    ),
    validate: zodResolver(extraWorksSchema),
    initialValues: { name },
  })

  const formErrors = Object.keys(form.errors).map((field) =>
    field in INSTALLATION_FIELDS_LABELS
      ? INSTALLATION_FIELDS_LABELS[field]
      : field,
  )
  const isMobile = useMediaQuery(devices.sm)

  return (
    <ClientHeader
      client={client}
      BreadCrumbs={
        isMobile
          ? []
          : [
              <Group key="installationName">
                <Input
                  autoFocus
                  variant="unstyled"
                  placeholder="Nom de l'installation"
                  styles={{
                    input: {
                      fieldSizing: "content",
                      paddingRight: 8,
                      maxWidth: 440,
                      height: 0,
                      fontSize: 18,
                      fontWeight: 500,
                      color: colors.blue[500],
                    },
                  }}
                  data-test="installationName"
                  {...headerForm.getInputProps("name")}
                />
              </Group>,
            ]
      }
      Actions={
        <>
          {!isMobile && (
            <Tooltip label="Générer un nom">
              <ActionIcon>
                <IconBulb
                  size={18}
                  onClick={() => {
                    const name = computeInstallationName(form.values)
                    headerForm.setValues({ name })
                  }}
                />
              </ActionIcon>
            </Tooltip>
          )}
          {projectId !== null && (
            <Link
              label="Projet"
              to={`/projects/${projectId}`}
              Icon={<IconBriefcase />}
              color="gray.5"
              isIconButton
            />
          )}
        </>
      }
      Infos={
        <>
          {form.values.isUpsell && (
            <Badge variant="outline" color="blue">
              Upsell
            </Badge>
          )}
        </>
      }
      RightSection={
        <>
          <Button
            onClick={toggleOverview}
            variant="transparent"
            rightSection={
              isOverviewVisible ? (
                <IconChevronRight size={16} />
              ) : (
                <IconChevronLeft size={16} />
              )
            }
          >
            Résumé du projet
          </Button>
          <Tooltip
            disabled={isInstallationValid}
            position="left"
            maw="400"
            multiline
            label={`L'installation est invalide${formErrors.length > 0 ? `, voici les sections qui comportent des erreurs : ${formErrors.join(", ")}` : ", vérifiez que le options sélectionnées sont disponibles"}`}
          >
            <Button
              loading={isSaving}
              onClick={onSave}
              leftSection={<IconDeviceFloppy size={18} />}
              disabled={!isInstallationValid || isEnergyStatsLoading}
              data-test="submitButton"
            >
              Enregistrer
            </Button>
          </Tooltip>
        </>
      }
      onClientEditSuccess={() => {
        queryClient.invalidateQueries({
          queryKey: ["installations", installationId],
        })
      }}
    />
  )
}
