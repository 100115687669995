import {
  Badge,
  Paper,
  SimpleGrid,
  Stack,
  Title,
  Text,
  Group,
} from "@mantine/core"
import {
  IconCheck,
  IconHourglassHigh,
  IconReceiptOff,
} from "@tabler/icons-react"
import _ from "lodash"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { Project } from "@ensol/types/entities/project"

import { Section } from "@ensol/shared/components/Section"
import { formatDate } from "@ensol/shared/utils/format"

import { DeleteEstimateButton } from "@ensol/entool/pages/Project/processes/Payment/components/DeleteEstimateButton"
import { PennylaneLink } from "@ensol/entool/pages/Project/processes/Payment/components/PennylaneLink"
import { getPennylaneEstimateLink } from "@ensol/entool/utils/pennylane"

import { Info } from "./Info"
import { TransactionsTable } from "./TransactionsTable"

type Props = {
  estimate: ProjectResponses.Estimate | null
  project: Project
}

export const PennylaneEstimate = ({ estimate, project }: Props) => {
  if (estimate === null) {
    return (
      <Group gap="4">
        <IconReceiptOff />
        <Text>Ce projet n&apos;a pas de devis Pennylane associé</Text>
      </Group>
    )
  }

  return (
    <Stack>
      <Section
        title="Devis"
        titleOrder={3}
        Actions={<DeleteEstimateButton projectId={project.id} />}
      >
        <Stack w="50%">
          <Info name="Lien devis">
            <PennylaneLink
              label="Voir le devis sur Pennylane"
              href={getPennylaneEstimateLink({
                estimateLabel: estimate.label,
              })}
            />
          </Info>
          <Info name="Montant devis">{`${estimate.amount} ${estimate.currency}`}</Info>
        </Stack>
      </Section>
      {estimate.invoices.length > 0 && (
        <Section title="Factures" titleOrder={3}>
          <SimpleGrid cols={2} spacing={32}>
            {estimate.invoices.map(
              ({
                name,
                link,
                amount,
                currency,
                date,
                deadline,
                isPaid,
                transactions,
              }) => {
                const amountPaid = isPaid
                  ? amount
                  : _.chain(transactions)
                      .map(({ amount }) => Number(amount))
                      .sum()
                      .round(2)
                      .value()

                return (
                  <Paper key={link} withBorder shadow="none" w="100%" pb="12">
                    <Stack bg="blue.0" px="24" py="20" gap="4">
                      <Title order={5}>{name}</Title>
                      <PennylaneLink
                        label="Voir la facture sur Pennylane"
                        href={link}
                      />
                    </Stack>

                    <Stack gap="8" mt="12" px="24">
                      <Info name="Montant">{`${amount} ${currency}`}</Info>
                      <Info name="Payé">
                        <Badge
                          rightSection={
                            isPaid ? (
                              <IconCheck size="16" />
                            ) : (
                              <IconHourglassHigh size="16" />
                            )
                          }
                          size="lg"
                          color={isPaid ? "green" : "orange"}
                        >
                          {`${amountPaid} ${currency}`}
                        </Badge>
                      </Info>
                      <Info name="Date de création">{formatDate(date)}</Info>
                      <Info name="Date d'échéance">{formatDate(deadline)}</Info>
                    </Stack>
                    <TransactionsTable transactions={transactions} />
                  </Paper>
                )
              },
            )}
          </SimpleGrid>
        </Section>
      )}
    </Stack>
  )
}
