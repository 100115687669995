import { Group, SimpleGrid, ThemeIcon, Text } from "@mantine/core"
import { IconTrendingUp } from "@tabler/icons-react"

import { Link } from "@ensol/shared/components/Link"
import { Section } from "@ensol/shared/components/Section"

import { StatCard } from "@ensol/entool/components/StatCard"

const ELECTRICITY_PRICE_EVOLUTION_SOURCE_URL =
  "https://docs.google.com/presentation/d/1x4CC-eeeYEa-99JdHhHeSBRwNbSU-0rkY7pXw_UKrUk/edit#slide=id.g2157e05d543_0_37"

export const ElectricityPriceFigures = () => (
  <Section
    title="Évolution prix de l'électricité"
    Actions={
      <Link
        label="Source"
        to={ELECTRICITY_PRICE_EVOLUTION_SOURCE_URL}
        isExternal
      />
    }
  >
    <SimpleGrid cols={3}>
      <StatCard label="Il y a 10 ans (oct 2014)" stat="0.1565€" unit="/ kWh" />
      <StatCard
        label="Aujourd’hui"
        stat="0.2519€"
        unit="/ kWh"
        extra={<PriceRaisePercent />}
      />
      <StatCard
        label="Dans 10 ans (estimé)"
        stat="0.4057€"
        unit="/ kWh"
        extra={<PriceRaisePercent />}
      />
    </SimpleGrid>
  </Section>
)

const PriceRaisePercent = () => (
  <Group gap="4">
    <ThemeIcon color="red" variant="transparent" size="xs">
      <IconTrendingUp />
    </ThemeIcon>
    <Text c="red" size="sm">
      (+61%)
    </Text>
  </Group>
)
