import { Drawer, Group, Tabs, Text } from "@mantine/core"

import { ProjectOperationsOverview } from "@ensol/types/entities/project/overview"

import { formatFullName } from "@ensol/shared/utils/format"

import { ClientMotivationBadges } from "@ensol/entool/components/entities/Project/ClientMotivationBadges"
import { ProjectNotes } from "@ensol/entool/components/entities/Project/ProjectNotes"

import { Details } from "./Details"
import { ProjectDrawerTab } from "./useProjectDrawer"

type Props = {
  project: ProjectOperationsOverview | null
  activeTab: ProjectDrawerTab
  setActiveTab: (tab: ProjectDrawerTab) => void
  onClose: () => void
}

export const ProjectDrawer = ({
  project,
  activeTab,
  setActiveTab,
  onClose,
}: Props) => {
  return (
    <Drawer
      styles={{
        content: { display: "flex", flexDirection: "column" },
        body: { padding: 0, display: "flex", minHeight: 0, flex: 1 },
      }}
      opened={project !== null}
      onClose={onClose}
      position="right"
      title={
        project && (
          <Group gap="8" h={44}>
            <Text size="xl">
              {formatFullName(project.installation.house.client)}
            </Text>
            <ClientMotivationBadges project={project} />
          </Group>
        )
      }
    >
      {project && (
        <Tabs
          p={16}
          value={activeTab}
          onChange={(tab) => setActiveTab(tab as ProjectDrawerTab)}
          styles={{
            root: {
              display: "flex",
              flexDirection: "column",
              minHeight: 0,
              flex: 1,
            },
            panel: {
              display: "flex",
              minHeight: 0,
              flex: 1,
            },
          }}
        >
          <Tabs.List grow>
            <Tabs.Tab value={ProjectDrawerTab.DETAILS}>
              <Text size="md">Détails</Text>
            </Tabs.Tab>
            <Tabs.Tab value={ProjectDrawerTab.NOTES}>
              <Text size="md">Notes</Text>
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value={ProjectDrawerTab.DETAILS}>
            <Details project={project} />
          </Tabs.Panel>
          <Tabs.Panel value={ProjectDrawerTab.NOTES}>
            <ProjectNotes projectId={project.id} justify="flex-end" />
          </Tabs.Panel>
        </Tabs>
      )}
    </Drawer>
  )
}
