import { Project } from "@ensol/types/entities/project"

import { getInstallationCharacteristicsTextSummary } from "@ensol/shared/entities/installations/characteristics"
import {
  formatDate,
  SHORT_DISPLAY_DATE_FORMAT,
} from "@ensol/shared/utils/format"

export const getProjectCharacteristicsTextSummary = (project: Project) => {
  const installationCharacteristicsTextSummary =
    getInstallationCharacteristicsTextSummary(project.installation)

  const projectCharacteristicsTextSummary = [
    { label: "Numéro DP", value: project.preliminaryRequestReference || null },
    {
      label: "Date estimée installation",
      value: formatDate(
        project.installationExpectedStartDate,
        SHORT_DISPLAY_DATE_FORMAT,
      ),
    },
  ]
    .map(({ label, value }) => `${label} : ${value !== null ? value : "-"}`)
    .join("\n")

  return `${installationCharacteristicsTextSummary}\n${projectCharacteristicsTextSummary}`
}
