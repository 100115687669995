import { filterSuggestionItems } from "@blocknote/core"
import { BlockNoteView } from "@blocknote/mantine"
import { SuggestionMenuController } from "@blocknote/react"
import { Button } from "@mantine/core"
import { Form } from "@mantine/form"
import _ from "lodash"

import { formatFullName } from "@ensol/shared/utils/format"

import { useListUsers } from "@ensol/entool/queries/users"

import { useNoteForm } from "./useNoteForm"

type Props = {
  projectId: string
  inputPlaceholder?: string
}

export const NoteForm = (props: Props) => {
  const { isAddNoteLoading, noteForm, onSubmit, editor } = useNoteForm(props)
  const { users } = useListUsers()

  return (
    <Form onSubmit={onSubmit} form={noteForm}>
      <BlockNoteView
        className="edit-mode"
        theme="light"
        editor={editor}
        onChange={async () =>
          noteForm
            .getInputProps("content")
            .onChange(
              editor.document.filter(({ content }) => !_.isEmpty(content)),
            )
        }
        style={{ marginBottom: 16 }}
        sideMenu={false}
        slashMenu={false}
      >
        <SuggestionMenuController
          triggerCharacter="@"
          getItems={async (query) =>
            filterSuggestionItems(
              users.map((user) => {
                const fullName = formatFullName(user)
                return {
                  title: fullName,
                  onItemClick: () =>
                    editor.insertInlineContent([
                      {
                        type: "mention",
                        props: { name: fullName, id: user.id },
                      },
                      " ", // add a space after the mention
                    ]),
                }
              }),
              query,
            )
          }
        />
      </BlockNoteView>
      <Button
        type="submit"
        style={{ float: "right" }}
        mr="auto"
        loading={isAddNoteLoading}
        w={150}
        disabled={!noteForm.isValid() || isAddNoteLoading}
      >
        Post
      </Button>
    </Form>
  )
}
