import { Prisma } from "@ensol/types/prisma-client"

export type KizeoProjectReport<
  Include extends Prisma.KizeoProjectReportDefaultArgs | null = null,
> = Prisma.KizeoProjectReportGetPayload<Include>

/**
 * This enum is used for report types generated from Kizeo.
 */
export const KizeoProjectReportType = {
  TECHNICAL_VISIT: "TECHNICAL_VISIT",
  INSTALLATION: "INSTALLATION",
  AUDIT: "AUDIT",
  CUSTOMER_SERVICE: "CUSTOMER_SERVICE",
} as const

export type KizeoProjectReportType = keyof typeof KizeoProjectReportType
