import { Group } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { IconMail, IconPhone } from "@tabler/icons-react"

import { Client } from "@ensol/types/entities/clients"

import { BreadCrumb } from "@ensol/shared/components/BreadCrumb"
import { Header, HeaderProps } from "@ensol/shared/components/layout/Header"
import { getFormalName } from "@ensol/shared/entities/clients"
import { devices } from "@ensol/shared/styles/theme"
import { formatFullName } from "@ensol/shared/utils/format"

import { InfosIconPopover } from "@ensol/entool/components/InfosIconPopover"
import { HubspotLink } from "@ensol/entool/components/entities/Client/HubspotLink"
import { EditClientButton } from "@ensol/entool/components/form/Client/EditClientButton"

type Props = HeaderProps & {
  client: Client
  onClientEditSuccess?: () => void
}

export const ClientHeader = ({
  client,
  BreadCrumbs = [],
  Actions,
  Infos,
  onClientEditSuccess,
  ...props
}: Props) => {
  const isMobile = useMediaQuery(devices.sm)

  return (
    <>
      <Header
        {...props}
        BreadCrumbs={[
          !isMobile && (
            <BreadCrumb key="clients" to="/clients" label="Clients" />
          ),
          <Group gap="4" key="clientName" data-test="headerTitle" wrap="nowrap">
            <BreadCrumb
              to={`/clients/${client.id}`}
              c={client.isComplexCase ? "red.3" : "inherit"}
              label={isMobile ? formatFullName(client) : getFormalName(client)}
            />
          </Group>,
          ...BreadCrumbs,
        ]}
        Actions={
          <>
            <EditClientButton client={client} onSuccess={onClientEditSuccess} />
            {Actions}
            {!isMobile && (
              <>
                <InfosIconPopover
                  infos={[
                    {
                      Icon: IconMail,
                      name: "Email",
                      value: client.email,
                    },
                    {
                      Icon: IconPhone,
                      name: "Téléphone",
                      value: client.phone,
                    },
                  ]}
                />
                {Infos}
                <HubspotLink
                  hubspotContactId={client.hubspotContactId}
                  isIconButton={true}
                />
              </>
            )}
          </>
        }
      />
    </>
  )
}
