import {
  Select,
  Textarea,
  Text,
  Stack,
  Button,
  SimpleGrid,
  Anchor,
} from "@mantine/core"
import { DateTimePicker } from "@mantine/dates"
import {
  IconAdjustmentsAlt,
  IconCalendarX,
  IconHomeMove,
  IconListCheck,
  IconSolarPanel,
  IconTools,
} from "@tabler/icons-react"

import { ProjectDocumentType } from "@ensol/types/entities/project"
import { KizeoProjectReportType } from "@ensol/types/entities/project/kizeoProjectReport"
import { InstallationStepInput } from "@ensol/types/forms/projects/installation"
import { requiredWorksFormSchema } from "@ensol/types/forms/projects/technicalVisit"

import { Section } from "@ensol/shared/components/Section"
import { computeInstallationEndDate } from "@ensol/shared/entities/projects/installation"
import { getKizeoProjectReportPathsFromType } from "@ensol/shared/entities/projects/kizeoProjectReport"
import {
  InstallationStep,
  TechnicalVisitStep,
  hasReachedStep,
} from "@ensol/shared/entities/projects/processes"
import { Team } from "@ensol/shared/entities/users"
import { getInverter } from "@ensol/shared/material/photovoltaic/inverters"
import { getClientFileName } from "@ensol/shared/utils/files"
import {
  DISPLAY_DATE_FORMAT,
  DISPLAY_DATE_TIME_FORMAT,
  formatDate,
} from "@ensol/shared/utils/format"

import { NotFound } from "@ensol/entool/components/NotFound"
import { MultiFilePreview } from "@ensol/entool/components/entities/File/MultiFilePreview"
import { PREVIEW_WIDTH } from "@ensol/entool/components/entities/File/constants"
import { ProjectNotes } from "@ensol/entool/components/entities/Project/ProjectNotes"
import {
  StepActionsProps,
  StepActions,
} from "@ensol/entool/components/entities/Project/StepActions"
import { TextInfo } from "@ensol/entool/components/entities/Project/TextInfo"
import { DateInput } from "@ensol/entool/components/form/DateInput"
import { Field } from "@ensol/entool/components/form/Field"
import { FileField } from "@ensol/entool/components/form/File/FileField"
import { MultiFileField } from "@ensol/entool/components/form/File/MultiFileField"
import { FormField } from "@ensol/entool/components/form/FormField"
import { UpdateRoofType } from "@ensol/entool/components/form/House/UpdateRoofType"
import { ExternalPlantSelect } from "@ensol/entool/components/form/Installation/ExternalPlantSelect"
import { InstallerSelect } from "@ensol/entool/components/form/InstallerSelect"
import { FormCheckbox } from "@ensol/entool/components/form/Project/FormCheckbox"
import { RequiredChangesForm } from "@ensol/entool/components/form/Project/RequiredChangesForm"
import { RequiredWorksForm } from "@ensol/entool/components/form/Project/RequiredWorksForm"
import { SchedulingIssueFormField } from "@ensol/entool/components/form/Project/SchedulingIssueFormField"
import { UpdateInstallationSection } from "@ensol/entool/components/form/Project/UpdateInstallationSection"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { UserSelect } from "@ensol/entool/components/form/UserSelect"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { useCreateDocumentMutation } from "@ensol/entool/queries/projects"
import { booleanOptions } from "@ensol/entool/utils/form/options"
import { getInitialValues } from "@ensol/entool/utils/initialValues"
import {
  INSTALLATION_NEW_VISIT_REASONS_OPTIONS,
  INTEGRATION_KITS_OPTIONS,
  SCHEDULING_ISSUES_REASONS_OPTIONS,
} from "@ensol/entool/utils/projects/options"
import {
  ProcessFormProps,
  useProcessForm,
} from "@ensol/entool/utils/projects/useProcessForm"

export const InstallationForm = <Input extends InstallationStepInput>({
  schema,
  initialValues,
  project,
  ...props
}: ProcessFormProps<Input> & StepActionsProps) => {
  const { house } = project.installation
  const { client } = house
  const form = useProcessForm<Input>({
    schema,
    initialValues,
    projectId: project.id,
    processId: props.processId,
  })
  const { mutateAsync: createCertificate, isPending: isCertificateLoading } =
    useCreateDocumentMutation(
      project.id,
      ProjectDocumentType.INSTALLATION_CERTIFICATE,
    )

  const { photovoltaicInstallation } = project.installation

  const currentStep = project[props.processId]
  const inverter = photovoltaicInstallation
    ? getInverter(photovoltaicInstallation.inverterType)
    : null

  const updateStartDate = form.getInputProps("installationStartDate").onChange
  const updateEndDate = form.getInputProps("installationEndDate").onChange

  const isInstallationScheduled = hasReachedStep({
    project,
    processId: "installationStep",
    step: InstallationStep.SCHEDULED,
  })

  return (
    <StepActions
      project={project}
      validateStep={() => schema.parse(form.values)}
      {...props}
    >
      {currentStep === TechnicalVisitStep.UPDATE_INSTALLATION && (
        <UpdateInstallationSection installationId={project.installation.id} />
      )}
      {(currentStep === InstallationStep.IN_PROGRESS ||
        currentStep === InstallationStep.NEW_VISIT_NEEDED) && (
        <Section title="Vérifications" icon={IconListCheck} titleOrder={3}>
          <SimpleGrid cols={2} spacing="32" mt="16">
            <Stack gap="24">
              <FormCheckbox
                initialValues={initialValues}
                propertyName="isActivationDone"
                {...form.getInputProps("isActivationDone")}
              />
              <FormCheckbox
                initialValues={initialValues}
                propertyName="isInstallationReportValidated"
                {...form.getInputProps("isInstallationReportValidated")}
              />
            </Stack>
            {inverter !== null && (
              <FormField
                initialValues={initialValues}
                propertyName="followUpExternalPlantIdentifier"
              >
                <ExternalPlantSelect
                  w="400"
                  installationId={project.installationId}
                  inverterBrand={inverter.brand}
                  {...form.getInputProps("followUpExternalPlantIdentifier")}
                  onChange={(plantId: string | null) => {
                    form
                      .getInputProps("followUpExternalPlantIdentifier")
                      .onChange(plantId)
                    form
                      .getInputProps("isActivationDone")
                      .onChange(plantId !== null)
                    form
                      .getInputProps("isClientAccountCreated")
                      .onChange(plantId !== null)
                  }}
                />
              </FormField>
            )}
          </SimpleGrid>
        </Section>
      )}
      {currentStep === InstallationStep.SCHEDULED && (
        <Section title="Vérifications" icon={IconListCheck} titleOrder={3}>
          <Stack gap="24" mt="16" mb="32">
            <Stack gap="8">
              <FormCheckbox
                initialValues={initialValues}
                propertyName="areInstallationsCommentsConsidered"
                {...form.getInputProps("areInstallationsCommentsConsidered")}
              />
              <Text
                size="sm"
                p="10px"
                m="0px 30px"
                style={({ colors }) => ({
                  borderLeft: `3px solid ${colors.gray[4]}`,
                })}
              >
                {project.technicalVisitInstallerConclusion ? (
                  project.technicalVisitInstallerConclusion
                ) : (
                  <Text c="gray" fs="italic">
                    Aucun commentaires
                  </Text>
                )}
              </Text>
            </Stack>
            <FormCheckbox
              initialValues={initialValues}
              propertyName="isActivationPrepared"
              {...form.getInputProps("isActivationPrepared")}
            />
            <FormCheckbox
              initialValues={initialValues}
              propertyName="isClientAccountCreated"
              {...form.getInputProps("isClientAccountCreated")}
            />
            <FormCheckbox
              initialValues={initialValues}
              propertyName="isTechnicalVisitReportSent"
              {...form.getInputProps("isTechnicalVisitReportSent")}
            />
          </Stack>
        </Section>
      )}
      {(currentStep === InstallationStep.SCHEDULING_ON_HOLD ||
        currentStep === InstallationStep.UNSCHEDULED) && (
        <Section title="Non planifiable" icon={IconCalendarX} titleOrder={3}>
          <SimpleGrid cols={2} spacing="32" mt="16">
            <Stack gap="24">
              <SchedulingIssueFormField
                options={SCHEDULING_ISSUES_REASONS_OPTIONS}
                form={form}
                initialValues={{
                  schedulingIssueMainReason: project.schedulingIssueMainReason,
                  schedulingIssueSubReason: project.schedulingIssueSubReason,
                }}
              />
              <FormField
                initialValues={initialValues}
                propertyName="installationSchedulingHoldEndDate"
              >
                <DateTimePicker
                  w={FIELD_WIDTH}
                  valueFormat={DISPLAY_DATE_TIME_FORMAT}
                  clearable
                  {...form.getInputProps("installationSchedulingHoldEndDate")}
                />
              </FormField>
              <FormField
                initialValues={initialValues}
                propertyName="installationLastContactDate"
              >
                <DateInput
                  w={FIELD_WIDTH}
                  valueFormat={DISPLAY_DATE_FORMAT}
                  clearable
                  {...form.getInputProps("installationLastContactDate")}
                />
              </FormField>
            </Stack>
            <ProjectNotes projectId={project.id} mah={300} />
          </SimpleGrid>
        </Section>
      )}
      {(currentStep === InstallationStep.PENDING ||
        currentStep === InstallationStep.SCHEDULING_ON_HOLD ||
        currentStep === InstallationStep.UNSCHEDULED ||
        currentStep === InstallationStep.REQUESTED ||
        currentStep === InstallationStep.SCHEDULED) && (
        <>
          {project.technicalVisitRequiredChanges.length > 0 && (
            <RequiredChangesForm
              form={form}
              initialValues={{
                technicalVisitRequiredChanges:
                  project.technicalVisitRequiredChanges,
              }}
            />
          )}
          {(project.technicalVisitEndOfWorksDate !== null ||
            project.technicalVisitRequiredWorks.length > 0) && (
            <RequiredWorksForm
              form={form}
              initialValues={getInitialValues(requiredWorksFormSchema, project)}
              client={client}
            />
          )}
        </>
      )}

      <Section
        title={
          isInstallationScheduled
            ? "Gestion de l'installation"
            : "Planification de l'installation"
        }
        icon={IconAdjustmentsAlt}
        titleOrder={3}
      >
        <SimpleGrid cols={2} spacing="32" mt="16">
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="installationEstimatedDuration"
            >
              <TextInfo
                value={
                  project.installationEstimatedDuration
                    ? `${project.installationEstimatedDuration} jour${project.installationEstimatedDuration > 1 ? "s" : ""}`
                    : null
                }
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="installationClientAvailabilityDate"
            >
              <DateInput
                w={FIELD_WIDTH}
                valueFormat={DISPLAY_DATE_FORMAT}
                clearable
                {...form.getInputProps("installationClientAvailabilityDate")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="installationStartDate"
              isRequired={!isInstallationScheduled}
            >
              {isInstallationScheduled ? (
                <TextInfo value={formatDate(project.installationStartDate)} />
              ) : (
                <DateInput
                  w={FIELD_WIDTH}
                  valueFormat={DISPLAY_DATE_FORMAT}
                  clearable
                  maxDate={form.values.installationEndDate ?? undefined}
                  {...form.getInputProps("installationStartDate")}
                  onChange={(value) => {
                    updateStartDate(value)
                    if (
                      form.values.installationEndDate === null &&
                      value !== null
                    ) {
                      updateEndDate(computeInstallationEndDate(project, value))
                    }
                  }}
                />
              )}
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="installationEndDate"
              isRequired={!isInstallationScheduled}
            >
              {isInstallationScheduled ? (
                <TextInfo value={formatDate(project.installationEndDate)} />
              ) : (
                <DateInput
                  w={FIELD_WIDTH}
                  valueFormat={DISPLAY_DATE_FORMAT}
                  clearable
                  minDate={form.values.installationStartDate ?? undefined}
                  {...form.getInputProps("installationEndDate")}
                />
              )}
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="installerId"
              isRequired={!isInstallationScheduled}
            >
              {isInstallationScheduled ? (
                <TextInfo value={project.installer?.name} />
              ) : (
                <InstallerSelect
                  w={FIELD_WIDTH}
                  {...form.getInputProps("installerId")}
                />
              )}
            </FormField>
          </Stack>
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitInstallerConclusion"
            >
              <Textarea
                autosize
                w={FIELD_WIDTH}
                minRows={3}
                {...form.getInputProps("technicalVisitInstallerConclusion")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitClientConclusion"
            >
              <Textarea
                autosize
                w={FIELD_WIDTH}
                minRows={3}
                {...form.getInputProps("technicalVisitClientConclusion")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="technicalManagerId"
            >
              <UserSelect
                teams={[Team.TECHNICAL_MANAGER]}
                w={FIELD_WIDTH}
                withAuthenticatedUserAsDefault={false}
                {...form.getInputProps("technicalManagerId")}
              />
            </FormField>
          </Stack>
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="installationReportPath"
              subtitle="Compte-rendu d'installation uploadé manuellement"
            >
              <FileField
                previewProps={{
                  fileName: getClientFileName(
                    "compte-rendu-installation",
                    client,
                  ),
                }}
                inputProps={{ accept: ["application/pdf"] }}
                {...form.getInputProps("installationReportPath")}
              />
            </FormField>
            {hasReachedStep({
              project,
              processId: "installationStep",
              step: InstallationStep.IN_PROGRESS,
            }) && (
              <Field
                name="Compte-rendu d'installation"
                subtitle="Compte-rendu interne uploadé automatiquement depuis Kizeo"
              >
                {getKizeoProjectReportPathsFromType(
                  project.kizeoProjectReports,
                  KizeoProjectReportType.INSTALLATION,
                ).length > 0 ? (
                  <MultiFilePreview
                    files={getKizeoProjectReportPathsFromType(
                      project.kizeoProjectReports,
                      KizeoProjectReportType.INSTALLATION,
                    ).map((path) => ({
                      path,
                    }))}
                    fileName={getClientFileName(
                      "compte-rendu-installation",
                      client,
                    )}
                  />
                ) : (
                  <NotFound type="file" />
                )}
              </Field>
            )}
            <FormField
              initialValues={initialValues}
              propertyName="installationPhotosPaths"
            >
              <MultiFileField
                previewProps={{
                  fileName: getClientFileName("photo-installation", client),
                }}
                inputProps={{
                  accept: ["image/png", "image/jpeg"],
                }}
                {...form.getInputProps("installationPhotosPaths")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="installationSecurityReportPath"
            >
              <FileField
                previewProps={{
                  fileName: getClientFileName("compte-rendu-sécurité", client),
                }}
                inputProps={{ accept: ["application/pdf"] }}
                {...form.getInputProps("installationSecurityReportPath")}
              />
            </FormField>
          </Stack>
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="installationCertificatePath"
              isRequired
            >
              <Stack gap="8">
                <Button
                  onClick={async () => {
                    const installationCertificatePath =
                      await createCertificate()
                    form
                      .getInputProps("installationCertificatePath")
                      .onChange(installationCertificatePath)
                  }}
                  loading={isCertificateLoading}
                  disabled={project.installationCertificatePath !== null}
                  w={PREVIEW_WIDTH}
                >
                  Générer le bon de fin de chantier
                </Button>
                <FileField
                  previewProps={{
                    fileName: getClientFileName("bon-fin-chantier", client),
                  }}
                  inputProps={{ accept: ["application/pdf"] }}
                  {...form.getInputProps("installationCertificatePath")}
                />
              </Stack>
            </FormField>
            {project.installationCertificateSignatureLink !== null && (
              <Anchor
                href={project.installationCertificateSignatureLink}
                target="_blank"
              >
                Lien de signature
              </Anchor>
            )}
          </Stack>
        </SimpleGrid>
      </Section>
      {"installationNewVisitDate" in initialValues && (
        <Section
          title="Chantier non terminé"
          icon={IconHomeMove}
          titleOrder={3}
        >
          <SimpleGrid cols={2} spacing="32" mt="16">
            <Stack gap="24">
              <FormField
                initialValues={initialValues}
                propertyName="installationNewVisitDate"
                isRequired
              >
                <DateInput
                  w={FIELD_WIDTH}
                  valueFormat={DISPLAY_DATE_FORMAT}
                  clearable
                  minDate={form.values.installationStartDate ?? new Date()}
                  {...form.getInputProps("installationNewVisitDate")}
                />
              </FormField>
              <FormField
                initialValues={initialValues}
                propertyName="installationNewVisitReason"
              >
                <Select
                  w={FIELD_WIDTH}
                  data={INSTALLATION_NEW_VISIT_REASONS_OPTIONS}
                  {...form.getInputProps("installationNewVisitReason")}
                />
              </FormField>
            </Stack>
            <FormField
              initialValues={initialValues}
              propertyName="installationNewVisitNotes"
            >
              <Textarea
                autosize
                w={FIELD_WIDTH}
                minRows={3}
                {...form.getInputProps("installationNewVisitNotes")}
              />
            </FormField>
          </SimpleGrid>
        </Section>
      )}
      <Section title="Matériel" icon={IconSolarPanel} titleOrder={3}>
        <SimpleGrid cols={2} spacing="32" mt="16">
          <Stack gap="24">
            <UpdateRoofType house={project.installation.house} />
            <FormField
              initialValues={initialValues}
              propertyName="integrationKitType"
            >
              <Select
                w={FIELD_WIDTH}
                data={INTEGRATION_KITS_OPTIONS}
                {...form.getInputProps("integrationKitType")}
              />
            </FormField>
          </Stack>
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="hardwareNotes"
            >
              <Textarea
                autosize
                w={FIELD_WIDTH}
                minRows={3}
                {...form.getInputProps("hardwareNotes")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="isPLCNeeded"
              isRequired
            >
              <RadioGroup
                options={booleanOptions}
                {...form.getInputProps("isPLCNeeded")}
              />
            </FormField>
          </Stack>
        </SimpleGrid>
      </Section>
      <Section title="Infos visite technique" icon={IconTools} titleOrder={3}>
        <SimpleGrid cols={2} spacing="32" mt="16">
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitReportsPath"
            >
              <MultiFileField
                previewProps={{
                  fileName: getClientFileName("compte-rendu-VT", client),
                }}
                inputProps={{ accept: ["application/pdf"] }}
                {...form.getInputProps("technicalVisitReportsPath")}
              />
            </FormField>
            <Field name="Compte-rendu VT">
              {getKizeoProjectReportPathsFromType(
                project.kizeoProjectReports,
                KizeoProjectReportType.TECHNICAL_VISIT,
              ).length > 0 ? (
                <MultiFilePreview
                  files={getKizeoProjectReportPathsFromType(
                    project.kizeoProjectReports,
                    KizeoProjectReportType.TECHNICAL_VISIT,
                  ).map((path) => ({
                    path,
                  }))}
                  fileName={getClientFileName("compte-rendu-VT", client)}
                />
              ) : (
                <NotFound type="file" />
              )}
            </Field>
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitK2ReportsPath"
            >
              <MultiFileField
                previewProps={{
                  fileName: getClientFileName("rapport-k2-VT", client),
                }}
                inputProps={{
                  accept: {
                    "application/pdf": [".pdf"],
                    "application/octet-stream": [".k2o"],
                  },
                }}
                {...form.getInputProps("technicalVisitK2ReportsPath")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitPanelsLayoutPath"
            >
              <FileField
                previewProps={{
                  fileName: getClientFileName("calepinage-VT", client),
                }}
                inputProps={{ accept: ["image/png", "image/jpeg"] }}
                {...form.getInputProps("technicalVisitPanelsLayoutPath")}
              />
            </FormField>
          </Stack>
          <Stack gap="24">
            <UpdateRoofType house={project.installation.house} />
            <FormField
              initialValues={initialValues}
              propertyName="integrationKitType"
            >
              <Select
                w={FIELD_WIDTH}
                data={INTEGRATION_KITS_OPTIONS}
                {...form.getInputProps("integrationKitType")}
              />
            </FormField>
          </Stack>
        </SimpleGrid>
      </Section>
    </StepActions>
  )
}
