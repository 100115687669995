import {
  TablerIcon,
  IconBatteryCharging,
  IconChargingPile,
  IconGavel,
  IconSolarPanel,
  IconPlug,
  IconCpu,
  IconMaximize,
  IconDashboard,
} from "@tabler/icons-react"
import _ from "lodash"

import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { BatteryInstallation } from "@ensol/types/entities/batteryInstallation"
import { ControlDeviceInstallation } from "@ensol/types/entities/controlDeviceInstallation"
import { EvChargerInstallation } from "@ensol/types/entities/evChargerInstallation"
import { ExtraWorks, Installation } from "@ensol/types/entities/installation"
import { PhotovoltaicInstallation } from "@ensol/types/entities/photovoltaicInstallation"
import { InstallationProductsInput } from "@ensol/types/forms/installations"

import { computePanelsCapacity } from "@ensol/shared/entities/installations/energy"
import { getBattery } from "@ensol/shared/material/batteries"
import { getControlDevice } from "@ensol/shared/material/controlDevices"
import { getCurrent } from "@ensol/shared/material/currentType"
import { getEvCharger } from "@ensol/shared/material/evCharger"
import { getInverter } from "@ensol/shared/material/photovoltaic/inverters"
import { getOptimizer } from "@ensol/shared/material/photovoltaic/optimizers"
import { getPanel } from "@ensol/shared/material/photovoltaic/panels"

export type InstallationCharacteristic = {
  Icon: TablerIcon
  label: string
  value: string | null
}

const INSTALLATION_CHARACTERISTICS_SELECT = {
  house: {
    select: {
      lat: true,
      lng: true,
      isAtticAccessible: true,
      currentType: true,
    },
  },
  photovoltaicInstallation: {
    select: {
      panelType: true,
      panelsCount: true,
      inverterType: true,
      optimizerType: true,
      optimizerCount: true,
      extraWorks: true,
    },
  },
  batteryInstallation: {
    select: {
      type: true,
      extraWorks: true,
      count: true,
    },
  },
  evChargerInstallation: {
    select: {
      type: true,
      extraWorks: true,
    },
  },
  controlDeviceInstallations: {
    select: {
      deviceType: true,
    },
  },
}

export type InstallationCharacteristicsInput = Installation<{
  select: typeof INSTALLATION_CHARACTERISTICS_SELECT
}>

export const computeInstallationCharacteristics = (
  installation: InstallationCharacteristicsInput,
) => {
  const {
    house,
    photovoltaicInstallation,
    batteryInstallation,
    evChargerInstallation,
    controlDeviceInstallations,
  } = installation

  return {
    house: [
      {
        label: "Coordonnées de la maison",
        value: `${house.lat},${house.lng}`,
      },
      {
        label: "Accès aux combles",
        value:
          house.isAtticAccessible !== null
            ? house.isAtticAccessible
              ? "Oui"
              : "Non"
            : null,
      },
      {
        label: "Type de courant",
        value: getCurrent(house.currentType).name,
      },
    ],
    photovoltaicInstallation:
      photovoltaicInstallation !== null
        ? computePhotovoltaicInstallationCharacteristics(
            photovoltaicInstallation,
          )
        : [],
    batteryInstallation:
      batteryInstallation !== null
        ? computeBatteryInstallationCharacteristics(batteryInstallation)
        : [],
    evChargerInstallation:
      evChargerInstallation !== null
        ? computeEvChargerInstallationCharacteristics(evChargerInstallation)
        : [],
    controlDeviceInstallations:
      controlDeviceInstallations.length > 0
        ? computeControlDeviceInstallationsCharacteristics(
            controlDeviceInstallations,
          )
        : [],
    extraWorks: computeInstallationExtraWorksCharacteristics([
      ...(photovoltaicInstallation?.extraWorks ?? []),
      ...(batteryInstallation?.extraWorks ?? []),
      ...(evChargerInstallation?.extraWorks ?? []),
    ]),
  }
}

const computePhotovoltaicInstallationCharacteristics = (
  photovoltaicInstallation: PhotovoltaicInstallation<{
    select: {
      panelType: true
      panelsCount: true
      inverterType: true
      optimizerType: true
      optimizerCount: true
    }
  }>,
): InstallationCharacteristic[] => {
  const {
    panelType,
    panelsCount,
    inverterType,
    optimizerType,
    optimizerCount,
  } = photovoltaicInstallation

  const panel = getPanel(panelType)
  const inverter = getInverter(inverterType)
  const optimizer = getOptimizer(optimizerType)
  const installedCapacity = computePanelsCapacity(photovoltaicInstallation)

  return [
    {
      label: "Capacité",
      Icon: IconPlug,
      value: `${installedCapacity} kWc`,
    },
    {
      label: "Panneaux",
      Icon: IconSolarPanel,
      value: `${panelsCount} × ${panel.name}`,
    },
    { label: "Onduleur", Icon: IconCpu, value: inverter.name },
    {
      label: "Optimiseurs",
      Icon: IconMaximize,
      value:
        optimizer && optimizerCount
          ? `${optimizerCount} × ${optimizer.name}`
          : null,
    },
  ]
}

const computeBatteryInstallationCharacteristics = (
  batteryInstallation: BatteryInstallation<{
    select: {
      type: true
      count: true
    }
  }>,
): InstallationCharacteristic[] => {
  const battery = getBattery(batteryInstallation.type)

  return [
    {
      label: "Batterie",
      Icon: IconBatteryCharging,
      value: `${batteryInstallation.count} × ${battery.name}`,
    },
  ]
}

const computeEvChargerInstallationCharacteristics = (
  evChargerInstallation: EvChargerInstallation<{
    select: {
      type: true
    }
  }>,
): InstallationCharacteristic[] => {
  const evCharger = getEvCharger(evChargerInstallation.type)

  return [{ label: "Borne", Icon: IconChargingPile, value: evCharger.name }]
}

const computeControlDeviceInstallationsCharacteristics = (
  controlDeviceInstallations: ControlDeviceInstallation<{
    select: {
      deviceType: true
    }
  }>[],
): InstallationCharacteristic[] => [
  {
    label: "Pilotage",
    Icon: IconDashboard,
    value: controlDeviceInstallations
      .map(({ deviceType }) => getControlDevice(deviceType).name)
      .join(", "),
  },
]

const computeInstallationExtraWorksCharacteristics = (
  extraWorks: ExtraWorks,
): InstallationCharacteristic[] => {
  if (extraWorks.length === 0) {
    return []
  }

  return [
    {
      label: "Travaux supplémentaires",
      Icon: IconGavel,
      value: extraWorks.map(({ name }) => name).join(", "),
    },
  ]
}

export const getInstallationCharacteristicsTextSummary = (
  installation: InstallationCharacteristicsInput,
) =>
  _.chain(computeInstallationCharacteristics(installation))
    .values()
    .flatten()
    .map(
      ({ label, value }: InstallationCharacteristic) =>
        `${label}: ${value !== null ? value : "-"}`,
    )
    .join("\n")
    .value()

export const hasPhotovoltaicInstallation = (installation: {
  photovoltaicInstallation: PhotovoltaicInstallation<{
    select: {
      panelsCount: true
    }
  }> | null
}): installation is InstallationsResponses.Installation & {
  photovoltaicInstallation: InstallationsResponses.PhotovoltaicInstallation
} => {
  return (
    installation.photovoltaicInstallation != null &&
    installation.photovoltaicInstallation.panelsCount > 0
  )
}

export const getInstallationProductsCount = ({
  photovoltaicInstallation,
  batteryInstallation,
  evChargerInstallation,
  controlDeviceInstallations,
}: Omit<InstallationProductsInput, "productsCount">): number => {
  let productsCount = 0

  if (photovoltaicInstallation !== null) {
    productsCount += 1
  }

  if (batteryInstallation !== null) {
    productsCount += 1
  }

  if (evChargerInstallation !== null) {
    productsCount += 1
  }

  if (controlDeviceInstallations.length > 0) {
    productsCount += 1
  }

  return productsCount
}
