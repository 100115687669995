import { Loader, Stack, Title } from "@mantine/core"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { ClientInput, createClientSchema } from "@ensol/types/forms/clients"

import { ContentContainer } from "@ensol/shared/components/layout/ContentContainer"
import { Gender, ResidentialStatus } from "@ensol/shared/entities/clients"
import { isZodError } from "@ensol/shared/utils/errors"
import { validateBody } from "@ensol/shared/utils/validation"

import { FallbackForm } from "@ensol/entool/pages/NewClient/FallbackForm"
import { useCreateClientMutation } from "@ensol/entool/queries/clients"

export const NewClientPage = () => {
  const [searchParams] = useSearchParams()
  const [newClientInput, setNewClientInput] = useState<ClientInput | null>(null)

  const { mutateAsync: createClient, isPending } = useCreateClientMutation()
  const [error, setError] = useState<string>()

  useEffect(() => {
    const phone = searchParams.get("phone") ?? ""
    const sanitizedPhone = phone.replace(/\s/g, "")

    const newClientInput: ClientInput = {
      firstName: searchParams.get("firstName") ?? "",
      lastName: searchParams.get("lastName") ?? "",
      email: searchParams.get("email") ?? "",
      phone: sanitizedPhone.length > 10 ? `+${sanitizedPhone}` : sanitizedPhone,
      residentialStatus:
        (searchParams.get("residentialStatus") as
          | ResidentialStatus
          | undefined) ?? ResidentialStatus.OWNER,
      hubspotContactId: searchParams.get("hubspotContactId") ?? null,
      gender: (searchParams.get("gender")?.toUpperCase() as Gender) ?? null,
      isComplexCase: false,
    }
    setNewClientInput(newClientInput)
  }, [searchParams])

  useEffect(() => {
    if (newClientInput !== null && !isPending) {
      validateBody(newClientInput, createClientSchema)
        .then(async (client) => {
          await createClient(client)
        })
        .catch((err) => {
          if (isZodError(err)) setError(err.message)
          else setError(err)
        })
    }
  }, [createClient, newClientInput, isPending])

  if (isPending)
    return (
      <ContentContainer flex="1">
        <Stack align="center" gap={48}>
          <Title>Création du client...</Title>
          <Loader />
        </Stack>
      </ContentContainer>
    )
  if (error && newClientInput !== null) {
    return <FallbackForm {...newClientInput} />
  }
  return null
}
