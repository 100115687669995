import { colors } from "@ensol/shared/styles/colors"

import "./global.css"
import "./fullcalendar-override.css"
import "./blocknote-override.css"

export const BG_LINEAR_GRADIENT = `linear-gradient(to bottom, ${colors.blue[200]} 0%, ${colors.pink[100]} 92.8%)`

// This allow to prevent pills from wrapping in multi select target
export const ONE_LINE_MULTI_SELECT_TARGET_STYLE = {
  pillsList: {
    flexWrap: "nowrap",
    overflow: "hidden",
    maxWidth: "calc(100% - 24px)",
  } as const,
}
