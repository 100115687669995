import dayjs from "dayjs"

import { PhotovoltaicSubsidyType } from "@ensol/shared/entities/installations/subsidies/photovoltaic"

type EnergyResellPriceInput = {
  subsidyType?: PhotovoltaicSubsidyType | null
  installedCapacity: number
  enedisFileCompletionDate?: Date
  currentDate?: Date
}

// We have to keep track of resell prices history to be able to compute
// the right price for a client based on enedisFileCompletionDate
// Source: https://www.les-energies-renouvelables.eu/conseils/photovoltaique/tarif-rachat-electricite-photovoltaique/
export const computeEnergyResellPrice = ({
  subsidyType,
  installedCapacity,
  enedisFileCompletionDate = new Date(),
  currentDate = new Date(),
}: EnergyResellPriceInput): number => {
  const installationAge = dayjs(currentDate).diff(
    enedisFileCompletionDate,
    "year",
  )
  if (
    installationAge >= 20 ||
    subsidyType !== PhotovoltaicSubsidyType.AUTO_CONSUMPTION
  ) {
    return 0
  }

  if (enedisFileCompletionDate < new Date("2023-11-01")) {
    return installedCapacity <= 9 ? 0.1339 : 0.0803
  }
  if (enedisFileCompletionDate < new Date("2024-02-01")) {
    return installedCapacity <= 9 ? 0.13 : 0.078
  }
  if (enedisFileCompletionDate < new Date("2024-05-01")) {
    return installedCapacity <= 9 ? 0.1297 : 0.078
  }
  if (enedisFileCompletionDate < new Date("2024-08-01")) {
    return installedCapacity <= 9 ? 0.1301 : 0.0781
  }
  if (enedisFileCompletionDate < new Date("2024-11-01")) {
    return installedCapacity <= 9 ? 0.1276 : 0.0765
  }
  if (enedisFileCompletionDate < new Date("2025-03-28")) {
    return installedCapacity <= 9 ? 0.1269 : 0.0761
  }

  // This is the current resell price returned by default if we don't have enedis file validation date
  return installedCapacity <= 9 ? 0.04 : 0.0761
}
