import { Alert, Loader, Select, Stack } from "@mantine/core"
import dayjs, { Dayjs } from "dayjs"
import { useEffect, useState } from "react"

import { formatDate, ISO_DATE_FORMAT } from "@ensol/shared/utils/format"

import { Field } from "@ensol/entool/components/form/Field"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { MonthlyReport } from "@ensol/entool/pages/Project/processes/FollowUp/components/MonthlyReport"
import { useGetHouseReportsAvailableDates } from "@ensol/entool/queries/houses"

type Props = {
  houseId: string
  slug: string
}

export const MonthlyReportSelect = ({ houseId, slug }: Props) => {
  const [date, setDate] = useState<string | null>(null)
  const { data, isLoading } = useGetHouseReportsAvailableDates(houseId)

  useEffect(() => {
    if (date === null && data?.dataTimeFrame) {
      setDate(
        formatDate(
          dayjs(data.dataTimeFrame.startDate).startOf("month"),
          ISO_DATE_FORMAT,
        ),
      )
    }
  }, [data, date])

  if (isLoading) {
    return (
      <Stack w="100%" justify="center" align="center" h="200">
        <Loader />
      </Stack>
    )
  }

  if (!data?.dataTimeFrame) {
    return (
      <Alert
        title="Aucun rapport mensuel trouvé pour cette installation"
        color="red"
      />
    )
  }

  return (
    <Stack>
      <Field name="Date du rapport">
        <Select
          w={FIELD_WIDTH}
          value={date}
          onChange={setDate}
          data={getMonthOptions(
            dayjs(data.dataTimeFrame.startDate),
            dayjs(data.dataTimeFrame.endDate),
          )}
        />
      </Field>
      {date && <MonthlyReport slug={slug} date={date} />}
    </Stack>
  )
}

const getMonthOptions = (startDate: Dayjs, endDate: Dayjs) => {
  const numberOfMonthSinceProductionStart = Math.ceil(
    endDate.diff(startDate.startOf("month"), "month", true),
  )

  return [...Array(numberOfMonthSinceProductionStart).keys()].map((index) => {
    const option = startDate.startOf("month").add(index, "month")

    return {
      value: formatDate(option, ISO_DATE_FORMAT)!,
      label: formatDate(option, "MMMM YYYY")!,
    }
  })
}
