import { useParams } from "react-router-dom"

import { ContentContainer } from "@ensol/shared/components/layout/ContentContainer"

import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { useGetInstallationQuery } from "@ensol/entool/queries/installations"
import { useInstallationMenu } from "@ensol/entool/utils/installations/useInstallationMenu"

import { PanelsLayout } from "./PanelsLayout"

export const PanelsLayoutPage = () => {
  const params = useParams()
  const installationId = params.installationId as string

  useInstallationMenu(installationId)
  const installationQuery = useGetInstallationQuery(installationId)

  return (
    <ContentContainer>
      <QueryWrapper query={installationQuery}>
        {({ data: installation }) => (
          <PanelsLayout installation={installation} />
        )}
      </QueryWrapper>
    </ContentContainer>
  )
}
