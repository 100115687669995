import { EnergyResponses } from "@ensol/types/endpoints/energy"
import { HousesResponses } from "@ensol/types/endpoints/houses"
import { OnSiteInstallation } from "@ensol/types/entities/installation"
import { InstallationFormInput } from "@ensol/types/forms/installations"
import { SimulationResults } from "@ensol/types/simulation"

import { getCoords } from "@ensol/shared/entities/houses/coords"
import { computeMaxAutoConsumptionPercent } from "@ensol/shared/entities/installations/energy"

import { ReportWrapper } from "@ensol/entool/pages/Installation/components/ReportWrapper"
import { useSimulationResults } from "@ensol/entool/pages/Installation/useSimulationResults"

import { AutoConsumptionSection } from "./AutoConsumptionSection"
import { BillEvolutionSection } from "./BillEvolutionSection"
import { SavingsReport } from "./SavingsReport"
import { StatsSection } from "./StatsSection"

type Props = {
  slug: string
  simulationResults?: SimulationResults
  setManualAutoConsumptionPercent: (value: number) => void
  energyStats?: EnergyResponses.Stats
  isEnergyStatsLoading: boolean
  installationValues: InstallationFormInput
  house: HousesResponses.House<{
    include: { roofSections: true; switchgridOrder: true }
  }>
  onSiteInstallation?: OnSiteInstallation
  isInstallationValid: boolean
  installationDate: Date
}

export const Savings = ({
  slug,
  simulationResults,
  setManualAutoConsumptionPercent,
  energyStats,
  isEnergyStatsLoading,
  installationValues,
  house,
  onSiteInstallation,
  isInstallationValid,
  installationDate,
}: Props) => {
  const {
    yearlyConsumption,
    electricityBuyPrice,
    electricityPriceAnnualRaise,
    computedAutoConsumptionPercent,
    manualAutoConsumptionPercent,
  } = installationValues

  const computedSimulationResults = useSimulationResults({
    energyStats,
    autoConsumptionPercent: energyStats?.autoConsumptionPercent,
    house,
    installationDate,
    installationValues,
    isInstallationValid,
    onSiteInstallation,
  })

  if (
    !isEnergyStatsLoading &&
    (!simulationResults || !computedSimulationResults)
  ) {
    return null
  }

  const isBoostActive = energyStats
    ? manualAutoConsumptionPercent > computedAutoConsumptionPercent
    : false

  return (
    <>
      <StatsSection
        isEnergyStatsLoading={isEnergyStatsLoading}
        simulationResults={simulationResults}
      />
      <ReportWrapper
        title="Votre facture"
        isLoading={isEnergyStatsLoading}
        timeFrame={energyStats?.dataTimeFrame}
        ReportComponent={(props) => (
          <SavingsReport
            slug={slug}
            coords={getCoords(house)}
            installationValues={installationValues}
            computedAutoConsumptionPercent={computedAutoConsumptionPercent}
            manualAutoConsumptionPercent={manualAutoConsumptionPercent}
            {...props}
          />
        )}
      />
      <AutoConsumptionSection
        computedAutoConsumptionPercent={computedAutoConsumptionPercent}
        manualAutoConsumptionPercent={manualAutoConsumptionPercent}
        maxAutoConsumptionPercent={
          energyStats ? computeMaxAutoConsumptionPercent(energyStats) : 100
        }
        setManualAutoConsumptionPercent={setManualAutoConsumptionPercent}
        isBoostActive={isBoostActive}
      />
      {computedSimulationResults && (
        <BillEvolutionSection
          computedSimulationResults={computedSimulationResults}
          manualSimulationResults={simulationResults}
          yearlyConsumption={yearlyConsumption}
          electricityBuyPrice={electricityBuyPrice}
          electricityPriceAnnualRaise={electricityPriceAnnualRaise}
          isBoostActive={isBoostActive}
        />
      )}
    </>
  )
}
