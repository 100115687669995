import { Client } from "@ensol/types/prisma-client"

export const FILE_UPLOAD_FIELD_NAME = "file"

export const getFileBaseUrl = (apiBaseUrl: string) => `${apiBaseUrl}/files`

export const getClientFileName = (
  documentName: string,
  client: Pick<Client, "firstName" | "lastName">,
) => removeDiacritics(`${documentName}-${client.firstName}-${client.lastName}`)

const removeDiacritics = (str: string) =>
  str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

export const getExtensionFromFileName = (fileName: string) =>
  fileName.split(".").pop()?.toLowerCase()

export const getExtensionFromContentType = (contentType: string) => {
  switch (contentType) {
    case "application/pdf":
      return "pdf"
    case "image/jpeg":
      return "jpg"
    case "image/png":
      return "png"
    case "image/heic":
      return "heic"
    case "text/csv":
      return "csv"
    default:
      return ""
  }
}

export const getContentType = (fileName: string) => {
  const extension = getExtensionFromFileName(fileName)

  switch (extension) {
    case "pdf":
      return "application/pdf"
    case "jpg":
    case "jpeg":
      return "image/jpeg"
    case "png":
      return "image/png"
    case "heic":
      return "image/heic"
    case "csv":
      return "text/csv"
    default:
      return "application/octet-stream"
  }
}
