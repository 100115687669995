import {
  ActionIcon,
  Card,
  Group,
  Text,
  UnstyledButton,
  Stack,
  Image,
  ThemeIcon,
  StackProps,
} from "@mantine/core"
import { modals } from "@mantine/modals"
import { IconFileTypeCsv, IconPaperclip, IconTrash } from "@tabler/icons-react"
import { Document, Thumbnail } from "react-pdf"

import { getExtensionFromFileName } from "@ensol/shared/utils/files"

import { LinkLoader } from "@ensol/entool/components/LinkLoader"
import { DownloadFileButton } from "@ensol/entool/components/entities/File/DownloadFileButton"
import { getFileUrl } from "@ensol/entool/utils/files"
import { isImage } from "@ensol/entool/utils/files/images"

import { PREVIEW_HEIGHT, PREVIEW_WIDTH } from "./constants"

type Props = {
  filePath: string
  fileName: string
  onClick?: () => void
  onClear?: () => void
} & StackProps

export const FileThumbnail = ({
  filePath,
  fileName,
  w,
  h,
  onClick,
  onClear,
  ...props
}: Props) => {
  const type = getExtensionFromFileName(filePath)

  if (type === undefined) {
    return null
  }

  return (
    <Stack h={h ?? PREVIEW_HEIGHT} w={w ?? PREVIEW_WIDTH} {...props}>
      <Card w="100%" h="100%" padding="lg" radius="md" p="0" withBorder>
        <Stack h="100%" gap="0">
          <Stack
            component={UnstyledButton}
            align="center"
            justify="center"
            flex="1"
            bg="gray.0"
            onClick={onClick}
            style={{
              overflow: "hidden",
              cursor: "pointer",
            }}
          >
            {getThumbnail(type, getFileUrl(filePath), PREVIEW_HEIGHT.base)}
          </Stack>
          {onClear !== undefined && (
            <ActionIcon
              aria-label="Supprimer"
              size="md"
              variant="white"
              pos="absolute"
              c="red"
              p="4"
              onClick={() =>
                modals.openConfirmModal({
                  title: "Supprimer le fichier",
                  children: (
                    <Text size="sm">
                      Êtes-vous sûr(e) de vouloir supprimer ce fichier ? Cette
                      action est irréversible.
                    </Text>
                  ),
                  confirmProps: { color: "red" },
                  centered: true,
                  labels: {
                    confirm: "Supprimer le fichier",
                    cancel: "Annuler",
                  },
                  onConfirm: onClear,
                })
              }
            >
              <IconTrash />
            </ActionIcon>
          )}
          <Group p="8" justify="space-between">
            <Text size="xs" truncate="end" maw="80%">
              {fileName}.{type}
            </Text>
            <DownloadFileButton
              filePath={filePath}
              fileName={fileName}
              type={type}
            />
          </Group>
        </Stack>
      </Card>
    </Stack>
  )
}

const getThumbnail = (type: string, fileUrl: string, height: number) => {
  if (type === "pdf") {
    return (
      <Stack h="100%" justify="flex-start">
        <Document
          file={fileUrl}
          loading={() => (
            <Stack p="48" align="center" justify="center">
              <LinkLoader />
            </Stack>
          )}
        >
          <Thumbnail pageNumber={1} height={height} scale={2} />
        </Document>
      </Stack>
    )
  } else if (isImage(type)) {
    return <Image src={fileUrl} />
  } else if (type === "csv") {
    return (
      <ThemeIcon c="gray" size="xl">
        <IconFileTypeCsv />
      </ThemeIcon>
    )
  } else {
    return (
      <ThemeIcon c="gray" size="xl">
        <IconPaperclip />
      </ThemeIcon>
    )
  }
}
