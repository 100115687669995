import {
  Group,
  Title,
  TitleOrder,
  ThemeIcon,
  Stack,
  Divider,
} from "@mantine/core"
import { TablerIcon } from "@tabler/icons-react"
import _ from "lodash"
import { ReactNode } from "react"

import { ColorShade } from "@ensol/shared/styles/colors"

type Props = {
  title?: string | ReactNode
  icon?: TablerIcon
  iconColor?: ColorShade
  titleOrder?: TitleOrder
  withBorder?: boolean
  children?: ReactNode
}

export const SubSection = ({
  title,
  titleOrder,
  icon,
  iconColor,
  withBorder = false,
  children,
}: Props) => {
  return (
    <Stack gap={8}>
      <SubSectionTitle
        title={title}
        icon={icon}
        iconColor={iconColor}
        titleOrder={titleOrder}
      />
      <Stack gap={8}>{children}</Stack>
      {withBorder && <Divider mt="8" />}
    </Stack>
  )
}

const SubSectionTitle = ({
  title,
  icon: Icon,
  iconColor,
  titleOrder = 3,
}: Props) => (
  <Group gap={8} flex={1} justify="space-between">
    <Group gap={8}>
      {Icon && (
        <ThemeIcon c={iconColor}>
          <Icon />
        </ThemeIcon>
      )}
      {_.isString(title) ? <Title order={titleOrder}>{title}</Title> : title}
    </Group>
  </Group>
)
