import { Box, Card } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { ReactNode } from "react"

import { devices } from "@ensol/shared/styles/theme"

interface Props {
  children: ReactNode
  isActive?: boolean
}

export const ProjectCard = ({ isActive, children }: Props) => {
  const isMobile = useMediaQuery(devices.sm)

  return (
    <Card
      withBorder
      bg={isActive ? "blue.1" : "white"}
      p="0"
      style={({ colors }) => ({
        borderColor: isActive ? colors.blue[4] : colors.gray[4],
      })}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          gap: isMobile ? 16 : 32,
        }}
      >
        {children}
      </Box>
    </Card>
  )
}
