import { KizeoProjectReportType } from "@ensol/types/entities/project/kizeoProjectReport"

export const KIZEO_PROJECT_REPORT_TYPE_LABELS: Record<
  KizeoProjectReportType,
  string
> = {
  [KizeoProjectReportType.AUDIT]: "Audit",
  [KizeoProjectReportType.CUSTOMER_SERVICE]: "SAV",
  [KizeoProjectReportType.TECHNICAL_VISIT]: "Visite technique",
  [KizeoProjectReportType.INSTALLATION]: "Installation",
}
