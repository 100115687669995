import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { RoofSectionConfig } from "@ensol/types/forms/houses"

import {
  isAutoConsumptionEligible,
  computeAutoConsumptionSubsidy,
} from "./autoConsumption"
import { isGreenDealEligible, computeGreenDealSubsidy } from "./greenDeal"
import { computeToulouseMetropolisSubsidy } from "./toulouseMetropolis"

export const PhotovoltaicSubsidyType = {
  AUTO_CONSUMPTION: "AUTO_CONSUMPTION",
  GREEN_DEAL: "GREEN_DEAL",
  TOULOUSE_METROPOLIS: "TOULOUSE_METROPOLIS",
} as const

export type PhotovoltaicSubsidyType = keyof typeof PhotovoltaicSubsidyType

export type PhotovoltaicSubsidyAmountInput = {
  photovoltaicInstallation: InstallationsResponses.PhotovoltaicInstallation
  house: Pick<
    HousesResponses.House<{ include: { roofSections: true } }>,
    "constructionYear" | "currentType" | "roofType" | "roofSections"
  >
  totalDiscount: number
}

export type PhotovoltaicSubsidyEligibilityInput = {
  postcode: string
  roofSections: RoofSectionConfig[]
}

const isToulouseMetropolisEligible = ({
  postcode,
}: PhotovoltaicSubsidyEligibilityInput): boolean => postcode.startsWith("31")

type PhotovoltaicSubsidyInfo = {
  name: string
  shortName: string
  payoutConditions: string
  disclaimer?: string
  isEligible: (input: PhotovoltaicSubsidyEligibilityInput) => boolean
  computeAmount: (input: PhotovoltaicSubsidyAmountInput) => number
  adminFeeDescription: string
  disabled?: boolean
}

export const PHOTOVOLTAIC_SUBSIDIES: Record<
  PhotovoltaicSubsidyType,
  PhotovoltaicSubsidyInfo
> = {
  AUTO_CONSUMPTION: {
    name: "prime à l'autoconsommation",
    shortName: "Prime Autoconsommation",
    payoutConditions: "versée 1 an après l'installation",
    disclaimer:
      "Le montant de la prime est calculé chaque trimestre par la CRE (Commission de Régulation de l'énergie)",
    isEligible: isAutoConsumptionEligible,
    computeAmount: computeAutoConsumptionSubsidy,
    adminFeeDescription: "Contrat d'obligation d'achat pour revente de surplus",
  },
  GREEN_DEAL: {
    name: "prime Green Deal",
    shortName: "Green Deal",
    payoutConditions: "versée 9 mois après l'installation",
    isEligible: isGreenDealEligible,
    computeAmount: computeGreenDealSubsidy,
    adminFeeDescription: "Prise en charge des démarches Green Deal",
    disabled: true,
  },
  TOULOUSE_METROPOLIS: {
    name: "prime solaire métropole Toulouse",
    shortName: "Toulouse Métropole",
    payoutConditions: "versée 3 mois après le dépôt du dossier",
    isEligible: isToulouseMetropolisEligible,
    computeAmount: computeToulouseMetropolisSubsidy,
    adminFeeDescription: "Prise en charge des démarches Toulouse Métropole",
  },
}

export const computePhotovoltaicSubsidyType = (
  input: PhotovoltaicSubsidyEligibilityInput,
): PhotovoltaicSubsidyType | null => {
  if (PHOTOVOLTAIC_SUBSIDIES.AUTO_CONSUMPTION.isEligible(input)) {
    return PhotovoltaicSubsidyType.AUTO_CONSUMPTION
  }
  return null
}
