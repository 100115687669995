import { Badge, Group, Stack, Text } from "@mantine/core"
import {
  IconArrowRight,
  IconTrendingDown,
  IconWallet,
} from "@tabler/icons-react"

import { formatCurrency } from "@ensol/shared/utils/format"

import { SummarySection } from "../shared"

type Props = {
  billReductionRatio: number
  firstYearSavings: number
  firstYearBill: number
}

export const SavingsSummary = ({
  billReductionRatio,
  firstYearSavings,
  firstYearBill,
}: Props) => (
  <SummarySection
    title="Vos économies"
    Icon={IconWallet}
    badge={`${formatCurrency(firstYearSavings, 0)}/an`}
  >
    <Group justify="space-between" wrap="nowrap">
      <Stack gap="4">
        <Text size="lg" c="red" fw="bold">
          {formatCurrency(firstYearBill, 0)}
        </Text>
        <Text c="gray.6">Facture aujourd&apos;hui</Text>
      </Stack>
      <IconArrowRight size={20} />
      <Stack gap="4">
        <Group gap="8">
          <Text size="lg" c="green" fw="bold">
            {formatCurrency(firstYearBill * (1 - billReductionRatio), 0)}
          </Text>
          <Badge
            variant="light"
            color="green"
            leftSection={<IconTrendingDown size={14} />}
            size="md"
          >
            {Math.round(billReductionRatio * 100)}%
          </Badge>
        </Group>
        <Text c="gray.6">Facture demain</Text>
      </Stack>
    </Group>
  </SummarySection>
)
