import { submittedStepFormSchema } from "@ensol/types/forms/projects/enedis"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { EnedisForm } from "@ensol/entool/pages/Project/processes/Enedis/components/EnedisForm"
import { ENEDIS_PROPERTIES } from "@ensol/entool/pages/Project/processes/Enedis/config"
import { pickDefinedProperties } from "@ensol/entool/utils/helpers/pick"
import { getInitialValues } from "@ensol/entool/utils/initialValues"

export const Validated = ({ project, processId }: StepComponentProps) => (
  <EnedisForm
    schema={submittedStepFormSchema}
    initialValues={{
      ...getInitialValues(submittedStepFormSchema, project),
      ...pickDefinedProperties(ENEDIS_PROPERTIES, project),
    }}
    project={project}
    processId={processId}
    actions={[{ action: "validate", label: "En attente mise en service" }]}
    relatedProcesses={["consuelStep"]}
  />
)
