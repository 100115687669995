import { Group } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { IconBriefcase } from "@tabler/icons-react"

import { Client } from "@ensol/types/entities/clients"

import { Link } from "@ensol/shared/components/Link"
import { devices } from "@ensol/shared/styles/theme"

import { queryClient } from "@ensol/entool/backend/queryClient"
import { ClientHeader } from "@ensol/entool/components/entities/Client/ClientHeader"
import { K2_LOGIN_URL } from "@ensol/entool/config"

type Props = {
  projectId: string
  client: Client
  children?: React.ReactNode
}

export const Header = ({ projectId, client, children }: Props) => {
  const isMobile = useMediaQuery(devices.sm)

  return (
    <ClientHeader
      client={client}
      Actions={
        !isMobile && (
          <Group gap="4">
            <Link
              label="Raccourci K2"
              to={K2_LOGIN_URL}
              Icon={<img src="/k2Logo.webp" height={20} />}
              color="red"
              isExternal
              isIconButton
            />
            <Link
              label="Projet"
              to={`/projects/${projectId}`}
              Icon={<IconBriefcase />}
              color="gray.5"
              isIconButton
            />
          </Group>
        )
      }
      RightSection={!isMobile && children}
      onClientEditSuccess={() => {
        queryClient.invalidateQueries({ queryKey: ["projects", projectId] })
      }}
    />
  )
}
