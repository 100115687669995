import { Accordion, Textarea } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { useMediaQuery } from "@mantine/hooks"
import { useState } from "react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { TechnicalOverviewInput } from "@ensol/types/forms/projects/technicalOverview"

import { devices } from "@ensol/shared/styles/theme"

import { SubSection } from "@ensol/entool/components/SubSection"
import { ProjectNotes } from "@ensol/entool/components/entities/Project/ProjectNotes"
import { TextInfo } from "@ensol/entool/components/entities/Project/TextInfo"

type Props = {
  project: ProjectResponses.ProjectTechnicalOverview
  form: UseFormReturnType<TechnicalOverviewInput>
}

enum AccordionItem {
  CHAT = "Chat",
  CONCLUSION_SALES_VISIT = "Conclusion VC",
  CONCLUSION_TECHNICAL_VISIT = "Conclusion VT",
}

export const Notes = ({ project, form }: Props) => {
  const isMobile = useMediaQuery(devices.sm)
  const [openedAccordion, setOpenedAccordion] = useState<string | null>(null)

  return (
    <SubSection title="Notes">
      <Accordion
        value={openedAccordion}
        onChange={setOpenedAccordion}
        variant="contained"
      >
        {!isMobile && (
          <Accordion.Item value={AccordionItem.CHAT}>
            <Accordion.Control bg="white">
              {AccordionItem.CHAT}
            </Accordion.Control>
            {openedAccordion === AccordionItem.CHAT && (
              <Accordion.Panel bg="white">
                <ProjectNotes
                  projectId={project.id}
                  h={400}
                  justify="flex-end"
                />
              </Accordion.Panel>
            )}
          </Accordion.Item>
        )}
        <Accordion.Item value={AccordionItem.CONCLUSION_SALES_VISIT}>
          <Accordion.Control bg="white">
            {AccordionItem.CONCLUSION_SALES_VISIT}
          </Accordion.Control>
          <Accordion.Panel bg="white">
            <TextInfo value={project.technicalVisitSalesComments} />
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value={AccordionItem.CONCLUSION_TECHNICAL_VISIT}>
          <Accordion.Control bg="white">
            {AccordionItem.CONCLUSION_TECHNICAL_VISIT}
          </Accordion.Control>
          <Accordion.Panel bg="white">
            <Textarea
              autosize
              minRows={3}
              w="100%"
              {...form.getInputProps("technicalVisitInstallerConclusion")}
            />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </SubSection>
  )
}
