import { useForm, zodResolver } from "@mantine/form"
import { ZodSchema } from "zod"

import { Project } from "@ensol/types/entities/project"
import { Nullable } from "@ensol/types/utils"

import { ProcessId } from "@ensol/shared/entities/projects/processes"

import { useUpdateProcessDataMutation } from "@ensol/entool/queries/projects"
import { useDebounce } from "@ensol/entool/utils/helpers/useDebounce"

export type ProcessFormProps<Input> = {
  project: Project
  schema: ZodSchema<Input>
  initialValues: Nullable<Input>
}

const UPDATE_URL_BY_PROCESS_ID: Record<ProcessId, string> = {
  preliminaryRequestStep: "preliminary-request",
  technicalVisitStep: "technical-visit",
  installationStep: "installation",
  paymentStep: "payment",
  hardwareStep: "hardware",
  greendealStep: "greendeal",
  enedisStep: "enedis",
  consuelStep: "consuel",
  edfOaStep: "edf-oa",
  toulouseMetropolisStep: "toulouse-metropolis",
  followUpStep: "follow-up",
}

export const useProcessForm = <Input extends Record<string, unknown>>({
  schema,
  initialValues,
  projectId,
  processId,
}: {
  projectId: string
  schema: ZodSchema<Input>
  initialValues: Nullable<Input>
  processId?: ProcessId
}) => {
  const { mutateAsync: updateProject } = useUpdateProcessDataMutation<
    Nullable<Input>
  >(processId ? UPDATE_URL_BY_PROCESS_ID[processId] : "", projectId)
  const debouncedUpdateProject = useDebounce(updateProject, 250)

  const form = useForm({
    validateInputOnChange: true,
    validate: zodResolver(schema),
    initialValues,
    onValuesChange: (values) => debouncedUpdateProject(values),
  })

  return form
}
