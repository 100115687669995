import { IconArrowBackUp } from "@tabler/icons-react"

import { pendingStepsFormSchema } from "@ensol/types/forms/projects/installation"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/utils/initialValues"

import { InstallationForm } from "./components/InstallationForm"

export const Requested = ({ project, processId }: StepComponentProps) => (
  <InstallationForm
    schema={pendingStepsFormSchema}
    initialValues={getInitialValues(pendingStepsFormSchema, project)}
    project={project}
    processId={processId}
    relatedProcesses={["technicalVisitStep", "preliminaryRequestStep"]}
    actions={[
      {
        action: "reschedule",
        label: "Repasser en prêt à planifier",
        color: "orange",
        Icon: IconArrowBackUp,
        confirmationMessage:
          "L'évènement installateur sera supprimé et l'installateur sera averti. Continuer ?",
      },
    ]}
  />
)
