type HouseInsightInfo = {
  name: string
}

export const HouseInsightType = {
  LOW_AUTO_CONSUMPTION: "LOW_AUTO_CONSUMPTION",
  HIGH_AUTO_CONSUMPTION: "HIGH_AUTO_CONSUMPTION",
  LOW_AUTO_PRODUCTION: "LOW_AUTO_PRODUCTION",
  BOILER_MISSCHEDULED: "BOILER_MISSCHEDULED",
  EV_DETECTED: "EV_DETECTED",
} as const

export type HouseInsightType = keyof typeof HouseInsightType

const HOUSE_INSIGHTS: Record<HouseInsightType, HouseInsightInfo> = {
  [HouseInsightType.LOW_AUTO_CONSUMPTION]: {
    name: "+batterie",
  },
  [HouseInsightType.HIGH_AUTO_CONSUMPTION]: {
    name: "+PV (basse production)",
  },
  [HouseInsightType.LOW_AUTO_PRODUCTION]: {
    name: "+PV (haute consommation)",
  },
  [HouseInsightType.BOILER_MISSCHEDULED]: {
    name: "Chauffe-eau mal réglé",
  },
  [HouseInsightType.EV_DETECTED]: {
    name: "+borne de recharge",
  },
}

export const getHouseInsight = (type: HouseInsightType) => HOUSE_INSIGHTS[type]
