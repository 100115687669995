import { Clerk } from "@clerk/clerk-js/headless"
import { ClerkProvider } from "@clerk/clerk-react"
import { useNavigate, Outlet } from "react-router-dom"

type Props = {
  clerkInstance: Clerk
  publishableKey: string
}

export const AuthProvider = ({ clerkInstance, publishableKey }: Props) => {
  const navigate = useNavigate()

  return (
    <ClerkProvider
      Clerk={clerkInstance}
      routerPush={(url: string) => navigate(url)}
      routerReplace={(url: string) => navigate(url)}
      publishableKey={publishableKey}
    >
      <Outlet />
    </ClerkProvider>
  )
}
