import { Anchor, Group, Text } from "@mantine/core"
import { Link } from "react-router-dom"

import {
  Notification,
  NotificationType,
} from "@ensol/types/entities/notifications"

import { formatFullName } from "@ensol/shared/utils/format"

type Props = {
  notification: Notification
  onDrawerClose: () => void
}

export const NotificationContent = ({ notification, onDrawerClose }: Props) => {
  const { data, notifier } = notification

  const isArchived = notification.archivedAt !== null
  const notifierName = notifier ? formatFullName(notifier) : "Entool"

  if (data.type === NotificationType.PROJECT_NOTE_MENTION) {
    return (
      <Group gap="0px 4px">
        <Text fw={500}>{notifierName}</Text>
        <Text c="gray.7">vous a mentionné(e) dans le projet de</Text>
        <Anchor
          component={Link}
          to={`/projects/${data.projectId}`}
          c={isArchived ? "gray.7" : "blue"}
          onClick={onDrawerClose}
          style={{ textDecoration: "underline" }}
        >
          {data.projectName}
        </Anchor>
      </Group>
    )
  }

  return null
}
