import { useForm, zodResolver } from "@mantine/form"

import { Project } from "@ensol/types/entities/project"
import { salesVisitFormSchema } from "@ensol/types/forms/projects/salesVisit"

import { getCetelemFormInitialValues } from "@ensol/entool/pages/Project/processes/Payment/cetelem/util"
import { useUpdateProcessDataMutation } from "@ensol/entool/queries/projects"
import { useDebounce } from "@ensol/entool/utils/helpers/useDebounce"

export const useProjectForm = (project: Project) => {
  const {
    id,
    salesOwnerId,
    salesVisitPanelsLayoutPath,
    salesVisitK2ReportPath,
    salesVisitClientMotivation,
    fundingSource,
    technicalVisitSalesComments,
    technicalVisitStartDate,
    technicalVisitInstallerId,
    technicalVisitOwnerId,
  } = project

  const { mutateAsync: updateProject } = useUpdateProcessDataMutation(
    "sales-visit",
    id,
  )
  const debouncedUpdateProject = useDebounce(updateProject, 250)

  const projectForm = useForm({
    validateInputOnChange: true,
    validate: zodResolver(salesVisitFormSchema),
    onValuesChange: async (values) => debouncedUpdateProject(values),
    initialValues: {
      salesOwnerId,
      salesVisitPanelsLayoutPath,
      salesVisitK2ReportPath,
      salesVisitClientMotivation,
      fundingSource,
      technicalVisitSalesComments,
      technicalVisitStartDate,
      technicalVisitInstallerId,
      technicalVisitOwnerId,
      ...getCetelemFormInitialValues(project),
    },
  })

  return projectForm
}
