import { locales } from "@blocknote/core"
import { useCreateBlockNote } from "@blocknote/react"
import { useForm, zodResolver } from "@mantine/form"
import { useCallback } from "react"

import {
  ProjectNoteInput,
  projectNoteSchema,
} from "@ensol/types/forms/projects/note"

import { useAddProjectNoteMutation } from "@ensol/entool/queries/projects"
import { useAuthenticatedUser } from "@ensol/entool/queries/users"

import { schema } from "./schema"

export const useNoteForm = ({
  projectId,
  inputPlaceholder,
}: {
  projectId: string
  inputPlaceholder?: string
}) => {
  const { data: connectedUser } = useAuthenticatedUser()
  const { mutateAsync: addProjectNotes, isPending: isAddNoteLoading } =
    useAddProjectNoteMutation(projectId)

  const noteForm = useForm<ProjectNoteInput>({
    validate: zodResolver(projectNoteSchema),
    initialValues: {
      content: [],
      userId: connectedUser?.id ?? "",
    },
  })

  const editor = useCreateBlockNote({
    schema,
    dictionary: {
      ...locales.fr,
      placeholders: {
        ...locales.fr.placeholders,
        default: inputPlaceholder,
      },
    },
  })

  const onSubmit = useCallback(
    async ({ content }: ProjectNoteInput) => {
      noteForm.validate()
      if (connectedUser) {
        await addProjectNotes({
          content,
          userId: connectedUser.id,
        })
        noteForm.reset()
        editor.replaceBlocks(editor.document, [])
      }
    },
    [connectedUser, addProjectNotes, editor, noteForm],
  )

  return {
    isAddNoteLoading,
    noteForm,
    onSubmit,
    editor,
  }
}
