import dayjs from "dayjs"

import { getHouseAlert } from "@ensol/shared/entities/houses/alerts"

import { InsightsBadges } from "@ensol/entool/components/entities/Installation/InsightsBadges"
import { IssuesBadges } from "@ensol/entool/components/entities/Project/IssuesBadges"
import { ProcessInfos } from "@ensol/entool/components/entities/Project/ProcessInfos"

import { InfoComponentProps } from "../config"

export const FollowUpInfo = ({
  project: { followUpProductionStartDate, houseOpenedAlerts, installation },
}: InfoComponentProps) => {
  const daysSinceProduction = dayjs().diff(followUpProductionStartDate, "day")

  return (
    <ProcessInfos
      infos={[
        {
          name: "Produit depuis",
          value: followUpProductionStartDate
            ? `${daysSinceProduction} jours`
            : null,
        },
        {
          name: "Alertes",
          dataType: "component",
          value: (
            <IssuesBadges
              issues={houseOpenedAlerts.map(
                (type) => getHouseAlert(type).shortName,
              )}
            />
          ),
          isHidden: houseOpenedAlerts.length === 0,
        },
        {
          name: "Conseil",
          dataType: "component",
          value: <InsightsBadges insights={installation.house.insights} />,
          isHidden: installation.house.insights.length === 0,
        },
      ]}
    />
  )
}
