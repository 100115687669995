import { installationToValidateFormSchema } from "@ensol/types/forms/projects/followUp"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { FollowUpForm } from "@ensol/entool/pages/Project/processes/FollowUp/components/FollowUpForm"
import { getInitialValues } from "@ensol/entool/utils/initialValues"

export const LessThan1MonthProducing = ({
  project,
  ...props
}: StepComponentProps) => (
  <FollowUpForm
    schema={installationToValidateFormSchema}
    initialValues={getInitialValues(installationToValidateFormSchema, project)}
    project={project}
    actions={[
      {
        action: "validate",
        label: "Onboarding à faire",
        confirmationMessage:
          "L'étape sera validée automatiquement lorsque l'installation produira depuis 1 mois, voulez-vous la valider manuellement ?",
      },
    ]}
    {...props}
  />
)
