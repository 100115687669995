import {
  SimpleGrid,
  Stack,
  Select,
  Textarea,
  Divider,
  Button,
  Group,
} from "@mantine/core"
import { DateInput } from "@mantine/dates"
import { useForm, zodResolver } from "@mantine/form"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import {
  TechnicalOverviewValidateInstallationInput,
  technicalOverviewValidateInstallationSchema,
} from "@ensol/types/forms/projects/technicalOverview"

import { Section } from "@ensol/shared/components/Section"
import {
  hasReachedStep,
  InstallationStep,
} from "@ensol/shared/entities/projects/processes"
import { Team } from "@ensol/shared/entities/users"
import { DISPLAY_DATE_FORMAT } from "@ensol/shared/utils/format"

import { Field } from "@ensol/entool/components/form/Field"
import { FormField } from "@ensol/entool/components/form/FormField"
import { FormCheckbox } from "@ensol/entool/components/form/Project/FormCheckbox"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { UserSelect } from "@ensol/entool/components/form/UserSelect"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { useValidateInstallationMutation } from "@ensol/entool/queries/projects/technicalOverview"
import { booleanOptions } from "@ensol/entool/utils/form/options"
import { INSTALLATION_NEW_VISIT_REASONS_OPTIONS } from "@ensol/entool/utils/projects/options"

type Props = {
  project: ProjectResponses.ProjectTechnicalOverview
}

export const InstallationValidationSection = ({ project }: Props) => {
  const form = useForm({
    validate: zodResolver(technicalOverviewValidateInstallationSchema),
    initialValues: {
      installationNewVisitDate: project.installationNewVisitDate,
      installationNewVisitReason: project.installationNewVisitReason,
      installationNewVisitNotes: project.installationNewVisitNotes,
      technicalManagerId: project.technicalManagerId,
      isInstallationCompleted: hasReachedStep({
        project,
        processId: "installationStep",
        step: InstallationStep.COMPLETED,
      }),
      isActivationDone: project.isActivationDone,
    },
  })

  const {
    mutateAsync: validateInstallation,
    isPending: isValidateInstallationPending,
  } = useValidateInstallationMutation(project.id)

  const handleSubmit = async (
    values: TechnicalOverviewValidateInstallationInput,
  ) => await validateInstallation(values)

  return (
    <Section
      title="Validation de l'installation"
      isCollapsible
      w="100%"
      isCollapsed={true}
    >
      <form
        onSubmit={form.onSubmit((values) =>
          handleSubmit(
            technicalOverviewValidateInstallationSchema.parse(values),
          ),
        )}
      >
        <Stack gap={24}>
          <FormField
            initialValues={form.values}
            propertyName="technicalManagerId"
            isRequired
          >
            <UserSelect
              teams={[Team.TECHNICAL_MANAGER]}
              w={FIELD_WIDTH}
              withAuthenticatedUserAsDefault={false}
              {...form.getInputProps("technicalManagerId")}
            />
          </FormField>
          <SimpleGrid cols={{ base: 1, md: 2 }} spacing={24}>
            <Field name="Le chantier est-il bien terminé ?">
              <RadioGroup
                options={booleanOptions}
                {...form.getInputProps("isInstallationCompleted")}
              />
            </Field>
            <FormCheckbox
              initialValues={form.values}
              propertyName="isActivationDone"
              {...form.getInputProps("isActivationDone")}
            />
          </SimpleGrid>

          {form.values.isInstallationCompleted === false && (
            <>
              <Divider />
              <SimpleGrid cols={{ base: 1, md: 2 }} spacing={24}>
                <Stack gap={24}>
                  <FormField
                    initialValues={form.values}
                    propertyName="installationNewVisitDate"
                  >
                    <DateInput
                      w={FIELD_WIDTH}
                      valueFormat={DISPLAY_DATE_FORMAT}
                      clearable
                      minDate={project.installationStartDate ?? new Date()}
                      {...form.getInputProps("installationNewVisitDate")}
                    />
                  </FormField>
                  <FormField
                    initialValues={form.values}
                    propertyName="installationNewVisitReason"
                    isRequired
                  >
                    <Select
                      w={FIELD_WIDTH}
                      data={INSTALLATION_NEW_VISIT_REASONS_OPTIONS}
                      {...form.getInputProps("installationNewVisitReason")}
                    />
                  </FormField>
                </Stack>
                <FormField
                  initialValues={form.values}
                  propertyName="installationNewVisitNotes"
                >
                  <Textarea
                    autosize
                    w={FIELD_WIDTH}
                    minRows={3}
                    {...form.getInputProps("installationNewVisitNotes")}
                  />
                </FormField>
              </SimpleGrid>
            </>
          )}
          <Group justify="flex-end">
            <Button
              type="submit"
              loading={isValidateInstallationPending}
              disabled={hasReachedStep({
                project,
                processId: "installationStep",
                step: InstallationStep.COMPLETED,
              })}
            >
              Valider
            </Button>
          </Group>
        </Stack>
      </form>
    </Section>
  )
}
