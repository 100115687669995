import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { SubSection } from "@ensol/entool/components/SubSection"

import { DownloadDocument } from "./DownloadDocument"

type Props = {
  project: ProjectResponses.ProjectTechnicalOverview
  config: {
    getFilePaths: (
      project: ProjectResponses.ProjectTechnicalOverview,
    ) => string[]
    fileName: string
  }[]
}

export const DocumentsSubSection = ({ project, config }: Props) => {
  const documents = config.filter(
    ({ getFilePaths }) => getFilePaths(project).length > 0,
  )
  if (documents.length === 0) {
    return null
  }

  return (
    <SubSection title="Documents">
      {documents.map(({ getFilePaths, fileName }) => {
        const filePaths = getFilePaths(project)
        return filePaths.map((path, index) => (
          <DownloadDocument
            key={path}
            filePath={path}
            fileName={`${fileName}${index > 0 ? `-${index}` : ""}`}
          />
        ))
      })}
    </SubSection>
  )
}
