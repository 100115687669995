import dayjs from "dayjs"

import { PreliminaryRequestStep } from "@ensol/shared/entities/projects/processes"
import {
  SHORT_DISPLAY_DATE_FORMAT,
  formatDate,
} from "@ensol/shared/utils/format"

import { IssuesBadges } from "@ensol/entool/components/entities/Project/IssuesBadges"
import { ProcessInfos } from "@ensol/entool/components/entities/Project/ProcessInfos"

import { InfoComponentProps } from "../config"

export const PreliminaryRequestInfo = ({
  project: {
    preliminaryRequestStep,
    preliminaryRequestExpectedValidationDate,
    preliminaryRequestDeadlineDate,
    preliminaryRequestLastContactDate,
    preliminaryRequestCheckCallDate,
    preliminaryRequestReference,
    preliminaryRequestAcknowledgedDate,
    preliminaryRequestABFAcknowledgmentPath,
    preliminaryRequestNotes,
    preliminaryRequestRequestedChangesReasons,
    preliminaryRequestAwaitedStakeholder,
  },
  milestoneId,
}: InfoComponentProps) => {
  const isProcessCompleted =
    preliminaryRequestStep === PreliminaryRequestStep.AUTHORISATION_RECEIVED ||
    preliminaryRequestStep === PreliminaryRequestStep.ABORTED

  const postAcknowledgmentDuration = dayjs().diff(
    dayjs(preliminaryRequestAcknowledgedDate),
    "days",
  )
  const hasABF = preliminaryRequestABFAcknowledgmentPath !== null

  return (
    <ProcessInfos
      infos={[
        {
          name: "Dernier contact",
          value: formatDate(
            preliminaryRequestLastContactDate,
            SHORT_DISPLAY_DATE_FORMAT,
          ),
        },
        {
          name: "Mairie à rappeler le",
          value: formatDate(
            preliminaryRequestCheckCallDate,
            SHORT_DISPLAY_DATE_FORMAT,
          ),
          color: dayjs().isAfter(preliminaryRequestCheckCallDate)
            ? "red.5"
            : undefined,
          isHidden:
            milestoneId !== PreliminaryRequestStep.ACKNOWLEDGMENT &&
            milestoneId !== PreliminaryRequestStep.INCOMPLETE_SUBMITTED &&
            milestoneId !== PreliminaryRequestStep.REJECTED_SUBMITTED,
        },
        {
          name: "Date prévue",
          value: formatDate(
            preliminaryRequestExpectedValidationDate,
            SHORT_DISPLAY_DATE_FORMAT,
          ),
          color:
            !isProcessCompleted &&
            dayjs().isAfter(preliminaryRequestExpectedValidationDate)
              ? "yellow.8"
              : undefined,
        },
        {
          name: "Date d'échéance",
          value: formatDate(
            preliminaryRequestDeadlineDate,
            SHORT_DISPLAY_DATE_FORMAT,
          ),
          color:
            !isProcessCompleted &&
            dayjs().isAfter(preliminaryRequestDeadlineDate)
              ? "red.5"
              : undefined,
        },
        {
          name: "Référence",
          value: preliminaryRequestReference,
        },
        {
          name: `Durée post dépôt${hasABF ? " (ABF)" : ""}`,
          value: `${postAcknowledgmentDuration} jours`,
          color: getPostAcknowledgmentDurationColor(
            postAcknowledgmentDuration,
            hasABF,
          ),
          isHidden: milestoneId !== PreliminaryRequestStep.ACKNOWLEDGMENT,
        },
        {
          name: "Notes",
          value: preliminaryRequestNotes,
          isHidden:
            milestoneId !== PreliminaryRequestStep.PRELIMINARY_VALIDATION &&
            milestoneId !== PreliminaryRequestStep.SUBMISSION_ON_HOLD,
        },
        {
          name: "Raisons",
          dataType: "component",
          value: (
            <IssuesBadges issues={preliminaryRequestRequestedChangesReasons} />
          ),
          isHidden:
            preliminaryRequestRequestedChangesReasons.length === 0 ||
            (preliminaryRequestStep !== PreliminaryRequestStep.INCOMPLETE &&
              preliminaryRequestStep !==
                PreliminaryRequestStep.INCOMPLETE_WAITING_FOR_STAKEHOLDER &&
              preliminaryRequestStep !==
                PreliminaryRequestStep.INCOMPLETE_TO_SUBMIT &&
              preliminaryRequestStep !==
                PreliminaryRequestStep.INCOMPLETE_SUBMITTED &&
              preliminaryRequestStep !== PreliminaryRequestStep.REJECTED &&
              preliminaryRequestStep !==
                PreliminaryRequestStep.REJECTED_WAITING_FOR_STAKEHOLDER &&
              preliminaryRequestStep !==
                PreliminaryRequestStep.REJECTED_TO_SUBMIT &&
              preliminaryRequestStep !==
                PreliminaryRequestStep.REJECTED_SUBMITTED),
        },
        {
          name: "En attente",
          value: preliminaryRequestAwaitedStakeholder,
          isHidden:
            preliminaryRequestStep !==
              PreliminaryRequestStep.INCOMPLETE_WAITING_FOR_STAKEHOLDER &&
            preliminaryRequestStep !==
              PreliminaryRequestStep.REJECTED_WAITING_FOR_STAKEHOLDER,
        },
      ]}
    />
  )
}

const getPostAcknowledgmentDurationColor = (
  postAcknowledgmentDuration: number,
  hasABF: boolean,
) => {
  if (hasABF) {
    if (postAcknowledgmentDuration > 80) {
      return "red"
    } else if (postAcknowledgmentDuration > 60) {
      return "yellow"
    } else {
      return "blue"
    }
  }

  if (postAcknowledgmentDuration > 30) {
    return "red"
  } else if (postAcknowledgmentDuration > 20) {
    return "yellow"
  } else {
    return "blue"
  }
}
