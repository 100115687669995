import { showNotification } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"

import { KizeoProjectReportType } from "@ensol/types/entities/project/kizeoProjectReport"

import { isEnsolError } from "@ensol/shared/utils/errors"

import { httpClient } from "@ensol/entool/backend/axios"
import { queryClient } from "@ensol/entool/backend/queryClient"

export const useCreateKizeoProjectReportMutation = (
  projectId: string,
  kizeoUserId: string | null,
) => {
  return useMutation({
    mutationFn: async (type: KizeoProjectReportType): Promise<void> => {
      await httpClient.post("/kizeo-project-reports", {
        type,
        kizeoUserId,
        projectId,
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["projects", projectId, "technical-overview"],
      })
      await queryClient.invalidateQueries({
        queryKey: ["projects", projectId],
      })
    },
    onError: async (error) => {
      if (isEnsolError(error)) {
        showNotification({
          title: "Erreur lors de la création du Kizeo",
          message: error.message,
          color: "red",
        })
      } else {
        showNotification({
          title: "Erreur lors de la création du Kizeo",
          message: "Merci de réessayer",
          color: "red",
        })
      }
    },
  })
}

export const useDeleteKizeoProjectReportMutation = (projectId: string) => {
  return useMutation({
    mutationFn: async (kizeoDataId: string) => {
      await httpClient.delete(`/kizeo-project-reports/${kizeoDataId}`)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["projects", projectId, "technical-overview"],
      })
      await queryClient.invalidateQueries({
        queryKey: ["projects", projectId],
      })
    },
    onError: async (error) => {
      if (isEnsolError(error)) {
        showNotification({
          title: "Erreur lors de la suppression du Kizeo",
          message: error.message,
          color: "red",
        })
      } else {
        showNotification({
          title: "Erreur lors de la suppression du Kizeo",
          message: "Merci de réessayer",
          color: "red",
        })
      }
    },
  })
}
