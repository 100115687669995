import { IconHomeMove } from "@tabler/icons-react"
import * as z from "zod"

import { inProgressStepFormSchema } from "@ensol/types/forms/projects/installation"
import { checkField } from "@ensol/types/forms/projects/shared"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/utils/initialValues"
import { validateAction } from "@ensol/entool/utils/projects/validateAction"

import { InstallationForm } from "./components/InstallationForm"

export const InProgress = ({ project, ...props }: StepComponentProps) => (
  <InstallationForm
    schema={inProgressStepFormSchema}
    initialValues={getInitialValues(inProgressStepFormSchema, project)}
    project={project}
    actions={[
      {
        action: "newVisitNeeded",
        label: "Chantier non-terminé",
        color: "orange",
        Icon: IconHomeMove,
      },
      {
        action: "validate",
        label: "Installation complétée",
        validateAction: validateAction(
          z.object({ isActivationDone: checkField }),
        ),
      },
    ]}
    {...props}
  />
)
