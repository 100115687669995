import { Button, Text } from "@mantine/core"
import { modals } from "@mantine/modals"
import { IconTrash } from "@tabler/icons-react"

import { useDeleteEstimateMutation } from "@ensol/entool/queries/projects"

type Props = {
  projectId: string
}

export const DeleteEstimateButton = ({ projectId }: Props) => {
  const { mutateAsync: deleteEstimate, isPending: isEstimateDeleting } =
    useDeleteEstimateMutation(projectId)

  return (
    <Button
      color="red"
      variant="light"
      loading={isEstimateDeleting}
      leftSection={<IconTrash size={20} />}
      onClick={() =>
        modals.openConfirmModal({
          title: "Supprimer le devis",
          children: (
            <Text size="sm">
              Êtes-vous sûr(e) de vouloir supprimer ce devis ? Cette action va
              également remettre le processus paiement au début pour ce projet.
            </Text>
          ),
          confirmProps: { color: "red" },
          centered: true,
          labels: { confirm: "Supprimer", cancel: "Annuler" },
          onConfirm: deleteEstimate,
        })
      }
    >
      Supprimer le devis
    </Button>
  )
}
