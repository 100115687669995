import { Group, Avatar, Text, Tooltip, MantineStyleProps } from "@mantine/core"
import { IconUserSquareRounded } from "@tabler/icons-react"

import { OWNER_SELECT } from "@ensol/types/endpoints/projects/technicalOverview"
import { Project } from "@ensol/types/entities/project"

type Props = {
  project: Project<{
    select: {
      salesOwner: { select: typeof OWNER_SELECT }
      operationsOwner: { select: typeof OWNER_SELECT }
      adminOwner: { select: typeof OWNER_SELECT }
      technicalVisitOwner: { select: typeof OWNER_SELECT }
    }
  }>
} & MantineStyleProps

export const ProjectOwners = ({ project, ...props }: Props) => {
  const { salesOwner, operationsOwner, adminOwner, technicalVisitOwner } =
    project

  return (
    <Group gap="2" {...props}>
      {operationsOwner && (
        <Owner label="Responsable Ops" {...operationsOwner} />
      )}
      {salesOwner && <Owner label="Responsable Sales" {...salesOwner} />}
      {adminOwner && <Owner label="Responsable Admin" {...adminOwner} />}
      {technicalVisitOwner && (
        <Owner label="Responsable VT" {...technicalVisitOwner} />
      )}
    </Group>
  )
}

type OwnerProps = {
  label: string
  avatarUrl: string | null
  firstName: string
  lastName: string
}

const Owner = ({ label, avatarUrl, firstName, lastName }: OwnerProps) => (
  <Tooltip
    label={
      <Group gap="4">
        <IconUserSquareRounded size={18} />
        <Text>
          {label}: {firstName} {lastName}
        </Text>
      </Group>
    }
  >
    <Avatar src={avatarUrl} size="24" />
  </Tooltip>
)
