import { useQuery } from "@tanstack/react-query"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import {
  ProjectsInstallersOverviewFilters,
  ListProjectsFilters,
} from "@ensol/types/forms/projects"

import { httpClient } from "@ensol/entool/backend/axios"

type GetProjectsOperationsOverviewOptions = {
  filters: ListProjectsFilters & {
    onlyActionNeeded?: boolean
  }
  enabled?: boolean
}

export const useGetProjectsOperationsOverviewQuery = ({
  filters,
  enabled = true,
}: GetProjectsOperationsOverviewOptions) => {
  return useQuery<ProjectResponses.ProjectsOperationsOverview>({
    queryKey: ["projects", "overview/operations", filters],
    queryFn: async () => {
      const response = await httpClient.post(
        "/projects/overview/operations",
        filters,
      )
      return response.data
    },
    enabled,
  })
}

type GetProjectsInstallersOverviewOptions = {
  filters: ProjectsInstallersOverviewFilters
}

export const useGetProjectsInstallersOverviewQuery = ({
  filters,
}: GetProjectsInstallersOverviewOptions) => {
  return useQuery<ProjectResponses.ProjectsInstallersOverview>({
    queryKey: ["projects", "overview/installers", filters],
    queryFn: async () => {
      const response = await httpClient.post(
        "/projects/overview/installers",
        filters,
      )
      return response.data
    },
  })
}
