import {
  MultiSelect,
  MultiSelectProps,
  Select,
  SelectProps,
} from "@mantine/core"

import { useSuppliersOptions } from "@ensol/entool/queries/suppliers"

export const SupplierMultiSelect = (props: Omit<MultiSelectProps, "data">) => {
  const suppliersOptions = useSuppliersOptions()

  return <MultiSelect {...props} data={suppliersOptions} />
}

export const SupplierSelect = (props: Omit<SelectProps, "data">) => {
  const suppliersOptions = useSuppliersOptions()

  return <Select {...props} data={suppliersOptions} />
}
