import { Button, Group, Text, ThemeIcon, Tooltip } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import {
  IconChevronsRight,
  IconCheckbox,
  IconCircleX,
} from "@tabler/icons-react"

import { Project } from "@ensol/types/entities/project"

import { ProjectStatus } from "@ensol/shared/entities/projects/statuses"
import { devices } from "@ensol/shared/styles/theme"

import { ProjectActions } from "@ensol/entool/pages/Project/ProjectActions"

type Props = {
  project: Project
  submitProject: () => void
  isSubmitting: boolean
}

export const Actions = ({ project, submitProject, isSubmitting }: Props) => {
  const isMobile = useMediaQuery(devices.sm)

  if (project.status === ProjectStatus.CREATED) {
    return (
      <Tooltip label="Le projet n'est pas encore signé">
        <Button disabled>Transmettre le projet aux Ops</Button>
      </Tooltip>
    )
  }

  if (project.isSalesVisitValidated === false) {
    return (
      <Group wrap="nowrap">
        <Button
          mt="4"
          leftSection={<IconChevronsRight size={14} />}
          loading={isSubmitting}
          onClick={submitProject}
        >
          Transmettre le projet aux Ops
        </Button>
        {!isMobile && <ProjectActions project={project} />}
      </Group>
    )
  }

  if (project.status === ProjectStatus.ABORTED) {
    return (
      <Group align="center" gap="4" pt="4">
        <ThemeIcon c="red">
          <IconCircleX />
        </ThemeIcon>
        <Text size="sm" c="red">
          Projet annulé
        </Text>
      </Group>
    )
  }

  return (
    <Group align="center" gap="4" pt="4">
      <ThemeIcon c="green">
        <IconCheckbox />
      </ThemeIcon>
      <Text size="sm" c="green.9">
        Projet commencé
      </Text>
    </Group>
  )
}
