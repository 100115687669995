import _ from "lodash"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"

import { computeDiscountBeforeTax } from "@ensol/shared/entities/installations/costs/discounts"
import { ControlDevicesCostsBuilder } from "@ensol/shared/entities/installations/costs/products/ControlDevicesCostsBuilder"
import { EvChargerCostsBuilder } from "@ensol/shared/entities/installations/costs/products/EvChargerCostsBuilder"
import { Subsidy } from "@ensol/shared/entities/installations/subsidies"
import { roundDecimalNumber } from "@ensol/shared/utils/format"

import { BatteryCostsBuilder } from "./products/BatteryCostsBuilder"
import { PhotovoltaicCostsBuilder } from "./products/PhotovoltaicCostsBuilder"

// MARGINS (%)
const ENSOL_MARGIN = 0.25 // %

export const withMargin = (
  value: number,
  margin: number = ENSOL_MARGIN,
): number => roundDecimalNumber(value * (1 + margin), 0)

type EffectiveMarginInput = {
  installationCost: number
  finalCost: number
}

export const computeEffectiveMargin = ({
  installationCost,
  finalCost,
}: EffectiveMarginInput): number => {
  const installationCostWithoutMargin = installationCost / (1 + ENSOL_MARGIN)
  return finalCost / installationCostWithoutMargin - 1
}

type AllCostsInput = {
  photovoltaicInstallation: InstallationsResponses.PhotovoltaicInstallation | null
  batteryInstallation: InstallationsResponses.BatteryInstallation | null
  evChargerInstallation: InstallationsResponses.EvChargerInstallation | null
  controlDeviceInstallations: InstallationsResponses.ControlDeviceInstallations
  productsCount: number
  house: Pick<
    HousesResponses.House<{ include: { roofSections: true } }>,
    "constructionYear" | "currentType" | "roofType" | "roofSections"
  >
  totalDiscount: number
  isUpsell: boolean
}

export type ProductCosts = {
  installationCost: number
  installationCostBeforeTax: number
  installationCostWithSubsidies: number
  vatRate: number
}

type AllCosts = {
  installationCost: number
  finalCost: number
  finalCostBeforeTax: number
  finalCostWithSubsidies: number
  installationCostsByProduct: {
    productType: string
    installationCost: number
  }[]
  subsidies: Subsidy[]
}

export const computeAllCosts = ({
  photovoltaicInstallation,
  batteryInstallation,
  evChargerInstallation,
  controlDeviceInstallations,
  productsCount,
  house,
  totalDiscount,
  isUpsell,
}: AllCostsInput): AllCosts => {
  const subsidies = new Array<Subsidy>()
  const productsCosts = new Array<{
    installationCost: number
    installationCostBeforeTax: number
    installationCostWithSubsidies: number
    vatRate: number
  }>()
  const installationCostsByProduct = new Array<{
    productType: string
    installationCost: number
  }>()

  const costsBuilders = {
    photovoltaicInstallation:
      photovoltaicInstallation !== null
        ? new PhotovoltaicCostsBuilder(
            photovoltaicInstallation,
            house,
            totalDiscount,
            isUpsell,
          )
        : null,
    batteryInstallation:
      batteryInstallation !== null
        ? new BatteryCostsBuilder(batteryInstallation)
        : null,
    evChargerInstallation:
      evChargerInstallation !== null
        ? new EvChargerCostsBuilder(evChargerInstallation, productsCount)
        : null,
    controlDeviceInstallations:
      controlDeviceInstallations.length > 0
        ? new ControlDevicesCostsBuilder(
            controlDeviceInstallations,
            productsCount,
          )
        : null,
  }

  Object.entries(costsBuilders).map(([productType, costsBuilder]) => {
    if (costsBuilder === null) {
      return
    }

    const { subsidy, ...costs } = costsBuilder.computeAllCosts()
    productsCosts.push(costs)
    installationCostsByProduct.push({
      productType,
      installationCost: costs.installationCost,
    })
    if (subsidy) {
      subsidies.push(subsidy)
    }
  })

  const installationCost = _.sumBy(
    productsCosts,
    ({ installationCost }) => installationCost,
  )
  const installationCostBeforeTax = _.sumBy(
    productsCosts,
    ({ installationCostBeforeTax }) => installationCostBeforeTax,
  )
  const installationCostWithSubsidies = _.sumBy(
    productsCosts,
    ({ installationCostWithSubsidies }) => installationCostWithSubsidies,
  )

  return {
    installationCost,
    finalCost: installationCost - totalDiscount,
    finalCostBeforeTax: roundDecimalNumber(
      installationCostBeforeTax -
        computeDiscountBeforeTax({
          totalDiscount,
          totalInstallationCost: installationCost,
          productsCosts,
        }),
    ),
    finalCostWithSubsidies: installationCostWithSubsidies - totalDiscount,
    installationCostsByProduct,
    subsidies,
  }
}
