import { beingProcessedStepFormSchema } from "@ensol/types/forms/projects/technicalVisit"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { TechnicalVisitForm } from "@ensol/entool/pages/Project/processes/TechnicalVisit/components/TechnicalVisitForm"
import { getInitialValues } from "@ensol/entool/utils/initialValues"

export const UpdateInstallation = ({
  project,
  ...props
}: StepComponentProps) => (
  <TechnicalVisitForm
    schema={beingProcessedStepFormSchema}
    initialValues={getInitialValues(beingProcessedStepFormSchema, project)}
    project={project}
    actions={[{ action: "validate", label: "Valider" }]}
    {...props}
  />
)
