// This method returns the list of house section panels with their associated panels count if some, 0 otherwise.

import _ from "lodash"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { RoofSectionWithPanels } from "@ensol/types/forms/installations/photovoltaic"

// They can differ from installation.roofSectionsWithPanels if some roof sections are empty, have been deleted or added.
export const computeHouseRoofSectionsWithPanels = (
  roofSections: HousesResponses.House<{
    include: { roofSections: true }
  }>["roofSections"],
  roofSectionsWithPanels: InstallationsResponses.PhotovoltaicInstallation["roofSectionsWithPanels"],
): RoofSectionWithPanels[] => {
  return _.sortBy(
    roofSections.map((roofSection) => {
      const relatedRoofSectionWithPanel = roofSectionsWithPanels.find(
        (roofSectionWithPanels) =>
          roofSection.id === roofSectionWithPanels.roofSection.id,
      )
      if (relatedRoofSectionWithPanel !== undefined) {
        return {
          ...relatedRoofSectionWithPanel,
          roofSection: {
            ...roofSection,
            horizonProfileFilePath: roofSection.horizonProfileFilePath,
          },
        }
      } else {
        return {
          roofSection,
          panelsCount: 0,
        }
      }
    }),
    ({ roofSection, panelsCount }) =>
      // Sort by number of installed panels and then by orientation
      Math.abs(roofSection.orientation - 180) - panelsCount,
  )
}
