import { SimpleGrid } from "@mantine/core"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { OnSiteInstallation } from "@ensol/types/entities/installation"
import { InstallationFormInput } from "@ensol/types/forms/installations"

import { Section } from "@ensol/shared/components/Section"
import { getCoords } from "@ensol/shared/entities/houses/coords"
import { roundDecimalNumber } from "@ensol/shared/utils/format"

import { StatComparisonCard } from "@ensol/entool/components/StatComparisonCard"
import { useSimulationResults } from "@ensol/entool/pages/Installation/useSimulationResults"
import { useInstallationEnergyStats } from "@ensol/entool/queries/energy"

type Props = {
  installationDate: Date
  installationValues: InstallationFormInput
  house: HousesResponses.House<{
    include: { roofSections: true; switchgridOrder: true }
  }>
  onSiteInstallation?: OnSiteInstallation
  defaultBatteryInstallation: InstallationsResponses.BatteryInstallation
  isInstallationValid: boolean
}

export const StatsSection = ({
  installationDate,
  installationValues,
  house,
  onSiteInstallation,
  defaultBatteryInstallation,
  isInstallationValid,
}: Props) => {
  const {
    data: energyStatsWithoutBattery,
    isPending: isEnergyStatsWithoutBatteryLoading,
  } = useInstallationEnergyStats({
    houseId: house.id,
    coords: getCoords(house),
    installation: { ...installationValues, batteryInstallation: null },
    switchgridOrderId: house.switchgridOrder?.orderId ?? null,
    onSiteInstallation,
  })

  const simulationResultsWithoutBattery = useSimulationResults({
    energyStats: energyStatsWithoutBattery,
    autoConsumptionPercent: energyStatsWithoutBattery?.autoConsumptionPercent,
    house,
    installationDate,
    installationValues: { ...installationValues, batteryInstallation: null },
    isInstallationValid,
    onSiteInstallation,
  })

  const batteryInstallation =
    installationValues.batteryInstallation === null
      ? defaultBatteryInstallation
      : installationValues.batteryInstallation

  const {
    data: energyStatsWithBattery,
    isPending: isEnergyStatsWithBatteryLoading,
  } = useInstallationEnergyStats({
    houseId: house.id,
    coords: getCoords(house),
    installation: {
      ...installationValues,
      batteryInstallation,
    },
    switchgridOrderId: house.switchgridOrder?.orderId ?? null,
    onSiteInstallation,
  })

  const simulationResultsWithBattery = useSimulationResults({
    energyStats: energyStatsWithBattery,
    autoConsumptionPercent: energyStatsWithBattery?.autoConsumptionPercent,
    house,
    installationDate,
    installationValues: {
      ...installationValues,
      batteryInstallation,
    },
    isInstallationValid,
    onSiteInstallation,
  })

  const isEnergyStatsLoading =
    isEnergyStatsWithoutBatteryLoading || isEnergyStatsWithBatteryLoading

  return (
    <Section title="Visez l'autonomie">
      <SimpleGrid cols={3} spacing={12}>
        <StatComparisonCard
          isLoading={isEnergyStatsLoading}
          label="Économies (1 an)"
          startStat={
            simulationResultsWithoutBattery &&
            roundDecimalNumber(
              simulationResultsWithoutBattery.firstYearSavings.totalSavings,
              0,
            )
          }
          targetStat={
            simulationResultsWithBattery &&
            roundDecimalNumber(
              simulationResultsWithBattery.firstYearSavings.totalSavings,
              0,
            )
          }
          unit="€"
        />
        <StatComparisonCard
          isLoading={isEnergyStatsLoading}
          label="Autoconsommation"
          startStat={
            energyStatsWithoutBattery &&
            roundDecimalNumber(
              energyStatsWithoutBattery.autoConsumptionPercent,
              0,
            )
          }
          targetStat={
            energyStatsWithBattery &&
            roundDecimalNumber(energyStatsWithBattery.autoConsumptionPercent, 0)
          }
          unit="%"
        />
        <StatComparisonCard
          isLoading={isEnergyStatsLoading}
          label="Indépendance"
          startStat={
            energyStatsWithoutBattery &&
            roundDecimalNumber(
              energyStatsWithoutBattery.autoProductionPercent,
              0,
            )
          }
          targetStat={
            energyStatsWithBattery &&
            roundDecimalNumber(energyStatsWithBattery.autoProductionPercent, 0)
          }
          unit="%"
        />
      </SimpleGrid>
    </Section>
  )
}
