import {
  Avatar,
  ComboboxItem,
  ComboboxLikeRenderOptionInput,
  Group,
  MultiSelect,
  MultiSelectProps,
  Select,
  SelectProps,
  ThemeIcon,
} from "@mantine/core"
import { IconCheck } from "@tabler/icons-react"
import _ from "lodash"
import { useEffect } from "react"

import { Team } from "@ensol/types/prisma-client/index.js"

import { useAuthenticatedUser, useListUsers } from "@ensol/entool/queries/users"
import { ONE_LINE_MULTI_SELECT_TARGET_STYLE } from "@ensol/entool/styles/theme"
import { Option } from "@ensol/entool/utils/form/radio"

type UserSelectOption = Option<string> & { avatarUrl: string }

export const UserSelect = ({
  teams,
  installerId,
  withAuthenticatedUserAsDefault,
  value,
  onChange,
  ...props
}: SelectProps & {
  teams: Team[]
  installerId?: string
  withAuthenticatedUserAsDefault: boolean
}) => {
  const { options, error, renderOption } = useUserOptions(teams, installerId)
  const { data: user } = useAuthenticatedUser()

  useEffect(() => {
    if (withAuthenticatedUserAsDefault && !value && user !== undefined) {
      const defaultOption = options.find((option) => option.value === user.id)

      if (defaultOption) {
        onChange?.(user.id, defaultOption)
      }
    }
  }, [withAuthenticatedUserAsDefault, user, value, onChange, options])

  if (error) {
    return null
  }

  return (
    <Select
      {...props}
      value={value}
      onChange={onChange}
      data={options}
      searchable
      renderOption={renderOption}
    />
  )
}

export const UserMultiSelect = ({
  teams,
  installerId,
  value,
  onChange,
  ...props
}: MultiSelectProps & { teams: Team[]; installerId?: string }) => {
  const { options, error, renderOption } = useUserOptions(teams, installerId)

  if (error) {
    return null
  }

  return (
    <MultiSelect
      {...props}
      value={value}
      onChange={onChange}
      data={options}
      renderOption={renderOption}
      searchable
      styles={ONE_LINE_MULTI_SELECT_TARGET_STYLE}
    />
  )
}

const useUserOptions = (teams: Team[], installerId?: string) => {
  const { users, error } = useListUsers()
  const options = users
    .filter((user) => _.intersection(user.teams, teams).length > 0)
    .filter((user) =>
      installerId !== undefined ? user.installerId === installerId : true,
    )
    .map(({ id, avatarUrl, firstName, lastName }) => ({
      value: id,
      label: `${firstName} ${lastName}`,
      avatarUrl,
    }))

  return {
    options,
    error,
    renderOption: ({
      option,
      checked,
    }: ComboboxLikeRenderOptionInput<ComboboxItem>) => (
      <Group flex="1" gap="xs" justify="space-between">
        <Group>
          <Avatar src={(option as UserSelectOption).avatarUrl} size="24" />
          {option.label}
        </Group>
        {checked && (
          <ThemeIcon c="gray">
            <IconCheck />
          </ThemeIcon>
        )}
      </Group>
    ),
  }
}
