import { Text } from "@mantine/core"
import { modals } from "@mantine/modals"

import { EmailInput } from "@ensol/types/forms/projects/emails"
import { Nullable } from "@ensol/types/utils"

import { EmailEvent } from "@ensol/shared/entities/projects/emails"

import { useClientEventsQuery } from "@ensol/entool/queries/intercom"
import { useSendEmailMutation } from "@ensol/entool/queries/projects"

export const useSendEmail = (
  projectId: string,
  clientId: string,
  eventName: EmailEvent,
) => {
  const { mutateAsync: sendEmail, isPending: isEmailSending } =
    useSendEmailMutation(projectId, clientId)
  const {
    data: clientEvents,
    isPending: areEventsLoading,
    error,
  } = useClientEventsQuery(clientId)

  const hasEmailBeenSent =
    clientEvents !== undefined
      ? clientEvents.events.some(
          ({ name, count }) => name === eventName && count > 0,
        )
      : false

  return {
    isEmailSending,
    areEventsLoading,
    hasEmailBeenSent,
    sendEmail: (data: Nullable<EmailInput>) => {
      if (error !== null) {
        return modals.openConfirmModal({
          title: "La récupération des mails déjà envoyés a échoué",
          children: (
            <Text size="sm">
              Le mail a peut-être déjà été envoyé, vérifier sur Intercom avant
              de confirmer
            </Text>
          ),
          centered: true,
          labels: { confirm: "Confirmer", cancel: "Annuler" },
          onConfirm: () => sendEmail(data),
        })
      }

      if (hasEmailBeenSent) {
        return modals.openConfirmModal({
          title: "Ce mail a déjà été envoyé",
          children: (
            <Text size="sm">
              Êtes-vous sûr de vouloir l&apos;envoyer quand même ?
            </Text>
          ),
          centered: true,
          labels: { confirm: "Confirmer", cancel: "Annuler" },
          onConfirm: () => sendEmail(data),
        })
      }

      return sendEmail(data)
    },
  }
}
