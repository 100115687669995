import { Project } from "."
import { ProcessId } from "@ensol/shared/entities/projects/processes"

export const OPS_PROJECT_OVERVIEW_SELECT = {
  id: true,
  createdAt: true,
  status: true,
  preliminaryRequestStep: true,
  technicalVisitStep: true,
  installationStep: true,
  paymentStep: true,
  hardwareStep: true,
  greendealStep: true,
  enedisStep: true,
  consuelStep: true,
  edfOaStep: true,
  followUpStep: true,
  toulouseMetropolisStep: true,
  salesVisitClientMotivation: true,
  preliminaryRequestExpectedValidationDate: true,
  preliminaryRequestAcknowledgedDate: true,
  preliminaryRequestABFAcknowledgmentPath: true,
  preliminaryRequestApprovalDate: true,
  technicalVisitClientMotivation: true,
  technicalVisitStartDate: true,
  technicalVisitSchedulingHoldEndDate: true,
  technicalVisitRequiredWorks: true,
  technicalVisitRequiredChanges: true,
  technicalVisitStartOfWorksDate: true,
  technicalVisitEndOfWorksDate: true,
  installationExpectedStartDate: true,
  installationSchedulingHoldEndDate: true,
  installationStartDate: true,
  consuelSummonsWeek: true,
  consuelSummonsDate: true,
  enedisReference: true,
  enedisActivationDate: true,
  schedulingIssueMainReason: true,
  schedulingIssueSubReason: true,
  fundingSource: true,
  fundingSourceStatus: true,
  technicalVisitInstaller: true,
  operationsOwner: true,
  adminOwner: true,
  technicalVisitOwner: true,
  installer: true,
  salesOwner: true,
  projectUpdates: true,
  isClientIntroductoryCallDone: true,
  installation: {
    select: {
      photovoltaicInstallation: {
        select: {
          panelType: true,
          panelsCount: true,
          inverterType: true,
          optimizerType: true,
          optimizerCount: true,
          extraWorks: true,
        },
      },
      batteryInstallation: {
        select: {
          type: true,
          extraWorks: true,
          count: true,
        },
      },
      evChargerInstallation: {
        select: {
          type: true,
          extraWorks: true,
        },
      },
      controlDeviceInstallations: {
        select: {
          deviceType: true,
        },
      },
      house: {
        select: {
          lat: true,
          lng: true,
          isAtticAccessible: true,
          currentType: true,
          address: true,
          client: {
            select: {
              firstName: true,
              lastName: true,
              phone: true,
              email: true,
              isComplexCase: true,
            },
          },
        },
      },
    },
  },
}

export type ProjectOperationsOverview = Omit<
  Project<{
    select: typeof OPS_PROJECT_OVERVIEW_SELECT
  }>,
  "projectUpdates"
> & {
  lastStatusEventDate: Date
  paymentDepositInvoiceSendDate: Date | null
  paymentFinalInvoiceSendDate: Date | null
  taskDetails: ProjectTaskDetails<ProjectOperationsTask> | null
  isActionNeeded: boolean
}

export const INSTALLER_PROJECT_OVERVIEW_SELECT = {
  id: true,
  status: true,
  installationStep: true,
  technicalVisitStep: true,
  technicalVisitRequiredChanges: true,
  technicalVisitRequiredWorks: true,
  technicalVisitEndOfWorksDate: true,
  technicalVisitSchedulingHoldEndDate: true,
  installationSchedulingHoldEndDate: true,
  kizeoProjectReports: {
    select: { type: true, reportPath: true },
  },
  installation: {
    select: {
      house: {
        select: {
          address: true,
          client: {
            select: {
              firstName: true,
              lastName: true,
              email: true,
              isComplexCase: true,
              phone: true,
            },
          },
        },
      },
    },
  },
}

export type ProjectInstallersOverview = Project<{
  select: typeof INSTALLER_PROJECT_OVERVIEW_SELECT
}> & {
  taskDetails: ProjectTaskDetails<ProjectInstallersTask> | null
  isActionNeeded: boolean
}

export type TaskDueDateProperty =
  | "technicalVisitSchedulingHoldEndDate"
  | "installationSchedulingHoldEndDate"

export type ProjectTaskDetails<
  T extends ProjectOperationsTask | ProjectInstallersTask,
> = {
  task: T
  type: ProjectTaskType
  dueDate?: {
    property: TaskDueDateProperty
    processId: ProcessId
  }
}

export enum ProjectOperationsTask {
  WAITING_FOR_INFO = "WAITING_FOR_INFO",
  TECHNICAL_VISIT_TO_SCHEDULE = "TECHNICAL_VISIT_TO_SCHEDULE",
  TECHNICAL_VISIT_WAITING_FOR_INSTALLER = "TECHNICAL_VISIT_WAITING_FOR_INSTALLER",
  TECHNICAL_VISIT_TO_CALL_BACK = "TECHNICAL_VISIT_TO_CALL_BACK",
  TECHNICAL_VISIT_UNSCHEDULED = "TECHNICAL_VISIT_UNSCHEDULED",
  CLIENT_TO_CALL = "CLIENT_TO_CALL",
  WAITING_FOR_VT = "WAITING_FOR_VT",
  WAITING_FOR_VT_VALIDATION = "WAITING_FOR_VT_VALIDATION",
  INSTALLATION_TO_SCHEDULE = "INSTALLATION_TO_SCHEDULE",
  INSTALLATION_WAITING_FOR_INSTALLER = "INSTALLATION_WAITING_FOR_INSTALLER",
  INSTALLATION_TO_CALL_BACK = "INSTALLATION_TO_CALL_BACK",
  INSTALLATION_UNSCHEDULED = "INSTALLATION_UNSCHEDULED",
  WAITING_FOR_DEPOSIT_PAYMENT = "WAITING_FOR_DEPOSIT_PAYMENT",
  WAITING_FOR_SITE_PREPARATION = "WAITING_FOR_SITE_PREPARATION",
  WAITING_FOR_INSTALLATION = "WAITING_FOR_INSTALLATION",
  INSTALLATION_IN_PROGRESS = "INSTALLATION_IN_PROGRESS",
  WAITING_FOR_FINAL_PAYMENT = "WAITING_FOR_FINAL_PAYMENT",
  WAITING_FOR_CONSUEL = "WAITING_FOR_CONSUEL",
  WAITING_FOR_CONSUEL_VISIT = "WAITING_FOR_CONSUEL_VISIT",
  WAITING_FOR_CONSUEL_VALIDATION = "WAITING_FOR_CONSUEL_VALIDATION",
  WAITING_FOR_SERVICE_ACTIVATION = "WAITING_FOR_SERVICE_ACTIVATION",
  FOLLOW_UP = "FOLLOW_UP",
}

export enum ProjectInstallersTask {
  TECHNICAL_VISIT_CALENDAR_TO_VALIDATE = "TECHNICAL_VISIT_CALENDAR_TO_VALIDATE",
  TECHNICAL_VISIT_REPORT_TO_SEND = "TECHNICAL_VISIT_REPORT_TO_SEND",
  INSTALLATION_REPORT_TO_SEND = "INSTALLATION_REPORT_TO_SEND",
  INSTALLATION_TO_VALIDATE = "INSTALLATION_TO_VALIDATE",
}

export enum ProjectTaskType {
  MONITORING = "monitoring",
  TODO = "todo",
  REMINDER = "reminder",
}
