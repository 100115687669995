import { SimulationResults } from "@ensol/types/simulation"

import { Section } from "@ensol/shared/components/Section"
import { computeYearlyBillsForecast } from "@ensol/shared/entities/installations/savings"
import { colors } from "@ensol/shared/styles/colors"
import { formatMonthsCount } from "@ensol/shared/utils/format"

import { BillEvolutionGraph } from "@ensol/entool/pages/Installation/components/BillEvolutionGraph"
import { InfoBadge } from "@ensol/entool/pages/Installation/components/InfoBadge"

type Props = {
  computedSimulationResults: SimulationResults
  manualSimulationResults?: SimulationResults
  yearlyConsumption: number
  electricityBuyPrice: number
  electricityPriceAnnualRaise: number
  isBoostActive: boolean
}

export const BillEvolutionSection = ({
  computedSimulationResults,
  manualSimulationResults,
  yearlyConsumption,
  electricityBuyPrice,
  electricityPriceAnnualRaise,
  isBoostActive,
}: Props) => {
  const computedBillReductionRatio =
    computedSimulationResults.billReductionRatio
  const manualBillReductionRatio =
    manualSimulationResults?.billReductionRatio ?? computedBillReductionRatio
  computedSimulationResults.billReductionRatio

  const monthsBeforeBreakEvenDate =
    manualSimulationResults?.monthsBeforeBreakEvenDate ??
    computedSimulationResults.monthsBeforeBreakEvenDate

  return (
    <Section
      title="Évolution de votre facture"
      Actions={
        <InfoBadge>
          Rentabilité atteinte en {formatMonthsCount(monthsBeforeBreakEvenDate)}
        </InfoBadge>
      }
    >
      <BillEvolutionGraph
        datasets={[
          {
            label: "Facture actuelle",
            data: computeYearlyBillsForecast({
              yearlyConsumption,
              electricityBuyPrice,
              electricityPriceAnnualRaise,
              config: { dataPointsCount: 5, stepInYears: 5 },
            }),
            backgroundColor: colors.red[300],
          },
          {
            label: "Après installation",
            data: computeYearlyBillsForecast({
              yearlyConsumption,
              electricityBuyPrice:
                electricityBuyPrice *
                (1 -
                  (isBoostActive
                    ? computedBillReductionRatio
                    : manualBillReductionRatio)),
              electricityPriceAnnualRaise,
              config: { dataPointsCount: 5, stepInYears: 5 },
            }),
            backgroundColor: colors.blue[500],
          },
          ...(isBoostActive && manualSimulationResults
            ? [
                {
                  label: "Avec l'accompagnement Ensol",
                  data: computeYearlyBillsForecast({
                    yearlyConsumption,
                    electricityBuyPrice:
                      electricityBuyPrice * (1 - manualBillReductionRatio),
                    electricityPriceAnnualRaise,
                    config: { dataPointsCount: 5, stepInYears: 5 },
                  }),
                  backgroundColor: colors.green[500],
                },
              ]
            : []),
        ]}
        withLegend
        shouldShowAmounts
      />
    </Section>
  )
}
