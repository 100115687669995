import {
  Indicator,
  MantineStyleProps,
  Text,
  ThemeIcon,
  UnstyledButton,
} from "@mantine/core"
import { IconBell } from "@tabler/icons-react"
import { useContext } from "react"

import { NotificationsDrawer } from "@ensol/shared/components/layout/Notifications/NotificationsDrawer"
import { NotificationsProviderContext } from "@ensol/shared/components/layout/Notifications/Provider"
import { useNotificationsDrawer } from "@ensol/shared/components/layout/Notifications/useNotificationsDrawer"

import styles from "./notifications.module.css"

export const NotificationBell = (props: MantineStyleProps) => {
  const { notifications } = useContext(NotificationsProviderContext)

  const {
    activeNotifications,
    archivedNotifications,
    isDrawerOpen,
    openDrawer,
    closeDrawer,
    activeDrawerTab,
    setActiveDrawerTab,
  } = useNotificationsDrawer(notifications)

  if (notifications === null) {
    return null
  }

  const activeNotificationsCount = activeNotifications.length

  return (
    <>
      <UnstyledButton className={styles.bell} {...props} onClick={openDrawer}>
        <Indicator
          label={
            <Text fw="bold" fz={10} lh={12}>
              {activeNotificationsCount}
            </Text>
          }
          size={16}
          offset={4}
          color="red"
          disabled={activeNotificationsCount === 0}
          styles={{ indicator: { padding: "0px 2px" } }}
        >
          <ThemeIcon color="gray.6" variant="transparent" size={28}>
            <IconBell stroke={1.5} />
          </ThemeIcon>
        </Indicator>
      </UnstyledButton>
      <NotificationsDrawer
        activeNotifications={activeNotifications}
        archivedNotifications={archivedNotifications}
        isOpen={isDrawerOpen}
        onClose={closeDrawer}
        activeTab={activeDrawerTab}
        setActiveTab={setActiveDrawerTab}
      />
    </>
  )
}
