import { transformSchemaToNullish } from "@ensol/types/forms/utils"
import * as z from "zod"

export const installationToValidateFormSchema = z.object({
  followUpNotes: z.string().optional(),
  followUpExternalPlantIdentifier: z.string().nullable().optional(),
  followUpProductionStartDate: z.date({
    invalid_type_error: "Champ obligatoire",
  }),
  isPanelsLayoutValid: z.boolean().optional(),
  areClampsWorking: z.boolean().optional(),
  hasNoAlerts: z.boolean().optional(),
})

export const onboardingPendingFormSchema =
  installationToValidateFormSchema.extend({
    followUpMonthlyReportsPath: z.array(z.string()),
    shouldSendMonthlyReports: z.boolean(),
  })

export type FollowUpStepInput =
  | z.infer<typeof installationToValidateFormSchema>
  | z.infer<typeof onboardingPendingFormSchema>

export const followUpSchema = transformSchemaToNullish(
  onboardingPendingFormSchema,
)
