import { notifications, showNotification } from "@mantine/notifications"
import { useMutation, useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { useNavigate } from "react-router-dom"

import { ClientResponses } from "@ensol/types/endpoints/clients"
import {
  ListClientsFilters,
  ClientInput,
  UpdateClientInput,
} from "@ensol/types/forms/clients"
import { CreateHouseInput } from "@ensol/types/forms/houses"
import { Client } from "@ensol/types/prisma-client/index.js"

import { isEnsolError } from "@ensol/shared/utils/errors"
import { formatFullName } from "@ensol/shared/utils/format"

import { httpClient } from "@ensol/entool/backend/axios"
import { queryClient } from "@ensol/entool/backend/queryClient"

type ListClientOptions<T> = {
  select?: (data: ClientResponses.ClientsList) => T
  filters?: ListClientsFilters
}

export const useListClientsQuery = <T = ClientResponses.ClientsList>({
  select,
  filters,
}: ListClientOptions<T>) => {
  return useQuery<ClientResponses.ClientsList, unknown, T>({
    queryKey: ["clients", filters],
    queryFn: async () => {
      const response = await httpClient.get("/clients", { params: filters })
      return response.data
    },
    select,
  })
}

export const useGetClientQuery = (clientId: string) => {
  return useQuery<ClientResponses.ClientDetails>({
    queryKey: ["clients", clientId],
    queryFn: async () => {
      const response = await httpClient.get(`/clients/${clientId}`)
      return response.data
    },
  })
}

export const useCreateClientMutation = () => {
  const navigate = useNavigate()

  return useMutation<Client, string, ClientInput>({
    mutationFn: async (client) => {
      const response = await httpClient.post<Client>("/clients", client)
      return response.data
    },
    onSuccess: (client) => {
      showNotification({
        message: `Client ${formatFullName(client)} créé !`,
      })
      navigate(`/clients/${client.id}`)
      queryClient.invalidateQueries({
        queryKey: ["clients"],
      })
    },
  })
}

export const useUpdateClientMutation = (
  clientId: string,
  onSuccess?: () => void,
) => {
  return useMutation<ClientResponses.Client, AxiosError, UpdateClientInput>({
    mutationFn: async (client) => {
      const response = await httpClient.put(`/clients/${clientId}`, client)
      return response.data
    },
    onSuccess: async () => {
      await onSuccess?.()
    },
    onError: (error) => {
      if (isEnsolError(error)) {
        showNotification({
          title: "Erreur lors de la mise à jour du client",
          message: error.message,
          color: "red",
        })
      } else {
        showNotification({
          title: "Erreur lors de la mise à jour du client",
          message: "Merci de réessayer",
          color: "red",
        })
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["clients", clientId],
      })
    },
  })
}

export const useCreateClientHouseMutation = (
  clientId: string,
  onSuccess: (data: ClientResponses.HouseWithRoofSections) => void,
) => {
  return useMutation<
    ClientResponses.HouseWithRoofSections,
    AxiosError,
    CreateHouseInput
  >({
    mutationFn: async (house) => {
      const response = await httpClient.post(
        `/clients/${clientId}/houses`,
        house,
      )
      return response.data
    },

    onSuccess: async (data) => {
      onSuccess(data)
      await queryClient.invalidateQueries({
        queryKey: ["clients", clientId],
      })
      notifications.show({ message: "Maison créée !" })
    },
  })
}

export const useDeleteClientMutation = (clientId: string) => {
  return useMutation<null, AxiosError>({
    mutationFn: async () => {
      const response = await httpClient.delete(`/clients/${clientId}`)
      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["clients"],
      })
    },
  })
}
