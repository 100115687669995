import { useDisclosure } from "@mantine/hooks"
import { IconBolt } from "@tabler/icons-react"

import { EvChargerType, getEvCharger } from "@ensol/shared/material/evCharger"
import { formatNumber } from "@ensol/shared/utils/format"

import { MaterialCard } from "@ensol/entool/pages/Installation/components/MaterialCard"
import { MaterialSelectorModal } from "@ensol/entool/pages/Installation/components/MaterialSelectorModal"
import { getEvChargerOptions } from "@ensol/entool/utils/installations/evCharger"

type Props = {
  evChargerType: EvChargerType
  onChange: (evChargerType: EvChargerType) => void
}

export const EvChargerSelector = ({ evChargerType, onChange }: Props) => {
  const [opened, { open, close }] = useDisclosure(false)

  const evCharger = getEvCharger(evChargerType)
  return (
    <>
      <MaterialCard
        {...evCharger}
        pictureUrl={evCharger.pictureUrl}
        ctaLabel="Changer borne"
        dataTest="updateEvChargerTypeButton"
        onClick={open}
        properties={[
          {
            icon: IconBolt,
            label: `Puissance ${formatNumber(evCharger.power)} kW`,
          },
        ]}
      />
      <MaterialSelectorModal<EvChargerType>
        title="Sélectionner un type de borne"
        isOpened={opened}
        onClose={close}
        onChange={onChange}
        extraColumnsLabel={["Puissance"]}
        options={getEvChargerOptions({ selectedValue: evChargerType })}
      />
    </>
  )
}
