import { SignOutButton, useUser } from "@clerk/clerk-react"
import * as Sentry from "@sentry/react"
import { useContext, useEffect } from "react"

import { AppShell as SharedAppShell } from "@ensol/shared/components/layout/AppShell"
import { NotificationsProviderContext } from "@ensol/shared/components/layout/Notifications/Provider"

import { Menu } from "@ensol/entool/components/layout/Menu"
import {
  useNotificationsQuery,
  useUpdateNotificationStatusMutation,
} from "@ensol/entool/queries/notifications"

export const AppShell = () => {
  const { setNotifications, setUpdateNotificationStatus } = useContext(
    NotificationsProviderContext,
  )
  const { isLoaded, isSignedIn, user } = useUser()
  const { notifications } = useNotificationsQuery()
  const { mutate: updateNotificationStatus } =
    useUpdateNotificationStatusMutation()

  useEffect(() => {
    setNotifications(notifications)
  }, [notifications, setNotifications])

  useEffect(() => {
    setUpdateNotificationStatus(() => updateNotificationStatus)
  }, [updateNotificationStatus, setUpdateNotificationStatus])

  useEffect(() => {
    if (isLoaded && isSignedIn) {
      Sentry.setUser({
        id: user.id,
        email: user.emailAddresses[0].emailAddress,
      })
    }
  }, [isLoaded, isSignedIn, user])

  return (
    <SharedAppShell
      environment={import.meta.env.VITE_ENVIRONMENT}
      Menu={Menu}
      SignOutButton={SignOutButton}
      user={user}
    />
  )
}
