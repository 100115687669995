import { Group, Tabs } from "@mantine/core"

import { ProjectInstallerStatus } from "@ensol/types/entities/project"
import { typedKeys } from "@ensol/types/utils"

import { useResponsiveDimensions } from "@ensol/shared/styles/constants"

import { StickyHeader } from "@ensol/entool/components/StickyHeader"
import { ProjectAccordion } from "@ensol/entool/components/entities/Project/ProjectAccordion"
import { ProjectSearch } from "@ensol/entool/components/entities/Project/ProjectSearch"
import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { useTabsNavigation } from "@ensol/entool/utils/helpers/useTabsNavigation"
import { PROJECT_SEARCHABLE_PROPS } from "@ensol/entool/utils/projects/search"

import { ProjectRow } from "./ProjectRow"
import { useProjectsInstallersOverview } from "./useProjectsInstallersOverview"

const INSTALLER_PROJECTS_STATUS_LABEL: Record<ProjectInstallerStatus, string> =
  {
    TECHNICAL_VISIT_TO_COME: "Visite technique à venir",
    TECHNICAL_VISIT_SCHEDULED: "Visite technique planifiée",
    TECHNICAL_VISIT_COMPLETED: "Visite technique réalisée",
    INSTALLATION_SCHEDULED: "Installations à venir",
    INSTALLATION_IN_PROGRESS: "Installation en cours",
    INSTALLATION_COMPLETED: "Installation réalisée",
    ABORTED: "Annulé",
  }

const ALL_PROJECTS_TAB_ID = "ALL"
const INSTALLER_PROJECTS_OVERVIEW_TABS = [
  { id: ALL_PROJECTS_TAB_ID, label: "Tous" },
  ...typedKeys(INSTALLER_PROJECTS_STATUS_LABEL).map((tab) => ({
    id: tab,
    label: INSTALLER_PROJECTS_STATUS_LABEL[tab],
  })),
]

export const InstallerProjectsOverview = () => {
  const { currentTab, handleTabChange } = useTabsNavigation(
    INSTALLER_PROJECTS_OVERVIEW_TABS,
  )

  const { navbarHeight } = useResponsiveDimensions()
  const installerProjectsQuery = useProjectsInstallersOverview(currentTab)

  return (
    <Tabs
      maw="100%"
      value={currentTab}
      onChange={handleTabChange}
      color="gray.8"
      styles={{
        root: {
          display: "flex",
          flexDirection: "column",
          flex: 1,
        },
      }}
    >
      <StickyHeader top={navbarHeight}>
        <Tabs.List>
          <Group
            maw="100%"
            wrap="nowrap"
            style={{
              overflowX: "auto",
            }}
          >
            {INSTALLER_PROJECTS_OVERVIEW_TABS.map(({ id, label }) => (
              <Tabs.Tab key={id} value={id}>
                {label}
              </Tabs.Tab>
            ))}
          </Group>
        </Tabs.List>
        <ProjectSearch filteredProps={[PROJECT_SEARCHABLE_PROPS.search]} />
      </StickyHeader>
      <QueryWrapper query={installerProjectsQuery}>
        {({ data }) => {
          return (
            <ProjectAccordion
              data={typedKeys(data).map((status) => ({
                items: data[status],
                id: status,
                title: INSTALLER_PROJECTS_STATUS_LABEL[status],
              }))}
              render={(items) => {
                return items.map((project) => (
                  <ProjectRow key={project.id} project={project} />
                ))
              }}
            />
          )
        }}
      </QueryWrapper>
    </Tabs>
  )
}
