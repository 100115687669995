import _ from "lodash"

import { SimulationResults } from "@ensol/types/simulation"

import { ProductCosts } from "@ensol/shared/entities/installations/costs"
import { roundDecimalNumber } from "@ensol/shared/utils/format"

export const computeDiscountBeforeTax = ({
  totalDiscount,
  totalInstallationCost,
  productsCosts,
}: {
  totalDiscount: number
  totalInstallationCost: number
  productsCosts: ProductCosts[]
}) =>
  _.sum(
    productsCosts.map(
      ({ installationCost, vatRate }) =>
        computeProductDiscount({
          totalDiscount,
          totalInstallationCost,
          productInstallationCost: installationCost,
        }) /
        (1 + vatRate),
    ),
  )

export const computeProductDiscount = ({
  totalDiscount,
  totalInstallationCost,
  productInstallationCost,
}: {
  totalDiscount: number
  totalInstallationCost: number
  productInstallationCost: number
}) => totalDiscount * (productInstallationCost / totalInstallationCost)

const MAX_DISCOUNT_RATE = 0.06

export const getMaxDiscount = (
  simulationResults?: SimulationResults,
): number => {
  if (!simulationResults) {
    return Infinity
  }

  const { finalCost, totalDiscount } = simulationResults
  const finalCostWithoutDiscount = finalCost + totalDiscount

  return roundDecimalNumber(MAX_DISCOUNT_RATE * finalCostWithoutDiscount, 0)
}
