import { Clerk } from "@clerk/clerk-js"
import { AxiosInstance } from "axios"
import _ from "lodash"

import {
  isAxiosBadRequestError,
  isEnsolError,
  isZodError,
} from "@ensol/shared/utils/errors"

export const createHTTPClient = (
  axiosClient: AxiosInstance,
  clerkClient: Clerk,
) => {
  const client = axiosClient

  axiosClient.interceptors.request.use(
    async (config) => {
      const token = await clerkClient.session?.getToken()
      if (!token) return config

      config.headers.Authorization = `Bearer ${token}`
      return config
    },
    (error) => Promise.reject(error),
  )

  axiosClient.interceptors.response.use(
    (response) => {
      const { data, ...rest } = response

      return { data: deepCoerceDates(data), ...rest }
    },
    async (error) => {
      if (
        isAxiosBadRequestError(error) &&
        (isEnsolError(error.response?.data) || isZodError(error.response?.data))
      ) {
        return Promise.reject(error.response?.data)
      }
      return Promise.reject(error)
    },
  )

  return client
}

const ISO_DATE_REGEX = new RegExp(
  "\\d{4}-[01]\\d-[0-3]\\dT[0-2]\\d:[0-5]\\d:[0-5]\\d\\.\\d{3}([+-][0-2]\\d:[0-5]\\d|Z)",
)

// It's a bit pointless to type object here as it can really be anything
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const deepCoerceDates = (data: any): any => {
  if (_.isArray(data)) {
    return data.map(deepCoerceDates)
  }

  if (!_.isPlainObject(data)) {
    return data
  }

  return _.transform(
    data,
    (result, value, key) => {
      if (_.isPlainObject(value)) {
        result[key] = deepCoerceDates(value)
      } else if (_.isArray(value)) {
        result[key] = value.map(deepCoerceDates)
      } else if (ISO_DATE_REGEX.test(value)) {
        result[key] = new Date(value)
      } else {
        result[key] = value
      }
    },
    Object.create({}),
  )
}
