import { IconArrowBackUp } from "@tabler/icons-react"
import * as z from "zod"

import { KizeoProjectReportType } from "@ensol/types/entities/project/kizeoProjectReport"
import { checkField } from "@ensol/types/forms/projects/shared"
import { scheduledStepFormSchema } from "@ensol/types/forms/projects/technicalVisit"

import { findFirstKizeoProjectReportFromType } from "@ensol/shared/entities/projects/kizeoProjectReport"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/utils/initialValues"
import { validateAction } from "@ensol/entool/utils/projects/validateAction"

import { TechnicalVisitForm } from "./components/TechnicalVisitForm"

export const Scheduled = ({ project, ...props }: StepComponentProps) => (
  <TechnicalVisitForm
    schema={scheduledStepFormSchema}
    initialValues={getInitialValues(scheduledStepFormSchema, project)}
    project={project}
    actions={[
      {
        action: "reschedule",
        label: "Replanifier la visite technique",
        color: "orange",
        Icon: IconArrowBackUp,
        confirmationMessage:
          "Les évènements installateur et client seront supprimés. L’installateur seul sera averti. Les CRs de VT existants seront supprimés. Continuer ?",
      },
      {
        action: "validate",
        label: "Visite réalisée",
        validateAction: validateAction(
          z.object({
            isTechnicalVisitReady: checkField,
            isClientIntroductoryCallDone: checkField,
          }),
        ),
        confirmationMessage: !findFirstKizeoProjectReportFromType(
          project.kizeoProjectReports,
          KizeoProjectReportType.TECHNICAL_VISIT,
        )
          ? "Le formulaire Kizeo n'a pas été créé pour cette visite technique, êtes-vous de vouloir continuer sans Kizeo ?"
          : undefined,
      },
    ]}
    {...props}
  />
)
