import { Select, SimpleGrid, Stack, Textarea } from "@mantine/core"
import { DateInput } from "@mantine/dates"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { Project } from "@ensol/types/entities/project"
import {
  equityPaymentStepsFormSchema,
  EquityPaymentStepInput,
} from "@ensol/types/forms/projects/payment"

import { Section } from "@ensol/shared/components/Section"
import { PaymentStep } from "@ensol/shared/entities/projects/processes"
import { DISPLAY_DATE_FORMAT } from "@ensol/shared/utils/format"

import { FormField } from "@ensol/entool/components/form/FormField"
import { FundingSourceField } from "@ensol/entool/components/form/Project/FundingSourceField"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { PAYMENT_HOLD_REASONS_OPTIONS } from "@ensol/entool/utils/projects/options"
import { useProcessForm } from "@ensol/entool/utils/projects/useProcessForm"

import { PennylaneEstimate } from "./PennylaneEstimate"

type Props = {
  project: Project
  estimate: ProjectResponses.Estimate
}

export const PostDepositPaymentForm = ({ project, estimate }: Props) => {
  const form = useProcessForm<EquityPaymentStepInput>({
    schema: equityPaymentStepsFormSchema,
    initialValues: {
      paymentNotes: project.paymentNotes,
      fundingSource: project.fundingSource,
      fundingSourceStatus: project.fundingSourceStatus,
      paymentHoldEndDate: project.paymentHoldEndDate,
      paymentHoldReason: project.paymentHoldReason,
    },
    projectId: project.id,
    processId: "paymentStep",
  })

  return (
    <>
      <Section>
        <SimpleGrid cols={2} spacing="32">
          <FundingSourceField
            fundingSourceStatus={project.fundingSourceStatus}
            form={form}
          />
          <Stack gap="24">
            <FormField initialValues={form.values} propertyName="paymentNotes">
              <Textarea
                autosize
                w={FIELD_WIDTH}
                minRows={3}
                {...form.getInputProps("paymentNotes")}
              />
            </FormField>
            {(project.paymentStep === PaymentStep.WAITING_FOR_DEPOSIT_PAYMENT ||
              project.paymentStep ===
                PaymentStep.WAITING_FOR_FINAL_PAYMENT) && (
              <>
                <FormField
                  initialValues={form.values}
                  propertyName="paymentHoldEndDate"
                >
                  <DateInput
                    w={FIELD_WIDTH}
                    clearable
                    valueFormat={DISPLAY_DATE_FORMAT}
                    minDate={new Date()}
                    {...form.getInputProps("paymentHoldEndDate")}
                  />
                </FormField>
                <FormField
                  initialValues={form.values}
                  propertyName="paymentHoldReason"
                >
                  <Select
                    w={FIELD_WIDTH}
                    data={PAYMENT_HOLD_REASONS_OPTIONS}
                    {...form.getInputProps("paymentHoldReason")}
                  />
                </FormField>
              </>
            )}
          </Stack>
        </SimpleGrid>
      </Section>

      <PennylaneEstimate estimate={estimate} project={project} />
    </>
  )
}
