import {
  Burger,
  Drawer,
  Avatar,
  Group,
  Text,
  Image,
  AppShell,
  ActionIcon,
  Badge,
  Stack,
  Button,
  Menu,
  ScrollArea,
  UnstyledButton,
} from "@mantine/core"
import { useMediaQuery, useDisclosure } from "@mantine/hooks"
import {
  IconChevronLeft,
  IconChevronRight,
  IconLogout,
} from "@tabler/icons-react"
import { useHover } from "@uidotdev/usehooks"
import { Link } from "react-router-dom"

import { AppShellProps } from "@ensol/shared/components/layout/AppShell"
import { NotificationBell } from "@ensol/shared/components/layout/Notifications/NotificationBell"
import { colors } from "@ensol/shared/styles/colors"
import { devices } from "@ensol/shared/styles/theme"

import { MOBILE_HEADER_PORTAL_CONTAINER_ID } from "./MobileHeaderPortal"

type Props = AppShellProps & {
  toggleDesktop: () => void
  desktopOpened: boolean
}

export const Navbar = ({
  environment,
  Menu: AppMenu,
  SignOutButton,
  desktopOpened,
  toggleDesktop,
  user,
}: Props) => {
  const isSmallScreen = useMediaQuery(devices.md)
  const [isNavbarOpen, { open: openNavBar, close: closeNavbar }] =
    useDisclosure(false)

  const [ref, hovered] = useHover()

  if (isSmallScreen) {
    return (
      <AppShell.Header>
        <Group h="100%" px="lg" justify="space-between" wrap="nowrap">
          <Group id={MOBILE_HEADER_PORTAL_CONTAINER_ID} wrap="nowrap">
            <Link to="/" style={{ position: "relative" }}>
              <Image w="40" src="/ensolLogo.svg" alt="Logo Entool" />
              <DevEnvironmentBadge
                environment={environment}
                top={28}
                left={14}
              />
            </Link>
          </Group>
          <Group h="100%" gap="12" wrap="nowrap">
            <Burger opened={isNavbarOpen} onClick={openNavBar} />
            <Drawer
              opened={isNavbarOpen}
              onClose={closeNavbar}
              size="230"
              position="right"
              styles={{ body: { padding: 0 } }}
            >
              <AppMenu closeNavbar={closeNavbar} />
              <Stack
                align="flex-start"
                pos="sticky"
                bottom={0}
                left={0}
                p="4"
                w="100%"
                bg="white"
                mt="24"
                style={{
                  borderTop: `1px solid ${colors.gray[300]}`,
                }}
              >
                <SignOutButton>
                  <Button
                    variant="subtle"
                    color="red"
                    leftSection={<IconLogout size={16} />}
                  >
                    Déconnexion
                  </Button>
                </SignOutButton>
              </Stack>
            </Drawer>
            <NotificationBell mt="12" mr="8" />
            {user && <Avatar src={user.imageUrl} />}
          </Group>
        </Group>
      </AppShell.Header>
    )
  }

  return (
    <AppShell.Navbar style={{ transition: "width 300ms" }} ref={ref}>
      <ActionIcon
        pos="absolute"
        variant="default"
        aria-label="Collapse"
        h={0}
        w={0}
        size={28}
        radius={50}
        right={-14}
        top={46}
        onClick={toggleDesktop}
        style={{
          zIndex: 1,
          opacity: hovered ? 1 : 0,
          display: hovered ? "block" : "hidden",
          transition: "opacity 600ms",
        }}
      >
        <IconChevronLeft
          style={{
            transform: desktopOpened ? "rotate(0)" : "rotate(-0.50turn)",
            transition: "transform 300ms",
          }}
        />
      </ActionIcon>
      <ScrollArea pt="lg" pb={8} px={8} scrollbars="y">
        <Group justify="space-between" wrap="nowrap" p="0 12px" mb="20">
          <Link to="/" style={{ position: "relative" }}>
            <Image w="40" src="/ensolLogo.svg" alt="Logo Ensol" />
            <DevEnvironmentBadge environment={environment} top={32} left={14} />
          </Link>
          {desktopOpened && <NotificationBell m="6px 8px 0px 0px" />}
        </Group>
        <Stack gap="0" align="center">
          {!desktopOpened && <NotificationBell py="16" />}
          <AppMenu
            desktopOpened={desktopOpened}
            toggleDesktop={toggleDesktop}
          />
        </Stack>
      </ScrollArea>
      {user && (
        <Menu position="right" withArrow arrowPosition="center" arrowSize={10}>
          <Menu.Target>
            <UnstyledButton mt="auto" mb={24} px="16">
              <Group wrap="nowrap" justify="space-evenly">
                <Avatar src={user.imageUrl} />
                <Text
                  w="120"
                  ta="left"
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {user.firstName}{" "}
                  {user.lastName
                    ? `${user.lastName.at(0)?.toUpperCase()}.`
                    : ""}
                </Text>
                {desktopOpened && <IconChevronRight size={16} />}
              </Group>
            </UnstyledButton>
          </Menu.Target>
          <Menu.Dropdown>
            <SignOutButton>
              <Menu.Item color="red" leftSection={<IconLogout size={16} />}>
                Déconnexion
              </Menu.Item>
            </SignOutButton>
          </Menu.Dropdown>
        </Menu>
      )}
    </AppShell.Navbar>
  )
}

const DevEnvironmentBadge = ({
  environment,
  top,
  left,
}: {
  environment: string
  top: number
  left: number
}) => {
  if (environment === "production") {
    return null
  }

  return (
    <Badge color="orange" w="40" size="sm" pos="absolute" top={top} left={left}>
      Dev
    </Badge>
  )
}
