import { createContext, ReactNode, useState } from "react"

import {
  UpdateNotificationStatusInput,
  Notification,
} from "@ensol/types/entities/notifications"

type Props = {
  children: ReactNode
}

type UpdateNotificationStatusMethod = (
  input: UpdateNotificationStatusInput,
) => void

export const NotificationsProviderContext = createContext<{
  notifications: Notification[] | null
  setNotifications: (notifications: Notification[] | null) => void
  updateNotificationStatus: UpdateNotificationStatusMethod
  setUpdateNotificationStatus: (
    updateNotificationStatus: UpdateNotificationStatusMethod,
  ) => void
}>({
  notifications: null,
  setNotifications: (_: Notification[] | null) => {},
  updateNotificationStatus: (_: UpdateNotificationStatusInput) => {},
  setUpdateNotificationStatus: (_: UpdateNotificationStatusMethod) => {},
})

export const NotificationsProvider = ({ children }: Props) => {
  const [notifications, setNotifications] = useState<Notification[] | null>(
    null,
  )
  const [updateNotificationStatus, setUpdateNotificationStatus] =
    useState<UpdateNotificationStatusMethod>(() => {})

  return (
    <NotificationsProviderContext.Provider
      value={{
        notifications,
        setNotifications,
        updateNotificationStatus,
        setUpdateNotificationStatus,
      }}
    >
      {children}
    </NotificationsProviderContext.Provider>
  )
}
