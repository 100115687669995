import { Card, Stack, Tabs, Text } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { showNotification } from "@mantine/notifications"
import { IconLibrary, IconMessageCircle } from "@tabler/icons-react"
import { useCallback } from "react"

import { Project } from "@ensol/types/entities/project"
import { updateClientSchema } from "@ensol/types/forms/clients"

import { Team } from "@ensol/shared/entities/users"
import { CONTENT_PADDING_IN_PX } from "@ensol/shared/styles/constants"
import { devices } from "@ensol/shared/styles/theme"
import { formatZodValidationError } from "@ensol/shared/utils/format"

import { ProjectNotes } from "@ensol/entool/components/entities/Project/ProjectNotes"
import { useStartProjectMutation } from "@ensol/entool/queries/projects"
import { useAuthenticatedUser } from "@ensol/entool/queries/users"
import { CLIENT_FIELDS_LABELS } from "@ensol/entool/utils/form/fieldLabels/clients"
import { useInstallationMenu } from "@ensol/entool/utils/installations/useInstallationMenu"

import { Actions } from "./Actions"
import { Header } from "./Header"
import { HouseForm } from "./House/Form"
import { useHouseForm } from "./House/useHouseForm"
import { ProjectForm } from "./Project/Form"
import { useProjectForm } from "./Project/useProjectForm"

type Props = {
  project: Project
}

enum MobileTab {
  FORM = "Formulaire",
  CHAT = "Chat",
}

export const SalesVisitForm = ({ project }: Props) => {
  useInstallationMenu(project.installationId)
  const { mutateAsync: startProject, isPending: isStartingProject } =
    useStartProjectMutation(project.id)
  const { data: user } = useAuthenticatedUser()

  const installation = project.installation
  const house = installation.house
  const client = house.client

  const houseForm = useHouseForm(house)
  const projectForm = useProjectForm(project)

  const submitProject = useCallback(async () => {
    if (user?.teams.includes(Team.INSIDE_SALES)) {
      await startProject()
    } else {
      const { hasErrors: hasHouseErrors } = houseForm.validate()
      const { hasErrors: hasProjectErrors } = projectForm.validate()

      if (hasHouseErrors || hasProjectErrors) {
        return showNotification({
          title: "Formulaire incomplet",
          message: "Veuillez remplir tous les champs obligatoires.",
          color: "red",
        })
      }
    }

    const { success, error } = updateClientSchema.safeParse(
      project.installation.house.client,
    )
    if (!success) {
      return showNotification({
        title: "Veuillez compléter les informations client manquantes",
        message: formatZodValidationError(error.issues, CLIENT_FIELDS_LABELS),
        color: "red",
      })
    }

    await startProject()
  }, [
    houseForm,
    project.installation.house.client,
    projectForm,
    startProject,
    user?.teams,
  ])

  const isMobile = useMediaQuery(devices.sm)

  return (
    <Stack flex={1} gap={0} bg="gray.0" h="100%">
      <Header projectId={project.id} client={client}>
        <Actions
          project={project}
          submitProject={submitProject}
          isSubmitting={isStartingProject}
        />
      </Header>
      {isMobile ? (
        <Tabs
          keepMounted={false}
          defaultValue={MobileTab.FORM}
          styles={{
            root: {
              display: "flex",
              flexDirection: "column",
              minHeight: 0,
              flex: 1,
            },
          }}
        >
          <Tabs.List bg="white" grow justify="space-around">
            <Tabs.Tab
              value={MobileTab.FORM}
              leftSection={<IconLibrary size={12} />}
            >
              <Text size="md">{MobileTab.FORM}</Text>
            </Tabs.Tab>
            <Tabs.Tab
              value={MobileTab.CHAT}
              leftSection={<IconMessageCircle size={12} />}
            >
              <Text size="md">{MobileTab.CHAT}</Text>
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel
            value={MobileTab.FORM}
            p={CONTENT_PADDING_IN_PX}
            h="100%"
            style={{ overflow: "auto" }}
          >
            <Stack gap="24" flex="1" bg="gray.0">
              <ProjectForm
                project={project}
                form={projectForm}
                titleOrder={2}
              />
              <HouseForm house={house} form={houseForm} />
            </Stack>
          </Tabs.Panel>
          <Tabs.Panel
            value={MobileTab.CHAT}
            p={CONTENT_PADDING_IN_PX}
            style={{ height: "100%", minHeight: 0, display: "flex" }}
          >
            <Card
              flex={1}
              shadow="xs"
              style={{ border: "none", justifyContent: "flex-end" }}
            >
              <ProjectNotes projectId={project.id} />
            </Card>
          </Tabs.Panel>
        </Tabs>
      ) : (
        <Stack gap="24" pb="24" p={CONTENT_PADDING_IN_PX} bg="gray.0">
          <ProjectForm project={project} form={projectForm} titleOrder={2} />
          <HouseForm house={house} form={houseForm} />
        </Stack>
      )}
    </Stack>
  )
}
