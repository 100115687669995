import { Project } from "@ensol/types/entities/project"
import { cetelemFormSchema } from "@ensol/types/forms/projects/payment"

import { getInitialValues } from "@ensol/entool/utils/initialValues"

export const getCetelemFormInitialValues = (project: Project) => ({
  ...getInitialValues(cetelemFormSchema.innerType(), project),
  cetelemFinancingRequestAmount: project.cetelemFinancingRequestAmount
    ? project.cetelemFinancingRequestAmount
    : (project.installation.activeQuote?.details?.totals.finalCost ?? null),
})
