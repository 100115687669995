import {
  InstallationStep,
  TechnicalVisitStep,
} from "@ensol/types/prisma-client"

export enum ProjectEventType {
  TECHNICAL_VISIT = "technicalVisit",
  INSTALLATION = "installation",
  INSTALLATION_NEW_VISIT = "installationNewVisit",
}

export enum ProjectEventStatus {
  PROPOSAL = "proposal",
  TO_SCHEDULE = "toSchedule",
  SCHEDULED = "scheduled",
  REJECTED = "rejected",
  COMPLETED = "completed",
}

export type ProjectEventProcessStep = InstallationStep | TechnicalVisitStep

export const TECHNICAL_VISIT_EVENT_DURATION_IN_HOURS = 2

export type Period = {
  from: Date
  to: Date
}
