import { workNeededStepFormSchema } from "@ensol/types/forms/projects/consuel"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { CONSUEL_PROPERTIES } from "@ensol/entool/pages/Project/processes/Consuel/config"
import { pickDefinedProperties } from "@ensol/entool/utils/helpers/pick"
import { getInitialValues } from "@ensol/entool/utils/initialValues"

import { ConsuelForm } from "./components/ConsuelForm"

export const WorkNeeded = ({ project, processId }: StepComponentProps) => (
  <ConsuelForm
    schema={workNeededStepFormSchema}
    initialValues={{
      ...getInitialValues(workNeededStepFormSchema, project),
      ...pickDefinedProperties(CONSUEL_PROPERTIES, project),
    }}
    project={project}
    processId={processId}
    actions={[
      {
        action: "validate",
        label: "Travaux prévus",
      },
    ]}
  />
)
