import { Group, Badge, Tooltip } from "@mantine/core"
import { IconFlame, IconFlameOff } from "@tabler/icons-react"

import { Project } from "@ensol/types/entities/project"

import { CLIENT_MOTIVATION_OPTIONS } from "@ensol/entool/utils/projects/options"

type Props = {
  project: Pick<
    Project,
    "salesVisitClientMotivation" | "technicalVisitClientMotivation"
  >
}

export const ClientMotivationBadges = ({
  project: { salesVisitClientMotivation, technicalVisitClientMotivation },
}: Props) => {
  return (
    <Group gap="8">
      <MotivationBadge
        label="VC"
        value={salesVisitClientMotivation}
        tooltip="Niveau de chaleur perçu en visite commerciale"
      />
      <MotivationBadge
        label="VT"
        value={technicalVisitClientMotivation}
        tooltip="Niveau de chaleur perçu en visite technique"
      />
    </Group>
  )
}

const BADGE_COLORS = ["blue", "yellow", "red"]

const MotivationBadge = ({
  label,
  value,
  tooltip,
}: {
  label: string
  value: string | null
  tooltip?: string
}) => {
  const optionIndex = CLIENT_MOTIVATION_OPTIONS.findIndex(
    (option) => option.value === value,
  )
  return (
    <Tooltip label={`${tooltip} : ${value !== null ? value : "Non renseigné"}`}>
      <Badge
        color={value === null ? "gray" : BADGE_COLORS[optionIndex]}
        variant="light"
        rightSection={
          value === null ? (
            <IconFlameOff size={16} />
          ) : (
            <Group gap="0">
              {Array.from({ length: optionIndex + 1 }).map((_, index) => (
                <IconFlame key={index} size={14} />
              ))}
            </Group>
          )
        }
      >
        {label}
      </Badge>
    </Tooltip>
  )
}
