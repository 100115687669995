import dayjs from "dayjs"

import { KizeoProjectReportType } from "@ensol/types/entities/project/kizeoProjectReport"

import {
  FundingSource,
  FundingSourceStatus,
} from "@ensol/shared/entities/projects/fundingSource"
import { getKizeoProjectReportPathsFromType } from "@ensol/shared/entities/projects/kizeoProjectReport"
import {
  HardwareStep,
  InstallationStep,
  PaymentStep,
  PreliminaryRequestStep,
  hasReachedStep,
} from "@ensol/shared/entities/projects/processes"
import {
  SHORT_DISPLAY_DATE_FORMAT,
  formatDate,
} from "@ensol/shared/utils/format"

import { IssuesBadges } from "@ensol/entool/components/entities/Project/IssuesBadges"
import { ProcessInfos } from "@ensol/entool/components/entities/Project/ProcessInfos"

import { CheckBadge } from "../components/CheckBadge"
import { TechnicalVisitBadge } from "../components/TechnicalVisitBadge"
import { InfoComponentProps } from "../config"

export const InstallationInfo = ({ project }: InfoComponentProps) => {
  const {
    preliminaryRequestExpectedValidationDate,
    installationStep,
    installationSchedulingHoldEndDate,
    installationLastContactDate,
    schedulingIssueMainReason,
    installationStartDate,
    installationEndDate,
    installationNewVisitDate,
    installationReportPath,
  } = project
  const daysBeforeInstallation = dayjs(installationStartDate).diff(
    dayjs(),
    "day",
  )

  return (
    <ProcessInfos
      infos={[
        {
          name: "Valid. DP prévue",
          value: formatDate(
            preliminaryRequestExpectedValidationDate,
            SHORT_DISPLAY_DATE_FORMAT,
          ),
          isHidden:
            installationStep !== InstallationStep.PENDING &&
            installationStep !== InstallationStep.SCHEDULED,
        },
        {
          name: "DP validée ?",
          dataType: "component",
          value: (
            <CheckBadge
              isChecked={hasReachedStep({
                project,
                processId: "preliminaryRequestStep",
                step: PreliminaryRequestStep.APPROVED,
              })}
            />
          ),
          isHidden:
            installationStep !== InstallationStep.PENDING &&
            installationStep !== InstallationStep.SCHEDULED,
        },
        {
          name: "VT validée ?",
          dataType: "component",
          value: <TechnicalVisitBadge project={project} />,
          isHidden:
            installationStep !== InstallationStep.PENDING &&
            installationStep !== InstallationStep.SCHEDULED,
        },
        {
          name: "Acompte payé ?",
          dataType: "component",
          value: (
            <CheckBadge
              isChecked={hasReachedStep({
                project,
                processId: "paymentStep",
                step: PaymentStep.DEPOSIT_INVOICE_PAID,
              })}
            />
          ),
          isHidden:
            (installationStep !== InstallationStep.PENDING &&
              installationStep !== InstallationStep.SCHEDULED) ||
            project.fundingSource === FundingSource.CETELEM,
        },
        {
          name: "Financement validé ?",
          dataType: "component",
          value: (
            <CheckBadge
              isChecked={
                project.fundingSourceStatus === FundingSourceStatus.APPROVED
              }
            />
          ),
          isHidden:
            (installationStep !== InstallationStep.PENDING &&
              installationStep !== InstallationStep.SCHEDULED) ||
            project.fundingSource !== FundingSource.CETELEM,
        },
        {
          name: "Matériel commandé ?",
          dataType: "component",
          value: (
            <CheckBadge
              isChecked={hasReachedStep({
                project,
                processId: "hardwareStep",
                step: HardwareStep.PREPARATION_PENDING,
              })}
            />
          ),
          isHidden:
            installationStep !== InstallationStep.PENDING &&
            installationStep !== InstallationStep.SCHEDULED,
        },
        {
          name: "Problème",
          dataType: "component",
          value: (
            <IssuesBadges
              issues={
                schedulingIssueMainReason ? [schedulingIssueMainReason] : []
              }
            />
          ),
          isHidden:
            installationStep !== InstallationStep.SCHEDULING_ON_HOLD &&
            installationStep !== InstallationStep.UNSCHEDULED,
        },
        {
          name: "À rappeler le",
          value: formatDate(
            installationSchedulingHoldEndDate,
            SHORT_DISPLAY_DATE_FORMAT,
          ),
          isHidden:
            installationStep !== InstallationStep.SCHEDULING_ON_HOLD &&
            installationStep !== InstallationStep.UNSCHEDULED,
          color: dayjs().isAfter(installationSchedulingHoldEndDate)
            ? "red.5"
            : undefined,
        },
        {
          name: "Dernier contact",
          value: formatDate(
            installationLastContactDate,
            SHORT_DISPLAY_DATE_FORMAT,
          ),
          isHidden:
            installationStep !== InstallationStep.SCHEDULING_ON_HOLD &&
            installationStep !== InstallationStep.UNSCHEDULED,
        },
        {
          name: "Date début installation",
          value: formatDate(installationStartDate, SHORT_DISPLAY_DATE_FORMAT),
          isHidden: hasReachedStep({
            project,
            processId: "installationStep",
            step: InstallationStep.IN_PROGRESS,
          }),
        },
        {
          name: "Date fin installation",
          value: formatDate(installationEndDate, SHORT_DISPLAY_DATE_FORMAT),
          isHidden: !hasReachedStep({
            project,
            processId: "installationStep",
            step: InstallationStep.IN_PROGRESS,
          }),
        },
        {
          name: "Date de passage",
          value: formatDate(
            installationNewVisitDate,
            SHORT_DISPLAY_DATE_FORMAT,
          ),
          isHidden: installationStep !== InstallationStep.NEW_VISIT_NEEDED,
        },
        {
          name: "Temps restant",
          value: `${daysBeforeInstallation} jours`,
          isHidden:
            installationStartDate === null || daysBeforeInstallation < 0,
          color: daysBeforeInstallation <= 7 ? "red" : undefined,
        },
        {
          name: "Installateur",
          value: project.installer?.name,
          isHidden:
            installationStep !== InstallationStep.COMPLETED &&
            installationStep !== InstallationStep.IN_PROGRESS &&
            installationStep !== InstallationStep.NEW_VISIT_NEEDED,
        },
        {
          name: "CR installation reçu",
          dataType: "component",
          value: (
            <CheckBadge
              isChecked={
                getKizeoProjectReportPathsFromType(
                  project.kizeoProjectReports,
                  KizeoProjectReportType.INSTALLATION,
                ).length > 0 || installationReportPath !== null
              }
            />
          ),
          isHidden: !hasReachedStep({
            project,
            processId: "installationStep",
            step: InstallationStep.IN_PROGRESS,
          }),
        },
      ]}
    />
  )
}
