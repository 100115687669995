import { approvedStepFormSchema } from "@ensol/types/forms/projects/preliminaryRequest"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { PreliminaryRequestForm } from "@ensol/entool/pages/Project/processes/PreliminaryRequest/components/PreliminaryRequestForm"
import { getInitialValues } from "@ensol/entool/utils/initialValues"

export const Approved = ({ project, ...props }: StepComponentProps) => (
  <PreliminaryRequestForm
    schema={approvedStepFormSchema}
    initialValues={getInitialValues(approvedStepFormSchema, project)}
    project={project}
    actions={[{ action: "validate", label: "Autorisation d'urbanisme reçue" }]}
    {...props}
  />
)
