import { typedKeys } from "@ensol/types/utils"

import { EXTRA_WORKS, ExtraWorkType } from "@ensol/shared/material/extraWorks"

export const IntegrationKitType = {
  ESDEC_HOOK: "ESDEC_HOOK",
  ESDEC_LAG_SCREW_250: "ESDEC_LAG_SCREW_250",
  ESDEC_LAG_SCREW_350: "ESDEC_LAG_SCREW_350",
  ESDEC_ROOF_HOOK: "ESDEC_ROOF_HOOK",
  ESDEC_FLAT_FIX: "ESDEC_FLAT_FIX",
  K2_HOOK: "K2_HOOK",
  K2_LAG_SCREW_200: "K2_LAG_SCREW_200",
  K2_LAG_SCREW_250: "K2_LAG_SCREW_250",
  K2_LAG_SCREW_300: "K2_LAG_SCREW_300",
  K2_LAG_SCREW_350: "K2_LAG_SCREW_350",
  K2_DOME: "K2_DOME",
  K2_MINI_RAIL: "K2_MINI_RAIL",
  K2_BASIC_RAIL: "K2_BASIC_RAIL",
  GSE_GROUND_SYSTEM: "GSE_GROUND_SYSTEM",
} as const

export type IntegrationKitType = keyof typeof IntegrationKitType

export const INTEGRATION_KITS = {
  [IntegrationKitType.ESDEC_HOOK]: {
    name: "ESDEC - Crochet - Trusshook",
  },
  [IntegrationKitType.ESDEC_LAG_SCREW_250]: {
    name: "ESDEC - Tire-fonds 250mm",
  },
  [IntegrationKitType.ESDEC_LAG_SCREW_350]: {
    name: "ESDEC - Tire-fonds 350mm",
  },
  [IntegrationKitType.ESDEC_ROOF_HOOK]: {
    name: "ESDEC - Pince",
  },
  [IntegrationKitType.ESDEC_FLAT_FIX]: {
    name: "ESDEC - FlatFix",
  },
  [IntegrationKitType.K2_HOOK]: {
    name: "K2 - Crochet 4S",
  },
  [IntegrationKitType.K2_LAG_SCREW_200]: {
    name: "K2 - Tire-fonds 200mm",
  },
  [IntegrationKitType.K2_LAG_SCREW_250]: {
    name: "K2 - Tire-fonds 250mm",
  },
  [IntegrationKitType.K2_LAG_SCREW_300]: {
    name: "K2 - Tire-fonds 300mm",
  },
  [IntegrationKitType.K2_LAG_SCREW_350]: {
    name: "K2 - Tire-fonds 350mm",
  },
  [IntegrationKitType.K2_DOME]: {
    name: "K2 - Dome",
  },
  [IntegrationKitType.K2_MINI_RAIL]: {
    name: "K2 - MiniRail",
  },
  [IntegrationKitType.K2_BASIC_RAIL]: {
    name: "K2 - BasicRail",
  },
  [IntegrationKitType.GSE_GROUND_SYSTEM]: {
    name: "Bac GSE Ground System",
  },
}

export const RequiredChangesType = {
  POTENTIAL_SHADING_0: "POTENTIAL_SHADING_0",
  POTENTIAL_SHADING_10: "POTENTIAL_SHADING_10",
  POTENTIAL_SHADING_20: "POTENTIAL_SHADING_20",
  INCREASE_PANELS: "INCREASE_PANELS",
  DECREASE_PANELS: "DECREASE_PANELS",
  CHANGE_PANELS_LAYOUT_DESIGN: "CHANGE_PANELS_LAYOUT_DESIGN",
  CHANGE_PANELS_LAYOUT: "CHANGE_PANELS_LAYOUT",
  CHANGE_PANELS_MODEL: "CHANGE_PANELS_MODEL",
  CHANGE_INVERTER_MODEL: "CHANGE_INVERTER_MODEL",
  CHANGE_BATTERY_MODEL: "CHANGE_BATTERY_MODEL",
  FIX_CURRENT_TYPE: "FIX_CURRENT_TYPE",
  ADD_OPTIMIZERS: "ADD_OPTIMIZERS",
  REMOVE_OPTIMIZERS: "REMOVE_OPTIMIZERS",
  ADD_BATTERY: "ADD_BATTERY",
  REMOVE_BATTERY: "REMOVE_BATTERY",
  ADD_EV_CHARGER: "ADD_EV_CHARGER",
  REMOVE_EV_CHARGER: "REMOVE_EV_CHARGER",
  ADD_SHELLY: "ADD_SHELLY",
  REMOVE_SHELLY: "REMOVE_SHELLY",
  ADD_MODULAR_CLOCK: "ADD_MODULAR_CLOCK",
  REMOVE_MODULAR_CLOCK: "REMOVE_MODULAR_CLOCK",
  ADD_ECOJOKO: "ADD_ECOJOKO",
  REMOVE_ECOJOKO: "REMOVE_ECOJOKO",
  ADD_ENPHASE_ROUTER: "ADD_ENPHASE_ROUTER",
  REMOVE_ENPHASE_ROUTER: "REMOVE_ENPHASE_ROUTER",
  ...typedKeys(EXTRA_WORKS).reduce(
    (extraWorksRequiredChanges, extraWorkType) => {
      extraWorksRequiredChanges[extraWorkType] = extraWorkType
      return extraWorksRequiredChanges
    },
    {} as Record<ExtraWorkType, string>,
  ),
} as const
export type RequiredChangesType = keyof typeof RequiredChangesType

export const REQUIRED_CHANGES: Record<string, string> = {
  [RequiredChangesType.POTENTIAL_SHADING_0]: "Risque d'ombrage 0-10%",
  [RequiredChangesType.POTENTIAL_SHADING_10]: "Risque d'ombrage 10-20%",
  [RequiredChangesType.POTENTIAL_SHADING_20]: "Risque d'ombrage +20%",
  [RequiredChangesType.INCREASE_PANELS]: "Augmentation du nb de panneaux",
  [RequiredChangesType.DECREASE_PANELS]: "Diminution du nb de panneaux",
  [RequiredChangesType.CHANGE_PANELS_LAYOUT_DESIGN]:
    "Changement du design du calepinage",
  [RequiredChangesType.CHANGE_PANELS_LAYOUT]:
    "Changement du calepinage affectant la production",
  [RequiredChangesType.CHANGE_PANELS_MODEL]:
    "Changement du modèle des panneaux PV",
  [RequiredChangesType.CHANGE_INVERTER_MODEL]:
    "Changement du modèle d'onduleur",
  [RequiredChangesType.CHANGE_BATTERY_MODEL]:
    "Changement du modèle de la batterie",
  [RequiredChangesType.FIX_CURRENT_TYPE]:
    "Correction du raccordement Mono VS Triphasé entre la Visite Commerciale et la Visite Technique",
  [RequiredChangesType.ADD_OPTIMIZERS]: "Ajout d'optimiseurs",
  [RequiredChangesType.REMOVE_OPTIMIZERS]: "Retrait d'optimiseurs",
  [RequiredChangesType.ADD_BATTERY]: "Ajout d'une batterie",
  [RequiredChangesType.REMOVE_BATTERY]: "Retrait d'une batterie",
  [RequiredChangesType.ADD_EV_CHARGER]: "Ajout d'une borne de recharge",
  [RequiredChangesType.REMOVE_EV_CHARGER]: "Retrait d'une borne de recharge",
  [RequiredChangesType.ADD_ECOJOKO]: "Ajout d'un Ecojoko",
  [RequiredChangesType.REMOVE_ECOJOKO]: "Retrait d'un Ecojoko",
  [RequiredChangesType.ADD_ENPHASE_ROUTER]: "Ajout d'un Enphase Router",
  [RequiredChangesType.REMOVE_ENPHASE_ROUTER]: "Retrait d'un Enphase Router",
  [RequiredChangesType.ADD_SHELLY]: "Ajout d'un Shelly",
  [RequiredChangesType.REMOVE_SHELLY]: "Retrait d'un Shelly",
  [RequiredChangesType.ADD_MODULAR_CLOCK]: "Ajout d'une horloge modulaire",
  [RequiredChangesType.REMOVE_MODULAR_CLOCK]: "Retrait d'une horloge modulaire",
  ...typedKeys(EXTRA_WORKS).reduce(
    (extraWorksRequiredChanges, extraWorkType) => {
      const extraWork = EXTRA_WORKS[extraWorkType]
      extraWorksRequiredChanges[extraWorkType] = extraWork.name
      return extraWorksRequiredChanges
    },
    {} as Record<ExtraWorkType, string>,
  ),
} satisfies Record<RequiredChangesType | ExtraWorkType, string>

export const RequiredWorksType = {
  FRAMEWORK_WORKS: "FRAMEWORK_WORKS",
  COVERAGE_WORKS: "COVERAGE_WORKS",
  CARPORT_CONSTRUCTION: "CARPORT_CONSTRUCTION",
  ELECTRICAL_INSTALLATION_WORKS: "ELECTRICAL_INSTALLATION_WORKS",
  MONO_TO_TRIPHASE_WORKS: "MONO_TO_TRIPHASE_WORKS",
  LINKY_INSTALLATION: "LINKY_INSTALLATION",
  RISKY_DRILLING: "RISKY_DRILLING",
  OTHER: "OTHER",
} as const
export type RequiredWorksType = keyof typeof RequiredWorksType

export const REQUIRED_WORKS: Record<string, string> = {
  [RequiredWorksType.FRAMEWORK_WORKS]: "Travaux important sur la charpente",
  [RequiredWorksType.COVERAGE_WORKS]: "Travaux important sur la couverture",
  [RequiredWorksType.CARPORT_CONSTRUCTION]: "Construction d'un carport",
  [RequiredWorksType.ELECTRICAL_INSTALLATION_WORKS]:
    "Remise en sécurité / au norme du tableau électrique",
  [RequiredWorksType.MONO_TO_TRIPHASE_WORKS]:
    "Travaux pour passer d’une installation monophasé à triphasé",
  [RequiredWorksType.LINKY_INSTALLATION]: "Installation d'un linky par Enedis",
  [RequiredWorksType.RISKY_DRILLING]:
    "Perçage à risque (toit terrasse, mur avec conduits d'eau, etc)",
  [RequiredWorksType.OTHER]: "Autre",
} satisfies Record<RequiredWorksType, string>

export enum RoofAccessibility {
  LADDER = "LADDER",
  POD = "POD",
  SCAFFOLDING = "SCAFFOLDING",
  LIFT = "LIFT",
  OTHER = "OTHER",
}

export const ROOF_ACCESSIBILITY: Record<string, string> = {
  [RoofAccessibility.LADDER]: "Échelle",
  [RoofAccessibility.POD]: "Nacelle",
  [RoofAccessibility.SCAFFOLDING]: "Échafaudage",
  [RoofAccessibility.LIFT]: "Monte-charge",
  [RoofAccessibility.OTHER]: "Autre",
}

export const getRoofAccessibility = (roofAccessibility: string | null) => {
  if (roofAccessibility === null) {
    return null
  }
  return ROOF_ACCESSIBILITY[roofAccessibility]
}

export enum ElectricMeterType {
  LINKY = "LINKY",
  DISK = "DISK",
  ELECTRONIC = "ELECTRONIC",
  OTHER = "OTHER",
}

export const ELECTRIC_METER_TYPE: Record<string, string> = {
  [ElectricMeterType.LINKY]: "Linky",
  [ElectricMeterType.DISK]: "Disque",
  [ElectricMeterType.ELECTRONIC]: "Électronique",
  [ElectricMeterType.OTHER]: "Autre",
}

export const getElectricMeter = (electricMeterType: string | null) => {
  if (electricMeterType === null) {
    return null
  }
  return ELECTRIC_METER_TYPE[electricMeterType]
}
