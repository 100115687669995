import * as z from "zod"
import { ControlDeviceType } from "@ensol/shared/material/controlDevices"
import { EquipmentType } from "@ensol/shared/entities/houses/equipments"

export const controlDeviceInstallationsSchema = z.array(
  z.object({
    id: z.string().optional(),
    deviceType: z.nativeEnum(ControlDeviceType),
    controlledEquipments: z.array(z.nativeEnum(EquipmentType)),
    isFree: z.boolean(),
  }),
)

export type ControlDeviceInstallations = z.infer<
  typeof controlDeviceInstallationsSchema
>
