import { Autocomplete, AutocompleteProps, Loader } from "@mantine/core"
import _ from "lodash"

import { Location } from "@ensol/types/forms/houses"

import {
  getPlaceDetails,
  useSearchPlace,
} from "@ensol/shared/thirdParties/google/places"

type Props = {
  defaultValue?: string
  handleSelectOption: (placeDetails: Location) => void
  googleApiKey: string
} & AutocompleteProps

export const AddressAutocomplete = ({
  defaultValue,
  handleSelectOption,
  googleApiKey,
  ...props
}: Props) => {
  const { predictions, onSearch, isLoading } = useSearchPlace(googleApiKey)
  return (
    <>
      <div id="places" />
      <Autocomplete
        defaultValue={defaultValue}
        onChange={_.debounce((value) => onSearch(value), 250)}
        onOptionSubmit={async (placeId: string) => {
          const placeDetails = await getPlaceDetails(googleApiKey, placeId)
          if (placeDetails) {
            handleSelectOption(placeDetails)
          }
        }}
        rightSection={isLoading ? <Loader size="sm" /> : undefined}
        filter={({ options }) => options}
        data={predictions}
        data-test="addressAutocomplete"
        scrollAreaProps={{
          // @ts-expect-error Mantine Autocomplete is not typed correctly
          viewportProps: { "data-test": "addressAutocompleteResults" },
        }}
        {...props}
      />
    </>
  )
}
