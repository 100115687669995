import { Textarea, SimpleGrid, Button, Stack } from "@mantine/core"

import { ProjectDocumentType } from "@ensol/types/entities/project"
import { EdfOaStepInput } from "@ensol/types/forms/projects/edfOa"

import { Section } from "@ensol/shared/components/Section"
import { getClientFileName } from "@ensol/shared/utils/files"

import { PREVIEW_WIDTH } from "@ensol/entool/components/entities/File/constants"
import {
  StepActions,
  StepActionsProps,
} from "@ensol/entool/components/entities/Project/StepActions"
import { FileField } from "@ensol/entool/components/form/File/FileField"
import { FormField } from "@ensol/entool/components/form/FormField"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { useCreateDocumentMutation } from "@ensol/entool/queries/projects"
import {
  ProcessFormProps,
  useProcessForm,
} from "@ensol/entool/utils/projects/useProcessForm"

export const EdfOaForm = <Input extends EdfOaStepInput>({
  schema,
  initialValues,
  project,
  processId,
  actions,
}: ProcessFormProps<Input> & StepActionsProps) => {
  const form = useProcessForm<Input>({
    schema,
    initialValues,
    projectId: project.id,
    processId,
  })
  const { mutateAsync: createCertificate, isPending: isCertificateLoading } =
    useCreateDocumentMutation(
      project.id,
      ProjectDocumentType.EDF_OA_CERTIFICATE,
    )

  const { client } = project.installation.house

  return (
    <StepActions
      project={project}
      processId={processId}
      actions={actions}
      validateStep={() => schema.parse(form.values)}
    >
      <Section>
        <SimpleGrid cols={2} spacing="32" mt="16">
          <FormField initialValues={initialValues} propertyName="edfOaNotes">
            <Textarea
              autosize
              w={FIELD_WIDTH}
              minRows={3}
              {...form.getInputProps("edfOaNotes")}
            />
          </FormField>
          <FormField
            initialValues={initialValues}
            propertyName="edfOaCertificatePath"
            isRequired
          >
            <Stack gap="8">
              <Button
                onClick={async () => {
                  const edfOaCertificatePath = await createCertificate()
                  form
                    .getInputProps("edfOaCertificatePath")
                    .onChange(edfOaCertificatePath)
                }}
                loading={isCertificateLoading}
                disabled={project.edfOaCertificatePath !== null}
                w={PREVIEW_WIDTH}
              >
                Générer l&apos;attestation BTA
              </Button>
              <FileField
                previewProps={{
                  fileName: getClientFileName("attestation-bta", client),
                }}
                inputProps={{ accept: ["application/pdf"] }}
                {...form.getInputProps("edfOaCertificatePath")}
              />
            </Stack>
          </FormField>
        </SimpleGrid>
      </Section>
    </StepActions>
  )
}
