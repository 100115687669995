import { useMediaQuery } from "@mantine/hooks"
import { useState } from "react"

import { FundingSource } from "@ensol/shared/entities/projects/fundingSource"
import { ProcessId } from "@ensol/shared/entities/projects/processes"
import { ProjectStatus } from "@ensol/shared/entities/projects/statuses"
import { devices } from "@ensol/shared/styles/theme"

import { useListProjectsQuery } from "@ensol/entool/queries/projects"
import { useProjectsSearch } from "@ensol/entool/utils/projects/search"

export const useProjects = (processId?: ProcessId) => {
  const [showOnlyScheduledInstallations, setShowOnlyScheduledInstallations] =
    useState(false)
  const [fundingSource, setFundingSource] = useState<FundingSource | null>(null)

  const isMobile = useMediaQuery(devices.sm)
  const {
    search,
    installerIds,
    supplierIds,
    salesOwnerIds,
    operationsOwnerIds,
    adminOwnerIds,
  } = useProjectsSearch()

  const projectsQuery = useListProjectsQuery({
    filters: {
      search,
      installerIds,
      supplierIds,
      salesOwnerIds,
      operationsOwnerIds,
      adminOwnerIds,
      statuses: [
        { status: ProjectStatus.CREATED, isIncluded: false },
        ...(search === ""
          ? [{ status: ProjectStatus.ABORTED, isIncluded: false }]
          : []),
      ],
      onlyScheduledInstallations:
        processId === "preliminaryRequestStep"
          ? showOnlyScheduledInstallations
          : false,
      fundingSource:
        processId === "paymentStep" && fundingSource
          ? fundingSource
          : undefined,
    },
    enabled: isMobile !== undefined && (isMobile ? search !== "" : true),
  })

  return {
    projectsQuery,
    showOnlyScheduledInstallations,
    setShowOnlyScheduledInstallations,
    fundingSource,
    setFundingSource,
    isSearching: search !== "",
  }
}
