import { Carousel, CarouselProps } from "@mantine/carousel"
import { Badge } from "@mantine/core"
import { useCallback, useState } from "react"

import { FileThumbnail } from "./FileThumbnail"
import { MultiFilePreviewModal } from "./MultiFilePreviewModal"
import { PREVIEW_HEIGHT, PREVIEW_WIDTH } from "./constants"

type FileWithTag = {
  path: string
  tag?: {
    label: string
    color: string
  }
}

type Props = {
  fileName: string
  files: FileWithTag[]
  onClear?: (path: string) => void
} & CarouselProps

export const MultiFilePreview = ({
  files,
  fileName,
  w,
  h,
  onClear,
  ...props
}: Props) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false)
  const onClose = useCallback(() => setIsPreviewOpen(false), [setIsPreviewOpen])
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  return (
    <>
      <Carousel
        {...props}
        w={w ?? PREVIEW_WIDTH}
        h={h ?? PREVIEW_HEIGHT}
        withControls={files.length > 1}
        onSlideChange={(index: number) => setCurrentSlideIndex(index)}
        loop
        styles={{ root: { flexShrink: 0 } }}
      >
        {files.map((file, index) => (
          <Carousel.Slide key={file.path} pos="relative" maw="100%">
            {file.tag && (
              <Badge
                style={{ zIndex: 1 }}
                bg={file.tag.color}
                size="md"
                pos="absolute"
                top={12}
                left={12}
              >
                {file.tag.label}
              </Badge>
            )}
            <FileThumbnail
              filePath={file.path}
              w={w}
              h={h}
              fileName={`${fileName}${index > 0 ? `-${index + 1}` : ""}`}
              onClear={onClear ? () => onClear(file.path) : undefined}
              onClick={() => setIsPreviewOpen(true)}
            />
          </Carousel.Slide>
        ))}
      </Carousel>
      <MultiFilePreviewModal
        isPreviewOpen={isPreviewOpen}
        filePaths={files.map((file) => file.path)}
        onClose={onClose}
        currentSlideIndex={currentSlideIndex}
      />
    </>
  )
}
