import _ from "lodash"

import { Client } from "@ensol/types/entities/clients"
import { ResidentialStatus as PrismaResidentialStatus } from "@ensol/types/prisma-client"
import { Nullable } from "@ensol/types/utils"

import { formatFullName } from "@ensol/shared/utils/format"

export const Gender = {
  FEMALE: "FEMALE",
  MALE: "MALE",
} as const

export type Gender = keyof typeof Gender

export const GENDERS = {
  [Gender.FEMALE]: { long: "Madame", short: "Mme." },
  [Gender.MALE]: { long: "Monsieur", short: "M." },
}

export const ResidentialStatus = {
  OWNER: "OWNER",
  RENTER: "RENTER",
} as const

export type ResidentialStatus = keyof typeof ResidentialStatus

export const isInEligibleArea = (postcode: string) =>
  [
    "06",
    "13",
    "83",
    "84",
    "30",
    "31",
    "11",
    "81",
    "34",
    "46",
    "12",
    "48",
    "32",
    "65",
    "09",
    "66",
  ].includes(postcode.slice(0, 2))

export const isEligible = (
  residentialStatus: PrismaResidentialStatus,
  postcode: string,
) => isInEligibleArea(postcode) && residentialStatus === ResidentialStatus.OWNER

export const getDefaultUniquePhone = () =>
  `+1${_.random(1000000000, 9999999999)}`

export const getDefaultUniqueEmail = () =>
  `client-${Date.now()}@ensol-offline.com`

export const getFormalName = (
  client: Nullable<Pick<Client, "firstName" | "lastName" | "gender">>,
) =>
  `${client.gender ? GENDERS[client.gender].short : ""} ${formatFullName(client)}`

export const REFERRAL_FORM_URL = "https://form.goensol.com/partager"
