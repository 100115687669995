import * as Sentry from "@sentry/react"
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom"

import { AuthProvider } from "@ensol/shared/components/AuthProvider"
import { Role, RoleGroups } from "@ensol/shared/entities/users"

import { clerk } from "@ensol/entool/backend/clerk"
import { AppShell } from "@ensol/entool/components/layout/AppShell"
import { ClientsPage } from "@ensol/entool/pages/Clients"
import { Home } from "@ensol/entool/pages/Home"
import { PanelsLayoutPage } from "@ensol/entool/pages/PanelsLayout"
import { ProjectFeedbackPage } from "@ensol/entool/pages/ProjectFeedback"
import { ProjectsAlerts } from "@ensol/entool/pages/ProjectsAlerts"
import { ProjectsEventsPage } from "@ensol/entool/pages/ProjectsEvents"
import { SalesTasksPage } from "@ensol/entool/pages/SalesTasks"
import { SalesVisitPage } from "@ensol/entool/pages/SalesVisit"

import { AuthGuard } from "./AuthGuard"
import { ClientPage } from "./Client"
import { DefaultProjectRedirect } from "./DefaultProjectRedirect"
import { DefaultTeamRedirect } from "./DefaultTeamRedirect"
import { InstallationPage } from "./Installation"
import { LegacyDomainRedirect } from "./LegacyDomainRedirect"
import { NewClientPage } from "./NewClient"
import { ProjectTechnicalOverviewPage } from "./ProjectTechnicalOverview"
import { ProjectsByProcess } from "./ProjectsByProcess"
import { ProjectsOverview } from "./ProjectsOverview"
import { RouteRoleGuard } from "./RouteRoleGuard"

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouterV6(createBrowserRouter)

export const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route element={<LegacyDomainRedirect />}>
      <Route
        element={
          <AuthProvider
            clerkInstance={clerk}
            publishableKey={import.meta.env.VITE_ENTOOL_CLERK_PUBLISHABLE_KEY}
          />
        }
      >
        <Route element={<AuthGuard />}>
          <Route element={<AppShell />}>
            <Route element={<DefaultTeamRedirect />}>
              <Route
                element={
                  <RouteRoleGuard
                    authorizedGroups={[
                      { group: RoleGroups.ENSOL, minimumRole: Role.EMPLOYEE },
                    ]}
                  />
                }
              >
                <Route index element={<Home />} />
              </Route>
            </Route>
            <Route path="/projects">
              <Route
                element={
                  <RouteRoleGuard
                    authorizedGroups={[
                      {
                        group: RoleGroups.PARTNER,
                        minimumRole: Role.INSTALLER,
                      },
                      {
                        group: RoleGroups.ENSOL,
                        minimumRole: Role.EMPLOYEE,
                      },
                    ]}
                  />
                }
              >
                <Route index element={<ProjectsOverview />} />
                <Route path=":projectId" element={<DefaultProjectRedirect />} />
                <Route
                  path=":projectId/technical-overview"
                  element={<ProjectTechnicalOverviewPage />}
                />
              </Route>
              <Route
                element={
                  <RouteRoleGuard
                    authorizedGroups={[
                      { group: RoleGroups.ENSOL, minimumRole: Role.EMPLOYEE },
                    ]}
                  />
                }
              >
                <Route path="alerts" element={<ProjectsAlerts />} />
                <Route
                  path="processes/:processId"
                  element={<ProjectsByProcess />}
                />
              </Route>
            </Route>
            <Route
              element={
                <RouteRoleGuard
                  authorizedGroups={[
                    { group: RoleGroups.ENSOL, minimumRole: Role.EMPLOYEE },
                  ]}
                />
              }
            >
              <Route path="/clients">
                <Route index element={<ClientsPage />} />
                <Route path="new" element={<NewClientPage />} />
                <Route path=":clientId">
                  <Route index element={<ClientPage />} />
                </Route>
              </Route>
              <Route path="/installations">
                <Route path=":installationId">
                  <Route index element={<InstallationPage />} />
                  <Route path="panels-layout" element={<PanelsLayoutPage />} />
                </Route>
              </Route>
              <Route path="/events">
                <Route index element={<ProjectsEventsPage />} />
              </Route>
              <Route path="/sales">
                <Route index element={<SalesTasksPage />} />
                <Route path=":projectId" element={<SalesVisitPage />} />
              </Route>
              <Route path="/feedbacks">
                <Route path=":projectId" element={<ProjectFeedbackPage />} />
              </Route>
            </Route>
          </Route>
          {/* This route is an unauthenticated one used by Cypress tests to programmatically login*/}
        </Route>
        <Route path="/cypress/sign-in" />
      </Route>
    </Route>,
  ),
)
