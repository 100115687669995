import { Group, Stack } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { IconDeviceFloppy } from "@tabler/icons-react"
import { useLocalStorage } from "@uidotdev/usehooks"
import { useEffect, useMemo } from "react"
import { Helmet } from "react-helmet-async"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import {
  OnSiteInstallation,
  MonthlyConsumptions,
} from "@ensol/types/entities/installation"
import {
  generalUpdateHouseSchema,
  GeneralUpdateHouseInput,
  HouseUpdateType,
} from "@ensol/types/forms/houses"
import {
  InstallationFormInput,
  updateInstallationSchema,
} from "@ensol/types/forms/installations"
import {
  ConsumptionDataSource,
  ProductionDataSource,
} from "@ensol/types/simulation"

import { ContentContainer } from "@ensol/shared/components/layout/ContentContainer"
import { getCoords } from "@ensol/shared/entities/houses/coords"
import { DEFAULT_ELECTRICITY_PRICE_ANNUAL_RAISE } from "@ensol/shared/entities/installations/energy"
import { computeTotalDiscount } from "@ensol/shared/entities/installations/savings"
import {
  CONTENT_PADDING_IN_PX,
  useResponsiveDimensions,
} from "@ensol/shared/styles/constants"
import { formatFullName } from "@ensol/shared/utils/format"

import { ControlDeviceInstallations } from "@ensol/entool/pages/Installation/sections/7-ControlDevices/ControlDeviceInstallations"
import { useInstallationEnergyStats } from "@ensol/entool/queries/energy"
import { getSubformActions } from "@ensol/entool/utils/form/subforms"
import { isFormValid } from "@ensol/entool/utils/form/validation"
import { InstallationPageSection } from "@ensol/entool/utils/installations/useInstallationMenu"
import { useBeforeLeavePage } from "@ensol/entool/utils/navigation"

import { InstallationHeader } from "./Header"
import {
  InstallationOverview,
  OVERVIEW_WIDTH_IN_PX,
} from "./components/Overview"
import { HouseDescriptionForm } from "./sections/1-Description"
import { Savings } from "./sections/10-Savings"
import { ConsumptionSection } from "./sections/2-Consumption"
import { HouseEquipmentsForm } from "./sections/3-Equipments"
import { PhotovoltaicInstallation } from "./sections/4-Photovoltaic/PhotovoltaicInstallation"
import { BatteryInstallation } from "./sections/5-Battery/BatteryInstallation"
import { EvChargerInstallation } from "./sections/6-EvCharger/EvChargerInstallation"
import { Settings } from "./sections/8-Settings"
import { AutoConsumption } from "./sections/9-AutoConsumption"
import { useInstallationNavigation } from "./useInstallationNavigation"
import { useSimulationResults } from "./useSimulationResults"
import { useUpdateProductsCount } from "./useUpdateProductsCount"

type Props = {
  installationId: string
  installationDate: Date
  name: string
  slug: string
  productionDataSource: ProductionDataSource
  consumptionDataSource: ConsumptionDataSource
  yearlyConsumption: number
  monthlyConsumptions?: MonthlyConsumptions
  monthlyBill: number
  electricityBuyPrice: number
  manualAutoConsumptionPercent: number
  computedAutoConsumptionPercent: number
  photovoltaicInstallation: InstallationsResponses.PhotovoltaicInstallation | null
  batteryInstallation: InstallationsResponses.BatteryInstallation | null
  evChargerInstallation: InstallationsResponses.EvChargerInstallation | null
  controlDeviceInstallations: InstallationsResponses.ControlDeviceInstallations
  productsCount: number
  onSiteInstallation?: OnSiteInstallation
  panelsLayout?: InstallationsResponses.Installation["panelsLayout"]
  referrerId?: string | null
  discount?: number | null
  client: InstallationsResponses.Installation["client"]
  house: HousesResponses.House<{
    include: {
      switchgridConsent: true
      switchgridOrder: true
      roofSections: true
    }
  }>
  projectId: string | null
  enedisFileCompletionDate?: Date
  onSave: (input: {
    house: GeneralUpdateHouseInput
    installation: InstallationFormInput
  }) => Promise<InstallationsResponses.Installation>
  isSaving: boolean
  isUpsell: boolean
}

export const Installation = ({
  installationId,
  installationDate,
  name,
  slug,
  productionDataSource,
  consumptionDataSource,
  monthlyBill,
  yearlyConsumption,
  electricityBuyPrice,
  monthlyConsumptions,
  manualAutoConsumptionPercent,
  computedAutoConsumptionPercent,
  photovoltaicInstallation,
  batteryInstallation,
  evChargerInstallation,
  controlDeviceInstallations,
  onSiteInstallation,
  panelsLayout,
  discount,
  referrerId,
  client,
  house,
  projectId,
  enedisFileCompletionDate,
  onSave,
  isSaving,
  productsCount,
  isUpsell,
}: Props) => {
  const { contentPadding } = useResponsiveDimensions()
  const { currentSection } = useInstallationNavigation()
  const [isOverviewVisible, setIsOverviewVisible] = useLocalStorage(
    "isInstallationOverviewVisible",
    true,
  )

  const houseForm = useForm<GeneralUpdateHouseInput>({
    validate: zodResolver(generalUpdateHouseSchema),
    validateInputOnChange: true,
    initialValues: {
      lat: house.lat,
      lng: house.lng,
      placeId: house.placeId,
      address: house.address,
      streetAddress: house.streetAddress,
      city: house.city,
      postcode: house.postcode,
      country: house.country,
      region: house.region,
      updateType: HouseUpdateType.GENERAL,
      constructionYear: house.constructionYear,
      currentType: house.currentType,
      roofType: house.roofType,
      surface: house.surface,
      floors: house.floors,
      isIndividualHouse: house.isIndividualHouse,
      hasAsbestos: house.hasAsbestos,
      isHistoricBuilding: house.isHistoricBuilding,
      isAtticAccessible: house.isAtticAccessible,
      roofFramingMaterial: house.roofFramingMaterial,
      equipments: house.equipments,
    },
  })

  const installationForm = useForm<InstallationFormInput>({
    validate: zodResolver(updateInstallationSchema),
    validateInputOnChange: true,
    initialValues: {
      name,
      productionDataSource,
      consumptionDataSource,
      monthlyBill,
      yearlyConsumption,
      electricityBuyPrice,
      monthlyConsumptions: monthlyConsumptions ?? [],
      manualAutoConsumptionPercent,
      computedAutoConsumptionPercent,
      photovoltaicInstallation,
      batteryInstallation,
      evChargerInstallation,
      controlDeviceInstallations,
      discount,
      referrerId,
      houseId: house.id,
      clientId: client.id,
      productsCount,
      electricityPriceAnnualRaise: DEFAULT_ELECTRICITY_PRICE_ANNUAL_RAISE,
      isUpsell,
    },
  })

  useUpdateProductsCount(installationForm)

  const totalDiscount = useMemo(
    () => computeTotalDiscount(installationForm.values.discount),
    [installationForm.values.discount],
  )

  const { data: energyStats, isPending: isEnergyStatsLoading } =
    useInstallationEnergyStats({
      houseId: house.id,
      coords: getCoords(houseForm.values),
      installation: installationForm.values,
      switchgridOrderId: house.switchgridOrder?.orderId ?? null,
      onSiteInstallation,
    })

  const isInstallationValid =
    isFormValid(houseForm) && isFormValid(installationForm)

  const houseWithFormValues = useMemo(
    () => ({ ...house, ...houseForm.values }),
    [house, houseForm.values],
  )

  const simulationResults = useSimulationResults({
    energyStats,
    autoConsumptionPercent:
      installationForm.values.manualAutoConsumptionPercent,
    house: houseWithFormValues,
    installationDate,
    installationValues: installationForm.values,
    isInstallationValid,
    onSiteInstallation,
  })

  const handleSave = async () => {
    const updatedInstallation = await onSave({
      house: houseForm.values,
      installation: installationForm.values,
    })
    // Set controlDeviceInstallations using the API response
    // to have the ids in the form for the next save
    installationForm.setFieldValue(
      "controlDeviceInstallations",
      updatedInstallation.controlDeviceInstallations,
    )
    installationForm.resetDirty()
    houseForm.resetDirty()
  }

  useBeforeLeavePage(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname &&
      (installationForm.isDirty() || houseForm.isDirty()),
    [
      {
        label: "Enregistrer et quitter",
        leftSection: <IconDeviceFloppy size={18} />,
        onClick: handleSave,
      },
    ],
  )

  // Update auto consumption percent (manual and computed)
  // to the new new computed value if it changed
  useEffect(() => {
    if (
      energyStats &&
      energyStats.autoConsumptionPercent !==
        installationForm.values.computedAutoConsumptionPercent
    ) {
      installationForm.setValues({
        computedAutoConsumptionPercent: energyStats.autoConsumptionPercent,
        manualAutoConsumptionPercent: energyStats.autoConsumptionPercent,
      })
    }
  }, [energyStats, installationForm])

  return (
    <Stack flex="1" gap="0" mih="100vh" h="fit-content">
      <Helmet>
        <title>
          {formatFullName(client)} - {name} | Entool
        </title>
      </Helmet>
      <InstallationHeader
        installationId={installationId}
        projectId={projectId}
        name={installationForm.values.name}
        address={houseForm.values.address}
        client={client}
        form={installationForm}
        isInstallationValid={isInstallationValid}
        onSave={handleSave}
        isSaving={isSaving}
        isOverviewVisible={isOverviewVisible}
        toggleOverview={() => setIsOverviewVisible((isVisible) => !isVisible)}
        isEnergyStatsLoading={isEnergyStatsLoading}
      />
      <ContentContainer flex="1" m="0" pt={CONTENT_PADDING_IN_PX} bg="gray.0">
        <Group flex="1" align="flex-start" justify="space-between" pos="sticky">
          <Stack
            w={
              isOverviewVisible
                ? `calc(100% - ${OVERVIEW_WIDTH_IN_PX + contentPadding}px)`
                : "100%"
            }
          >
            <Stack gap="xl" mb={24}>
              <SectionWrapper
                section={InstallationPageSection.DESCRIPTION}
                currentActiveSection={currentSection}
              >
                <HouseDescriptionForm
                  form={houseForm}
                  panelsLayout={panelsLayout}
                />
              </SectionWrapper>
              <SectionWrapper
                section={InstallationPageSection.EQUIPMENTS}
                currentActiveSection={currentSection}
              >
                <HouseEquipmentsForm
                  form={houseForm}
                  onSiteInstallation={onSiteInstallation}
                />
              </SectionWrapper>
              <SectionWrapper
                section={InstallationPageSection.CONSUMPTION}
                currentActiveSection={currentSection}
              >
                <ConsumptionSection
                  form={installationForm}
                  house={houseWithFormValues}
                />
              </SectionWrapper>
              <SectionWrapper
                section={InstallationPageSection.PHOTOVOLTAIC}
                currentActiveSection={currentSection}
              >
                <PhotovoltaicInstallation
                  photovoltaicInstallation={
                    installationForm.values.photovoltaicInstallation
                  }
                  house={houseWithFormValues}
                  panelsLayout={panelsLayout}
                  yearlyConsumption={installationForm.values.yearlyConsumption}
                  totalDiscount={totalDiscount}
                  onSiteInstallation={onSiteInstallation}
                  enedisFileCompletionDate={enedisFileCompletionDate}
                  {...getSubformActions(
                    installationForm,
                    "photovoltaicInstallation",
                  )}
                  isUpsell={isUpsell}
                />
              </SectionWrapper>
              <SectionWrapper
                section={InstallationPageSection.BATTERY}
                currentActiveSection={currentSection}
              >
                <BatteryInstallation
                  installationValues={installationForm.values}
                  isInstallationValid={isInstallationValid}
                  installationDate={installationDate}
                  house={houseWithFormValues}
                  onSiteInstallation={onSiteInstallation}
                  batteryInstallation={
                    installationForm.values.batteryInstallation
                  }
                  inverterType={
                    installationForm.values.photovoltaicInstallation
                      ?.inverterType ??
                    (isUpsell
                      ? onSiteInstallation?.photovoltaicInstallation
                          .inverterType
                      : undefined)
                  }
                  {...getSubformActions(
                    installationForm,
                    "batteryInstallation",
                  )}
                  batteryStats={energyStats?.batteryStats}
                />
              </SectionWrapper>
              <SectionWrapper
                section={InstallationPageSection.EV_CHARGER}
                currentActiveSection={currentSection}
              >
                <EvChargerInstallation
                  evChargerInstallation={
                    installationForm.values.evChargerInstallation
                  }
                  {...getSubformActions(
                    installationForm,
                    "evChargerInstallation",
                  )}
                  productsCount={installationForm.values.productsCount}
                  monthlyBill={installationForm.values.monthlyBill}
                  yearlyProduction={installationForm.values.yearlyConsumption}
                  billReductionRatio={simulationResults?.billReductionRatio}
                />
              </SectionWrapper>
              <SectionWrapper
                section={InstallationPageSection.CONTROL_DEVICES}
                currentActiveSection={currentSection}
              >
                <ControlDeviceInstallations
                  installationId={installationId}
                  controlDeviceInstallations={
                    installationForm.values.controlDeviceInstallations
                  }
                  {...getSubformActions(
                    installationForm,
                    "controlDeviceInstallations",
                  )}
                  house={houseWithFormValues}
                />
              </SectionWrapper>
              <SectionWrapper
                section={InstallationPageSection.SETTINGS}
                currentActiveSection={currentSection}
              >
                <Settings
                  form={installationForm}
                  house={houseWithFormValues}
                  simulationResults={simulationResults}
                />
              </SectionWrapper>
              <SectionWrapper
                section={InstallationPageSection.AUTO_CONSUMPTION}
                currentActiveSection={currentSection}
              >
                <AutoConsumption
                  coords={getCoords(houseForm.values)}
                  installationValues={installationForm.values}
                  slug={slug}
                  energyStats={energyStats}
                  isEnergyStatsLoading={isEnergyStatsLoading}
                />
              </SectionWrapper>
              <SectionWrapper
                section={InstallationPageSection.SAVINGS}
                currentActiveSection={currentSection}
              >
                <Savings
                  slug={slug}
                  simulationResults={simulationResults}
                  setManualAutoConsumptionPercent={(value) => {
                    installationForm.setValues({
                      manualAutoConsumptionPercent: value,
                    })
                    // We reset the dirty state here to avoid the reports
                    // to be outdated because of this property as they are updated
                    // in real time with manualAutoConsumptionPercent
                    installationForm.resetDirty()
                  }}
                  installationValues={installationForm.values}
                  house={houseWithFormValues}
                  onSiteInstallation={onSiteInstallation}
                  isInstallationValid={isInstallationValid}
                  installationDate={installationDate}
                  energyStats={energyStats}
                  isEnergyStatsLoading={isEnergyStatsLoading}
                />
              </SectionWrapper>
            </Stack>
          </Stack>
          {isOverviewVisible && (
            <InstallationOverview
              house={houseWithFormValues}
              client={client}
              installationId={installationId}
              installationDate={installationDate}
              form={installationForm}
              isInstallationValid={isInstallationValid}
              areEnergyStatsAvailable={
                energyStats !== undefined && energyStats !== null
              }
              isLoading={isEnergyStatsLoading}
              simulationResults={simulationResults}
            />
          )}
        </Group>
      </ContentContainer>
    </Stack>
  )
}

const SectionWrapper = ({
  section,
  currentActiveSection,
  children,
}: {
  section: InstallationPageSection
  currentActiveSection: InstallationPageSection | null
  children?: React.ReactNode
}) => (
  <Stack display={currentActiveSection === section ? "flex" : "none"}>
    {children}
  </Stack>
)
