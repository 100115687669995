import { Button, Divider, Group, Stack, Text, ThemeIcon } from "@mantine/core"
import {
  IconAt,
  IconFolderSymlink,
  IconHeartHandshake,
  IconPhone,
  IconUsers,
} from "@tabler/icons-react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { Section } from "@ensol/shared/components/Section"
import { formatFullName } from "@ensol/shared/utils/format"

import { MapLink } from "@ensol/entool/components/MapLink"
import { InstallationCharacteristics } from "@ensol/entool/components/entities/Installation/InstallationCharacteristics"
import { ProjectOwners } from "@ensol/entool/components/entities/Project/ProjectOwners"
import { getProjectInstallers } from "@ensol/entool/utils/projects/installers"

type Props = {
  project: ProjectResponses.ProjectTechnicalOverview
}

export const CharacteristicsTab = ({ project }: Props) => {
  const { house, referrer } = project.installation
  const { client } = house

  return (
    <Section title="Caractéristiques">
      <Stack gap={24}>
        <Stack gap={8}>
          <MapLink
            address={house.address}
            h="fit-content"
            textProps={{
              size: "md",
              c: "gray.6",
              ta: "left",
              style: { textWrap: "wrap" },
            }}
          />
          <Button
            h="fit-content"
            href={`mailto:${client.email}`}
            component="a"
            variant="transparent"
            p="0"
            justify="flex-start"
          >
            <Group gap="4">
              <IconAt size={16} />
              <Text size="md" c="gray.6">
                {client.email}
              </Text>
            </Group>
          </Button>
          <Button
            h="fit-content"
            href={`tel:${client.phone}`}
            component="a"
            variant="transparent"
            p="0"
            justify="flex-start"
          >
            <Group gap="4">
              <IconPhone size={16} />
              <Text size="md" c="gray.6">
                {client.phone}
              </Text>
            </Group>
          </Button>
          {referrer && (
            <Group gap="4">
              <ThemeIcon size={18} color="blue">
                <IconHeartHandshake />
              </ThemeIcon>
              <Text size="md" c="gray.6">
                Parrainé par <b>{formatFullName(referrer)}</b>
              </Text>
            </Group>
          )}
          <Group gap="4">
            <ThemeIcon size={18} color="blue">
              <IconUsers />
            </ThemeIcon>
            <Text size="md" c="gray.6">
              Responsables :{" "}
            </Text>
            <ProjectOwners project={project} />
          </Group>
          {project.preliminaryRequestReference && (
            <Group gap="4">
              <ThemeIcon size={18} color="blue">
                <IconFolderSymlink />
              </ThemeIcon>
              <Text size="md" c="gray.6">
                Numéro de DP : {project.preliminaryRequestReference}
              </Text>
            </Group>
          )}
        </Stack>
        <Divider />
        <Stack gap={8}>
          <InstallationCharacteristics
            size="md"
            installation={project.installation}
            installers={getProjectInstallers(project)}
          />
        </Stack>
      </Stack>
    </Section>
  )
}
