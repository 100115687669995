import { Button, SimpleGrid, Slider } from "@mantine/core"
import { IconBolt } from "@tabler/icons-react"

import { Section } from "@ensol/shared/components/Section"

import { Field } from "@ensol/entool/components/form/Field"

type Props = {
  computedAutoConsumptionPercent: number
  manualAutoConsumptionPercent: number | undefined
  maxAutoConsumptionPercent: number
  setManualAutoConsumptionPercent: (value: number) => void
  isBoostActive: boolean
}

const BOOST_EXTRA_AUTO_CONSUMPTION_PERCENT = 15

export const AutoConsumptionSection = ({
  computedAutoConsumptionPercent,
  manualAutoConsumptionPercent,
  maxAutoConsumptionPercent,
  setManualAutoConsumptionPercent,
  isBoostActive,
}: Props) => (
  <Section
    title="Boostez vos économies"
    Actions={
      <Button
        leftSection={<IconBolt size={16} />}
        onClick={() => {
          if (isBoostActive) {
            setManualAutoConsumptionPercent(computedAutoConsumptionPercent)
          } else {
            setManualAutoConsumptionPercent(
              Math.min(
                computedAutoConsumptionPercent +
                  BOOST_EXTRA_AUTO_CONSUMPTION_PERCENT,
                100,
              ),
            )
          }
        }}
        color={isBoostActive ? "green" : "blue"}
      >
        {isBoostActive ? "Boost Ensol activé" : "Boost Ensol"}
      </Button>
    }
  >
    <SimpleGrid cols={2}>
      <Field name="Taux d'autoconsommation">
        <Slider
          color={isBoostActive ? "green" : "blue"}
          marks={[
            {
              value: computedAutoConsumptionPercent,
              label: `Taux prévu : ${computedAutoConsumptionPercent}%`,
            },
          ]}
          label={(value) => `${value}%`}
          max={maxAutoConsumptionPercent}
          value={manualAutoConsumptionPercent ?? computedAutoConsumptionPercent}
          onChange={setManualAutoConsumptionPercent}
        />
      </Field>
    </SimpleGrid>
  </Section>
)
