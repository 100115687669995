import { Card, Stack, StackProps, Tabs, Text } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import {
  IconInfoCircle,
  IconLibrary,
  IconMessageCircle,
} from "@tabler/icons-react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import {
  hasReachedStep,
  InstallationStep,
} from "@ensol/shared/entities/projects/processes"
import { CONTENT_PADDING_IN_PX } from "@ensol/shared/styles/constants"
import { devices } from "@ensol/shared/styles/theme"

import { ProjectNotes } from "@ensol/entool/components/entities/Project/ProjectNotes"

import { CharacteristicsTab } from "./CharacteristicsTab"
import { HardwareSection } from "./HardwareSection"
import { HouseSection } from "./HouseSection"
import { InstallationValidationSection } from "./InstallationValidationSection"
import { KizeoProjectReportsSection } from "./KizeoProjectReportsSection"
import { ProcessesStatusSection } from "./ProcessesStatusSection"
import { ProjectSection } from "./ProjectSection"

type Props = {
  project: ProjectResponses.ProjectTechnicalOverview
} & StackProps

enum Tab {
  DETAILS = "Détails",
  CHARACTERISTICS = "Caractéristiques",
  CHAT = "Chat",
}

export const ProjectTechnicalOverview = ({ project, ...props }: Props) => {
  const isMobile = useMediaQuery(devices.sm)

  if (isMobile) {
    return <MobileTechnicalOverview project={project} />
  }

  return <TechnicalOverviewView project={project} {...props} />
}

const MobileTechnicalOverview = ({ project }: Props) => {
  return (
    <Tabs
      defaultValue={Tab.DETAILS}
      keepMounted={false}
      styles={{
        root: {
          display: "flex",
          flexDirection: "column",
          minHeight: 0,
          flex: 1,
        },
      }}
    >
      <Tabs.List bg="white" grow justify="space-around">
        <Tabs.Tab value={Tab.DETAILS} leftSection={<IconLibrary size={12} />}>
          <Text size="md">{Tab.DETAILS}</Text>
        </Tabs.Tab>
        <Tabs.Tab
          value={Tab.CHARACTERISTICS}
          leftSection={<IconInfoCircle size={12} />}
        >
          <Text size="md">{Tab.CHARACTERISTICS}</Text>
        </Tabs.Tab>
        <Tabs.Tab
          value={Tab.CHAT}
          leftSection={<IconMessageCircle size={12} />}
        >
          <Text size="md">{Tab.CHAT}</Text>
        </Tabs.Tab>
      </Tabs.List>
      <Tabs.Panel
        value={Tab.DETAILS}
        p={CONTENT_PADDING_IN_PX}
        h="100%"
        style={{ overflow: "auto" }}
      >
        <TechnicalOverviewView project={project} />
      </Tabs.Panel>
      <Tabs.Panel value={Tab.CHARACTERISTICS} p={CONTENT_PADDING_IN_PX}>
        <CharacteristicsTab project={project} />
      </Tabs.Panel>
      <Tabs.Panel
        value={Tab.CHAT}
        p={CONTENT_PADDING_IN_PX}
        style={{ height: "100%", minHeight: 0, display: "flex" }}
      >
        <Card
          flex={1}
          shadow="xs"
          style={{ border: "none", justifyContent: "flex-end" }}
        >
          <ProjectNotes projectId={project.id} />
        </Card>
      </Tabs.Panel>
    </Tabs>
  )
}

const TechnicalOverviewView = ({ project, ...props }: Props) => {
  return (
    <Stack gap="24" flex="1" {...props} bg="gray.0">
      <ProjectSection project={project} />
      <HouseSection project={project} />
      <ProcessesStatusSection project={project} />
      <KizeoProjectReportsSection project={project} />
      {hasReachedStep({
        project,
        processId: "installationStep",
        step: InstallationStep.IN_PROGRESS,
      }) && <InstallationValidationSection project={project} />}
      <HardwareSection project={project} />
    </Stack>
  )
}
