import { Stack, List, ThemeIcon, Text, Group } from "@mantine/core"
import { IconInfoCircle, IconTools, IconGavel } from "@tabler/icons-react"

import {
  ProjectStatus,
  getStatusIndex,
} from "@ensol/shared/entities/projects/statuses"
import {
  REQUIRED_CHANGES,
  REQUIRED_WORKS,
} from "@ensol/shared/entities/projects/technicalVisit"
import {
  formatDate,
  SHORT_DISPLAY_DATE_FORMAT,
} from "@ensol/shared/utils/format"

import { Tooltip } from "@ensol/entool/components/Tooltip"
import { PROJECT_FIELDS_LABELS } from "@ensol/entool/utils/form/fieldLabels/projects"

type Props = {
  technicalVisitRequiredChanges: string[]
  technicalVisitRequiredWorks: string[]
  technicalVisitEndOfWorksDate: Date | null
  status: ProjectStatus
}

export const WorksAndChangesIndicators = ({
  technicalVisitRequiredChanges,
  technicalVisitRequiredWorks,
  technicalVisitEndOfWorksDate,
  status,
}: Props) => {
  if (
    (technicalVisitRequiredChanges.length === 0 &&
      technicalVisitRequiredWorks.length === 0) ||
    getStatusIndex(status) >=
      getStatusIndex(ProjectStatus.INSTALLATION_COMPLETED)
  ) {
    return null
  }

  return (
    <Group gap="4" wrap="nowrap">
      {technicalVisitRequiredChanges.length > 0 && (
        <Tooltip
          label={
            <Stack gap={4}>
              <Group gap={4}>
                <IconInfoCircle size={18} />
                <Text>Changement requis</Text>
              </Group>
              <List pl={8}>
                {technicalVisitRequiredChanges.map((change) => (
                  <List.Item key={change}>{REQUIRED_CHANGES[change]}</List.Item>
                ))}
              </List>
            </Stack>
          }
        >
          <ThemeIcon color="yellow.4">
            <IconTools />
          </ThemeIcon>
        </Tooltip>
      )}
      {technicalVisitRequiredWorks.length > 0 && (
        <Tooltip
          label={
            <Stack gap={4}>
              <Group gap={4}>
                <IconInfoCircle size={18} />
                <Text>Travaux requis</Text>
              </Group>
              <List pl={8}>
                {technicalVisitRequiredWorks.map((change) => (
                  <List.Item key={change}>{REQUIRED_WORKS[change]}</List.Item>
                ))}
              </List>
              <Group gap={4}>
                <Text>
                  {PROJECT_FIELDS_LABELS.technicalVisitEndOfWorksDate} :
                </Text>
                <Text fw="bold">
                  {technicalVisitEndOfWorksDate
                    ? formatDate(
                        technicalVisitEndOfWorksDate,
                        SHORT_DISPLAY_DATE_FORMAT,
                      )
                    : "-"}
                </Text>
              </Group>
            </Stack>
          }
        >
          <ThemeIcon c="red.4">
            <IconGavel />
          </ThemeIcon>
        </Tooltip>
      )}
    </Group>
  )
}
