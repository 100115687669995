import {
  Card,
  Stack,
  Group,
  ThemeIcon,
  Title,
  Badge,
  Text,
  Flex,
  Button,
} from "@mantine/core"
import { useMediaQuery, useOs } from "@mantine/hooks"
import { modals } from "@mantine/modals"
import { IconCircleCheck, IconReportAnalytics } from "@tabler/icons-react"

import { KizeoProjectReport } from "@ensol/types/entities/project/kizeoProjectReport"

import { Link } from "@ensol/shared/components/Link"
import { getKizeoProjectReportFormUrl } from "@ensol/shared/entities/projects/kizeoProjectReport"
import { devices } from "@ensol/shared/styles/theme"
import {
  formatFullName,
  SHORT_DISPLAY_DATE_TIME_FORMAT,
  SHORT_DISPLAY_DATE_FORMAT,
  formatDate,
} from "@ensol/shared/utils/format"

import { useDeleteKizeoProjectReportMutation } from "@ensol/entool/queries/kizeoProjectReports"

import { KIZEO_PROJECT_REPORT_TYPE_LABELS } from "./constants"

type Props = {
  kizeoProjectReport: KizeoProjectReport<{
    select: {
      kizeoDataId: true
      type: true
      submittedAt: true
      reportPath: true
      createdAt: true
      projectId: true
      user: {
        select: {
          firstName: true
          lastName: true
        }
      }
    }
  }>
}

export const ReportCard = ({ kizeoProjectReport }: Props) => {
  const os = useOs()
  const isMobile = useMediaQuery(devices.sm)
  const { mutate: deleteKizeoProjectReport, isPending: isDeleting } =
    useDeleteKizeoProjectReportMutation(kizeoProjectReport.projectId)

  return (
    <Card withBorder radius={8} p={12} flex={1}>
      <Flex
        gap={12}
        flex={1}
        justify="space-between"
        direction={isMobile ? "column" : "row"}
        align={isMobile ? "normal" : "center"}
      >
        <Stack gap={8}>
          <Group gap={8} align="center">
            {kizeoProjectReport.reportPath ? (
              <ThemeIcon color="green.5">
                <IconCircleCheck size={20} />
              </ThemeIcon>
            ) : (
              <ThemeIcon color="blue">
                <IconReportAnalytics size={20} />
              </ThemeIcon>
            )}
            <Title order={4}>
              CR - {KIZEO_PROJECT_REPORT_TYPE_LABELS[kizeoProjectReport.type]}
            </Title>
            {kizeoProjectReport.reportPath ? (
              <Badge color="green" variant="light">
                Soumis
              </Badge>
            ) : (
              <Badge variant="light" color="yellow">
                Brouillon
              </Badge>
            )}
          </Group>
          <Stack gap={4}>
            {kizeoProjectReport.submittedAt && (
              <Group gap={4} c="green.5">
                <Text>
                  Reçu le{" "}
                  {formatDate(
                    kizeoProjectReport.submittedAt,
                    SHORT_DISPLAY_DATE_TIME_FORMAT,
                  )}
                </Text>
              </Group>
            )}
            <Group gap={4} c="gray.6">
              <Text>
                Créé le{" "}
                {formatDate(
                  kizeoProjectReport.createdAt,
                  SHORT_DISPLAY_DATE_FORMAT,
                )}
                {kizeoProjectReport.user &&
                  ` par ${formatFullName(kizeoProjectReport.user)}`}
              </Text>
            </Group>
          </Stack>
        </Stack>
        <Group gap={8} wrap="nowrap" flex={{ base: 1, sm: "initial" }}>
          {!kizeoProjectReport.reportPath && (
            <Button
              loading={isDeleting}
              variant="light"
              flex={{ base: 1, sm: "initial" }}
              color="red"
              onClick={() =>
                modals.openConfirmModal({
                  title: "Supprimer le rapport",
                  children: (
                    <Text size="sm">
                      Êtes-vous sûr(e) de vouloir supprimer ce rapport ?
                    </Text>
                  ),
                  confirmProps: { color: "red" },
                  centered: true,
                  labels: { confirm: "Supprimer", cancel: "Annuler" },
                  onConfirm: () =>
                    deleteKizeoProjectReport(kizeoProjectReport.kizeoDataId),
                })
              }
            >
              Supprimer
            </Button>
          )}
          <Link
            flex={{ base: 1, sm: "initial" }}
            variant={kizeoProjectReport.reportPath ? "light" : "filled"}
            label={kizeoProjectReport.reportPath ? "Voir" : "Finaliser"}
            to={`${os === "ios" || os === "android" ? "kizeoforms://--/receipts" : getKizeoProjectReportFormUrl(kizeoProjectReport.type)}`}
            isExternal
          />
        </Group>
      </Flex>
    </Card>
  )
}
