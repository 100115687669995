import { MantineStyleProps } from "@mantine/core"

import { Project } from "@ensol/types/entities/project"

import { Link } from "@ensol/shared/components/Link"
import { getSolteoProjectLink, SOLTEO_URL } from "@ensol/shared/utils/solteo"

type Props = {
  project: Project<{ select: { solteoProjectId: true } }>
} & MantineStyleProps

export const SolteoLink = ({ project, ...props }: Props) => (
  <Link
    label="Accéder à Solteo"
    to={
      project.solteoProjectId
        ? getSolteoProjectLink(project.solteoProjectId)
        : SOLTEO_URL
    }
    Icon={<img src="/solteoLogo.png" height={20} />}
    isExternal
    {...props}
  />
)
