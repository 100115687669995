import dayjs from "dayjs"

import {
  ProjectOperationsOverview,
  ProjectOperationsTask,
} from "@ensol/types/entities/project/overview"

import { ProcessId } from "@ensol/shared/entities/projects/processes"
import { ProjectStatus } from "@ensol/shared/entities/projects/statuses"

import {
  SchedulingIssueReasonsInfo,
  TaskDetails,
} from "@ensol/entool/pages/ProjectsOverview/components/ProjectTaskDetails"

export const OPS_PROJECT_TASK_CONFIG: Record<
  ProjectOperationsTask,
  TaskDetails
> = {
  [ProjectOperationsTask.TECHNICAL_VISIT_UNSCHEDULED]: {
    label: "VT - Non planifiable",
    color: "red",
    ExtraInfoComponent: SchedulingIssueReasonsInfo,
  },
  [ProjectOperationsTask.TECHNICAL_VISIT_TO_CALL_BACK]: {
    label: "VT - À rappeler",
    color: "orange",
    ExtraInfoComponent: SchedulingIssueReasonsInfo,
  },
  [ProjectOperationsTask.TECHNICAL_VISIT_WAITING_FOR_INSTALLER]: {
    label: "VT - En attente Installateur",
    color: "blue",
  },
  [ProjectOperationsTask.WAITING_FOR_INFO]: {
    label: "En attente info",
    color: "blue",
  },
  [ProjectOperationsTask.TECHNICAL_VISIT_TO_SCHEDULE]: {
    label: "VT - À planifier",
    color: "orange",
  },
  [ProjectOperationsTask.CLIENT_TO_CALL]: {
    label: "VT AE - Client à appeler",
    color: "orange",
  },
  [ProjectOperationsTask.WAITING_FOR_VT]: {
    label: "En attente VT",
    color: "blue",
  },
  [ProjectOperationsTask.INSTALLATION_UNSCHEDULED]: {
    label: "Installation - Non planifiable",
    color: "red",
    ExtraInfoComponent: SchedulingIssueReasonsInfo,
  },
  [ProjectOperationsTask.INSTALLATION_TO_CALL_BACK]: {
    label: "Installation - À rappeler",
    color: "orange",
    ExtraInfoComponent: SchedulingIssueReasonsInfo,
  },
  [ProjectOperationsTask.INSTALLATION_WAITING_FOR_INSTALLER]: {
    label: "Installation - En attente Installateur",
    color: "blue",
  },
  [ProjectOperationsTask.INSTALLATION_TO_SCHEDULE]: {
    label: "Installation - À planifier",
    color: "orange",
  },
  [ProjectOperationsTask.WAITING_FOR_VT_VALIDATION]: {
    label: "En attente validation VT",
    color: "blue",
  },
  [ProjectOperationsTask.WAITING_FOR_DEPOSIT_PAYMENT]: {
    label: "En attente paiement acompte",
    color: "orange",
  },
  [ProjectOperationsTask.INSTALLATION_IN_PROGRESS]: {
    label: "Installation en cours",
    color: "blue",
  },
  [ProjectOperationsTask.WAITING_FOR_INSTALLATION]: {
    label: "En attente installation",
    color: "blue",
  },
  [ProjectOperationsTask.WAITING_FOR_SITE_PREPARATION]: {
    label: "En attente préparation chantier",
    color: "blue",
  },
  [ProjectOperationsTask.WAITING_FOR_FINAL_PAYMENT]: {
    label: "En attente paiement final",
    color: "orange",
  },
  [ProjectOperationsTask.WAITING_FOR_CONSUEL]: {
    label: "En attente consuel",
    color: "blue",
  },
  [ProjectOperationsTask.WAITING_FOR_CONSUEL_VISIT]: {
    label: "En attente visite consuel",
    color: "blue",
  },
  [ProjectOperationsTask.WAITING_FOR_CONSUEL_VALIDATION]: {
    label: "En attente validation consuel",
    color: "blue",
  },
  [ProjectOperationsTask.WAITING_FOR_SERVICE_ACTIVATION]: {
    label: "En attente mise en service",
    color: "blue",
  },
  [ProjectOperationsTask.FOLLOW_UP]: { label: "Suivi", color: "yellow" },
}

export type ProjectStatusConfig = {
  processes: ProcessId[]
  referenceDateLabel?: string
  getReferenceDates: (project: ProjectOperationsOverview) => {
    date: Date | null
    label: string
    status: "success" | "info" | "warning"
  }[]
}

const computePreliminaryRequestReferenceDate = (
  project: ProjectOperationsOverview,
) => {
  if (project.preliminaryRequestApprovalDate !== null) {
    return {
      label: "Date validation DP",
      date: project.preliminaryRequestApprovalDate,
      status: "success",
    } as const
  }

  return {
    label: "Valid. prévue DP",
    date: project.preliminaryRequestExpectedValidationDate,
    status: dayjs().isAfter(project.preliminaryRequestExpectedValidationDate)
      ? "warning"
      : "info",
  } as const
}

export const STATUS_SECTION_CONFIG: Record<ProjectStatus, ProjectStatusConfig> =
  {
    [ProjectStatus.CREATED]: { processes: [], getReferenceDates: () => [] },
    [ProjectStatus.SIGNED]: {
      processes: ["preliminaryRequestStep", "technicalVisitStep"],
      getReferenceDates: () => [],
    },
    [ProjectStatus.TECHNICAL_VISIT_SCHEDULED]: {
      processes: ["preliminaryRequestStep", "technicalVisitStep"],
      getReferenceDates: (project) => [
        {
          label: "Date VT",
          date: project.technicalVisitStartDate,
          status: "info",
        },
        computePreliminaryRequestReferenceDate(project),
      ],
    },
    [ProjectStatus.TECHNICAL_VISIT_COMPLETED]: {
      processes: [
        "preliminaryRequestStep",
        "technicalVisitStep",
        "enedisStep",
        "installationStep",
      ],
      getReferenceDates: (project) => [
        computePreliminaryRequestReferenceDate(project),
        {
          label: "Date début travaux",
          date: project.technicalVisitStartOfWorksDate,
          status: "info",
        },
      ],
    },
    [ProjectStatus.INSTALLATION_SCHEDULED]: {
      processes: [
        "preliminaryRequestStep",
        "enedisStep",
        "installationStep",
        "paymentStep",
        "hardwareStep",
      ],
      getReferenceDates: (project) => [
        {
          label: "Date envoi acompte",
          date:
            project.taskDetails?.task ===
            ProjectOperationsTask.WAITING_FOR_DEPOSIT_PAYMENT
              ? project.paymentDepositInvoiceSendDate
              : null,
          status: "info",
        },
        {
          label: "Date installation",
          date: project.installationStartDate,
          status:
            project.preliminaryRequestApprovalDate === null &&
            dayjs(project.preliminaryRequestExpectedValidationDate).isAfter(
              project.installationStartDate,
            )
              ? "warning"
              : "info",
        },
        computePreliminaryRequestReferenceDate(project),
      ],
    },
    [ProjectStatus.INSTALLATION_COMPLETED]: {
      processes: [
        "enedisStep",
        "installationStep",
        "paymentStep",
        "consuelStep",
        "edfOaStep",
        "followUpStep",
      ],
      getReferenceDates: ({
        consuelSummonsDate,
        paymentFinalInvoiceSendDate,
        taskDetails,
      }) => [
        {
          label: "Date visite Consuel",
          date: consuelSummonsDate,
          status: "info",
        },
        {
          label: "Date envoi solde",
          date:
            taskDetails?.task ===
            ProjectOperationsTask.WAITING_FOR_FINAL_PAYMENT
              ? paymentFinalInvoiceSendDate
              : null,
          status: "info",
        },
      ],
    },
    [ProjectStatus.CONSUEL_VALIDATED]: {
      getReferenceDates: ({ enedisActivationDate }) => [
        {
          label: "Mise en service",
          date: enedisActivationDate,
          status: "info",
        },
      ],
      processes: [
        "enedisStep",
        "paymentStep",
        "consuelStep",
        "edfOaStep",
        "followUpStep",
      ],
    },
    [ProjectStatus.DONE]: {
      processes: ["followUpStep", "edfOaStep", "greendealStep"],
      getReferenceDates: () => [],
    },
    [ProjectStatus.ABORTED]: {
      processes: [
        "preliminaryRequestStep",
        "technicalVisitStep",
        "installationStep",
      ],
      getReferenceDates: () => [],
    },
  }
