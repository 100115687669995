import { Group, Badge, Text, ThemeIcon } from "@mantine/core"
import { IconChecklist, IconBellRinging, IconEye } from "@tabler/icons-react"

import { Project } from "@ensol/types/entities/project"
import {
  ProjectOperationsOverview,
  ProjectTaskType,
} from "@ensol/types/entities/project/overview"

export type TaskDetails = {
  label: string
  color: string
  ExtraInfoComponent?: React.FC<{
    project: ProjectOperationsOverview
  }>
}

type ProjectTaskDetailsProps = {
  taskDetails: TaskDetails
  type: ProjectTaskType
  project?: ProjectOperationsOverview
}

export const ProjectTaskDetails = ({
  taskDetails: { label, color, ExtraInfoComponent },
  type,
  project,
}: ProjectTaskDetailsProps) => {
  return (
    <Group gap={8}>
      <Badge
        variant="light"
        color={color}
        size="sm"
        styles={{
          label: { display: "flex", alignItems: "center", gap: 4 },
        }}
      >
        <ThemeIcon c={color}>
          <ProjectTaskIcon type={type} />
        </ThemeIcon>
        {label}
      </Badge>
      {ExtraInfoComponent && project && (
        <ExtraInfoComponent project={project} />
      )}
    </Group>
  )
}

type ProjectTaskIconProps = {
  type: ProjectTaskType
}

const ProjectTaskIcon = ({ type }: ProjectTaskIconProps) => {
  switch (type) {
    case ProjectTaskType.TODO:
      return <IconChecklist />
    case ProjectTaskType.REMINDER:
      return <IconBellRinging />
    case ProjectTaskType.MONITORING:
      return <IconEye />
    default:
      return null
  }
}

export const SchedulingIssueReasonsInfo = ({
  project,
}: {
  project: Project<{
    select: { schedulingIssueMainReason: true; schedulingIssueSubReason: true }
  }>
}) => {
  return (
    project.schedulingIssueMainReason &&
    project.schedulingIssueSubReason && (
      <Text c="red" style={{ fontSize: 12 }}>
        Raison :{" "}
        <b>
          {project.schedulingIssueMainReason} (
          {project.schedulingIssueSubReason})
        </b>
      </Text>
    )
  )
}
