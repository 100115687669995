import { useForm, zodResolver } from "@mantine/form"

import {
  AbortProjectInput,
  abortProjectSchema,
} from "@ensol/types/forms/projects/abort"
import { Nullable } from "@ensol/types/utils"

import { CustomActionModalProps } from "@ensol/entool/components/entities/Project/StepActions"
import { useAbortProjectMutation } from "@ensol/entool/queries/projects"
import { ABORT_REASONS_OPTIONS } from "@ensol/entool/utils/projects/options"

export const useAbortProjectReasonsModal = ({
  project,
  onClose,
  onValidate,
}: CustomActionModalProps) => {
  const initialValues = {
    schedulingIssueMainReason: ABORT_REASONS_OPTIONS.some(({ items }) =>
      items.includes(project.schedulingIssueSubReason ?? ""),
    )
      ? project.schedulingIssueMainReason
      : null,
    schedulingIssueSubReason: project.schedulingIssueSubReason,
  }

  const form = useForm<Nullable<AbortProjectInput>>({
    validate: zodResolver(abortProjectSchema),
    initialValues,
  })

  const { mutateAsync: abortProject, isPending: isAbortingProject } =
    useAbortProjectMutation(project.id)

  const handleSubmit = async (values: AbortProjectInput) => {
    await abortProject(values)
    onClose()
    await onValidate?.()
  }

  const isLoading = isAbortingProject

  return { isLoading, handleSubmit, form, initialValues }
}
