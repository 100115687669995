import _ from "lodash"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"

import { withMargin } from "@ensol/shared/entities/installations/costs"
import { sumExtraWorksCosts } from "@ensol/shared/entities/installations/costs/extraWorks"
import {
  computeInvertersCount,
  computePanelsCapacity,
} from "@ensol/shared/entities/installations/energy"
import {
  PHOTOVOLTAIC_SUBSIDIES,
  PhotovoltaicSubsidyType,
} from "@ensol/shared/entities/installations/subsidies/photovoltaic"
import { ELECTRICAL_SAFE_GUARDS_PRICES } from "@ensol/shared/material/photovoltaic/electricalSafeGuards"
import { getInverter } from "@ensol/shared/material/photovoltaic/inverters"
import { getOptimizer } from "@ensol/shared/material/photovoltaic/optimizers"
import { getPanel } from "@ensol/shared/material/photovoltaic/panels"
import { getPhotovoltaicSmartMeter } from "@ensol/shared/material/photovoltaic/smartMeters"

import { CostsBuilder } from "./CostsBuilder"

// HARDWARE COSTS (€)
const BUILD_KIT_VARIABLE_COST_IN_EURO = 60
const FLAT_ROOF_BUILD_KIT_VARIABLE_COST_IN_EURO = 75
const BUILD_KIT_FIXED_COST_IN_EURO = 450

// WORKFORCE COSTS (€)
const TECHNICAL_VISIT_COST = 250
const FIRST_SELL_WORKFORCE_VARIABLE_COST_IN_EURO = 80
const FIRST_SELL_WORKFORCE_FIXED_COST_IN_EURO = 1150
const UPSELL_WORKFORCE_VARIABLE_COST_IN_EURO = 100
const UPSELL_WORKFORCE_FIXED_COST_IN_EURO = 150

// ADMIN COSTS (€)
const PHOTOVOLTAIC_ADMIN_FEE_COST_IN_EURO = 250

// MARGIN (%)
// TODO: when battery & evCharger pricing are updated, rework withMargin method to compute the margin that way : value * (margin/1-margin)
const PHOTOVOLTAIC_MARGIN = 0.33 / (1 - 0.33)

const withPhotovoltaicMargin = (value: number) =>
  withMargin(value, PHOTOVOLTAIC_MARGIN)

export class PhotovoltaicCostsBuilder extends CostsBuilder<
  InstallationsResponses.PhotovoltaicInstallation,
  PhotovoltaicSubsidyType
> {
  constructor(
    installation: InstallationsResponses.PhotovoltaicInstallation,
    private house: Pick<
      HousesResponses.House<{ include: { roofSections: true } }>,
      "constructionYear" | "currentType" | "roofSections" | "roofType"
    >,
    private totalDiscount: number,
    private isUpsell: boolean,
  ) {
    super(installation)
  }

  public computeVatRate() {
    if (this.isUpsell) {
      return 0.2
    }

    const currentYear = new Date().getFullYear()
    const houseAge = currentYear - this.house.constructionYear
    const installedCapacity = computePanelsCapacity(this.installation)

    if (installedCapacity <= 3 && houseAge >= 2) return 0.1
    return 0.2
  }

  public computePanelUnitPrice() {
    const { panelType } = this.installation

    const panel = getPanel(panelType)
    return withPhotovoltaicMargin(panel.price)
  }

  public computeInverterUnitPrice(): number {
    const { inverterType } = this.installation

    const inverter = getInverter(inverterType)
    return withPhotovoltaicMargin(inverter.price)
  }

  public computeOptimizerUnitPrice(): number {
    const { optimizerType } = this.installation

    if (optimizerType === null) {
      return 0
    }
    const optimizer = getOptimizer(optimizerType)
    return withPhotovoltaicMargin(optimizer!.price)
  }

  public computePhotovoltaicSmartMeterPrice(): number {
    const { inverterType } = this.installation

    const smartMeter = getPhotovoltaicSmartMeter(inverterType)
    return withPhotovoltaicMargin(smartMeter.price)
  }

  public computeElectricalSafeGuardUnitPrice() {
    const { inverterType } = this.installation
    const { currentType } = this.house

    const inverter = getInverter(inverterType)
    const installedCapacity = computePanelsCapacity(this.installation)

    const { fixedPrice, capacityVariablePrice, panelVariablePrice } =
      ELECTRICAL_SAFE_GUARDS_PRICES[currentType][
        inverter.isCentralInverter ? "central" : "micro"
      ]

    return withPhotovoltaicMargin(
      capacityVariablePrice * installedCapacity +
        panelVariablePrice * this.installation.panelsCount +
        fixedPrice,
    )
  }

  public computeBuildKitPrice() {
    const { roofSectionsWithPanels } = this.installation

    return withPhotovoltaicMargin(
      _.sumBy(
        roofSectionsWithPanels,
        ({ roofSection, panelsCount }) =>
          panelsCount *
          (roofSection.hasFlatRoof
            ? FLAT_ROOF_BUILD_KIT_VARIABLE_COST_IN_EURO
            : BUILD_KIT_VARIABLE_COST_IN_EURO),
      ) + BUILD_KIT_FIXED_COST_IN_EURO,
    )
  }

  private computeUpsellMaterialCosts() {
    const { panelsCount, inverterType } = this.installation

    // Only if micro-inverters are already installed. Otherwise we keep the current central inverter
    const inverter = getInverter(inverterType)
    const invertersCost = inverter.isCentralInverter
      ? 0
      : computeInvertersCount(inverterType, panelsCount) *
        this.computeInverterUnitPrice()

    return (
      panelsCount * this.computePanelUnitPrice() +
      this.computeBuildKitPrice() +
      invertersCost
    )
  }

  private computeFirstSellMaterialCosts() {
    const { panelsCount, inverterType, optimizerCount } = this.installation
    const invertersCount = computeInvertersCount(inverterType, panelsCount)

    return (
      panelsCount * this.computePanelUnitPrice() +
      invertersCount * this.computeInverterUnitPrice() +
      (optimizerCount ?? 0) * this.computeOptimizerUnitPrice() +
      this.computeBuildKitPrice() +
      this.computePhotovoltaicSmartMeterPrice() +
      this.computeElectricalSafeGuardUnitPrice()
    )
  }

  public computeMaterialCosts() {
    return this.isUpsell
      ? this.computeUpsellMaterialCosts()
      : this.computeFirstSellMaterialCosts()
  }

  private computeUpsellWorkforceCosts() {
    return withPhotovoltaicMargin(
      this.installation.panelsCount * UPSELL_WORKFORCE_VARIABLE_COST_IN_EURO +
        UPSELL_WORKFORCE_FIXED_COST_IN_EURO,
    )
  }

  private computeFirstSellWorkforceCosts() {
    const { panelsCount } = this.installation

    return withPhotovoltaicMargin(
      panelsCount * FIRST_SELL_WORKFORCE_VARIABLE_COST_IN_EURO +
        FIRST_SELL_WORKFORCE_FIXED_COST_IN_EURO +
        TECHNICAL_VISIT_COST,
    )
  }

  public computeWorkforceCosts() {
    return this.isUpsell
      ? this.computeUpsellWorkforceCosts()
      : this.computeFirstSellWorkforceCosts()
  }

  public computeExtraWorksCosts() {
    return sumExtraWorksCosts(this.installation)
  }

  public computeAdminCosts() {
    return this.isUpsell ? 0 : PHOTOVOLTAIC_ADMIN_FEE_COST_IN_EURO
  }

  public computeSubsidy() {
    const { subsidyType } = this.installation
    return subsidyType !== null
      ? {
          subsidyType,
          subsidyAmount: PHOTOVOLTAIC_SUBSIDIES[subsidyType].computeAmount({
            photovoltaicInstallation: this.installation,
            house: this.house,
            totalDiscount: this.totalDiscount,
          }),
        }
      : null
  }
}
