import { SimpleGrid, Stack } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { useEffect } from "react"

import { EnergyResponses } from "@ensol/types/endpoints/energy"
import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { OnSiteInstallation } from "@ensol/types/entities/installation"
import { InstallationFormInput } from "@ensol/types/forms/installations"
import { batteryInstallationSchema } from "@ensol/types/forms/installations/battery"
import { extraWorksSchema } from "@ensol/types/forms/installations/extraWorks"

import { Section } from "@ensol/shared/components/Section"
import { getDefaultBatteryInstallation } from "@ensol/shared/entities/installations/defaults"
import { getBattery } from "@ensol/shared/material/batteries"
import {
  getInverter,
  InverterType,
} from "@ensol/shared/material/photovoltaic/inverters"

import { InfoBadge } from "@ensol/entool/pages/Installation/components/InfoBadge"
import {
  ProductWrapper,
  ProductFormProps,
} from "@ensol/entool/pages/Installation/components/ProductWrapper"
import { findSelectedOption } from "@ensol/entool/utils/form/radio"
import { isFormValid } from "@ensol/entool/utils/form/validation"
import { useBatteryMaterialOptions } from "@ensol/entool/utils/installations/battery"

import { BatterySelector } from "./BatterySelector"
import { SettingsSection } from "./SettingsSection"
import { StatsSection } from "./StatsSection"

type BaseProps = {
  onChange: (data: InstallationsResponses.BatteryInstallation | null) => void
  setError: (error: string) => void
  clearError: () => void
  inverterType?: InverterType
}

type Props = BaseProps & {
  batteryInstallation: InstallationsResponses.BatteryInstallation | null
  installationValues: InstallationFormInput
  house: HousesResponses.House<{
    include: { roofSections: true; switchgridOrder: true }
  }>
  onSiteInstallation?: OnSiteInstallation
  isInstallationValid: boolean
  installationDate: Date
  batteryStats?: EnergyResponses.BatteryStats
}

export const BatteryInstallation = ({
  batteryInstallation,
  installationDate,
  installationValues,
  house,
  onSiteInstallation,
  inverterType,
  batteryStats,
  isInstallationValid,
  ...props
}: Props) => {
  const defaultBatteryInstallation = getDefaultBatteryInstallation(
    inverterType,
    batteryStats,
  )

  return (
    <>
      <StatsSection
        isInstallationValid={isInstallationValid}
        installationDate={installationDate}
        installationValues={installationValues}
        house={house}
        onSiteInstallation={onSiteInstallation}
        defaultBatteryInstallation={defaultBatteryInstallation}
      />
      <ProductWrapper<
        InstallationsResponses.BatteryInstallation,
        {
          inverterType?: InverterType
          batteryStats?: EnergyResponses.BatteryStats
        }
      >
        type="Battery"
        title="Ajouter une batterie"
        addButtonLabel="Ajouter une batterie"
        deleteButtonLabel="Supprimer la batterie"
        pictureUrl="/batteryHuawei.png"
        onAdd={() => props.onChange(defaultBatteryInstallation)}
        ProductForm={BatteryInstallationForm}
        formProps={{
          productInstallation: batteryInstallation,
          inverterType,
          batteryStats,
          ...props,
        }}
      />
    </>
  )
}

const BatteryInstallationForm = ({
  productInstallation,
  onChange,
  setError,
  clearError,
  inverterType,
  DeleteButton,
  batteryStats,
}: ProductFormProps<InstallationsResponses.BatteryInstallation> & {
  inverterType?: InverterType
  batteryStats?: EnergyResponses.BatteryStats
}) => {
  const form = useForm<InstallationsResponses.BatteryInstallation>({
    onValuesChange: (values) => onChange(values),
    validate: zodResolver(batteryInstallationSchema.merge(extraWorksSchema)),
    initialValues: productInstallation ?? undefined,
  })

  const inverterInfo = inverterType ? getInverter(inverterType) : undefined
  const batteryOptions = useBatteryMaterialOptions({
    inverterInfo,
    batteryType: form.values.type,
    batteryStats,
  })

  useEffect(() => {
    if (
      findSelectedOption(form.values.type, batteryOptions)?.metadata
        ?.incompatible
    ) {
      const firstCompatibleBattery = batteryOptions.find(
        (battery) => !battery.metadata?.incompatible,
      )
      form.setFieldValue(
        "type",
        firstCompatibleBattery?.value ??
          getDefaultBatteryInstallation(inverterType, batteryStats).type,
      )
    }

    const selectedBatteryInfo = getBattery(form.values.type)
    if (!selectedBatteryInfo.mountingTypes.includes(form.values.mountingType)) {
      form.setFieldValue("mountingType", selectedBatteryInfo.mountingTypes[0])
    }
  }, [form, batteryOptions, inverterType, batteryStats])

  useEffect(() => {
    if (!isFormValid(form)) {
      setError("battery installation is invalid")
    } else {
      clearError()
    }
  })

  return (
    <>
      <Section title="Batterie" Actions={DeleteButton}>
        <SimpleGrid cols={2} spacing={32}>
          <Stack>
            {inverterInfo && (
              <InfoBadge>
                {inverterInfo.isCentralInverter
                  ? "Onduleur central"
                  : "Micro-onduleurs"}{" "}
                : {inverterInfo.name}
              </InfoBadge>
            )}
            <BatterySelector
              selectedBatteryType={form.values.type}
              batteryOptions={batteryOptions}
              onChange={(batteryType) =>
                form.setFieldValue("type", batteryType)
              }
            />
          </Stack>
        </SimpleGrid>
      </Section>
      <SettingsSection form={form} batteryOptions={batteryOptions} />
    </>
  )
}
